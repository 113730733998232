import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import CarouselImages from './Fragment/CarouselImages';
import NoImages from './Fragment/NoImages';
import Thumbnails from './Fragment/Thumbnails';
import classes from './styles/Gallery.module.scss';
import MediaMainContainer from 'components/Page/Property/Part/Gallery/Fragment/MediaMainContainer';

const Gallery = ({
    hasImages,
    slidesImages,
    mainImageUrl,
    thumbnails,
    activeThumbnailIndex,
    areThumbnailsVisible,
    onClickPrevious,
    onClickNext,
    onThumbnailClick,
    className,
}) => {
    let cssClass = joinNotEmpty([classes.gallery, className], ' ');

    return (
        <div className={cssClass}>
            {hasImages ? (
                <React.Fragment>
                    <CarouselImages slidesImages={slidesImages} />
                    <MediaMainContainer
                        thumbnail={thumbnails[activeThumbnailIndex]}
                        mainImageUrl={mainImageUrl}
                        withArrows={areThumbnailsVisible}
                        onClickPrevious={onClickPrevious}
                        onClickNext={onClickNext}
                    />
                    <Thumbnails
                        areVisible={areThumbnailsVisible}
                        images={thumbnails}
                        activeImageIndex={activeThumbnailIndex}
                        onThumbnailClick={onThumbnailClick}
                    />
                </React.Fragment>
            ) : (
                <NoImages />
            )}
        </div>
    );
};

Gallery.propTypes = {
    hasImages: PropTypes.bool.isRequired,
    slidesImages: PropTypes.arrayOf(PropTypes.element),
    mainImageUrl: PropTypes.string,
    thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            ref: PropTypes.object.isRequired,
        }),
    ),
    activeThumbnailIndex: PropTypes.number,
    areThumbnailsVisible: PropTypes.bool,
    onClickPrevious: PropTypes.func.isRequired,
    onClickNext: PropTypes.func.isRequired,
    onThumbnailClick: PropTypes.func,
    className: PropTypes.string,
};

Gallery.defaultProps = {
    slidesImages: [],
    mainImageUrl: '',
    thumbnails: [],
    activeThumbnailIndex: 0,
    areThumbnailsVisible: true,
    onThumbnailClick: () => true,
    className: '',
};

export default Gallery;
