import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { featuredPropertiesAction } from 'redux/actions/propertiesActions';
import slugify from 'slugify';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import routes from '../../../../../domain/routes';
import FeaturedProperties from './FeaturedProperties';
import FeaturedProperty from './Fragment/FeaturedProperty';
import './styles/FeaturedProperties.scss';

class FeaturedPropertiesContainer extends Component {
    componentDidMount() {
        this.props.getFeaturedProperties();
    }

    getFeaturedPropertiesBoxes(properties) {
        const getPropertyUrl = (property) => {
            const stateSlug = slugify(property.geo.state, { lower: true });
            return route(routes.properties.one, { state: stateSlug, slug: property.slug });
        };

        return properties.map((property, index) => {
            const url = getPropertyUrl(property);
            return (
                <FeaturedProperty
                    key={index}
                    images={property.gallery}
                    name={property.title}
                    url={url}
                    price={property.price.price}
                    monthly={property.price.monthly_payment}
                    location={{
                        address: property.geo.address,
                        county: property.geo.county,
                        state: property.geo.state,
                    }}
                    landSize={property.geo.acreage}
                />
            );
        });
    }

    render() {
        const { properties, areLoading } = this.props;

        if (areLoading) {
            return <AcreSpinner />;
        }

        const propertiesBoxes = this.getFeaturedPropertiesBoxes(properties);
        return <FeaturedProperties properties={propertiesBoxes} />;
    }
}

FeaturedPropertiesContainer.propTypes = {
    properties: PropTypes.array.isRequired,
    areLoading: PropTypes.bool.isRequired,
    getFeaturedProperties: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getFeaturedProperties: featuredPropertiesAction,
};

const mapStateToProps = (state) => {
    return {
        properties: state.featuredProperties.properties,
        areLoading: state.featuredProperties.areLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedPropertiesContainer);
