import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import Title from 'components/Common/Title';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Col, Form, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import ContentFields from './Part/ContentFields';
import FilesUploadFields from './Part/FilesUploadFields';
import FinancialFieldsContainer from './Part/FinancialFields';
import GeneralFields from './Part/GeneralFields';
import ImagesFields from './Part/ImagesFields';
import ImportantLinksFields from './Part/ImportantLinksFields';
import LocalizationFieldsContainer from './Part/LocalizationFields';
import SubdivisionFields from './Part/SubdivisionFields';
import UtilitiesFields from './Part/UtilitiesFields';
import VideosFields from './Part/VideosFields';
import styles from './styles/PropertyForm.module.scss';

const PropertyForm = ({ onSubmit, isSaving }) => {
    return (
        <div className={styles['form-wrapper']}>
            <Form onSubmit={onSubmit} className={styles['property-form']}>
                <Row form>
                    <Col md={9}>
                        <GeneralFields />
                        <FinancialFieldsContainer />
                        <ContentFields />
                        <LocalizationFieldsContainer />
                        <UtilitiesFields />
                        <SubdivisionFields />
                        <ImportantLinksFields />
                        <VideosFields />
                        <FilesUploadFields />
                        <ImagesFields />
                    </Col>
                    <Col md={3}>
                        <div className={styles['form-buttons']}>
                            <Title size={5} withoutMarginTop withoutMarginBottom>
                                Actions:
                            </Title>
                            <SpinnerButton isLoading={isSaving} color={'primary'} block>
                                Save
                            </SpinnerButton>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

PropertyForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
};

export default PropertyForm;
