import AcreSpinner from 'components/Common/AcreSpinner';
import PropertyFormContainer from 'components/Common/Panel/PropertyForm/PropertyFormContainer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { fetchPropertyAdminAction } from 'redux/actions/propertiesActions';
import routes from 'domain/routes';

class EditPropertyContainer extends Component {
    componentDidMount() {
        const params = this.getParams();

        this.props.fetchProperty(params.id);
        this.id = params.id;
    }

    getParams = () => {
        const match = matchPath(window.location.pathname, {
            path: routes.panel.properties.edit,
        });

        return match.params;
    };

    render() {
        return this.props.propertyIsLoading ? <AcreSpinner wrapped /> : <PropertyFormContainer id={this.id} />;
    }
}

const mapStateToProps = (state) => ({
    propertyIsLoading: state.propertyAdmin.isLoading,
});

const mapDispatchToProps = {
    fetchProperty: fetchPropertyAdminAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPropertyContainer);
