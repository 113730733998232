import Categories from 'components/Page/Property/Part/Categories';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import BuyNowBox from './Part/BuyNowBox';
import ContactBox from './Part/ContactBox';
import Description from './Part/Description';
import GalleryContainer from './Part/Gallery';
import InformationTable from './Part/InformationTable';
import Map from './Part/Map';
import Top from './Part/Top';
import classes from './styles/Property.module.scss';

const Property = ({ property, seller, onBuy }) => {
    return (
        <React.Fragment>
            <Row className={classes.property}>
                <Col xs={12}>
                    <Top
                        propertyId={property.id}
                        propertyName={property.name}
                        location={property.location}
                        isFavourite={property.isFavourite}
                        className={classes.top}
                    />
                </Col>
                <Col className={classes.left} sm={12} md={7} lg={8}>
                    <GalleryContainer
                        images={property.images || []}
                        videos={property.relatedVideos || []}
                        className={classes.gallery}
                    />
                    <BuyNowBox property={property} className={classes['buy-now-box-mobile']} onBuy={onBuy} />
                    <ContactBox property={property} seller={seller} className={classes['contact-box-mobile']} />
                    <Description description={property.description} className={classes.description} />
                    <Categories categories={property.categories} className={classes.categories} />
                    <InformationTable property={property} className={classes['information-table']} />
                    <Map
                        propertyName={property.name}
                        longitude={property.gpsLocation.longitude}
                        latitude={property.gpsLocation.latitude}
                        address={property.location.address}
                        className={classes.map}
                    />
                </Col>
                <Col className={classes.right} sm={12} md={5} lg={4}>
                    <BuyNowBox property={property} className={classes['buy-now-box']} onBuy={onBuy} />
                    <ContactBox property={property} seller={seller} className={classes['contact-box']} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

Property.propTypes = {
    property: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
            }),
        ),
        location: PropTypes.shape({
            address: PropTypes.string.isRequired,
            city: PropTypes.string,
            county: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            zip: PropTypes.string.isRequired,
        }).isRequired,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                thumbnailUrl: PropTypes.string.isRequired,
                fullSizeUrl: PropTypes.string.isRequired,
            }),
        ),
        description: PropTypes.string.isRequired,
        listingId: PropTypes.string.isRequired,
        financialTerm: PropTypes.string,
        finances: PropTypes.shape({
            price: PropTypes.number.isRequired,
            monthlyPayment: PropTypes.number.isRequired,
            processingFee: PropTypes.number.isRequired,
        }).isRequired,
        propertyState: PropTypes.string.isRequired,
        propertyCounty: PropTypes.string.isRequired,
        parcelNumber: PropTypes.string.isRequired,
        landSize: PropTypes.number.isRequired,
        gpsLocation: PropTypes.shape({
            longitude: PropTypes.number.isRequired,
            latitude: PropTypes.number.isRequired,
        }).isRequired,
        roadAccess: PropTypes.string.isRequired,
        utilities: PropTypes.string,
        zoning: PropTypes.string.isRequired,
        zoningDescription: PropTypes.string,
        taxes: PropTypes.number,
        subdivisionName: PropTypes.string,
        subdivisionYearlyDues: PropTypes.number,
        relatedLinks: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        relatedDocs: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        relatedVideos: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        isFavourite: PropTypes.bool,
    }).isRequired,
    seller: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        logoPath: PropTypes.string,
    }),
    onBuy: PropTypes.func.isRequired,
};

export default Property;
