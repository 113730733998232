import { createRoutine } from 'redux-saga-routines';
import { store } from 'redux/store';
import { IN_LEFT, IN_RIGHT } from 'utils/constans/orderingDirections';
import { arrayElementMove } from 'utils/functions/arrays';
import generateRandomId from 'utils/functions/generateRandomId';

const ADMIN_ARTICLE_FORM_EDITOR_CHANGE_ACTION_TYPE = 'ADMIN_ARTICLE_FORM_EDITOR_CHANGE_ACTION_TYPE';

const ADMIN_ARTICLES_FETCH_ACTION_TYPE = 'ADMIN_ARTICLES_FETCH_ACTION_TYPE';
const ADMIN_ARTICLE_NEW_ACTION_TYPE = 'ADMIN_ARTICLE_NEW_ACTION_TYPE';
const ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE = 'ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE';
const ADMIN_ARTICLE_DELETE_IMAGE_ACTION_TYPE = 'ADMIN_ARTICLE_DELETE_IMAGE_ACTION_TYPE';

const ADMIN_ARTICLE_CREATE_ACTION_TYPE = 'ADMIN_ARTICLE_CREATE_ACTION_TYPE';
const ADMIN_ARTICLE_READ_ACTION_TYPE = 'ADMIN_ARTICLE_READ_ACTION_TYPE';
const ADMIN_ARTICLE_UPDATE_ACTION_TYPE = 'ADMIN_ARTICLE_UPDATE_ACTION_TYPE';
const ADMIN_ARTICLE_DELETE_ACTION_TYPE = 'ADMIN_ARTICLE_DELETE_ACTION_TYPE';

const adminArticleFormEditorChangeAction = (editorState) => {
    return {
        type: ADMIN_ARTICLE_FORM_EDITOR_CHANGE_ACTION_TYPE,
        payload: {
            contentEditorState: editorState,
        },
    };
};

const adminArticleNewAction = () => {
    return {
        type: ADMIN_ARTICLE_NEW_ACTION_TYPE,
    };
};

const adminArticlesFetchAction = createRoutine(ADMIN_ARTICLES_FETCH_ACTION_TYPE);
const adminArticleCreateAction = createRoutine(ADMIN_ARTICLE_CREATE_ACTION_TYPE);
const adminArticleReadAction = createRoutine(ADMIN_ARTICLE_READ_ACTION_TYPE);
const adminArticleUpdateAction = createRoutine(ADMIN_ARTICLE_UPDATE_ACTION_TYPE);
const adminArticleDeleteAction = createRoutine(ADMIN_ARTICLE_DELETE_ACTION_TYPE);

const adminArticleAddImageAction = (image) => {
    return {
        type: ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE,
        payload: {
            image: image,
        },
    };
};

const convertAPIArticleImageToFileObjectAction = (image) => {
    const fileUrl = URL.createObjectURL(image);
    const id = generateRandomId();

    Object.assign(image, {
        id: id,
        thumbnailUrl: fileUrl,
        fullSizeUrl: fileUrl,
        isNew: true,
    });

    return {
        type: ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE,
        payload: {
            image: image,
        },
    };
};

const REORDER_ADMIN_ARTICLE_IMAGES_ACTION = 'REORDER_ADMIN_ARTICLE_IMAGES_ACTION';
const adminArticleReorderImagesAction = (id, direction) => {
    const images = store.getState().adminArticleNewRead.images;

    for (let index = 0; index < images.length; index++) {
        const image = images[index];

        if (image.id === id) {
            if (direction === IN_LEFT && index > 0) {
                arrayElementMove(images, index, index + IN_LEFT);
            } else if (direction === IN_RIGHT && index < images.length - 1) {
                arrayElementMove(images, index, index + IN_RIGHT);
            }

            break;
        }
    }

    return {
        type: REORDER_ADMIN_ARTICLE_IMAGES_ACTION,
        payload: [...images],
    };
};

const SET_REORDERED_ADMIN_ARTICLE_IMAGES_ACTION = 'SET_REORDERED_ADMIN_ARTICLE_IMAGES_ACTION';
const adminSetArticleReorderedImagesAction = (images) => {
    return {
        type: SET_REORDERED_ADMIN_ARTICLE_IMAGES_ACTION,
        payload: [...images],
    };
};

const adminArticleDeleteImageAction = createRoutine(ADMIN_ARTICLE_DELETE_IMAGE_ACTION_TYPE);

export {
    ADMIN_ARTICLE_FORM_EDITOR_CHANGE_ACTION_TYPE,
    ADMIN_ARTICLE_NEW_ACTION_TYPE,
    ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE,
    REORDER_ADMIN_ARTICLE_IMAGES_ACTION,
    SET_REORDERED_ADMIN_ARTICLE_IMAGES_ACTION,
    adminArticleReorderImagesAction,
    adminSetArticleReorderedImagesAction,
    adminArticleFormEditorChangeAction,
    adminArticlesFetchAction,
    adminArticleNewAction,
    adminArticleCreateAction,
    adminArticleReadAction,
    adminArticleUpdateAction,
    adminArticleDeleteAction,
    adminArticleAddImageAction,
    adminArticleDeleteImageAction,
    convertAPIArticleImageToFileObjectAction,
};
