import ACREAPI from 'utils/services/ACREAPI';

const ENDPOINT_URL_PREFIX = 'auth/';

export default class Auth {
    static requestGuest = (endpointSuffix, type, data = {}) => {
        switch (type) {
            case 'get':
                return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + endpointSuffix, {
                    params: data,
                });
            default:
                return ACREAPI.guestInstance().post(ENDPOINT_URL_PREFIX + endpointSuffix, data);
        }
    };

    static login = ({ login, password, recaptcha }) => {
        return Auth.requestGuest('login', 'post', {
            email: login,
            password: password,
            recaptcha: recaptcha,
        });
    };

    static register = (data, forceAdd = false) => {
        if (forceAdd) {
            return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + 'register', {
                email: data.email,
                fname: data.firstName,
                lname: data.lastName,
                password: 'TempPassword',
                password_confirmation: 'TempPassword',
            });
        }
        return Auth.requestGuest('register', 'post', {
            email: data.email,
            password: data.password,
            password_confirmation: data.passwordConfirmation,
            fname: data.firstName,
            lname: data.lastName,
            recaptcha: data.recaptcha,
        });
    };

    static confirmAccount = (confirmationToken) => {
        return Auth.requestGuest('confirm', 'get', { token: confirmationToken });
    };

    static refreshToken = () => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + 'refresh-token');
    };

    static forgottenPasswordSendEmail = ({ email, recaptcha }) => {
        return ACREAPI.guestInstance().post(ENDPOINT_URL_PREFIX + 'forgot', {
            email: email,
            recaptcha: recaptcha,
        });
    };

    static updatePassword = ({ token, password, passwordConfirmation }) => {
        return ACREAPI.guestInstance().post(ENDPOINT_URL_PREFIX + 'forgot-password', {
            token: token,
            password: password,
            password_confirmation: passwordConfirmation,
        });
    };

    static loginBySocialMedia = ({ socialId, firstName, lastName, accessToken, email, type }) => {
        return ACREAPI.guestInstance().post(ENDPOINT_URL_PREFIX + 'login-socialmedia', {
            socialId,
            firstName,
            lastName,
            accessToken,
            email,
            type,
        });
    };
}
