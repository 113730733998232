import { isEmptyString } from 'utils/functions/miscellaneous';
import { getRandomNumber } from './numbers';

export const getRandomItem = (array) => {
    const keys = Object.keys(array);
    const keyIndex = getRandomNumber(keys.length);
    const randomKey = keys[keyIndex];

    return array[randomKey];
};

export const isExistingIndex = (array, index) => {
    return index !== undefined && index !== null && array[index] !== undefined;
};

export const getPreviousIndex = (array, currentIndex) => {
    // It's not an array or it's an empty array?
    if (!Array.isArray(array) || isEmptyArray(array)) {
        return undefined;
    }

    // The current index is invalid?
    // Let's reset the current index to 1st element
    if (!isExistingIndex(array, currentIndex)) {
        currentIndex = 0;
    }

    const maximum = array.length - 1;
    const result = currentIndex - 1;

    return result < 0 ? maximum : result;
};

export const getNextIndex = (array, currentIndex) => {
    // It's not an array or it's an empty array?
    if (!Array.isArray(array) || isEmptyArray(array)) {
        return undefined;
    }

    // The current index is invalid?
    // Let's reset the current index to 1st element
    if (!isExistingIndex(array, currentIndex)) {
        currentIndex = 0;
    }

    const maximum = array.length - 1;
    const result = currentIndex + 1;

    return result > maximum ? 0 : result;
};

export const isEmptyArray = (array) => {
    return Array.isArray(array) && array.length === 0;
};

export const isNullOrEmptyArray = (array) => {
    return array === null || array === undefined || isEmptyArray(array);
};

export const joinNotEmpty = (values, separator = '') => {
    if (!Array.isArray(values) || isNullOrEmptyArray(values)) {
        return '';
    }

    const notEmptyValues = values.filter((value) => !isEmptyString(value));
    return notEmptyValues.join(separator);
};

export const arrayElementMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
};
