import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const mapStatusToName = (status) => {
    switch (status) {
        case 1:
            return 'INCOMPLETE';
        case 2:
            return 'AVAILABLE';
        case 3:
            return 'LISTED';
        case 4:
            return 'PENDING';
        case 5:
            return 'SOLD';
        case 6:
            return 'UNAVAILABLE';
        default:
            throw new Error(`Status ${status} doesn't exist`);
    }
};

const StatisticsModal = ({ isOpen, toggle, stats }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Property statistics</ModalHeader>
            <ModalBody>
                <div>Views: {stats.views}</div>
                <div>Phone inquiries: {stats.phoneInquiries}</div>
                {stats.statusChanges.length ? (
                    <div>
                        Status changes:{' '}
                        <ul>
                            {stats.statusChanges.map((change, index) => (
                                <li key={index}>
                                    {change.changedDate}: {mapStatusToName(change.listingStatus)}
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    ``
                )}
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

StatisticsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    stats: PropTypes.object.isRequired,
};

export default StatisticsModal;
