import AcreSpinner from 'components/Common/AcreSpinner';
import SellerConverter from 'domain/api2domain/SellerConverter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSellerForPanelAction, updateSellerAction } from 'redux/actions/sellersActions';
import { handleInput } from 'utils/functions/inputHelpers';
import { Lodash, PropTypes } from 'utils/wrappers';
import SellerForm from './SellerForm';

class SellerFormContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            image: '',
            actuallyLogoUri: '',
            title: '',
            address: '',
            telephones: [''],
            emails: [''],
        };
    }

    componentDidMount() {
        this.props.fetchSellerAccount(this.props.user.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.sellerIsLoading !== this.props.sellerIsLoading &&
            !this.props.sellerIsLoading &&
            this.props.seller
        ) {
            const newSellerState = SellerConverter.toFrontend(this.props.seller);

            this.setState({ ...newSellerState });
        }
    }

    handleArrayInput = (e, index) => {
        const elements = this.state[e.target.name];
        elements[index] = e.target.value;

        this.setState({ [e.target.name]: [...elements] });
    };

    removeItem = (type, index) => {
        const newArray = Lodash.cloneDeep(this.state[type]);
        newArray.splice(index, 1);
        this.setState({
            [type]: newArray,
        });
    };

    addInput = (type) => {
        const newArray = Lodash.cloneDeep(this.state[type]);
        newArray.push('');

        this.setState({
            [type]: [...newArray],
        });
    };

    handleLogoChange = (e) => {
        this.setState({
            actuallyLogoUri: window.URL.createObjectURL(e.target.files[0]),
            image: e.target.files[0],
        });
    };

    onSubmit = (e) => {
        e.preventDefault();

        const data = SellerConverter.toApi(this.state);

        this.props.updateSeller({ id: this.props.user.id, data });
    };

    render() {
        const { title, address, image, telephones, emails, actuallyLogoUri } = this.state;
        const { sellerIsLoading } = this.props;

        return sellerIsLoading ? (
            <AcreSpinner wrapped />
        ) : (
            <SellerForm
                handleInput={this.handleInput}
                onSubmit={this.onSubmit}
                handleArrayInput={this.handleArrayInput}
                handleLogoChange={this.handleLogoChange}
                addInput={this.addInput}
                removeItem={this.removeItem}
                title={title}
                address={address}
                image={image}
                telephones={telephones}
                emails={emails}
                actuallyLogoUri={actuallyLogoUri}
            />
        );
    }
}

SellerFormContainer.propTypes = {
    updateSeller: PropTypes.func.isRequired,
    fetchSellerAccount: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    sellerIsLoading: PropTypes.bool.isRequired,
    // this is null until fetchSellerAccount() is called
    seller: PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        head: PropTypes.string,
        title: PropTypes.string,
        emails: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                email: PropTypes.string,
            }),
        ),
        telephones: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                number: PropTypes.string,
                type: PropTypes.string,
            }),
        ),
    }),
};

const mapDispatchToProps = {
    updateSeller: updateSellerAction,
    fetchSellerAccount: fetchSellerForPanelAction,
};

const mapStateToProps = (state) => ({
    user: state.user,
    sellerIsLoading: state.panelSeller.isLoading,
    seller: state.panelSeller.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(SellerFormContainer);
