import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const AnswerDeleteModal = ({ isOpen, toggle, onYes }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Delete property</ModalHeader>
            <ModalBody>Are you sure that you want delete this property?</ModalBody>
            <ModalFooter>
                <Button color='danger' onClick={toggle}>
                    No
                </Button>
                <Button color='success' onClick={onYes}>
                    Yes
                </Button>
            </ModalFooter>
        </Modal>
    );
};

AnswerDeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onYes: PropTypes.func.isRequired,
};

export default AnswerDeleteModal;
