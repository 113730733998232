import { toggleIsFavouritePropertyAction } from 'redux/actions/propertiesActions';

const defaultState = {
    isToggling: {}, // Key-value pairs, where: key - property ID, value - information if is toggling
};

export default (state = defaultState, action) => {
    const propertyId = action.payload;

    switch (action.type) {
        case toggleIsFavouritePropertyAction.REQUEST:
            state.isToggling[propertyId] = true;

            return {
                ...state,
            };
        case toggleIsFavouritePropertyAction.SUCCESS:
            return {
                ...state,
            };
        case toggleIsFavouritePropertyAction.FULFILL:
            state.isToggling[propertyId] = false;

            return {
                ...state,
            };
        default:
            return {
                ...state,
            };
    }
};
