import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchAllStatesAction } from 'redux/actions/statesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* allStatesSaga(action) {
    try {
        yield put(fetchAllStatesAction.request());

        const response = yield call(ACREAPI.properties().states, action.payload);

        yield put(fetchAllStatesAction.success(response.data));
    } catch (e) {
        notifiFail({
            message: 'Loading of states has failed',
        });

        yield put(fetchAllStatesAction.failure());
    } finally {
        yield put(fetchAllStatesAction.fulfill());
    }
}

export default function* allStatesSagaWatcher() {
    yield takeLatest(fetchAllStatesAction.trigger, allStatesSaga);
}
