import noImage from 'common/images/no-image.svg';
import ArticleDate from 'components/Common/ArticleDate/ArticleDate';
import ArticleConverter from 'domain/api2domain/ArticleConverter';
import routes from 'domain/routes';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { setSlide } from 'redux/actions/blogSliderAction';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import './Slider.scss';

const Slider = ({ featuredArticles, activeFeaturedArticle, setSlide }) => {
    const article = ArticleConverter.toDomain(activeFeaturedArticle);

    if (article === null) {
        return null;
    }

    const articleTitle = article.getTitle();
    const mainImage = article.getMainImage();

    const isImageAvailable = mainImage !== null;
    const imagePath = isImageAvailable ? mainImage.getThumbnailUrl() : noImage;

    return featuredArticles && activeFeaturedArticle ? (
        <Container className={'slider-wrapper'}>
            <Row>
                <Col className={'col-lg-8'}>
                    <Row>
                        <Col className={'active-image'}>
                            <img src={imagePath} alt={isImageAvailable ? articleTitle : null} />
                            <div className={'cover'}>
                                <div className={'foot'}>
                                    <div>{articleTitle}</div>
                                    <Button
                                        color={'primary'}
                                        tag={Link}
                                        to={route(routes.blog.article, { articleSlug: activeFeaturedArticle.slug })}
                                    >
                                        Read More
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={'d-display d-lg-none'}>
                        <Col className={'active-image-decoration'}>
                            <span>▲</span>
                        </Col>
                    </Row>
                    <Row className={'d-display d-lg-none'}>
                        <Col className={'active-image-info'}>
                            {/*<div className={'category'}>{article.category.name}</div>*/}
                            <div className={'title'}>{articleTitle}</div>
                            <ArticleDate date={activeFeaturedArticle.updated_at} />
                        </Col>
                    </Row>
                </Col>
                <Col className={'d-none d-lg-block other-articles col-lg-3'}>
                    {featuredArticles.map((article, index) => (
                        <div
                            key={index}
                            className={'other-article' + (activeFeaturedArticle.id === article.id ? ' active' : '')}
                        >
                            {/*<div className={'category'}>{article.category.name}</div>*/}
                            <div className={'title'} onClick={() => setSlide(index)}>
                                {article.title}
                            </div>
                            <ArticleDate onClick={() => setSlide(index)} date={article.updated_at} />
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    ) : null;
};

Slider.propTypes = {
    activeFeaturedArticle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                fullsize: PropTypes.string.isRequired,
            }),
        ).isRequired,
        updated_at: PropTypes.string.isRequired,
    }).isRequired,
    featuredArticles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            updated_at: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

const mapDispatchToProps = {
    setSlide: setSlide,
};

export default connect(null, mapDispatchToProps)(Slider);
