import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import sorting from 'data/properties/sorting';
import { call, put, takeLatest } from 'redux-saga/effects';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import { store } from 'redux/store';
import ACREAPI from 'utils/services/ACREAPI';

function* searchedPropertiesSaga() {
    try {
        yield put(searchedPropertiesAction.request());

        const storePropertiesFilters = store.getState().propertiesSearchParams;

        // if sorting is default value change to empty string - backend don't has 'default' sorting
        const sortingParam = storePropertiesFilters.sorting === sorting[0].value ? '' : storePropertiesFilters.sorting;

        const response = yield call(
            ACREAPI.properties().searched,
            {
                ...storePropertiesFilters.appliedFilters,
            },
            sortingParam,
            storePropertiesFilters.search,
            storePropertiesFilters.activePage,
        );

        yield put(searchedPropertiesAction.success(response.data.listings));
    } catch (error) {
        notifiFail({
            message: 'Loading of properties has failed',
            duration: 3000,
        });

        yield put(searchedPropertiesAction.failure());
    } finally {
        yield put(searchedPropertiesAction.fulfill());
    }
}

export default function* searchedPropertiesSagaWatcher() {
    yield takeLatest(searchedPropertiesAction.TRIGGER, searchedPropertiesSaga);
}
