import { ALIGN_CENTER, ALIGN_LEFT, ALIGN_RIGHT } from 'domain/constants/alignment';
import React from 'react';
import { PropTypes } from 'utils/wrappers';
import classes from './Title.module.scss';

const Title = ({
    size,
    className,
    children,
    alignment,
    withoutMarginTop,
    underline,
    afterTitle,
    withoutMarginBottom,
}) => {
    const TagName = `h${size}`;
    let cssClass = classes.title;

    if (className) {
        cssClass += ' ' + className;
    }

    if (alignment) {
        cssClass += ' ' + classes['align-' + alignment];
    }

    if (withoutMarginTop) {
        cssClass += ' ' + classes['without-margin-top'];
    }

    if (withoutMarginBottom) {
        cssClass += ' ' + classes['without-margin-bottom'];
    }

    if (underline) {
        cssClass += ' ' + classes.underline;
    }

    return (
        <TagName className={cssClass}>
            {children}
            {afterTitle ? afterTitle : null}
        </TagName>
    );
};

Title.propTypes = {
    size: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
    alignment: PropTypes.oneOf([ALIGN_LEFT, ALIGN_RIGHT, ALIGN_CENTER]),
    withoutMarginTop: PropTypes.bool,
    withoutMarginBottom: PropTypes.bool,
    underline: PropTypes.bool,
    afterTitle: PropTypes.element,
};

Title.defaultProps = {
    alignment: ALIGN_CENTER,
    withoutMarginTop: false,
    withoutMarginBottom: false,
    underline: false,
};

export default Title;
