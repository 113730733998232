import CarouselNavigation from 'components/Common/Carousel/Navigation';
import PaginationContainer from 'components/Common/Pagination';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { setToNextSlide, setToPreviousSlide } from 'redux/actions/blogSliderAction';
import { PropTypes } from 'utils/wrappers';
import './Blog.scss';
import ArticlesContainer from './Part/Articles/ArticlesContainer';
import SliderContainer from './Part/Slider';

const Blog = ({ activePage, onPageChange, pagination, setToPreviousSlide, setToNextSlide }) => {
    return (
        <div className={'blog-wrapper'}>
            <Container className={'slider-part'}>
                <Row>
                    <Col>
                        <div className={'acre-header'}>
                            <h4>Blog</h4>
                            <p>{pagination.total ? pagination.total : 0} articles</p>
                        </div>
                    </Col>
                </Row>
                <SliderContainer />
                <Row className='d-lg-none'>
                    <Col>
                        <CarouselNavigation
                            className={'d-display d-lg-none'}
                            onClickLeft={setToPreviousSlide}
                            onClickRight={setToNextSlide}
                        />
                    </Col>
                </Row>
            </Container>
            <div className={'second-part'}>
                <Container>
                    <Row className={'justify-content-center'}>
                        <ArticlesContainer activePage={activePage} />
                    </Row>
                    <Row className={'justify-content-center'}>
                        <Col xs={{ size: 'auto' }}>
                            {pagination.total ? (
                                <PaginationContainer
                                    activePage={activePage}
                                    itemsPerPage={8}
                                    onPageChange={onPageChange}
                                    totalItemsNum={pagination.total}
                                />
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

Blog.propTypes = {
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
    }).isRequired,
    activePage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setToPreviousSlide: setToPreviousSlide,
    setToNextSlide: setToNextSlide,
};

export default connect(null, mapDispatchToProps)(Blog);
