import axios from 'axios';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import draftToHtml from 'draftjs-to-html';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { isNullOrEmptyObject } from 'utils/functions/objects';
import Article from '../blog/Article';
import ArticleImage from '../blog/ArticleImage';

class ArticleConverter {
    static toDomain(source): ?Article {
        if (isNullOrEmptyObject(source)) {
            return null;
        }

        const content = ArticleConverter.prepareContent(source);
        const images = ArticleConverter.prepareImages(source);

        return new Article(
            source.id,
            source.title,
            source.slug,
            content,
            source.description,
            Boolean(source.allow_comments),
            Boolean(source.allow_somethings),
            images,
        );
    }

    static prepareContent(source): ?String {
        if (source.content === undefined) {
            return undefined;
        }

        let result = '';

        try {
            let parsed = JSON.parse(source.content);
            result = draftToHtml(parsed);
        } catch (error) {
            result = source.content;
        }

        return result;
    }

    static prepareImagesToEdit = (images, addImageFunction) => {
        images.forEach((image) => {
            let blob = null;
            axios
                .get(image.fullSizeUrl, {
                    responseType: 'blob',
                })
                .then((res) => {
                    blob = res.data;
                    const imageFile = new File([blob], image.name);

                    addImageFunction(imageFile);
                })
                .catch(() => {
                    notifiFail({
                        message: "Loading of article's images has been failed",
                    });
                });
        });
    };

    static prepareImages(source): ?Array {
        if (isNullOrEmptyArray(source.gallery)) {
            return [];
        }

        const images = source.gallery.filter((image) => {
            return !(
                image.id === null ||
                image.id === undefined ||
                image.preview === null ||
                image.preview === undefined ||
                image.fullsize === null ||
                image.fullsize === undefined
            );
        });

        if (isNullOrEmptyArray(images)) {
            return [];
        }

        return images.map((image) => {
            return new ArticleImage(image.id, image.preview, image.fullsize);
        });
    }
}

export default ArticleConverter;
