import { joinNotEmpty } from 'utils/functions/arrays';

class User {
    constructor(id: Number, firstName: String, lastName: String, token: String) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.token = token;
    }

    getId() {
        return this.id;
    }

    getFirstName() {
        return this.firstName;
    }

    getLastName() {
        return this.lastName;
    }

    getToken() {
        return this.token;
    }

    toString() {
        if (this.firstName === undefined || this.lastName === undefined) {
            return null;
        }

        return joinNotEmpty([this.firstName, this.lastName], ' ');
    }
}

export default User;
