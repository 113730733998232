import { addParamAndPush, deleteParamAndPush } from 'utils/history';

export const modalDefaultState = {
    isOpen: false,
};

/**
 * Generate URI param for modal name
 *
 * @param actionTypeMainPart
 * @returns {string}
 */
export const generateModalUriParam = (actionTypeMainPart) => {
    let nameParts = actionTypeMainPart.split('_');
    nameParts = nameParts.map((item) => {
        item = item.toLowerCase();
        return item.charAt(0).toUpperCase() + item.substr(1);
    });

    const finalNamePrefix = nameParts.join('');

    return finalNamePrefix + 'Modal';
};

/**
 * Generate action name
 *
 * @param action
 * @param actionTypeMainPart
 * @returns {string}
 */
export const generateModalActionType = (action, actionTypeMainPart) => {
    const actions = {
        hide: 'HIDE_' + actionTypeMainPart.toUpperCase() + '_MODAL',
        show: 'SHOW_' + actionTypeMainPart.toUpperCase() + '_MODAL',
    };

    return actions[action];
};

/**
 * Create reducer for modal
 *
 * @param name
 * @param defaultState
 * @returns {Function}
 */
export const modalReducerFactory = (name, defaultState = modalDefaultState) => {
    return (state = defaultState, action) => {
        switch (action.type) {
            case generateModalActionType('show', name):
                return {
                    isOpen: true,
                };
            case generateModalActionType('hide', name):
                return {
                    isOpen: false,
                };
            default:
                return state;
        }
    };
};

/**
 * Create action for modal
 *
 * @param action
 * @param actionTypeMainPart
 * @returns {Function}
 */
export const modalActionFactory = (action, actionTypeMainPart) => {
    const uriParam = generateModalUriParam(actionTypeMainPart);
    const actionType = generateModalActionType(action, actionTypeMainPart);

    const actions = {
        hide: () => {
            deleteParamAndPush(uriParam);

            return { type: actionType };
        },
        show: () => {
            addParamAndPush({ [uriParam]: 'show' });

            return { type: actionType };
        },
    };

    return actions[action];
};
