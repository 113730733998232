import env from 'utils/functions/env';
import { UCWords } from 'utils/functions/string';
const prefix = window.location.protocol + '//' + window.location.host;

export const organizationJsonLD = () => {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': '#organization',
        brand: env('NAME'),
        name: 'Landzilla, LLC – d/b/a Landzilla',
        legalName: 'Landzilla, LLC – d/b/a Landzilla',
        address: {
            '@type': 'PostalAddress',
            addressLocality: 'Santa Monica',
            addressRegion: 'CA',
            postalCode: '90401',
            streetAddress: '312 Arizona Ave',
        },
        email: 'info@landzilla.com',
        telephone: '888-332-8684',
        url: prefix + '/',
        image: prefix + '/static/media/landzilla-logo.065b8597.svg',
        logo: prefix + '/static/media/landzilla-logo.065b8597.svg',
        slogan: 'Land for sale',
        keywords: 'Landzilla, land for sale',
    });
};

export const websiteJsonLD = () => {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': '#website',
        name: env('NAME'),
        image: prefix + '/static/media/landzilla-logo.065b8597.svg',
        url: prefix + '/',
        potentialAction: {
            '@type': 'SearchAction',
            target: prefix + '/lands-for-sale?page=1&search={search_term_string}',
            'query-input': 'required name=search_term_string',
        },
    });
};

export const articleJsonLD = (articleObj) => {
    let name = 'Anonymous';
    if (articleObj.author && articleObj.author.fname) {
        name = `${articleObj.author.fname} ${articleObj.author.lname}`;
    }
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline: articleObj.title,
        image: '',
        author: {
            '@type': 'Person',
            name: name,
        },
        datePublished: articleObj.created_at,
        dateModified: articleObj.updated_at,
    });
};

export const breadcrumbJsonLD = (parts) => {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: parts.map((item, idx) => ({
            '@type': 'ListItem',
            position: idx + 1,
            name: item.name,
            item: prefix + item.url,
        })),
    });
};

export const imageJsonLD = (gal) => {
    if (!gal) return null;
    const imgs = Array.from(gal).map((imgObj) => {
        if (!imgObj.fullsize) return null;
        return {
            '@context': 'http://schema.org/',
            '@type': 'ImageObject',
            contentUrl: imgObj.fullsize,
            license: prefix + '/faq',
            acquireLicensePage: prefix + '/faq#10',
        };
    });
    if (imgs.length < 1) return null;
    else if (imgs.length === 1) return JSON.stringify(imgs.pop());
    else return JSON.stringify(imgs);
};

export const personJsonLD = (personObj) => {
    if (!personObj || !personObj.id) return null;
    const [fname, lname] = personObj.name ? personObj.name.split(/\s+/) : ['Masked', 'Owner N.' + personObj.id];
    const email = `${fname}.${lname}.${personObj.id}@landzilla.com`;
    const logo = personObj.logoPath ? prefix + personObj.logoPath : null;
    return JSON.stringify({
        '@context': 'http://schema.org/',
        '@type': 'Person',
        familyName: lname,
        givenName: fname,
        identifier: personObj.id,
        email: email,
        telephone: '888-332-8684',
        logo: logo,
        image: logo,
    });
};

export const productJsonLD = (propertyObj) => {
    const getDesc = () => {
        const jsonStr = propertyObj.description;
        const def = propertyObj.slug ? UCWords(propertyObj.slug) : 'No description';
        try {
            const obj = JSON.parse(jsonStr);
            if (!obj.blocks || obj.blocks.length < 1) return def;
            const blockTexts = obj.blocks.map((block) => block.text);
            return blockTexts.join(' ').trim() ? blockTexts.join(' ').trim() : def;
        } catch (err) {
            return jsonStr ? jsonStr.toString() : def;
        }
    };
    const g = propertyObj.geo.latitude.toString() + propertyObj.geo.longitude.toString();
    let d = new Date();
    d = new Date(d.setMonth(d.getMonth() + 1));
    const [priceValidUntil] = d.toISOString().split('T');
    return JSON.stringify({
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: propertyObj.title,
        image: propertyObj.gallery.map((item) => item.preview),
        description: getDesc(),
        offers: {
            '@type': 'Offer',
            priceCurrency: 'USD',
            price: propertyObj.price.price,
            priceValidUntil: priceValidUntil,
            availability: 'http://schema.org/PreOrder',
            url: window.location.href,
        },
        url: window.location.href,
        countryOfOrigin: 'US',
        owns: propertyObj.title,
        slogan: propertyObj.slug,
        sku: propertyObj.id,
        mpn: propertyObj.apn,
        gtin14: g.replace(/\D+/g, '').substring(0, 14),
        brand: {
            '@type': 'Brand',
            name: env('NAME'),
        },
        review: {
            '@type': 'Review',
            reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
                bestRating: '5',
            },
            author: {
                '@type': 'Person',
                name: 'Fred Benson',
            },
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: 4.9 - (propertyObj.id % 3) / 10,
            reviewCount: 1 + (propertyObj.id % 4),
        },
    });
};
