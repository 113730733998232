import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editUserAction } from 'redux/actions/usersActions';
import { STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { handleInput } from 'utils/functions/inputHelpers';
import { isEmptyString } from 'utils/functions/miscellaneous';
import { Lodash, PropTypes } from 'utils/wrappers';
import UserEditingForm from './UserEditingForm';

class UserEditingFormContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            address: '',
            addressSecond: '',
            city: '',
            state: '',
            zip: '',
            phoneNumber: '',
            faxNumber: '',
            tollFreeNumber: '',
        };
    }

    getNumberFromArray(telephones, type) {
        const data = telephones.filter((ele) => ele.type === type)[0];

        return data ? data.number : '';
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.editedUser.data && prevProps.editedUser.data !== this.props.editedUser.data) {
            const { data } = this.props.editedUser;

            if (!data.address) {
                data.address = {};
            }

            this.setState({
                firstName: data.fname || '',
                lastName: data.lname || '',
                email: data.email,
                address: data.address.address_first || '',
                addressSecond: data.address.address_second || '',
                city: data.address.city || '',
                state: data.address.state || '',
                zip: data.address.zip || '',
                phoneNumber: this.getNumberFromArray(data.telephones, 'phone'),
                faxNumber: this.getNumberFromArray(data.telephones, 'fax'),
                tollFreeNumber: this.getNumberFromArray(data.telephones, 'toll_free'),
            });
        }

        if (
            prevProps.requestEditUser !== this.props.requestEditUser &&
            prevProps.requestEditUser.isSending &&
            this.props.requestEditUser.status === STATUS_SUCCESS
        ) {
            this.props.hideEditingModal();
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        const data = Lodash.cloneDeep(this.state);

        if (isEmptyString(data.password)) {
            delete data.password;
            delete data.passwordConfirmation;
        }

        if (data.email === this.props.editedUser.data.email) {
            delete data.email;
        }

        this.props.editUser({
            ...data,
            id: this.props.editingUserId,
        });
    };

    render() {
        return <UserEditingForm handleInput={this.handleInput} onSubmit={this.onSubmit} {...this.state} />;
    }
}

UserEditingFormContainer.propTypes = {
    editingUserId: PropTypes.number,
    editUser: PropTypes.func.isRequired,
    hideEditingModal: PropTypes.func.isRequired,
    editedUser: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            fname: PropTypes.string,
            lname: PropTypes.string,
            email: PropTypes.string,
            telephones: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string,
                    number: PropTypes.string,
                }),
            ),
            address: PropTypes.shape({
                address_first: PropTypes.string,
                address_second: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                zip: PropTypes.string,
            }),
        }).isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    editedUser: state.editedUser,
    requestEditUser: state.requestEditUser,
});

const mapDispatchToProps = {
    editUser: editUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditingFormContainer);
