import React, { useEffect, useState } from 'react';
import ACREAPI from 'utils/services/ACREAPI';
import AcreSpinner from 'components/Common/AcreSpinner';

const modifyHtml = (html) => {
    const doc = document.createElement('html');
    const script = document.createElement('script');
    // we have to send the information to the parent which log file we want to load when we click it
    script.innerHTML = `
         function goToParent(href){
            window.parent.document.changeIframeUrl(href);
         }
    `;

    doc.innerHTML = html;

    doc.querySelector('head').appendChild(script);

    doc.querySelectorAll('.sidebar a').forEach((el) => {
        el.href = `javascript:goToParent('${el.href}');`;
    });

    return doc.innerHTML;
};

const Logs = () => {
    const [response, setResponse] = useState(null);
    const [fetched, setFetched] = useState(false);
    const [apiQueryPart, setApiQueryPart] = useState('');

    useEffect(() => {
        document.changeIframeUrl = (href) => {
            const queryPart = href.split('?')[1];
            setApiQueryPart(`?${queryPart}`);
        };

        ACREAPI.logs().show(apiQueryPart, setResponse, setFetched);
    }, [apiQueryPart, fetched]);

    return (
        <React.Fragment>
            {fetched && response ? (
                <iframe
                    frameBorder='0'
                    style={{ overflow: 'hidden', height: '100vh', width: '100vw' }}
                    height='100%'
                    width='100%'
                    title='Logs'
                    srcDoc={modifyHtml(response)}
                />
            ) : (
                <AcreSpinner />
            )}
        </React.Fragment>
    );
};

export default Logs;
