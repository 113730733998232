import { FORM_FIELD_CHANGE_ACTION_TYPE } from 'redux/actions/formActions';

const defaultState = {};

const handleFormFieldChange = (state = defaultState, action) => {
    let result = {
        ...state,
    };

    if (action.type === FORM_FIELD_CHANGE_ACTION_TYPE) {
        const type = action.payload.type;
        const name = action.payload.name;
        let value = action.payload.value;

        if (type.toLowerCase() === 'checkbox') {
            value = !state[name];
        }

        result = {
            ...state,
            [name]: value,
        };
    }

    return result;
};

export { handleFormFieldChange };
