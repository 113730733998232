import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { newsletterSubscribeAction } from 'redux/actions/newsletterActions';
import ACREAPI from 'utils/services/ACREAPI';

function* subscribeNewsletterSaga(action) {
    try {
        yield put(newsletterSubscribeAction.request());
        const response = yield call(ACREAPI.newsletter().subscribe, action.payload);

        const message = response.data ? response.data.result : 'Thank you for subscription! Check your e-mail, please';

        notifiSuccess({
            message,
        });

        yield put(newsletterSubscribeAction.success());
    } catch (e) {
        const message =
            e.response && e.response.data
                ? e.response.data.errors
                    ? e.response.data.errors.email[0]
                    : e.response.data.result
                : 'Subscription error';

        notifiFail({
            message: message,
            duration: 5000,
        });

        yield put(newsletterSubscribeAction.failure());
    } finally {
        yield put(newsletterSubscribeAction.fulfill());
    }
}

export default function* subscribeNewsletterSagaWatcher() {
    yield takeLatest(newsletterSubscribeAction.trigger, subscribeNewsletterSaga);
}
