import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProfileAction, updateProfileAction } from 'redux/actions/userActions';
import { handleInput } from 'utils/functions/inputHelpers';
import { objectToFormData } from 'utils/functions/objectToFormData';
import { PropTypes } from 'utils/wrappers';
import isEmpty from 'validator/lib/isEmpty';
import Account from './Account';

class AccountContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            firstName: '',
            lastName: '',
            password: '',
            passwordConfirmation: '',
            email: '',
            phoneNumber: '',
            faxNumber: '',
            tollFreeNumber: '',
            address: '',
            addressSecond: '',
            city: '',
            state: '',
            zip: '',
            avatar: null,
            avatarBackendUri: '',
        };
    }

    componentDidMount() {
        this.props.fetchProfile();
    }

    getNumber = (type, arr) => {
        const element = arr.filter((ele) => {
            return ele.type === type;
        })[0];

        return element ? element.number : '';
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.profileData !== this.props.profileData) {
            const profile = this.props.profileData;

            if (!profile.address) {
                profile.address = {};
            }

            this.setState({
                firstName: profile.fname,
                lastName: profile.lname,
                email: profile.email,
                avatarBackendUri: profile.avatar ? profile.avatar.public_uri : null,
                phoneNumber: this.getNumber('phone', profile.telephones),
                faxNumber: this.getNumber('fax', profile.telephones),
                tollFreeNumber: this.getNumber('toll_free', profile.telephones),
                address: profile.address.address_first ? profile.address.address_first : '',
                addressSecond: profile.address.address_second ? profile.address.address_second : '',
                city: profile.address.city ? profile.address.city : '',
                zip: profile.address.zip ? profile.address.zip : '',
                state: profile.address.state ? profile.address.state : '',
            });
        }
    }

    handleAvatarChange = (e) => {
        this.setState({
            avatar: e.target.files[0],
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            fname: this.state.firstName,
            lname: this.state.lastName,
            telephones: {
                phone: this.state.phoneNumber,
                fax: this.state.faxNumber,
                toll_free: this.state.tollFreeNumber,
            },
            address: {
                address_first: this.state.address,
                address_second: this.state.addressSecond,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
            },
        };

        if (this.props.userEmail !== this.state.email) {
            data.email = this.state.email;
        }

        if (!isEmpty(this.state.password)) {
            data.password = this.state.password;
            data.password_confirmation = this.state.passwordConfirmation;
        }

        if (this.state.avatar) {
            data.avatar = this.state.avatar;
        }

        const formData = objectToFormData(data);

        this.props.updateProfile({ id: this.props.userId, data: formData });
    };

    render() {
        return (
            <Account
                handleInput={this.handleInput}
                handleSubmit={this.handleSubmit}
                handleAvatarChange={this.handleAvatarChange}
                data={this.state}
            />
        );
    }
}

AccountContainer.propTypes = {
    fetchProfile: PropTypes.func.isRequired,
    profileData: PropTypes.shape({
        address: PropTypes.shape({
            address_first: PropTypes.string,
            address_second: PropTypes.string,
            state: PropTypes.string,
            city: PropTypes.string,
            zip: PropTypes.string,
        }),
        avatar: PropTypes.shape({
            public_uri: PropTypes.string.isRequired,
        }),
        fname: PropTypes.string,
        lname: PropTypes.string,
        telephones: PropTypes.arrayOf(
            PropTypes.shape({
                number: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
            }),
        ),
        userId: PropTypes.number,
        userEmail: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = (state) => {
    return {
        profileData: state.profile.data,
        userId: state.user.id,
        userEmail: state.user.email,
    };
};

const mapDispatchToProps = {
    fetchProfile: fetchProfileAction,
    updateProfile: updateProfileAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
