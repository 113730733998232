import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deletePropertyAction } from 'redux/actions/propertiesActions';
import { STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { PropTypes } from 'utils/wrappers';
import AnswerDeleteModal from './AnswerDeleteModal';

class AnswerDeleteModalContainer extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { deletedProperty } = this.props;

        if (
            prevProps.deletedProperty !== deletedProperty &&
            prevProps.deletedProperty.isSending &&
            !deletedProperty.isSending &&
            deletedProperty.status === STATUS_SUCCESS
        ) {
            this.props.fetchProperties();
        }
    }

    onYes = (e) => {
        this.props.toggle();

        this.props.deleteProperty(this.props.propertyIdToDelete);
    };

    render() {
        const { isOpen, toggle } = this.props;

        return <AnswerDeleteModal isOpen={isOpen} toggle={toggle} onYes={this.onYes} />;
    }
}

AnswerDeleteModalContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    fetchProperties: PropTypes.func.isRequired,
    propertyIdToDelete: PropTypes.number,
};

const mapStateToProps = (state) => ({
    deletedProperty: state.deletedProperty,
});

const mapDispatchToProps = {
    deleteProperty: deletePropertyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerDeleteModalContainer);
