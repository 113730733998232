import React, { Component } from 'react';
import { PropTypes } from 'utils/wrappers';
import testimonials from './data/testimonials';
import TestimonialBox from './Fragment/TestimonialBox';
import Testimonials from './Testimonials';

const DEFAULT_MAXIMUM = 6;

class TestimonialsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 1,
            maximum: props.maximum || DEFAULT_MAXIMUM,
            testimonials: [],
        };
    }

    componentDidMount() {
        this.setState({
            testimonials: this.getTestimonialsBoxes(),
        });
    }

    getTestimonialsBoxes = () => {
        const testimonialsSliced = testimonials.slice(0, this.state.maximum);

        return testimonialsSliced.map((testimonial, index) => {
            return (
                <TestimonialBox
                    key={index}
                    opinion={testimonial.opinion}
                    client={testimonial.client}
                    location={testimonial.location}
                />
            );
        });
    };

    render() {
        return <Testimonials testimonials={this.state.testimonials} />;
    }
}

TestimonialsContainer.propTypes = {
    maximum: PropTypes.number,
};

export default TestimonialsContainer;
