import axios from 'axios';
import { store } from 'redux/store';
import env from 'utils/functions/env';
import Newsletter from 'utils/services/ACREAPI/nodes/Newsletter';
import Articles from './nodes/Articles';
import Auth from './nodes/Auth';
import Checkout from 'utils/services/ACREAPI/nodes/Checkout';
import ContactMessages from './nodes/ContactMessages';
import FavouriteProperties from './nodes/FavouriteProperties';
import Payments from './nodes/Payments';
import Properties from './nodes/Properties';
import PropertyCategories from './nodes/PropertyCategories';
import Roles from './nodes/Roles';
import Sellers from './nodes/Sellers';
import Users from './nodes/Users';
import ShowPhone from './nodes/ShowPhone';
import Logs from './nodes/Logs';

export const ADMIN_ENDPOINT_URL_PREFIX = 'admin/';

export default class ACREAPI {
    static guestInstance = () => {
        return axios.create({
            baseURL: env('API_URI'),
            timeout: 60000, // needs more time for backend data
        });
    };

    static authInstance = () => {
        return axios.create({
            baseURL: env('API_URI'),
            timeout: 60000, // needs more time for backend data
            headers: {
                Authorization: 'Bearer ' + store.getState().user.token,
            },
        });
    };

    static logs = () => Logs;

    static articles = () => Articles;

    static newsletter = () => Newsletter;

    static auth = () => Auth;

    static users = () => Users;

    static contactMessages = () => ContactMessages;

    static properties = () => Properties;

    static propertyCategories = () => PropertyCategories;

    static sellers = () => Sellers;

    static roles = () => Roles;

    static payments = () => Payments;

    static favouriteProperties = () => FavouriteProperties;

    static checkout = () => Checkout;

    static showPhone = () => ShowPhone;
}
