import { activateAccountAction } from 'redux/actions/authActions';

const defaultState = {
    isLoading: false,
    data: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case activateAccountAction.REQUEST:
            return { ...state, isLoading: true };
        case activateAccountAction.SUCCESS:
            return { ...state, data: action.payload };
        case activateAccountAction.FULFILL:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};
