import defaultAvatar from 'common/images/avatar-default.jpg';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import Title from 'components/Common/Title';
import SellerFormContainer from 'components/Page/Panel/Account/Part/SellerForm';
import React from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { isSeller } from 'utils/functions/authorization';
import { PropTypes } from 'utils/wrappers';
import { ALIGN_LEFT } from '../../../../domain/constants/alignment';
import { states } from '../data/states';
import styles from './styles/Account.module.scss';

const Account = ({
    handleInput,
    handleSubmit,
    handleAvatarChange,
    data,
    profileIsLoading,
    profileSavingIsSending,
    user,
}) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Title size={4} alignment={ALIGN_LEFT}>
                        Your Account Information
                    </Title>
                </Col>
            </Row>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg={4}>
                        <Title size={5} alignment={ALIGN_LEFT} underline>
                            Account Information
                        </Title>
                        <FormGroup>
                            <Label>First Name</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'firstName'}
                                value={data.firstName}
                                onChange={handleInput}
                                placeholder={'First Name'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'lastName'}
                                value={data.lastName}
                                onChange={handleInput}
                                placeholder={'Last Name'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input
                                bsSize={'sm'}
                                type={'password'}
                                name={'password'}
                                value={data.password}
                                onChange={handleInput}
                                placeholder={'Password'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Password Confirmation</Label>
                            <Input
                                bsSize={'sm'}
                                type={'password'}
                                name={'passwordConfirmation'}
                                value={data.passwordConfirmation}
                                onChange={handleInput}
                                placeholder={'Password Confirmation'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <Title size={5} alignment={ALIGN_LEFT} underline>
                            Contact Information
                        </Title>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'email'}
                                value={data.email}
                                onChange={handleInput}
                                placeholder={'Email'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Phone Number</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'phoneNumber'}
                                value={data.phoneNumber}
                                onChange={handleInput}
                                placeholder={'Phone Number'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fax Number</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'faxNumber'}
                                value={data.faxNumber}
                                onChange={handleInput}
                                placeholder={'Fax Number'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Toll Free Number</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'tollFreeNumber'}
                                value={data.tollFreeNumber}
                                onChange={handleInput}
                                placeholder={'Toll Free Number'}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <Title size={5} alignment={ALIGN_LEFT} underline>
                            Billing Address
                        </Title>
                        <FormGroup>
                            <Label>Address</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'address'}
                                value={data.address}
                                onChange={handleInput}
                                placeholder={'Street Address'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label hidden>Address</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'addressSecond'}
                                value={data.addressSecond}
                                onChange={handleInput}
                                placeholder={'Address Second Line'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>City</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'city'}
                                value={data.city}
                                onChange={handleInput}
                                placeholder={'City'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>State</Label>
                            <Input
                                bsSize={'sm'}
                                type={'select'}
                                name={'state'}
                                value={data.state}
                                onChange={handleInput}
                                placeholder={'State'}
                            >
                                <option value={''}>State</option>
                                {states.map((val, i) => (
                                    <option key={i} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Zip Code</Label>
                            <Input
                                bsSize={'sm'}
                                type={'text'}
                                name={'zip'}
                                value={data.zip}
                                onChange={handleInput}
                                placeholder={'Zip Code'}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <Title size={5} alignment={ALIGN_LEFT} underline>
                            Avatar
                        </Title>
                        <p>
                            <img className={styles.avatar} src={data.avatarBackendUri || defaultAvatar} alt={'-'} />
                        </p>
                        <FormGroup>
                            <Label>Avatar</Label>
                            <Input
                                bsSize={'sm'}
                                type={'file'}
                                accept={'image/*'}
                                name={'avatar'}
                                onChange={handleAvatarChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col xs={12} sm={12} lg={8}>
                        <SpinnerButton
                            isLoading={profileIsLoading || profileSavingIsSending}
                            color={'primary'}
                            type={'submit'}
                            block
                        >
                            Save account
                        </SpinnerButton>
                    </Col>
                </Row>
            </form>
            {isSeller() ? <SellerFormContainer /> : null}
        </React.Fragment>
    );
};

Account.propTypes = {
    handleInput: PropTypes.func.isRequired,
    handleAvatarChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    data: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        passwordConfirmation: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        faxNumber: PropTypes.string.isRequired,
        tollFreeNumber: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        addressSecond: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
    }).isRequired,
    profileIsLoading: PropTypes.bool.isRequired,
    profileSavingIsSending: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        role: PropTypes.string,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    profileIsLoading: state.profile.isLoading,
    profileSavingIsSending: state.requestUpdateProfile.isSending,
    user: state.user,
});

export default connect(mapStateToProps, null)(Account);
