import routes from 'domain/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { loginModalActionType, registrationModalActionType } from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { isAdmin, isContentManager, isLogged } from 'utils/functions/authorization';
import env from 'utils/functions/env';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import { PropTypes } from 'utils/wrappers';
import logo from './images/landzilla-logo.svg';
import AuthMenuItems from './Part/AuthMenuItems';
import './styles/Header.scss';

const Header = ({ toggleNavbar, hideNavbar, getTogglerClass, navbarIsOpen, menuItems }) => {
    const registerUrl = routeWithQuery({ [generateModalUriParam(registrationModalActionType)]: 'show' });
    const loginUrl = routeWithQuery({ [generateModalUriParam(loginModalActionType)]: 'show' });
    let panelUrl = routes.panel.account;

    if (isAdmin()) {
        panelUrl = routes.panel.properties.main;
    }

    if (isContentManager()) {
        panelUrl = routes.panel.blog.main;
    }

    return (
        <React.Fragment>
            <Navbar color='light' light expand='md'>
                <Container>
                    <NavbarBrand tag={Link} to={routes.home}>
                        <img src={logo} alt={env('NAME')} />
                    </NavbarBrand>
                    <div className='mobile-buttons'>
                        <Button
                            outline
                            color='secondary'
                            className='user'
                            tag={Link}
                            to={isLogged() ? panelUrl : loginUrl}
                        >
                            <i className='far fa-user' />
                        </Button>
                        <NavbarToggler onClick={toggleNavbar} className={'menu-toggler ' + getTogglerClass()}>
                            <div className='crossbar' />
                            <div className='crossbar' />
                            <div className='crossbar' />
                        </NavbarToggler>
                    </div>
                    <Collapse isOpen={navbarIsOpen} navbar>
                        <Nav className='ml-auto' navbar>
                            {menuItems.map((item, index) => (
                                <NavItem key={index} className={item.className}>
                                    <NavLink
                                        tag={Link}
                                        to={item.to}
                                        onClick={hideNavbar}
                                        disabled={window.location.pathname === item.to}
                                    >
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            ))}
                            <div className={'vsplit'}></div>
                            <AuthMenuItems registerUrl={registerUrl} loginUrl={loginUrl} panelUrl={panelUrl} />
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    );
};

Header.propTypes = {
    toggleNavbar: PropTypes.func.isRequired,
    hideNavbar: PropTypes.func.isRequired,
    getTogglerClass: PropTypes.func.isRequired,
    navbarIsOpen: PropTypes.bool.isRequired,
    menuItems: PropTypes.array.isRequired,
};

export default Header;
