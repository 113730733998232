import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from './styles/ContactForm.module.scss';

const ContactForm = ({
    fieldsNames,
    hasError,
    handleInput,
    handleSubmit,
    isSending,
    name,
    email,
    phoneNumber,
    message,
}) => {
    return (
        <Form className={classes['contact-form']} onSubmit={handleSubmit}>
            <FormGroup>
                <Input
                    type='text'
                    name={fieldsNames.name}
                    placeholder='Name'
                    className={classes['text-field']}
                    invalid={hasError(fieldsNames.name)}
                    onChange={handleInput}
                    value={name}
                />
            </FormGroup>
            <FormGroup>
                <Input
                    type='text'
                    name={fieldsNames.email}
                    placeholder='Email'
                    className={classes['text-field']}
                    invalid={hasError(fieldsNames.email)}
                    onChange={handleInput}
                    value={email}
                />
            </FormGroup>
            <FormGroup>
                <Input
                    type='text'
                    name={fieldsNames.phoneNumber}
                    placeholder='Phone number'
                    className={classes['text-field']}
                    invalid={hasError(fieldsNames.phoneNumber)}
                    onChange={handleInput}
                    value={phoneNumber}
                />
            </FormGroup>
            <FormGroup>
                <Input
                    type='textarea'
                    name={fieldsNames.message}
                    placeholder='Message'
                    className={classes['text-field'] + ' ' + classes.message}
                    invalid={hasError(fieldsNames.message)}
                    onChange={handleInput}
                    value={message}
                />
            </FormGroup>
            <div className={classes['submit-button-wrapper']}>
                <SpinnerButton color='primary' size='sm' className={classes['submit-button']} isLoading={isSending}>
                    Contact Seller
                </SpinnerButton>
            </div>
        </Form>
    );
};

ContactForm.propTypes = {
    fieldsNames: PropTypes.shape({
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }).isRequired,
    hasError: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSending: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isSending: state.contactMessage.isSending,
    };
};

export default connect(mapStateToProps, null)(ContactForm);
