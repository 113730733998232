/**
 * Returns file type from the path
 * ex. "some/path/something.other.pdf" -> "pdf"
 * ex. "some/path/something.JPG" -> "jpg"
 * @param {String} fileName
 */
export default (fileName) => {
    const dotIndex = fileName.lastIndexOf('.');
    if (dotIndex === -1) {
        return '';
    }
    return fileName.slice(dotIndex + 1, fileName.length).toLowerCase();
};
