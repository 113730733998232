import noImage from 'common/images/no-image.svg';
import AcreCard from 'components/Common/AcreCard/AcreCard';
import Location from 'components/Common/Property/Location';
import Price from 'components/Common/Property/Price';
import Size from 'components/Common/Property/Size';
import PropertyConverter from 'domain/api2domain/PropertyConverter';
import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'utils/wrappers';
import '../styles/FeaturedProperty.scss';

const FeaturedProperty = ({ images, name, url, price = 0, monthly = 0, location, landSize = 0 }) => {
    const converter = new PropertyConverter();
    const mainImage = converter.getMainImage({ gallery: images });
    const imagePath = mainImage ? mainImage.thumbnailUrl : noImage;

    if (!url) {
        return null;
    }

    const title = (
        <Link to={url}>
            <Price value={price} />
            <div className='est_price'>Est. ${monthly} / mo.</div>
        </Link>
    );

    const beforeTitle = (
        <div className='before-title'>
            <Link to={url}>{name}</Link>
        </div>
    );

    const footer = (
        <Link to={url}>
            <div className='land-size'>
                <i className='fas fa-expand icon' />
                <Size value={landSize} />
            </div>
            <i className='fas fa-arrow-right icon-arrow' />
        </Link>
    );

    return (
        <AcreCard
            imagePath={imagePath}
            imageUrl={url}
            beforeTitle={beforeTitle}
            title={title}
            className='featured-property'
            footer={footer}
        >
            {!location ? null : (
                <div className='location'>
                    <Link to={url}>
                        <i className='fas fa-map-marker-alt icon' />
                        <Location address='' county={location.county} state={location.state} zip='' withoutZip={true} />
                    </Link>
                </div>
            )}
        </AcreCard>
    );
};

FeaturedProperty.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            preview: PropTypes.string.isRequired,
        }),
    ),
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    location: PropTypes.shape({
        address: PropTypes.string.isRequired,
        county: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
    }),
    landSize: PropTypes.number.isRequired,
};

export default FeaturedProperty;
