import {
    forgotModalActionType,
    loginModalActionType,
    registrationModalActionType,
    checkoutModalActionType,
} from 'redux/actions/modalsActions';
import { modalReducerFactory } from 'redux/utils/modal';

export const forgotPasswordModalReducer = modalReducerFactory(forgotModalActionType);
export const loginModalReducer = modalReducerFactory(loginModalActionType);
export const registrationModalReducer = modalReducerFactory(registrationModalActionType);
export const checkoutModalReducer = modalReducerFactory(checkoutModalActionType);
