import React from 'react';
import { Button, Col, Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import AcreBootstrapTable from 'components/Common/AcreBootstrapTable';
import ModalContainer from 'components/Common/Modal';
import { editingUserModalUri } from 'components/Page/Panel/Users/UsersContainer';
import { PropTypes } from 'utils/wrappers';
import UserEditingFormContainer from './Part/UserEditingForm';
import './Users.scss';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';

const Users = ({
    columns,
    users,
    areLoading,
    paginationOptions,
    selectRowOptions,
    onTableChange,
    onExportUsers,
    onSearch,
    onAddUser,
    sendingNewUser,
    handleInput,
    search,
    showAddUserModal,
    hideAddUserModal,
    showAddUser,
    hideEditingModal,
    showEditingModal,
    isEditingModalOpened,
    editingUserId,
    email,
    firstName,
    lastName,
}) => {
    const xls = () => onExportUsers('xls');
    const csv = () => onExportUsers('csv');

    return (
        <React.Fragment>
            <ModalContainer
                isOpen={showAddUser}
                showModal={showAddUserModal}
                hideModal={hideAddUserModal}
                additionalModalClass={'registration-modal'}
                modalUriName={'#addUser'}
                title={'Add new user'}
                body={
                    <Form onSubmit={onAddUser}>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup>
                                        <Input
                                            type={'text'}
                                            placeholder={'First Name'}
                                            value={firstName}
                                            name={'firstName'}
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type={'text'}
                                            placeholder={'Last Name'}
                                            value={lastName}
                                            name={'lastName'}
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type={'text'}
                                            placeholder={'Email'}
                                            value={email}
                                            name={'email'}
                                            onChange={handleInput}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup className={'password-group'}>
                                        Warning! The new user will be assigned the following temporary password:
                                        "TempPassword"
                                    </FormGroup>
                                    <SpinnerButton
                                        isLoading={sendingNewUser}
                                        color='primary'
                                        className={'submit'}
                                        type={'submit'}
                                    >
                                        Add User
                                    </SpinnerButton>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                }
            />

            <div className={'header'}>
                <div className={'header'}>
                    <div style={{ marginRight: '16px' }}>
                        <Button title={'Add a new user'} onClick={showAddUserModal}>
                            <i className={'fa fa-user-plus'} />
                        </Button>
                    </div>
                    <div>
                        <form onSubmit={onSearch}>
                            <InputGroup>
                                <Input
                                    name={'search'}
                                    placeholder={'Search...'}
                                    value={search}
                                    onChange={handleInput}
                                    type={'text'}
                                />
                                <InputGroupAddon addonType='append'>
                                    <Button>
                                        <i className={'fas fa-search'} />
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </form>
                    </div>
                </div>
                <div className={'exports'}>
                    Export to:&nbsp;
                    <Button onClick={csv}>CSV</Button>
                    <Button onClick={xls}>XLS</Button>
                </div>
            </div>
            <AcreBootstrapTable
                keyField={'id'}
                columns={columns}
                data={users}
                paginationOptions={paginationOptions}
                remote={{
                    filter: false,
                    pagination: true,
                    sort: false,
                    cellEdit: false,
                }}
                onTableChange={onTableChange}
                loading={areLoading}
                selectRow={selectRowOptions}
            />
            <ModalContainer
                additionalModalClass={'editing-modal'}
                showModal={showEditingModal}
                hideModal={hideEditingModal}
                isOpen={isEditingModalOpened}
                modalUriName={editingUserModalUri}
                title={'Editing user'}
                body={<UserEditingFormContainer hideEditingModal={hideEditingModal} editingUserId={editingUserId} />}
            />
        </React.Fragment>
    );
};

Users.propTypes = {
    onTableChange: PropTypes.func.isRequired,
    onExportUsers: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    showEditingModal: PropTypes.func.isRequired,
    hideEditingModal: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    paginationOptions: PropTypes.shape({
        custom: PropTypes.bool,
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        hideSizePerPage: PropTypes.bool,
        showTotal: PropTypes.bool,
        totalSize: PropTypes.number,
    }).isRequired,
    selectRowOptions: PropTypes.shape({
        mode: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
    }).isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataField: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        }),
    ).isRequired,
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
        per_page: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
        current_page: PropTypes.number.isRequired,
        total_pages: PropTypes.number.isRequired,
    }),
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            fname: PropTypes.string.isRequired,
            lname: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            avatar: PropTypes.string,
            telephones: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    type: PropTypes.string.isRequired,
                    number: PropTypes.string.isRequired,
                }),
            ).isRequired,
            address: PropTypes.shape({
                id: PropTypes.number.isRequired,
                address_first: PropTypes.string,
                address_second: PropTypes.string,
                state: PropTypes.string,
                city: PropTypes.string,
                zip: PropTypes.string,
            }),
            payment_token: PropTypes.string,
        }),
    ).isRequired,
    areLoading: PropTypes.bool.isRequired,
    isEditingModalOpened: PropTypes.bool.isRequired,
    showAddUser: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    editingUserId: PropTypes.number,
};

export default Users;
