import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { store } from 'redux/store';
import { IN_LEFT, IN_RIGHT } from 'utils/constans/orderingDirections';
import { arrayElementMove } from 'utils/functions/arrays';
import generateRandomId from 'utils/functions/generateRandomId';
import { isEmptyString } from 'utils/functions/miscellaneous';
import GoogleMapsAPI from 'utils/services/GoogleMapsAPI/GoogleMapsAPI';
import { Lodash } from 'utils/wrappers';

const HANDLE_PROPERTY_FORM_INPUT_ACTION = 'HANDLE_PROPERTY_FORM_INPUT_ACTION';
const handlePropertyFormInputAction = (e) => {
    return {
        type: HANDLE_PROPERTY_FORM_INPUT_ACTION,
        payload: {
            name: e.target.name,
            value: e.target.value,
        },
    };
};

const handlePropertyFormSwitchInputAction = (e) => {
    return {
        type: HANDLE_PROPERTY_FORM_INPUT_ACTION,
        payload: {
            name: e.target.name,
            value: e.target.checked ? 1 : 0,
        },
    };
};

const calculateMonthlyPayment = () => {
    const { purchasePrice, financeTerm, rate } = store.getState().propertyForm;

    let calculatedMonthlyPayment = 0;

    if (purchasePrice > 0 && financeTerm > 0 && rate > 0) {
        const rateDigit = parseFloat(rate) / 100;
        const monthlyInterestRate = rateDigit / 12;
        const paymentsCount = parseInt(financeTerm);

        const numerator = monthlyInterestRate * Math.pow(1 + monthlyInterestRate, paymentsCount);
        const denominator = Math.pow(1 + monthlyInterestRate, paymentsCount) - 1;

        calculatedMonthlyPayment = purchasePrice * (numerator / denominator);
    }

    return {
        type: HANDLE_PROPERTY_FORM_INPUT_ACTION,
        payload: {
            name: 'monthlyPayment',
            value: calculatedMonthlyPayment.toFixed(2),
        },
    };
};

const calculateFinanceTerm = () => {
    const { financeTermMonths, financeTermYears } = store.getState().propertyForm;

    let months = parseInt(financeTermMonths);
    let years = parseInt(financeTermYears);

    if (!months) {
        months = 0;
    }

    if (!years) {
        years = 0;
    }

    const numberMonthsYear = 12;
    const monthsFromYears = years * numberMonthsYear;

    return {
        type: HANDLE_PROPERTY_FORM_INPUT_ACTION,
        payload: {
            name: 'financeTerm',
            value: monthsFromYears + months,
        },
    };
};

const HANDLE_CHANGE_DESC_ACTION = 'HANDLE_CHANGE_DESC_ACTION';
const onDescChangeAction = (editorState) => {
    return {
        type: HANDLE_CHANGE_DESC_ACTION,
        payload: {
            desc: editorState,
        },
    };
};

const HANDLE_REMOVE_ITEM_ACTION = 'HANDLE_REMOVE_ITEM_ACTION';
const removeItemAction = ({ id, stateItem }) => {
    const items = store.getState().propertyForm[stateItem];

    return {
        type: HANDLE_REMOVE_ITEM_ACTION,
        payload: {
            stateName: stateItem,
            items: items.filter((item) => item.id !== id),
        },
    };
};

// const HANDLE_FILE_CHANGE_ACTION = 'HANDLE_FILE_CHANGE_ACTION';
// const handleFileChangeAction = (e, uploadedFileDescription) => {
//     return {
//         type: HANDLE_FILE_CHANGE_ACTION,
//         payload: {
//             uploadingFileInputValue: e.target.value,
//             uploadedFile: e.target.files[0],
//             uploadedFileDescription: uploadedFileDescription,
//         },
//     };
// };

const HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION = 'HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION';
const handleFileChangeAction = (e, uploadedFileDescription) => {
    const files = store.getState().propertyForm.uploadedFiles ?? [];
    const uploadedFile = e.target.files[0];
    const description = uploadedFileDescription;

    if (!uploadedFile) {
        notifiFail({
            message: 'Before add, choose file',
        });
        return;
    }

    if (!description || description.length < 3) {
        notifiFail({
            message: 'File description must have at least 3 characters',
        });
        return;
    }

    files.push({ id: generateRandomId(), file: uploadedFile, description: description });

    return {
        type: HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION,
        payload: [...files],
    };
};

const addFileToUploadedAction = (newFile, description) => {
    const files = store.getState().propertyForm.uploadedFiles ?? [];
    files.push({ id: generateRandomId(), file: newFile, description: description });

    return {
        type: HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION,
        payload: [...files],
    };
};

const HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION = 'HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION';

/**
 * @param id
 * @param direction IN_LEFT or IN_RIGHT
 */
const reorderUploadedImageAction = (id, direction) => {
    const images = store.getState().propertyForm.uploadedImages ?? [];

    for (let index = 0; index < images.length; index++) {
        const image = images[index];

        if (image.id === id) {
            if (direction === IN_LEFT && index > 0) {
                arrayElementMove(images, index, index + IN_LEFT);
            } else if (direction === IN_RIGHT && index < images.length - 1) {
                arrayElementMove(images, index, index + IN_RIGHT);
            }

            break;
        }
    }

    return {
        type: HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
        payload: {
            uploadedImages: [...images],
        },
    };
};

const applyNewOrderOfUploadedImageAction = (images) => {
    return {
        type: HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
        payload: {
            uploadedImages: [...images],
        },
    };
};

const removeUploadedImageAction = (id) => {
    return {
        type: HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
        payload: {
            uploadedImages: store.getState().propertyForm.uploadedImages.filter((image) => image.id !== id),
        },
    };
};

const addImageToUploadedAction = (image) => {
    const uploadedImages = store.getState().propertyForm.uploadedImages ?? [];
    uploadedImages.push({ id: generateRandomId(), blob: URL.createObjectURL(image), image });

    return {
        type: HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
        payload: {
            uploadedImages: [...uploadedImages],
        },
    };
};

const onClickChangeToPrimaryAction = ({ e, id }) => {
    e.preventDefault();

    let uploadedImages = store.getState().propertyForm.uploadedImages ?? [];
    const thisImage = Lodash.cloneDeep(uploadedImages.filter((image) => image.id === id)[0]);
    uploadedImages = uploadedImages.filter((image) => image.id !== id);

    uploadedImages.unshift(thisImage);

    return {
        type: HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
        payload: {
            uploadedImages: uploadedImages,
        },
    };
};

const HANDLE_ON_CLICK_ADD_LINK_ACTION = 'HANDLE_ON_CLICK_ADD_LINK_ACTION';
const onClickAddLinkAction = () => {
    const links = store.getState().propertyForm.importantLinks ?? [];

    const { importantLinkUrl, importantLinkDesc } = store.getState().propertyForm;

    if (isEmptyString(importantLinkUrl) || isEmptyString(importantLinkDesc)) {
        notifiFail({
            message: 'Please, fill URL and description fields',
        });

        return;
    }

    links.push({
        id: generateRandomId(),
        link: importantLinkUrl,
        desc: importantLinkDesc,
    });

    return {
        type: HANDLE_ON_CLICK_ADD_LINK_ACTION,
        payload: [...links],
    };
};

const HANDLE_CLICK_CATEGORY_CHECKBOX_ACTION = 'HANDLE_CLICK_CATEGORY_CHECKBOX_ACTION';
const onClickCategoryCheckboxAction = (e) => {
    const value = parseInt(e.target.value);
    const state = store.getState();
    const selected = state.propertyForm.categories ?? [];
    const indexOfId = selected.indexOf(value);
    if (indexOfId !== -1) {
        selected.splice(indexOfId, 1);
    } else {
        selected.push(value);
    }
    return {
        type: HANDLE_CLICK_CATEGORY_CHECKBOX_ACTION,
        payload: [...selected],
    };
};

const HANDLE_CLICK_UTILITY_CHECKBOX_ACTION = 'HANDLE_CLICK_UTILITY_CHECKBOX_ACTION';
const onClickUtilityCheckboxAction = (a, e) => {
    const utilityId = parseInt(e.target.value);
    const selected = store.getState().propertyForm.selectedUtilities ?? [];
    const indexOfId = selected.indexOf(utilityId);

    if (indexOfId !== -1) {
        selected.splice(indexOfId, 1);
    } else {
        selected.push(utilityId);
    }

    return {
        type: HANDLE_CLICK_UTILITY_CHECKBOX_ACTION,
        payload: [...selected],
    };
};

const HANDLE_CLICK_ADD_VIDEO_ACTION = 'HANDLE_CLICK_ADD_VIDEO_ACTION';
const onClickAddVideoAction = () => {
    const videos = store.getState().propertyForm.videos ?? [];

    const { videoUrl, videoDesc } = store.getState().propertyForm;

    if (isEmptyString(videoUrl) || isEmptyString(videoDesc)) {
        notifiFail({
            message: 'Please, fill video URL and description fields',
        });

        return;
    }

    videos.push({
        id: generateRandomId(),
        link: videoUrl,
        desc: videoDesc,
    });

    return {
        type: HANDLE_CLICK_ADD_VIDEO_ACTION,
        payload: [...videos],
    };
};

const HANDLE_SET_ADDRESS_DATA_FROM_API_ACTION = 'HANDLE_SET_ADDRESS_DATA_FROM_API_ACTION';
const handleSetAddressDataFromAPI = () => {
    const data = store.getState().googleMapsAddress.data.results;
    const payload = {
        city: '',
        state: '',
        county: '',
        address: '',
        zip: '',
    };
    data.forEach((googleMapAddress) => {
        const p = GoogleMapsAPI.explodeAddressComponents(googleMapAddress.address_components);
        let key;
        for (key in p) {
            if (payload[key] === '' || !payload[key]) {
                payload[key] = p[key].trim() ?? payload[key];
            }
        }
    });
    return { type: HANDLE_SET_ADDRESS_DATA_FROM_API_ACTION, payload: payload };
};

const CLEAR_PROPERTY_FORM_ACTION = 'CLEAR_PROPERTY_FORM_ACTION';
const clearPropertyFormAction = () => ({
    type: CLEAR_PROPERTY_FORM_ACTION,
});

const FILL_PROPERTY_FORM_ACTION = 'FILL_PROPERTY_FORM_ACTION';
const fillPropertyFormAction = (data) => {
    return {
        type: FILL_PROPERTY_FORM_ACTION,
        payload: data,
    };
};

export {
    CLEAR_PROPERTY_FORM_ACTION,
    FILL_PROPERTY_FORM_ACTION,
    HANDLE_CLICK_ADD_VIDEO_ACTION,
    HANDLE_SET_ADDRESS_DATA_FROM_API_ACTION,
    HANDLE_CLICK_UTILITY_CHECKBOX_ACTION,
    HANDLE_CLICK_CATEGORY_CHECKBOX_ACTION,
    HANDLE_PROPERTY_FORM_INPUT_ACTION,
    HANDLE_CHANGE_DESC_ACTION,
    HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION,
    // HANDLE_FILE_CHANGE_ACTION,
    HANDLE_REMOVE_ITEM_ACTION,
    HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
    HANDLE_ON_CLICK_ADD_LINK_ACTION,
    addFileToUploadedAction,
    handleSetAddressDataFromAPI,
    fillPropertyFormAction,
    clearPropertyFormAction,
    onClickAddVideoAction,
    onClickUtilityCheckboxAction,
    onClickCategoryCheckboxAction,
    handlePropertyFormInputAction,
    handlePropertyFormSwitchInputAction,
    onClickChangeToPrimaryAction,
    reorderUploadedImageAction,
    applyNewOrderOfUploadedImageAction,
    addImageToUploadedAction,
    removeUploadedImageAction,
    onDescChangeAction,
    handleFileChangeAction,
    // onClickAddFileAction,
    onClickAddLinkAction,
    removeItemAction,
    calculateMonthlyPayment,
    calculateFinanceTerm,
    IN_LEFT,
    IN_RIGHT,
};
