import ACREAPI from 'utils/services/ACREAPI';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';

class Logs {
    static show = (apiQueryPart, setResponse, setFetched) => {
        ACREAPI.authInstance()
            .get(`/admin/logs${apiQueryPart}`, {
                transformRequest: (data, headers) => {
                    headers.common['Accept'] = 'text/plain';
                    return data;
                },
            })
            .then((res) => {
                setResponse(res.data);
            })
            .catch((err) => {
                console.error(err);
                notifiFail({
                    message: "Error: Couldn't fetch logs",
                    duration: 0,
                });
            })
            .finally(() => {
                setFetched(true);
                console.log('Logs were fetched');
            });
    };
}

export default Logs;
