import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import ListContainer from './Part/List/ListContainer';
import SearchingContainer from './Part/Searching/SearchingContainer';
//import SidebarContainer from './Part/Sidebar';
import SearchToolbar from './Part/SearchToolbar';
import SortingContainer from './Part/Sorting/SortingContainer';
import './styles/Properties.scss';

const Properties = () => {
    return (
        <Row>
            <Col>
                <Title size={2} alignment={ALIGN_LEFT} withoutMarginTop={true}>
                    Listings
                </Title>
                <div className={'searchtoolbar-wrapper'}>
                    <div className={'sorting-container-wrapper'}>
                        <SortingContainer />
                    </div>
                    <SearchingContainer />
                    <SearchToolbar />
                </div>
                <ListContainer />
            </Col>
        </Row>
    );
};

export default Properties;
