import Location from 'components/Common/Property/Location';
import Price from 'components/Common/Property/Price';
import Size from 'components/Common/Property/Size';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import { DEFAULT_EMPTY_VALUE_REPLACEMENT, getReplacementIfEmpty } from 'utils/functions/miscellaneous';
import { isNullOrEmptyObject } from 'utils/functions/objects';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/InformationTable.module.scss';
import classesTitle from '../styles/Title.module.scss';

const InformationTable = ({ property, className }) => {
    const price = <Price value={property.finances.price} />;
    const monthlyPayment = <Price value={property.finances.monthlyPayment} />;
    const taxes = <Price value={property.taxes} withFraction={true} />;
    const subdivisionYearlyDues = <Price value={property.subdivisionYearlyDues} withFraction={true} />;
    const size = <Size value={property.landSize} />;
    const cssClass = joinNotEmpty([classes.information, className], ' ');

    const fullAddress = (
        <Location
            address={property.location.address}
            county={property.location.county}
            state={property.location.state}
            zip={property.location.zip}
        />
    );

    const gpsLocation = [
        getReplacementIfEmpty(property.gpsLocation.latitude),
        getReplacementIfEmpty(property.gpsLocation.longitude),
    ].join(', ');

    // Required to avoid "undefined is not an object (evaluating 'property.relatedLinks.map')"
    property.relatedLinks = property.relatedLinks || [];

    return (
        <div className={cssClass}>
            <Title size={2} alignment={ALIGN_LEFT} className={classesTitle.title}>
                Information
            </Title>
            <table className={classes.table + ' table table-sm table-borderless table-striped'}>
                <tbody>
                    <tr>
                        <td>Listing ID:</td>
                        <td>{getReplacementIfEmpty(property.listingId)}</td>
                    </tr>
                    <tr>
                        <td>Price:</td>
                        <td>{getReplacementIfEmpty(price)}</td>
                    </tr>
                    <tr>
                        <td>Financing Term:</td>
                        <td>{getReplacementIfEmpty(property.financialTerm)}</td>
                    </tr>
                    <tr>
                        <td>Monthly Payment:</td>
                        <td>{getReplacementIfEmpty(monthlyPayment)}</td>
                    </tr>
                    <tr>
                        <td>Property State:</td>
                        <td>{getReplacementIfEmpty(property.propertyState)}</td>
                    </tr>
                    <tr>
                        <td>Property County:</td>
                        <td>{getReplacementIfEmpty(property.propertyCounty)}</td>
                    </tr>
                    <tr>
                        <td>Parcel Number (APN):</td>
                        <td>{getReplacementIfEmpty(property.parcelNumber)}</td>
                    </tr>
                    <tr>
                        <td>Property Size:</td>
                        <td>{getReplacementIfEmpty(size)}</td>
                    </tr>
                    <tr>
                        <td>Address:</td>
                        <td>{getReplacementIfEmpty(fullAddress)}</td>
                    </tr>
                    <tr>
                        <td>GPS Location:</td>
                        <td>{gpsLocation}</td>
                    </tr>
                    <tr>
                        <td>Road Access:</td>
                        <td>{getReplacementIfEmpty(property.roadAccess)}</td>
                    </tr>
                    <tr>
                        <td>Utilities:</td>
                        <td>{getReplacementIfEmpty(property.utilities, 'None')}</td>
                    </tr>
                    <tr>
                        <td>Zoning:</td>
                        <td>{getReplacementIfEmpty(property.zoning)}</td>
                    </tr>
                    <tr>
                        <td>Zoning Description:</td>
                        <td>{getReplacementIfEmpty(property.zoningDescription)}</td>
                    </tr>
                    <tr>
                        <td>Yearly Taxes:</td>
                        <td>{getReplacementIfEmpty(taxes)}</td>
                    </tr>
                    <tr>
                        <td>Subdivision:</td>
                        <td>{getReplacementIfEmpty(property.subdivisionName)}</td>
                    </tr>
                    <tr>
                        <td>Subdivision Yearly Dues:</td>
                        <td>{getReplacementIfEmpty(subdivisionYearlyDues)}</td>
                    </tr>
                    <tr>
                        <td>Related Resources:</td>
                        <td className={classes['related-resources']}>
                            {property.relatedLinks === null &&
                            property.relatedDocs === null &&
                            property.relatedVideos === null ? (
                                DEFAULT_EMPTY_VALUE_REPLACEMENT
                            ) : (
                                <ul className={classes.list}>
                                    {isNullOrEmptyObject(property.relatedLinks)
                                        ? null
                                        : property.relatedLinks.map((link, index) => (
                                              <li key={index} className={classes.resource}>
                                                  <i className='fas fa-link' />
                                                  <a href={link.url} target='_blank' rel='noreferrer'>
                                                      {link.name}
                                                  </a>
                                              </li>
                                          ))}

                                    {isNullOrEmptyObject(property.relatedDocs)
                                        ? null
                                        : property.relatedDocs.map((doc, index) => (
                                              <li key={index} className={classes.resource}>
                                                  <i className='fas fa-file-download' />
                                                  <a href={doc.url} target='_blank' rel='noreferrer'>
                                                      {doc.name}
                                                  </a>
                                              </li>
                                          ))}

                                    {isNullOrEmptyObject(property.relatedVideos)
                                        ? null
                                        : property.relatedVideos.map((video, index) => (
                                              <li key={index} className={classes.resource}>
                                                  <i className='fab fa-youtube' />
                                                  <a href={video.url} target='_blank' rel='noreferrer'>
                                                      {video.name}
                                                  </a>
                                              </li>
                                          ))}
                                </ul>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

InformationTable.propTypes = {
    property: PropTypes.shape({
        listingId: PropTypes.string.isRequired,
        financialTerm: PropTypes.string,
        finances: PropTypes.shape({
            price: PropTypes.number.isRequired,
            monthlyPayment: PropTypes.number.isRequired,
        }).isRequired,
        propertyState: PropTypes.string.isRequired,
        propertyCounty: PropTypes.string.isRequired,
        parcelNumber: PropTypes.string.isRequired,
        landSize: PropTypes.number.isRequired,
        location: PropTypes.shape({
            address: PropTypes.string.isRequired,
            county: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            zip: PropTypes.string.isRequired,
        }).isRequired,
        gpsLocation: PropTypes.shape({
            longitude: PropTypes.number.isRequired,
            latitude: PropTypes.number.isRequired,
        }).isRequired,
        roadAccess: PropTypes.string.isRequired,
        utilities: PropTypes.string,
        zoning: PropTypes.string.isRequired,
        zoningDescription: PropTypes.string,
        taxes: PropTypes.number,
        subdivisionName: PropTypes.string,
        subdivisionYearlyDues: PropTypes.number,
        relatedLinks: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        relatedDocs: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        relatedVideos: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
    }).isRequired,
    className: PropTypes.string,
};

export default InformationTable;
