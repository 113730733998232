import { fetchUserAction } from 'redux/actions/usersActions';

const defaultState = {
    isLoading: true,
    data: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchUserAction.REQUEST:
            return { ...state, isLoading: true };
        case fetchUserAction.SUCCESS:
            return { ...state, data: action.payload };
        case fetchUserAction.FULFILL:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
