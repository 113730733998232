import AcreSpinner from 'components/Common/AcreSpinner';
import HtmlHead from 'components/Common/HtmlHead';
import PaginationContainer from 'components/Common/Pagination';
import PropertyCard from 'components/Common/Property/PropertyCard';
import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import { ALIGN_LEFT } from '../../../domain/constants/alignment';
import styles from './PropertiesState.module.scss';

const PropertiesState = ({ propertiesState, searchedProperties, onPageChange, activePage }) => {
    const properties = !searchedProperties.areLoading ? (
        <React.Fragment>
            {searchedProperties.properties.length > 0 ? (
                searchedProperties.properties.map((property, index) => <PropertyCard property={property} key={index} />)
            ) : (
                <Alert color={'info'}>No properties found</Alert>
            )}
        </React.Fragment>
    ) : (
        <AcreSpinner wrapped />
    );

    const pagination =
        searchedProperties.pagination && searchedProperties.pagination.total_pages > 1 ? (
            <div className={'my-pagination'}>
                <PaginationContainer
                    onPageChange={onPageChange}
                    itemsPerPage={searchedProperties.pagination.per_page}
                    activePage={parseInt(activePage)}
                    totalItemsNum={searchedProperties.pagination.total}
                />
            </div>
        ) : null;

    return (
        <div>
            <HtmlHead title={'Lands for sale - ' + propertiesState.title} description={propertiesState.description} />
            <Title size={2} alignment={ALIGN_LEFT} withoutMarginTop={true}>
                {propertiesState.title}
            </Title>
            <div className={styles.description}>{propertiesState.description}</div>
            {properties}
            {pagination}
        </div>
    );
};

PropertiesState.propTypes = {
    propertiesState: PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    onPageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    activePage: state.propertiesSearchParams.activePage,
    searchedProperties: state.searchedProperties,
});

export default connect(mapStateToProps, null)(PropertiesState);
