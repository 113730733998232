import ArticleCard from 'components/Common/ArticleCard';
import Carousel from 'components/Common/Carousel';
import Title from 'components/Common/Title';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import routes from '../../../../../domain/routes';
import CtaButton from '../Common/CtaButton';
import SectionSeparator from '../Common/SectionSeparator';

const FeaturedNews = ({ articles }) => {
    if (!articles || articles.length === 0) {
        return <SectionSeparator />;
    }

    return (
        <section className='homepage-section featured-news-section'>
            <Container>
                <Row>
                    <Col>
                        <Title size={2}>Featured News and Articles</Title>
                    </Col>
                </Row>
                <Row className='d-sm-none'>
                    <Carousel slides={articles} />
                </Row>
                <Row className='d-none d-sm-flex justify-content-center'>
                    {articles.map((article) => {
                        return article;
                    })}
                </Row>
                <CtaButton text='Check our Blog' url={routes.blog.main} />
            </Container>
        </section>
    );
};

FeaturedNews.propTypes = {
    articles: PropTypes.arrayOf(ArticleCard).isRequired,
};

export default FeaturedNews;
