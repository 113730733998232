import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchUserAction } from 'redux/actions/usersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* editedUserSaga(action) {
    try {
        yield put(fetchUserAction.request());

        const response = yield call(ACREAPI.users().show, action.payload);

        yield put(fetchUserAction.success(response.data.user));
    } catch (e) {
        const message = e.response && e.response.data ? e.response.data.message : "Getting user's data has been failed";

        notifiFail({
            message: message,
        });

        yield put(fetchUserAction.failure());
    } finally {
        yield put(fetchUserAction.fulfill());
    }
}

export default function* editedUserSagaWatcher() {
    yield takeLatest(fetchUserAction.trigger, editedUserSaga);
}
