import { fetchSellerAction } from 'redux/actions/sellersActions';

const defaultState = {
    data: {},
    isLoading: true,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchSellerAction.REQUEST:
            return { ...state, isLoading: true };
        case fetchSellerAction.SUCCESS:
            return { ...state, data: action.payload };
        case fetchSellerAction.FULFILL:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
