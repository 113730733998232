import React, { Component } from 'react';
import { connect } from 'react-redux';
import { calculateFinanceTerm, calculateMonthlyPayment } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import FinancialFields from './FinancialFields';

class FinancialFieldsContainer extends Component {
    componentDidUpdate(prevProps, prevState, snapshot): void {
        if (
            prevProps.financeTermMonths !== this.props.financeTermMonths ||
            prevProps.financeTermYears !== this.props.financeTermYears
        ) {
            this.props.calculateFinanceTerm();
        }

        if (
            prevProps.purchasePrice !== this.props.purchasePrice ||
            prevProps.financeTerm !== this.props.financeTerm ||
            prevProps.rate !== this.props.rate
        ) {
            this.props.calculateMonthlyPayment();
        }
    }

    render() {
        return <FinancialFields />;
    }
}

FinancialFieldsContainer.propTypes = {
    purchasePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    financeTerm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    calculateMonthlyPayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    purchasePrice: state.propertyForm.purchasePrice,
    financeTerm: state.propertyForm.financeTerm,
    financeTermYears: state.propertyForm.financeTermYears,
    financeTermMonths: state.propertyForm.financeTermMonths,
    rate: state.propertyForm.rate,
});

const mapDispatchToProps = {
    calculateMonthlyPayment: calculateMonthlyPayment,
    calculateFinanceTerm: calculateFinanceTerm,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialFieldsContainer);
