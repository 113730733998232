import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { isEmptyObject } from 'utils/functions/objects';
import { uppercaseFirstLetter } from 'utils/functions/string';

class Filter {
    constructor(rawData) {
        this.rawData = rawData;
    }

    toOptions() {
        if (isNullOrEmptyArray(this.rawData) || isEmptyObject(this.rawData)) {
            return null;
        }
        if (!this.rawData[0].name) {
            return this.rawData.map((county) => {
                return {
                    label: uppercaseFirstLetter(county),
                    value: county,
                };
            });
        }
        return this.rawData.map((state) => {
            return {
                label: uppercaseFirstLetter(state.name),
                value: state.name,
            };
        });
    }
}

export default Filter;
