import ImageDropZone from 'components/Common/Panel/PropertyForm/Part/ImageDropZone';
import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import {
    addImageToUploadedAction,
    applyNewOrderOfUploadedImageAction,
    onClickChangeToPrimaryAction,
    removeUploadedImageAction,
    reorderUploadedImageAction,
} from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/PropertyForm.module.scss';
import SortableImagesContainer from 'components/Common/Panel/PropertyForm/Part/ImagesFields/SortableImagesContainer';

const ImagesFields = ({
    uploadedImages,
    addImageToUploaded,
    onClickChangeToPrimary,
    removeUploadedImage,
    reorderUploadedImage,
    applyNewOrderOfUploadedImage,
}) => {
    return (
        <Row form>
            <Col>
                <Title size={5} underline className={styles['form-title']}>
                    Images
                </Title>
                <div className={styles['tip']}>
                    Only images; max. 2MB each image; min. height 720px; min. width 1280px
                </div>
                <ImageDropZone addImageToUploaded={addImageToUploaded} />
                <SortableImagesContainer
                    images={uploadedImages}
                    onDragEnd={applyNewOrderOfUploadedImage}
                    onClickChangeToPrimary={onClickChangeToPrimary}
                    reorderUploadedImage={reorderUploadedImage}
                    removeUploadedImage={removeUploadedImage}
                />
            </Col>
        </Row>
    );
};

ImagesFields.propTypes = {
    addImageToUploaded: PropTypes.func.isRequired,
    onClickChangeToPrimary: PropTypes.func.isRequired,
    removeUploadedImage: PropTypes.func.isRequired,
    reorderUploadedImage: PropTypes.func.isRequired,
    uploadedImages: PropTypes.arrayOf(
        PropTypes.shape({
            blob: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            image: PropTypes.object.isRequired,
        }),
    ).isRequired,
};

const mapDispatchToProps = {
    addImageToUploaded: addImageToUploadedAction,
    onClickChangeToPrimary: onClickChangeToPrimaryAction,
    removeUploadedImage: removeUploadedImageAction,
    reorderUploadedImage: reorderUploadedImageAction,
    applyNewOrderOfUploadedImage: applyNewOrderOfUploadedImageAction,
};

const mapStateToProps = (state) => ({
    uploadedImages: state.propertyForm.uploadedImages,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesFields);
