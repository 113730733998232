import noImage from 'common/images/no-image.svg';
import Price from 'components/Common/Property/Price';
import Size from 'components/Common/Property/Size';
import ToggleFavouriteButton from 'components/Common/Property/ToggleFavouriteButton';
import routes from 'domain/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import styles from './styles/PropertyCard.module.scss';

const PropertyCard = ({ property }) => {
    const stateSlug = slugify(property.geo.state, { lower: true });
    return (
        <div className={styles['property']}>
            <div className={styles['preview']}>
                <Link to={route(routes.properties.one, { state: stateSlug, slug: property.slug })}>
                    <img src={property.gallery[0] ? property.gallery[0].preview : noImage} alt={property.title} />
                </Link>
            </div>
            <div className={styles['data']}>
                <ToggleFavouriteButton
                    propertyId={property.id}
                    isFavourite={property.is_favorite}
                    size='sm'
                    className={styles['favourite-button']}
                    iconOnly
                />
                <div className={styles['name']} title={property.title}>
                    {property.title}
                </div>
                <div className={styles['price']}>
                    <Price value={property.price.price} />
                </div>
                <div className={styles['est_price']}>Est. ${property.price.monthly_payment} / mo.</div>

                <div className={styles['address']}>
                    <i className={'fas fa-map-marker-alt'} /> {property.geo.state}, {property.geo.county}
                </div>
                <div className={styles['meta-data']}>
                    <div className={styles['size']}>
                        <i className={'fas fa-expand'} /> <Size value={property.geo.acreage} />
                    </div>
                    <Link to={route(routes.properties.one, { state: stateSlug, slug: property.slug })}>
                        <div className={styles['arrow']}>
                            <i className={'fas fa-arrow-right'} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const propertyPropType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    geo: PropTypes.shape({
        acreage: PropTypes.number.isRequired,
        address: PropTypes.string.isRequired,
    }).isRequired,
    is_favorite: PropTypes.bool,
    slug: PropTypes.string.isRequired,
    price: PropTypes.shape({
        price: PropTypes.number.isRequired,
    }).isRequired,
    gallery: PropTypes.arrayOf(
        PropTypes.shape({
            preview: PropTypes.string.isRequired,
        }),
    ).isRequired,
    seller: PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
        email: PropTypes.arrayOf(
            PropTypes.shape({
                email: PropTypes.string,
            }),
        ),
    }),
});

PropertyCard.propTypes = {
    property: propertyPropType,
};

export default PropertyCard;
