import noImage from 'common/images/no-image.svg';
import UserFromApi from 'components/Common/UserFromApi';
import ArticleConverter from 'domain/api2domain/ArticleConverter';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { adminArticleDeleteAction, adminArticlesFetchAction } from 'redux/actions/adminArticleCrudActions';
import { PAGINATION } from 'utils/constans/bootstrapTableChangeType';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import BlogList from './BlogList';
import classes from './styles/BlogListContainer.module.scss';

class BlogListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
        };
    }

    componentDidMount() {
        this.fetchAllArticles();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { pagination, isDeletingArticle } = this.props;

        if (prevProps.pagination !== pagination) {
            this.setState({
                page: pagination.current_page,
            });
        }

        // update table after deleting
        if (prevProps.isDeletingArticle !== isDeletingArticle && !isDeletingArticle) {
            this.fetchAllArticles();
        }
    }

    getColumns() {
        return [
            {
                isDummyField: true,
                dataField: 'dummy1',
                text: 'Main image',
                formatter: (cell, row) => {
                    const article = ArticleConverter.toDomain(row);
                    const mainImage = article.getMainImage();
                    const imagePath = mainImage ? mainImage.getThumbnailUrl() : noImage;

                    return <img src={imagePath} className={classes['list-image']} alt='' />;
                },
            },
            {
                dataField: 'title',
                text: 'Title',
            },
            {
                dataField: 'author',
                text: 'Author',
                formatter: this.authorFormatter,
            },
            {
                dataField: 'updated_at',
                text: 'Updated at',
                formatter: this.dateFormatter,
                align: 'center',
            },
            {
                isDummyField: true,
                dataField: 'dummy2',
                text: 'Actions',
                formatter: (cell, row) => {
                    const articleId = Number(row.id);
                    const articleSlug = row.slug;

                    const updateUrl = route(routes.panel.blog.update, {
                        articleSlug: articleSlug,
                    });

                    const showUrl = route(routes.blog.article, {
                        articleSlug: articleSlug,
                    });

                    return (
                        <div className='actions'>
                            <Link to={updateUrl} className='action without-underline' title='Edit'>
                                <i className='fas fa-edit' />
                            </Link>
                            <i
                                className='action fas fa-trash'
                                onClick={() => this.onDelete(articleId)}
                                title='Delete'
                            />
                            <Link to={showUrl} className='action without-underline' title='Show' target='_blank'>
                                <i className='far fa-newspaper' />
                            </Link>
                        </div>
                    );
                },
            },
        ];
    }

    authorFormatter(cell, row) {
        return <UserFromApi apiData={cell} />;
    }

    dateFormatter(cell, row) {
        return <FormattedDate value={cell} format='short' />;
    }

    onDelete = (articleId) => {
        if (window.confirm('Are you sure you want to delete this article?')) {
            this.props.deleteArticle(articleId);
        }
    };

    onTableChange = (type, data) => {
        if (type === PAGINATION) {
            this.setState(
                {
                    page: data.page,
                },
                () => {
                    this.fetchAllArticles();
                },
            );
        }
    };

    fetchAllArticles = () => {
        this.props.fetchArticles(this.state.page);
    };

    getPaginationOptions() {
        const { pagination } = this.props;

        return {
            page: this.state.page,
            sizePerPage: pagination.per_page,
            totalSize: pagination.total,
            showTotal: true,
            hideSizePerPage: true,
        };
    }

    render() {
        const { articles, areLoading, isDeletingArticle } = this.props;

        return (
            <BlogList
                columns={this.getColumns()}
                paginationOptions={this.getPaginationOptions()}
                onTableChange={this.onTableChange}
                articles={articles}
                areLoading={areLoading || isDeletingArticle}
            />
        );
    }
}

BlogListContainer.propTypes = {
    fetchArticles: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
        total: PropTypes.number,
        count: PropTypes.number,
        per_page: PropTypes.number,
        current_page: PropTypes.number,
        total_pages: PropTypes.number,
    }).isRequired,
    areLoading: PropTypes.bool,
};

BlogListContainer.defaultProps = {
    areLoading: false,
};

const mapStateToProps = (state) => ({
    articles: state.adminArticles.data,
    pagination: state.adminArticles.pagination,
    areLoading: state.adminArticles.areLoading,
    isDeletingArticle: state.adminArticleDelete.isSending,
});

const mapDispatchToProps = {
    fetchArticles: adminArticlesFetchAction,
    deleteArticle: adminArticleDeleteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogListContainer);
