class ArticleImage {
    constructor(id: Number, thumbnailUrl: String, fullSizeUrl: String) {
        this.id = id;
        this.thumbnailUrl = thumbnailUrl;
        this.fullSizeUrl = fullSizeUrl;
    }

    getId(): Number {
        return this.id;
    }

    getThumbnailUrl(): String {
        return this.thumbnailUrl;
    }

    getFullSizeUrl(): String {
        return this.fullSizeUrl;
    }
}

export default ArticleImage;
