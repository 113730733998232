export const STATUS_DEFAULT = null;
export const STATUS_SUCCESS = 'success';
export const STATUS_FAIL = 'fail';

const defaultState = {
    isSending: false,
    status: STATUS_DEFAULT,
};

export const generateSimpleRequestReducer = (routineActionMethod) => {
    return (state = defaultState, action) => {
        switch (action.type) {
            case routineActionMethod.REQUEST:
                return { ...state, isSending: true, status: STATUS_DEFAULT };
            case routineActionMethod.SUCCESS:
                return { ...state, status: STATUS_SUCCESS };
            case routineActionMethod.FAILURE:
                return { ...state, status: STATUS_FAIL };
            case routineActionMethod.FULFILL:
                return { ...state, isSending: false };
            default:
                return { ...state };
        }
    };
};
