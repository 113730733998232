import React, { Component } from 'react';
import FloatingNotifications from './FloatingNotifications';
import { store } from './notifi';

class FloatingNotificationsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
        };

        store.subscribe(this.updateNotifications);
    }

    componentWillUnmount() {
        store.unsubscribe(this.updateNotifications);
    }

    updateNotifications = () => {
        this.setState({
            notifications: store.data,
        });
    };

    render() {
        const { notifications } = this.state;

        return notifications.length ? <FloatingNotifications notifications={notifications} /> : null;
    }
}

export default FloatingNotificationsContainer;
