import Title from 'components/Common/Title';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { handleFileChangeAction, removeItemAction } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';
import UploadedFiles from '../UploadedFiles';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';

const FilesUploadFields = ({ uploadedFiles, uploadingFileInputValue, removeItem, handleFileChange }) => {
    const [fileDescription, setFileDescription] = useState('');

    const onFileChangeHandler = async (e) => {
        try {
            await handleFileChange(e, fileDescription);
            setFileDescription('');
        } catch (err) {
            notifiFail({
                message: 'Please provide description before choosing file',
            });
        }
    };

    const onChangeFileDescriptionHandler = (e) => {
        setFileDescription(e.target.value);
    };

    return (
        <Row form>
            <Col>
                <Title size={5} underline className={styles['form-title']}>
                    File upload
                </Title>
                <FormGroup>
                    <Label>{fileDescription.length < 3 ? fields.noFileDescription : fields.file}</Label>
                    <Input
                        type={'text'}
                        placeholder={fields.fileDesc}
                        value={fileDescription}
                        onChange={onChangeFileDescriptionHandler}
                        name={'uploadedFileDescription'}
                    />
                    {fileDescription.length >= 3 ? (
                        <Input
                            type={'file'}
                            placeholder={fields.file}
                            value={uploadingFileInputValue}
                            name={'uploadedFile'}
                            onChange={onFileChangeHandler}
                            bsSize={bsSize}
                        />
                    ) : (
                        ''
                    )}
                </FormGroup>
                <UploadedFiles files={uploadedFiles} removeItem={removeItem} />
            </Col>
        </Row>
    );
};

FilesUploadFields.propTypes = {
    handleFileChange: PropTypes.func.isRequired,
    // onAddFile: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    uploadedFiles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            file: PropTypes.shape(File).isRequired, // file object
            description: PropTypes.string.isRequired,
        }),
    ).isRequired,
    uploadingFileInputValue: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
    handleFileChange: handleFileChangeAction,
    removeItem: removeItemAction,
};

const mapStateToProps = (state) => ({
    uploadedFiles: state.propertyForm.uploadedFiles,
    uploadingFileInputValue: state.propertyForm.uploadingFileInputValue,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesUploadFields);
