import AcreSpinner from 'components/Common/AcreSpinner';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ACREAPI from 'utils/services/ACREAPI';
import Slider from './Slider';

class SliderContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            featuredArticles: [],
            activeFeaturedArticle: {},
            isLoading: true,
        };
    }

    componentDidMount() {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                ACREAPI.articles()
                    .recommended()
                    .then((res) => {
                        this.setState(
                            {
                                featuredArticles: res.data.recommendPosts.data,
                            },
                            () => {
                                this.onParentActiveImageChange();
                            },
                        );
                    })
                    .catch((err) => {
                        notifiFail({
                            message: "Error: Couldn't load recommended articles",
                            duration: 0,
                        });
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            },
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.onParentActiveImageChange();
        }
    }

    onParentActiveImageChange = () => {
        let activeFeaturedArticle = null;

        this.state.featuredArticles.map((val, i) => {
            if (i + 1 === this.props.blogSlider.activeSlide) {
                activeFeaturedArticle = val;
            }

            return val;
        });

        this.setState({
            activeFeaturedArticle: activeFeaturedArticle,
        });
    };

    render() {
        return this.state.isLoading ? (
            <AcreSpinner />
        ) : (
            <Slider
                featuredArticles={this.state.featuredArticles}
                activeFeaturedArticle={this.state.activeFeaturedArticle}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        blogSlider: state.blogSlider,
    };
};

export default connect(mapStateToProps, null)(SliderContainer);
