import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { deleteUserAction } from 'redux/actions/usersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* deleteUserSaga(action) {
    try {
        yield put(deleteUserAction.request());

        yield call(ACREAPI.users().delete, action.payload);

        notifiSuccess({
            message: 'User has been deleted',
        });

        yield put(deleteUserAction.success());
    } catch (e) {
        notifiFail({
            message: 'Deleting user failed',
        });

        yield put(deleteUserAction.failure());
    } finally {
        yield put(deleteUserAction.fulfill());
    }
}

export default function* deleteUserSagaWatcher() {
    yield takeLatest(deleteUserAction.trigger, deleteUserSaga);
}
