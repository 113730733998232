import PaginationContainer from 'components/Common/Pagination';
import PropertyCard, { propertyPropType } from 'components/Common/Property/PropertyCard';
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import '../../styles/List.scss';

const List = ({ searchedProperties, activePage, onPageChange }) => {
    return (
        <div className={'list-wrapper'}>
            {searchedProperties.properties.length > 0 ? (
                <React.Fragment>
                    <div className={'my-pagination'}>
                        <PaginationContainer
                            onPageChange={onPageChange}
                            itemsPerPage={searchedProperties.pagination.per_page}
                            activePage={parseInt(activePage)}
                            totalItemsNum={searchedProperties.pagination.total}
                        />
                    </div>
                    <div className={'my-list-container'}>
                        {searchedProperties.properties.map((property, index) => (
                            <PropertyCard property={property} key={index} />
                        ))}
                    </div>
                </React.Fragment>
            ) : (
                <Alert color={'info'}>No properties found</Alert>
            )}
        </div>
    );
};

List.propTypes = {
    activePage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onPageChange: PropTypes.func.isRequired,
    searchedProperties: PropTypes.shape({
        properties: PropTypes.arrayOf(propertyPropType).isRequired,
    }),
};

const mapStateToProps = (state) => ({
    searchedProperties: state.searchedProperties,
    activePage: state.propertiesSearchParams.activePage,
});

export default connect(mapStateToProps, null)(List);
