import { articlesAction } from 'redux/actions/articlesActions';

const defaultState = {
    pagination: {
        total: 0,
    },
    data: [],
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case articlesAction.REQUEST:
            return { ...state, isLoading: true };
        case articlesAction.SUCCESS:
            return {
                ...state,
                pagination: action.payload.pagination,
                data: action.payload.data,
            };
        case articlesAction.FULFILL:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
