import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import ACREAPI from 'utils/services/ACREAPI';
import { sendShowPhoneAction } from 'redux/actions/showPhoneActions';

function* showPhoneSaga(action) {
    try {
        yield put(sendShowPhoneAction.request());

        const response = yield call(ACREAPI.showPhone().send, action.payload);

        yield put(sendShowPhoneAction.success(response.data));

        notifiSuccess({
            message: 'Phone was fetched!',
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);

        notifiFail({
            message: 'Phone cannot be fetched from the server',
        });

        yield put(sendShowPhoneAction.failure());
    } finally {
        yield put(sendShowPhoneAction.fulfill());
    }
}

export default function* showPhoneSagaWatcher() {
    yield takeLatest(sendShowPhoneAction.trigger, showPhoneSaga);
}
