import React from 'react';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/FormListElements.module.scss';

const ImportantLinks = ({ importantLinks, removeItem }) => {
    return isNullOrEmptyArray(importantLinks) ? null : (
        <div className={styles['items']}>
            {importantLinks.map((importantLink, index) => (
                <div className={styles['item']} key={index}>
                    <div className={styles['item-no']}>
                        <div>{index + 1}</div>
                    </div>
                    <div className={styles['item-data']}>
                        <div className={styles['item-title']}>
                            <a href={importantLink.link} target={'_blank'} rel='noreferrer'>
                                {importantLink.link}
                            </a>
                        </div>
                        <div className={styles['item-desc']}>{importantLink.desc}</div>
                    </div>
                    <div
                        className={styles['remove-btn']}
                        onClick={() => removeItem({ id: importantLink.id, stateItem: 'importantLinks' })}
                    >
                        <i className={'fas fa-times'} />
                    </div>
                </div>
            ))}
        </div>
    );
};

ImportantLinks.propTypes = {
    importantLinks: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
        }),
    ).isRequired,
    removeItem: PropTypes.func.isRequired,
};

export default ImportantLinks;
