const formats = {
    number: {
        USD: {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        },
    },
    date: {
        long: {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        },
        short: {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        },
    },
};

export default {
    locale: 'en-US',
    formats: formats,
    defaultFormats: formats,
};
