import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'reactstrap';
import { fetchAllRolesAction } from 'redux/actions/rolesActions';
import {
    deleteUserAction,
    exportUsersAction,
    fetchAllUsersAction,
    fetchUserAction,
    updateUserRoleAction,
} from 'redux/actions/usersActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { STATUS_DEFAULT, STATUS_FAIL, STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { PAGINATION } from 'utils/constans/bootstrapTableChangeType';
import { handleInput } from 'utils/functions/inputHelpers';
import { addParamAndPush, deleteParamAndPush } from 'utils/history';
import { PropTypes } from 'utils/wrappers';
import Users from './Users';
import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import ACREAPI from 'utils/services/ACREAPI';
import validator from 'validator';

export const editingUserModalUri = generateModalUriParam('EDITING_USER_MODAL');

class UsersContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            showAddUser: false,
            sendingNewUser: false,
            page: 1,
            selected: [],
            search: '',
            isEditingModalOpened: false,
            editingUserId: null,
            email: '',
            firstName: '',
            lastName: '',
        };
    }

    componentDidMount() {
        this.fetchAllUsers();
        this.props.fetchAllRoles();

        if (!this.state.editingUserId) {
            deleteParamAndPush(editingUserModalUri);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { pagination, deletingUserSending } = this.props;

        if (prevProps.pagination !== pagination) {
            this.setState({
                page: pagination.current_page,
            });
        }

        // update table after deleting a user
        if (prevProps.deletingUserSending !== deletingUserSending && !deletingUserSending) {
            this.fetchAllUsers();
        }

        // update table after editing a user
        if (
            prevProps.requestEditUser.isSending !== this.props.requestEditUser.isSending &&
            !this.props.requestEditUser.isSending &&
            this.props.requestEditUser.status === STATUS_SUCCESS
        ) {
            this.fetchAllUsers();
        }

        // update table after editing a user' role
        if (
            prevProps.requestUpdateUserRole.isSending !== this.props.requestUpdateUserRole.isSending &&
            !this.props.requestUpdateUserRole.isSending &&
            this.props.requestUpdateUserRole.status === STATUS_SUCCESS
        ) {
            this.fetchAllUsers();
        }
    }

    onChangeRole = (event, record) => {
        const roleId = this.props.roles.filter((role) => role.name === event.target.value)[0].id;
        this.props.updateUserRole({ id: record.id, roleId });
    };

    getColumns = () => [
        {
            dataField: 'fname',
            text: 'First name',
        },
        {
            dataField: 'lname',
            text: 'Last name',
        },
        {
            dataField: 'email',
            text: 'Email',
        },
        {
            dataField: 'role',
            text: 'Role',
            formatter: (cell, row) => {
                return (
                    <Form>
                        <Input
                            type={'select'}
                            value={row.role}
                            bsSize={'sm'}
                            onChange={(e) => this.onChangeRole(e, row)}
                        >
                            {this.props.roles.map((role, i) => (
                                <option key={i} value={role.name}>
                                    {role.name}
                                </option>
                            ))}
                        </Input>
                    </Form>
                );
            },
        },
        {
            isDummyField: true,
            dataField: 'dummyField',
            text: '',
            headerStyle: {
                width: '5%',
            },
            formatter: (cell, row) => {
                return (
                    <div>
                        <a href={'#'} onClick={(e) => this.showEditingModal(e, row.id)} title={'Edit user'}>
                            <i className={'fas fa-edit'} />
                        </a>{' '}
                        <a href={'#'} onClick={() => this.onDeleteUser(row.id)} title={'Delete user'}>
                            <i className={'fas fa-trash'} />
                        </a>
                    </div>
                );
            },
        },
    ];

    onDeleteUser = (id) => {
        this.props.deleteUser(id);
    };

    onExportUsers = (type) => {
        this.props.exportUsers({ ids: this.state.selected, type });
    };

    getPaginationOptions = () => {
        const { pagination } = this.props;

        return {
            page: this.state.page,
            sizePerPage: pagination.per_page,
            totalSize: pagination.total,
            showTotal: true,
            hideSizePerPage: true,
        };
    };

    getSelectRowOptions = () => ({
        mode: 'checkbox',
        selected: this.state.selected,
        onSelect: this.onSelectRow,
        onSelectAll: this.onSelectAllRows,
    });

    onSelectRow = (row, isSelected) => {
        if (isSelected) {
            this.setState(() => ({
                selected: [...this.state.selected, row.id],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x !== row.id),
            }));
        }
    };

    onSelectAllRows = (isSelected, rows) => {
        const ids = rows.map((r) => r.id);

        if (isSelected) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };

    onTableChange = (type, data) => {
        if (type === PAGINATION) {
            this.setState(
                {
                    page: data.page,
                },
                () => {
                    this.fetchAllUsers();
                },
            );
        }
    };

    addUserValidate = () => {
        let errors = [];
        const { email, firstName, lastName } = this.state;
        if (!validator.isLength(firstName, { min: 2, max: 255 })) {
            errors.push({ field: 'firstName', message: 'First name is incorrect' });
        }
        if (!validator.isLength(lastName, { min: 2, max: 255 })) {
            errors.push({ field: 'lastName', message: 'Last name is incorrect' });
        }
        if (!validator.isEmail(email)) {
            errors.push({ field: 'email', message: 'Email is incorrect' });
        }
        return errors;
    };

    onAddUser = (e) => {
        e.preventDefault();
        const errors = this.addUserValidate();
        const refresh = this.fetchAllUsers;
        const callback = () => {
            const { email, firstName, lastName } = this.state;
            ACREAPI.auth()
                .register(
                    {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                    },
                    true,
                )
                .then((res) => {
                    notifiSuccess({
                        message: res.data.message,
                    });
                    this.setState({
                        email: '',
                        firstName: '',
                        lastName: '',
                    });
                    this.hideAddUserModal();
                })
                .catch((err) => {
                    if (err.response) {
                        notifiFail({
                            message: err.response.data.message,
                        });
                    }
                })
                .finally(() => {
                    refresh();
                    this.setState({
                        sendingNewUser: false,
                    });
                });
        };
        this.setState(
            {
                errors: errors,
            },
            () => {
                if (this.state.errors.length > 0) {
                    notifiFail({
                        message: errors,
                        title: 'Add user problems:',
                        duration: 0,
                    });

                    return;
                }

                this.setState(
                    {
                        sendingNewUser: true,
                    },
                    callback,
                );
            },
        );
    };

    onSearch = (e) => {
        e.preventDefault();
        this.setState(
            {
                page: 1,
            },
            () => this.fetchAllUsers(),
        );
    };

    fetchAllUsers = () => {
        const data = this.state.search ? { search: this.state.search, page: this.state.page } : this.state.page;

        this.setState(
            {
                selected: [],
            },
            () => {
                this.props.fetchAllUsers(data);
            },
        );
    };

    showAddUserModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            showAddUser: true,
            isEditingModalOpened: false,
        });
    };

    hideAddUserModal = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            showAddUser: false,
            isEditingModalOpened: false,
        });
    };
    showEditingModal = (e, userId) => {
        if (e) {
            e.preventDefault();
        }

        if (userId) {
            addParamAndPush({ [editingUserModalUri]: 'show' });
            this.props.fetchUser(userId);
            this.setState({
                showAddUser: false,
                isEditingModalOpened: true,
                editingUserId: userId,
            });
        }
    };

    hideEditingModal = () => {
        deleteParamAndPush(editingUserModalUri);

        this.setState({
            showAddUser: false,
            isEditingModalOpened: false,
            editingUserId: null,
        });
    };

    render() {
        const { users, areLoading, deletingUserSending } = this.props;
        const { isSending } = this.props.requestUpdateUserRole;

        return (
            <Users
                paginationOptions={this.getPaginationOptions()}
                columns={this.getColumns()}
                users={users}
                areLoading={areLoading || deletingUserSending || isSending}
                onTableChange={this.onTableChange}
                onExportUsers={this.onExportUsers}
                onSearch={this.onSearch}
                onAddUser={this.onAddUser}
                selectRowOptions={this.getSelectRowOptions()}
                showAddUserModal={this.showAddUserModal}
                hideAddUserModal={this.hideAddUserModal}
                showEditingModal={this.showEditingModal}
                hideEditingModal={this.hideEditingModal}
                showAddModal={this.showAddModal}
                hideAddModal={this.hideAddModal}
                isAddModalOpened={this.isAddModalOpened}
                handleInput={this.handleInput}
                search={this.state.search}
                showAddUser={this.state.showAddUser}
                sendingNewUser={this.state.sendingNewUser}
                isEditingModalOpened={this.state.isEditingModalOpened}
                editingUserId={this.state.editingUserId}
                {...this.state}
            />
        );
    }
}

UsersContainer.propTypes = {
    pagination: PropTypes.shape({
        total: PropTypes.number,
        per_page: PropTypes.number,
        count: PropTypes.number,
        current_page: PropTypes.number,
        total_pages: PropTypes.number,
    }),
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            fname: PropTypes.string.isRequired,
            lname: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            role: PropTypes.string.isRequired,
            avatar: PropTypes.string,
            telephones: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    type: PropTypes.string.isRequired,
                    number: PropTypes.string.isRequired,
                }),
            ).isRequired,
            address: PropTypes.shape({
                id: PropTypes.number.isRequired,
                address_first: PropTypes.string,
                address_second: PropTypes.string,
                state: PropTypes.string,
                city: PropTypes.string,
                zip: PropTypes.string,
            }),
            payment_token: PropTypes.string,
        }),
    ).isRequired,
    editedUser: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        data: PropTypes.shape({
            fname: PropTypes.string,
            lname: PropTypes.string,
            email: PropTypes.string,
            telephones: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string,
                    number: PropTypes.string,
                }),
            ),
            address: PropTypes.shape({
                address_first: PropTypes.string,
                address_second: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                zip: PropTypes.string,
            }),
        }).isRequired,
    }),
    fetchAllUsers: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    exportUsers: PropTypes.func.isRequired,
    updateUserRole: PropTypes.func.isRequired,
    areLoading: PropTypes.bool.isRequired,
    deletingUserSending: PropTypes.bool.isRequired,
    requestEditUser: PropTypes.shape({
        isSending: PropTypes.bool.isRequired,
        status: PropTypes.oneOf([STATUS_SUCCESS, STATUS_DEFAULT, STATUS_FAIL]),
    }).isRequired,
    requestUpdateUserRole: PropTypes.shape({
        isSending: PropTypes.bool.isRequired,
        status: PropTypes.oneOf([STATUS_SUCCESS, STATUS_DEFAULT, STATUS_FAIL]),
    }).isRequired,
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    ).isRequired,
};

const mapStateToProps = (state) => ({
    users: state.users.data,
    pagination: state.users.pagination,
    areLoading: state.users.areLoading,
    deletingUserSending: state.deletedUser.isSending,
    requestEditUser: state.requestEditUser,
    roles: state.roles.data,
    requestUpdateUserRole: state.requestUpdateUserRole,
});

const mapDispatchToState = {
    fetchAllUsers: fetchAllUsersAction,
    fetchUser: fetchUserAction,
    deleteUser: deleteUserAction,
    exportUsers: exportUsersAction,
    fetchAllRoles: fetchAllRolesAction,
    updateUserRole: updateUserRoleAction,
};

export default connect(mapStateToProps, mapDispatchToState)(UsersContainer);
