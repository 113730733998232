import validator from 'validator';
import { isRecaptchaEnabled } from 'utils/featureFlags';

export function hasError(fieldName) {
    let has = false;

    this.state.errors.map((error) => {
        if (fieldName === error.field) {
            has = true;
            return false;
        }

        return true;
    });

    return has;
}

export function recaptchaValidator(recaptcha) {
    if (isRecaptchaEnabled()) {
        return !(!recaptcha || !validator.isLength(recaptcha, { min: 1 }));
    }

    return true;
}

export const DEFAULT_VALIDATOR_MESSAGES = { recaptcha: 'You must valid reCaptcha' };
