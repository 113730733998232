import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchPropertyAdminAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';
import routes from 'domain/routes';

function* fetchPropertyAdminSaga(action) {
    try {
        yield put(fetchPropertyAdminAction.request());

        const response = yield call(ACREAPI.properties().fetchPropertyAdmin, action.payload);

        yield put(fetchPropertyAdminAction.success(response.data));
    } catch (e) {
        notifiFail({
            message: 'Loading of property has been failed',
        });

        yield put(fetchPropertyAdminAction.failure());
        yield put(push(routes.panel.properties.main));
    } finally {
        yield put(fetchPropertyAdminAction.fulfill());
    }
}

export default function* fetchPropertyAdminSagaWatcher() {
    yield takeLatest(fetchPropertyAdminAction.trigger, fetchPropertyAdminSaga);
}
