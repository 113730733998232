import 'common/styles/modules/page.scss';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import './ContactUs.module.scss';

const ContactUs = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} className={'content'}>
                    <Title size={2} alignment={ALIGN_LEFT} withoutMarginTop={true}>
                        Contact Us
                    </Title>
                    <p>312 Arizona Ave, Santa Monica, CA 90401</p>
                    <p>Mon-Fri 9am – 5pm (Pacific Time)</p>
                    <h5 className='small-header'>Property Inquiries – General Inquiries</h5>
                    <p>
                        Call: <a href={'tel:888-332-8684'}>888-332-8684</a>
                    </p>
                    <p>
                        Email: <a href={'mailto:info@landzilla.com'}>info@landzilla.com</a>
                    </p>
                    <h5 className='small-header'>Payments and Account Support</h5>
                    <p>
                        Call: <a href={'tel:(949)715-1367'}>(949)715-1367</a>
                    </p>
                    <p>
                        Email: <a href={'mailto:support@landzilla.com'}>support@landzilla.com</a>
                    </p>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ContactUs;
