import React, { Component } from 'react';
import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { connect } from 'react-redux';
import { loginSocialAction } from 'redux/actions/authActions';
import { hideRegistrationModal } from 'redux/actions/modalsActions';
import { handleCheckbox, handleInput } from 'utils/functions/inputHelpers';
import { DEFAULT_VALIDATOR_MESSAGES, hasError, recaptchaValidator } from 'utils/functions/validation';
import ACREAPI from 'utils/services/ACREAPI';
import validator from 'validator';
import RegistrationModal from './RegistrationModal';

const recaptchaRef = React.createRef();
class RegistrationModalContainer extends Component {
    constructor(props) {
        super(props);
        this.handleInput = handleInput.bind(this);
        this.handleCheckbox = handleCheckbox.bind(this);
        this.hasError = hasError.bind(this);

        this.fieldsNames = {
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            password: 'password',
            passwordConfirmation: 'passwordConfirmation',
            areRegulationsChecked: 'areRegulationsChecked',
            recaptcha: 'recaptcha',
        };
        this.fbAPI = null;

        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            passwordConfirmation: '',
            areRegulationsChecked: false,
            errors: [],
            isLoading: false,
        };
    }

    // componentDidMount() {
    // }

    onSubmit = (e) => {
        e.preventDefault();

        const errors = this.validate();
        this.setState(
            {
                errors: errors,
            },
            () => {
                if (this.state.errors.length > 0) {
                    notifiFail({
                        message: errors,
                        title: 'Registration problems:',
                        duration: 0,
                    });

                    return;
                }

                this.setState(
                    {
                        isLoading: true,
                    },
                    () => {
                        const { email, password, passwordConfirmation, firstName, lastName, recaptcha } = this.state;

                        ACREAPI.auth()
                            .register({
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                password: password,
                                passwordConfirmation: passwordConfirmation,
                                recaptcha: recaptcha,
                            })
                            .then((res) => {
                                notifiSuccess({
                                    message: res.data.message,
                                });

                                this.setState({
                                    password: '',
                                    passwordConfirmation: '',
                                    email: '',
                                    firstName: '',
                                    lastName: '',
                                    areRegulationsChecked: false,
                                    recaptcha: '',
                                });

                                this.props.hideRegistrationModal();
                            })
                            .catch((err) => {
                                if (err.response) {
                                    notifiFail({
                                        message: err.response.data.message,
                                    });
                                }
                            })
                            .finally(() => {
                                recaptchaRef.current.reset();
                                this.setState({
                                    isLoading: false,
                                });
                            });
                    },
                );
            },
        );
    };

    validate = () => {
        let errors = [];
        const { email, passwordConfirmation, password, firstName, lastName, areRegulationsChecked, recaptcha } =
            this.state;

        if (!validator.isEmail(email)) {
            errors.push({ field: this.fieldsNames.email, message: 'Email is incorrect' });
        }

        if (!validator.isLength(email, { min: 1, max: 255 })) {
            errors.push({ field: this.fieldsNames.email, message: 'Email must be at least 1 character' });
        }

        if (!recaptchaValidator(recaptcha)) {
            errors.push({ field: this.fieldsNames.recaptcha, message: DEFAULT_VALIDATOR_MESSAGES.recaptcha });
        }

        if (password !== passwordConfirmation || validator.isEmpty(password)) {
            errors.push({
                field: this.fieldsNames.password,
                message: 'Password and password confirmation must be the same.',
            });
        }

        if (!validator.isLength(password, { min: 6 })) {
            errors.push({ field: this.fieldsNames.password, message: 'Password must be at least 6 character' });
        }

        if (!validator.isLength(firstName, { min: 2, max: 255 })) {
            errors.push({
                field: this.fieldsNames.firstName,
                message: 'First name must have minimum 2 chars and maximum 255 chars',
            });
        }

        if (!validator.isLength(lastName, { min: 2, max: 255 })) {
            errors.push({
                field: this.fieldsNames.lastName,
                message: 'Last name must have minimum 2 chars and maximum 255 chars',
            });
        }

        if (!areRegulationsChecked) {
            errors.push({
                field: this.fieldsNames.areRegulationsChecked,
                message: 'You must agree with Terms and Privacy Policy',
            });
        }

        return errors;
    };

    render() {
        return (
            <RegistrationModal
                handleInput={this.handleInput}
                handleCheckbox={this.handleCheckbox}
                onSubmit={this.onSubmit}
                hasError={this.hasError}
                fieldsNames={this.fieldsNames}
                recaptchaRef={recaptchaRef}
                {...this.state}
            />
        );
    }
}

const mapDispatchToProps = {
    hideRegistrationModal: hideRegistrationModal,
    loginBySocial: loginSocialAction,
};

export default connect(null, mapDispatchToProps)(RegistrationModalContainer);
