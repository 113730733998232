import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { exportAdminPropertiesAction } from 'redux/actions/propertiesActions';
import { download } from 'utils/functions/files';
import ACREAPI from 'utils/services/ACREAPI';

function* exportAdminPropertiesSaga(action) {
    try {
        yield put(exportAdminPropertiesAction.request());

        const response = yield call(ACREAPI.properties().adminExport, action.payload);

        download(response.data, 'listings', action.payload.type);

        yield put(exportAdminPropertiesAction.success());
    } catch (e) {
        notifiFail({
            message: 'Properties cannot be exported',
        });

        yield put(exportAdminPropertiesAction.failure());
    } finally {
        yield put(exportAdminPropertiesAction.fulfill());
    }
}

export default function* exportAdminPropertiesSagaWatcher() {
    yield takeLatest(exportAdminPropertiesAction.trigger, exportAdminPropertiesSaga);
}
