import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, NavItem } from 'reactstrap';
import { isAdmin, isLogged } from 'utils/functions/authorization';
import { PropTypes } from 'utils/wrappers';
import routes from '../../../../domain/routes';

const AuthMenuItems = ({ loginUrl, registerUrl, panelUrl }) => {
    let items = null;
    if (isLogged()) {
        items = (
            <React.Fragment>
                <NavItem tag={Link} to={panelUrl} className={'auth-button'}>
                    <Button color='primary'>{isAdmin() ? 'Admin' : 'Account'}</Button>
                </NavItem>
                <NavItem tag={Link} to={routes.logout} className='logout-button auth-button'>
                    <Button>{'Logout'}</Button>
                </NavItem>
            </React.Fragment>
        );
    } else {
        items = (
            <React.Fragment>
                <NavItem className='login-button auth-button' tag={Link} to={loginUrl}>
                    <Button color='secondary' outline>
                        Login
                    </Button>
                </NavItem>
                <NavItem className='sign-up-button auth-button' tag={Link} to={registerUrl}>
                    <Button color='primary'>Sign Up</Button>
                </NavItem>
            </React.Fragment>
        );
    }

    return items;
};

AuthMenuItems.propTypes = {
    registerUrl: PropTypes.string.isRequired,
    loginUrl: PropTypes.string.isRequired,
    panelUrl: PropTypes.string.isRequired,
    user: PropTypes.shape({
        token: PropTypes.string,
        role: PropTypes.string,
        email: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(AuthMenuItems);
