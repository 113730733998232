import Title from 'components/Common/Title';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const ListingPurchase = () => {
    return (
        <Container>
            <Row>
                <Col xs={12}>
                    <Title size={3} alignment={'center'} underline>
                        Thank you very much for purchase. We has sent confirmation on your e-mail.
                    </Title>
                </Col>
            </Row>
        </Container>
    );
};

export default ListingPurchase;
