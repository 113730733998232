import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { isEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from './styles/TitleWithActions.module.scss';

const TitleWithActions = ({ children, actions }) => {
    const cssClass = classes.title;
    let afterTitle = null;

    if (!isEmptyArray(actions)) {
        afterTitle = (
            <div className={classes.actions}>
                {actions.map((action, index) => {
                    const name = action.name;
                    const url = action.url;

                    return (
                        <Button key={index} color='primary' tag={Link} to={url} className={classes.action} size='md'>
                            {name}
                        </Button>
                    );
                })}
            </div>
        );
    }

    return (
        <Title size={1} alignment={ALIGN_LEFT} withoutMarginTop={true} className={cssClass} afterTitle={afterTitle}>
            {children}
        </Title>
    );
};

TitleWithActions.propTypes = {
    children: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
};

TitleWithActions.defaultProps = {
    actions: [],
};

export default TitleWithActions;
