import draftToHtml from 'draftjs-to-html';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { isNullOrEmptyObject } from 'utils/functions/objects';

class PropertyConverter {
    toNumber(obj) {
        return typeof obj === 'number' ? obj : 0;
    }

    toString(obj) {
        return typeof obj === 'string' ? obj : '';
    }

    toDomain(source) {
        if (isNullOrEmptyObject(source)) {
            return null;
        }

        //console.log('SOURCE PRICE: ', source.price);

        return {
            id: source.id,
            name: source.title,
            slug: source.slug,
            categories: this.prepareCategories(source),
            location: {
                address: source.geo.address,
                city: source.geo.city,
                county: source.geo.county,
                state: source.geo.state,
                zip: source.geo.zip,
            },
            images: this.prepareImages(source),
            description: PropertyConverter.prepareContent(source),
            listingId: source.listing_id,
            financialTerm: this.prepareFinancialTerm(source),
            finances: {
                price: source.price.price,
                monthlyPayment: source.price.monthly_payment,
                processingFee: this.toNumber(source.price.processing_fee),
            },
            propertyState: source.geo.state,
            propertyCounty: source.geo.county,
            parcelNumber: source.apn,
            landSize: source.geo.acreage,
            gpsLocation: {
                longitude: source.geo.longitude,
                latitude: source.geo.latitude,
            },
            roadAccess: source.geo.road_access,
            utilities: this.prepareUtilities(source),
            zoning: source.zoning,
            zoningDescription: source.zoning_desc,
            taxes: source.price.taxes,
            subdivisionName: source.subdivision ? source.subdivision.name : '',
            subdivisionYearlyDues: source.subdivision ? source.subdivision.yearly_dues : 0,
            relatedLinks: this.prepareLinks(source),
            relatedVideos: this.prepareVideos(source),
            relatedDocs: this.prepareDocs(source),
            seller: {
                id: source.seller ? source.seller.id : null,
                name: this.prepareSellerName(source),
                phone: '', //is fetched via show-phone endpoint
                logoPath: this.prepareSellerLogoUrl(source),
            },
            isFavourite: source.is_favorite,
        };
    }

    prepareCategories(source) {
        if (isNullOrEmptyArray(source.categories)) {
            return null;
        }

        return source.categories.map((category) => {
            return {
                name: category.name,
                slug: category.slug,
            };
        });
    }

    prepareLinks(source) {
        if (isNullOrEmptyArray(source.links)) {
            return null;
        }

        return source.links.map((link) => {
            return {
                name: link.desc,
                url: link.name,
            };
        });
    }

    prepareVideos(source) {
        if (isNullOrEmptyArray(source.videos)) {
            return null;
        }

        return source.videos.map((video) => {
            return {
                name: video.desc,
                url: video.name,
            };
        });
    }

    prepareDocs(source) {
        if (isNullOrEmptyArray(source.docs)) {
            return null;
        }

        return source.docs.map((doc) => {
            return {
                name: doc.name,
                url: doc.uri,
            };
        });
    }

    prepareImages(source) {
        if (isNullOrEmptyArray(source.gallery)) {
            return null;
        }

        return source.gallery.map((image) => {
            return {
                thumbnailUrl: image.preview,
                fullSizeUrl: image.fullsize,
            };
        });
    }

    prepareSellerName(source) {
        if (
            source.seller === null ||
            source.seller === undefined ||
            source.seller.head === null ||
            source.seller.head === undefined
        ) {
            return null;
        }

        return source.seller.head;
    }

    prepareSellerLogoUrl(source) {
        if (
            source.seller === null ||
            source.seller === undefined ||
            source.seller.avatar === null ||
            source.seller.avatar === undefined ||
            source.seller.avatar.public_uri === null ||
            source.seller.avatar.public_uri === undefined
        ) {
            return null;
        }

        return source.seller.avatar.public_uri;
    }

    prepareUtilities(source, asString = true) {
        if (isNullOrEmptyArray(source.utilities)) {
            return null;
        }

        const utilitiesNames = source.utilities.map((utility) => {
            return utility.name;
        });

        if (asString) {
            return utilitiesNames.join(', ');
        }

        return utilitiesNames;
    }

    prepareFinancialTerm(source) {
        if (
            source.price === null ||
            source.price === undefined ||
            source.price.financial_term === null ||
            source.price.financial_term === undefined ||
            source.price.percentage_rate === null ||
            source.price.percentage_rate === undefined
        ) {
            return null;
        }

        const financialTerm = parseInt(source.price.financial_term);
        const numberMonthInYear = 12;

        const financeTermMonths = financialTerm % numberMonthInYear;
        const financeTermYears = (financialTerm - financeTermMonths) / numberMonthInYear;

        return (
            (financeTermYears ? financeTermYears + ' yrs.' : '') +
            (financeTermYears && financeTermMonths ? ' and ' : '') +
            (financeTermMonths ? financeTermMonths + ' mos.' : '') +
            ' at ' +
            source.price.percentage_rate +
            '%'
        );
    }

    static prepareContent(source) {
        if (source.description === undefined) {
            return undefined;
        }

        let result = '';

        try {
            let parsed = JSON.parse(source.description);
            result = draftToHtml(parsed);
        } catch (error) {
            result = source.description;
        }

        return result;
    }

    getMainImage(source) {
        const images = this.prepareImages(source);

        if (images === null || !images[0]) {
            return null;
        }

        return images[0];
    }
}

export default PropertyConverter;
