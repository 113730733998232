import React, { Component } from 'react';
import LandDeals from './LandDeals';

class LandDealsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            boxesContent: [],
        };
    }

    componentDidMount() {
        this.setState({
            boxesContent: this.getBoxesContent(),
        });
    }

    getBoxesContent = () => {
        return [
            {
                iconCssClass: 'fas fa-dollar-sign',
                title: 'Cheap Land',
                description:
                    'With decades of land buying experience we’re able to leverage our resources to find ' +
                    'off-market deals on quality land properties and bring them straight to you.',
            },
            {
                iconCssClass: 'fas fa-home',
                title: 'Owner Financed Land',
                description:
                    'You’re approved! Many of our properties can be purchased with pre-approved in-house ' +
                    'financing options. NO Banks, Credit Checks, or hoops to jump through.',
            },
        ];
    };

    render() {
        return <LandDeals boxes={this.state.boxesContent} />;
    }
}

export default LandDealsContainer;
