import React from 'react';
import { Input } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import '../../../../styles/FilterCheckboxes.scss';

const FilterCheckboxes = ({ elements, filterSlug, toggleCheckbox, isElementChecked }) => (
    <div className={'filter-checkboxes'}>
        {elements.map((element, index) => {
            return (
                <div className={'element'} key={index}>
                    <Input
                        id={'checkbox_' + filterSlug + '_' + index}
                        type={'checkbox'}
                        onChange={(e) => toggleCheckbox(e, element)}
                        checked={isElementChecked(filterSlug, element.value)}
                    />
                    <label htmlFor={'checkbox_' + filterSlug + '_' + index}>{element.label}</label>
                </div>
            );
        })}
    </div>
);

FilterCheckboxes.propTypes = {
    filterSlug: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }),
    ).isRequired,
    toggleCheckbox: PropTypes.func.isRequired,
    isElementChecked: PropTypes.func.isRequired,
};

export default FilterCheckboxes;
