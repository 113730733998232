import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { articlesAction } from 'redux/actions/articlesActions';
import { PropTypes } from 'utils/wrappers';
import Articles from './Articles';

class ArticlesContainer extends Component {
    render() {
        const { articles, isLoading } = this.props;

        return isLoading ? <AcreSpinner /> : <Articles articles={articles} />;
    }
}

ArticlesContainer.propTypes = {
    articles: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getArticles: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getArticles: articlesAction,
};

const mapStateToProps = (state, ownProps) => {
    return {
        articles: state.articles.data,
        isLoading: state.articles.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesContainer);
