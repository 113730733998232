import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getRelatedPropertiesElementsAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* getRelatedPropertiesElementsSaga(action) {
    try {
        yield put(getRelatedPropertiesElementsAction.request());

        const response = yield call(ACREAPI.properties().getRelatedPropertiesElements, action.payload);

        yield put(getRelatedPropertiesElementsAction.success(response.data.result));
    } catch (e) {
        notifiFail({
            message: 'Statuses cannot be loaded',
        });

        yield put(getRelatedPropertiesElementsAction.failure());
    } finally {
        yield put(getRelatedPropertiesElementsAction.fulfill());
    }
}

export default function* getRelatedPropertiesElementsSagaWatcher() {
    yield takeLatest(getRelatedPropertiesElementsAction.trigger, getRelatedPropertiesElementsSaga);
}
