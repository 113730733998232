import classes from './styles/ShowPhoneButton.module.scss';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { sendShowPhoneAction } from 'redux/actions/showPhoneActions';
import { PropTypes } from 'utils/wrappers';
import { ReCaptchaWrapper } from 'components/Common/ReCaptcha';
import { recaptchaValidator } from 'utils/functions/validation';
import { isRecaptchaEnabled } from 'utils/featureFlags';

const ShowPhoneButton = ({ sendShowPhone, isLoading, phoneNumber, sellerId, listingId }) => {
    const recaptchaEnabled = isRecaptchaEnabled();
    const [recaptchaClicked, setRecaptchaClicked] = useState(!recaptchaEnabled);
    const [recaptchaVal, setRecaptchaVal] = useState(false);
    const recaptchaRef = React.createRef();

    const showPhoneClickHandler = () => {
        sendShowPhone({
            sellerId,
            listingId,
            recaptcha: recaptchaVal,
        });
    };

    const handleGoogleRecaptcha = (recaptchaVal) => {
        if (recaptchaValidator(recaptchaVal)) {
            setRecaptchaClicked(true);
            setRecaptchaVal(recaptchaVal);
        }
    };

    const getSpinnerButton = () => {
        return (
            <SpinnerButton
                color='primary'
                size='sm'
                className={classes.ShowPhoneButton}
                isLoading={isLoading}
                onClick={showPhoneClickHandler}
            >
                Show phone
            </SpinnerButton>
        );
    };

    const getRecaptchaInfo = () => {
        return (
            <div className={classes.RecaptchaInfoVerificationRequired}>Verify you're human to see the phone number</div>
        );
    };

    const getButtonWithRecaptcha = () => {
        return (
            <React.Fragment>
                {recaptchaClicked ? getSpinnerButton() : getRecaptchaInfo()}
                <ReCaptchaWrapper
                    style={{ transformOrigin: 'center center' }}
                    recaptchaRef={recaptchaRef}
                    handleGoogleRecaptcha={handleGoogleRecaptcha}
                />
            </React.Fragment>
        );
    };

    return phoneNumber ? <div>{phoneNumber}</div> : getButtonWithRecaptcha();
};

ShowPhoneButton.propTypes = {
    sendShowPhone: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    phoneNumber: PropTypes.string,
    sellerId: PropTypes.number.isRequired,
    listingId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.showPhone.isLoading,
        phoneNumber: state.showPhone.phoneNumber,
    };
};

const mapDispatchToProps = {
    sendShowPhone: sendShowPhoneAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowPhoneButton);
