import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendRequestResetPasswordAction } from 'redux/actions/authActions';
import { hideForgotPasswordModal } from 'redux/actions/modalsActions';
import { STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { handleInput } from 'utils/functions/inputHelpers';
import ForgotPasswordModal from './ForgotPasswordModal';
import validator from 'validator';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { DEFAULT_VALIDATOR_MESSAGES, hasError, recaptchaValidator } from 'utils/functions/validation';

const recaptchaRef = React.createRef();

class ForgotPasswordModalContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);
        this.hasError = hasError.bind(this);
        this.fieldsNames = {
            email: 'email',
            recaptcha: 'recaptcha',
        };
        this.state = {
            email: '',
            recaptcha: '',
            errors: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.requestPasswordReset.status !== prevProps.requestPasswordReset.status &&
            this.props.requestPasswordReset.status === STATUS_SUCCESS
        ) {
            this.props.hideForgotPasswordModal();
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();

        this.setState({ errors: errors }, () => {
            if (this.state.errors.length > 0) {
                notifiFail({
                    message: errors,
                    title: 'Validation problems:',
                    duration: 0,
                });

                return;
            }

            recaptchaRef.current.reset();
            this.props.sendRequestResetPassword({ email: this.state.email, recaptcha: this.state.recaptcha });
            this.setState({
                email: '',
                recaptcha: '',
            });
        });
    };

    validate = () => {
        let errors = [];
        const { email, recaptcha } = this.state;

        if (!validator.isEmail(email)) {
            errors.push({ field: this.fieldsNames.email, message: 'Email is incorrect' });
        }

        if (!recaptchaValidator(recaptcha)) {
            errors.push({ field: this.fieldsNames.recaptcha, message: DEFAULT_VALIDATOR_MESSAGES.recaptcha });
        }

        return errors;
    };

    render() {
        return (
            <ForgotPasswordModal
                hasError={this.hasError}
                email={this.state.email}
                handleInput={this.handleInput}
                onSubmit={this.onSubmit}
                errors={this.state.errors}
                recaptchaRef={recaptchaRef}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        requestPasswordReset: state.requestPasswordReset,
    };
};

const mapDispatchToProps = {
    sendRequestResetPassword: sendRequestResetPasswordAction,
    hideForgotPasswordModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModalContainer);
