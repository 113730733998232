import React from 'react';
import { Helmet } from 'react-helmet';
import { websiteJsonLD, organizationJsonLD } from 'domain/seo/JsonLD/jsonld';
import { PropTypes } from 'utils/wrappers';
import { get_description, get_title } from 'domain/seo/title';

const HtmlHead = ({ title, description }) => {
    const t = title ? title : get_title();
    const d = description ? description : get_description();
    const j = 'application/ld+json';
    return (
        <Helmet>
            <title>{t}</title>
            <meta name='description' content={d} />
            <script id='breadcrumbJsonLD' type={j}></script>
            <script id='websiteJsonLD' type={j}>
                {websiteJsonLD()}
            </script>
            <script id='organizationJsonLD' type={j}>
                {organizationJsonLD()}
            </script>
            <script id='personJsonLD' type={j}></script>
            <script id='productJsonLD' type={j}></script>
            <script id='imageJsonLD' type={j}></script>
            <script id='articleJsonLD' type={j}></script>
            <meta property='og:locale' content='en_US' />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={t} />
            <meta property='og:description' content={d} />
            <meta property='og:url' content={window.location.href} />
            <meta property='og:site_name' content='Landzilla' />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:description' content={d} />
            <meta name='twitter:title' content={t} />
        </Helmet>
    );
};

HtmlHead.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

export default HtmlHead;
