import Price from 'components/Common/Property/Price';
import Size from 'components/Common/Property/Size';
import React from 'react';
import { Button } from 'reactstrap';
import { joinNotEmpty } from 'utils/functions/arrays';
import { getReplacementIfEmpty } from 'utils/functions/miscellaneous';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/BuyNowBox.module.scss';

const BuyNowBox = ({ property, className, onBuy }) => {
    const price = <Price value={property.finances.price} />;
    const monthlyPayment = <Price value={property.finances.monthlyPayment} withFraction={true} />;
    const processingFee = <Price value={property.finances.processingFee} />;
    const size = <Size value={property.landSize} />;
    const cssClass = joinNotEmpty([classes['buy-now-box'], className], ' ');

    return (
        <div className={cssClass}>
            <div className={classes.wrapper}>
                <div className={classes['price-and-size']}>
                    <div className={classes.price}>
                        <div className={classes.value}>{getReplacementIfEmpty(price)}</div>
                        <div className={classes['below-price']}>Purchase price</div>
                    </div>
                    <div className={classes.size}>
                        <div className={classes.value}>{getReplacementIfEmpty(size)}</div>
                        <div className={classes['below-size']}>Property size</div>
                    </div>
                </div>
                <div className={classes['short-information']}>
                    <div className={classes['listing-id']}>
                        <span className={classes.name}>Listing ID:</span>
                        <span className={classes.value}>{getReplacementIfEmpty(property.listingId)}</span>
                    </div>
                    <div className={classes['financing-term']}>
                        <span className={classes.name}>Financing Term:</span>
                        <span className={classes.value}>{getReplacementIfEmpty(property.financialTerm)}</span>
                    </div>
                    <div className={classes['monthly-payment']}>
                        <span className={classes.name}>Monthly Payment:</span>
                        <span className={classes.value}>{getReplacementIfEmpty(monthlyPayment)}</span>
                    </div>
                </div>
                <div className={classes['processing-fee']}>
                    <span className={classes.name}>Processing Fee:</span>
                    <span className={classes.value}>{getReplacementIfEmpty(processingFee)}</span>
                </div>
            </div>
            <div className={classes['buy-button']}>
                <Button color='primary' size='lg' className={classes.button} onClick={onBuy}>
                    Buy it now
                </Button>
            </div>
        </div>
    );
};

BuyNowBox.propTypes = {
    property: PropTypes.shape({
        listingId: PropTypes.string.isRequired,
        landSize: PropTypes.number.isRequired,
        financialTerm: PropTypes.string,
        finances: PropTypes.shape({
            price: PropTypes.number.isRequired,
            monthlyPayment: PropTypes.number.isRequired,
            processingFee: PropTypes.number.isRequired,
        }).isRequired,
        seller: PropTypes.shape({
            name: PropTypes.string,
        }),
    }).isRequired,
    className: PropTypes.string,
    onBuy: PropTypes.func.isRequired,
};

export default BuyNowBox;
