import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState as DraftEditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { joinNotEmpty } from 'utils/functions/arrays';

class EditorState {
    constructor(state: DraftEditorState = null) {
        this.use(state);
    }

    static loadFromHtml(htmlContent: String): DraftEditorState {
        const blocks = convertFromHTML(htmlContent);
        const contentState = ContentState.createFromBlockArray(blocks);

        return DraftEditorState.createWithContent(contentState);
    }

    static loadFromText(plainTextContent: String): DraftEditorState {
        const contentState = ContentState.createFromText(plainTextContent);
        return DraftEditorState.createWithContent(contentState);
    }

    static loadFromJson(jsonContent: String): DraftEditorState {
        const parsed = JSON.parse(jsonContent);
        const contentState = convertFromRaw(parsed);

        return DraftEditorState.createWithContent(contentState);
    }

    static createNew() {
        return DraftEditorState.createEmpty();
    }

    use(state: DraftEditorState): EditorState {
        this.state = state;

        return this;
    }

    toHtml(): String {
        if (this.state === null) {
            return null;
        }

        const contentState = this.state.getCurrentContent();
        const rawDraft = convertToRaw(contentState);

        return draftToHtml(rawDraft);
    }

    toText(): String {
        const html = this.toHtml();

        if (html === null) {
            return null;
        }

        const blocks = convertFromHTML(html);
        const content = blocks.contentBlocks.map((block) => block.getText());

        return joinNotEmpty(content, ' ');
    }

    toJson(): String {
        return JSON.stringify(convertToRaw(this.state.getCurrentContent()));
    }
}

export default EditorState;
