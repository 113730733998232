// import { priceFilter, sizeFilter } from 'data/properties/filters';
import Filter from 'domain/property/Filter';
import React from 'react';
import Select from 'react-select';
import { Button, Form, FormGroup } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
// import '../styles/SearchForm.scss';

const SearchForm = ({
    states,
    handleSubmit,
    handleAddressChange,
    handleStateChange,
    handlePriceChange,
    handleSizeChange,
}) => {
    const cssClasses = {
        container: 'filterable-select-container',
        prefix: 'filterable-select',
    };

    const statesFilter = new Filter(states);
    const statesOptions = statesFilter.toOptions();

    // const prices = priceFilter.values;
    // const sizes = sizeFilter.values;

    return (
        <Form className='search-box' onSubmit={handleSubmit}>
            <FormGroup>
                <div className={'input'}>
                    <Select
                        placeholder='Choose state'
                        options={statesOptions}
                        className={cssClasses.container}
                        classNamePrefix={cssClasses.prefix}
                        isClearable={true}
                        name='filterState'
                        menuPlacement={'bottom'}
                        onChange={handleStateChange}
                    />
                </div>
                <div className={'button'}>
                    <Button color='primary' type='submit'>
                        <i className='fas fa-search icon-in-field' />
                    </Button>
                </div>
            </FormGroup>
        </Form>
    );
};

SearchForm.propTypes = {
    states: PropTypes.arrayOf(PropTypes.object),
    handleSubmit: PropTypes.func.isRequired,
    handleAddressChange: PropTypes.func.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    handlePriceChange: PropTypes.func.isRequired,
    handleSizeChange: PropTypes.func.isRequired,
};

export default SearchForm;
