import Layout from 'components/Common/Layout';
import React from 'react';
import ResponseError500 from 'components/Page/ResponseError/ResponseError500';

const Error500Page = () => {
    return (
        <Layout title={'Error'}>
            <ResponseError500 />
        </Layout>
    );
};

export default Error500Page;
