import { sendShowPhoneAction } from 'redux/actions/showPhoneActions';

const defaultState = {
    phoneNumber: null,
    isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
    switch (action.type) {
        case sendShowPhoneAction.REQUEST:
            return { ...state, isLoading: true };
        case sendShowPhoneAction.SUCCESS:
            return {
                ...state,
                phoneNumber: action.payload.phone_number,
            };
        case sendShowPhoneAction.FULFILL:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
