import { store } from 'redux/store';
import ACREAPI from 'utils/services/ACREAPI';
import { ENDPOINT_URL_PREFIX as USERS_ENDPOINT_URL_PREFIX } from './Users';

export default class FavouriteProperties {
    static index = () => {
        const id = store.getState().user.id;

        return ACREAPI.authInstance().get(USERS_ENDPOINT_URL_PREFIX + id + '/favorite');
    };
}
