import React from 'react';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/TestimonialBox.module.scss';

const TestimonialBox = ({ opinion, client, location }) => {
    return (
        <div className={classes['testimonial-box']}>
            <div className={classes.opinion}>{opinion}</div>
            <div className={classes.triangle} />
            <div className={classes.client}>{client}</div>
            <div className={classes.location}>{location}</div>
        </div>
    );
};

TestimonialBox.propTypes = {
    opinion: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
};

export default TestimonialBox;
