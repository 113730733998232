import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { STATUS_404 } from 'domain/constants/responseStatus';
import { call, put, takeLatest } from 'redux-saga/effects';
import { isFavouritePropertyAction } from 'redux/actions/propertiesActions';
import redirectToErrorPage from 'redux/utils/redirectToErrorPage';
import ACREAPI from 'utils/services/ACREAPI';

function* isFavouritePropertySaga(action) {
    try {
        yield put(isFavouritePropertyAction.request());
        const response = yield call(ACREAPI.properties().isFavouriteProperty, action.payload);
        yield put(isFavouritePropertyAction.success(response.data.result));
    } catch (error) {
        notifiFail({
            message: 'Loading information if property is favourite has failed',
            duration: 3000,
        });

        yield put(isFavouritePropertyAction.failure());

        if (error.response !== undefined && error.response.status !== undefined && error.response.status === 404) {
            yield redirectToErrorPage(STATUS_404);
        }
    } finally {
        yield put(isFavouritePropertyAction.fulfill());
    }
}

export default function* isFavouritePropertySagaWatcher() {
    yield takeLatest(isFavouritePropertyAction.TRIGGER, isFavouritePropertySaga);
}
