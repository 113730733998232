import AcreBootstrapTable from 'components/Common/AcreBootstrapTable';
import AnswerDeleteModalContainer from 'components/Page/Panel/Properties/Part/AnswerDeleteModal';
import routes from 'domain/routes';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import './Properties.scss';
import StatisticsModal from 'components/Page/Panel/Properties/Part/StatisticsModal';

const filtersFields = {
    state: 'State',
    county: 'County',
    from: 'from',
    to: 'to',
};

const bsSize = 'sm';

const Properties = ({
    columns,
    properties,
    paginationOptions,
    onTableChange,
    onApplyFilters,
    handleInput,
    arePropertiesLoading,
    requestChangePropertyStatusIsSending,
    selectRowOptions,
    onExport,
    filterState,
    filterCounty,
    filterSizeFrom,
    filterSizeTo,
    filterPriceFrom,
    filterPriceTo,
    filterStatus,
    relatedPropertiesElements,
    toggleAnswerDeleteModal,
    fetchProperties,
    propertyIdToDelete,
    isAnswerDeleteModalOpen,
    statisticsModalStats,
    isStatisticsModalOpen,
    statisticsModalToggle,
}) => {
    return (
        <React.Fragment>
            <AnswerDeleteModalContainer
                toggle={toggleAnswerDeleteModal}
                fetchProperties={fetchProperties}
                isOpen={isAnswerDeleteModalOpen}
                propertyIdToDelete={propertyIdToDelete}
            />
            <StatisticsModal
                toggle={statisticsModalToggle}
                isOpen={isStatisticsModalOpen}
                stats={statisticsModalStats}
            />
            <div className={'header adding-header'}>
                <Button color={'primary'} tag={Link} to={routes.panel.properties.create}>
                    Add Listing
                </Button>
            </div>
            <div className={'header'}>
                <Form className={'filters'} onSubmit={onApplyFilters}>
                    <FormGroup>
                        <Label>{filtersFields.state}</Label>
                        <Input
                            placeholder={filtersFields.state}
                            bsSize={bsSize}
                            name={'filterState'}
                            value={filterState}
                            onChange={handleInput}
                            type={'text'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{filtersFields.county}</Label>
                        <Input
                            placeholder={filtersFields.county}
                            bsSize={bsSize}
                            name={'filterCounty'}
                            value={filterCounty}
                            onChange={handleInput}
                            type={'text'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Size</Label>
                        <Input
                            placeholder={filtersFields.from}
                            bsSize={bsSize}
                            name={'filterSizeFrom'}
                            value={filterSizeFrom}
                            onChange={handleInput}
                            type={'text'}
                        />
                        <Input
                            placeholder={filtersFields.to}
                            bsSize={bsSize}
                            name={'filterSizeTo'}
                            value={filterSizeTo}
                            onChange={handleInput}
                            type={'text'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Price</Label>
                        <Input
                            placeholder={filtersFields.from}
                            bsSize={bsSize}
                            name={'filterPriceFrom'}
                            value={filterPriceFrom}
                            onChange={handleInput}
                            type={'text'}
                        />
                        <Input
                            placeholder={filtersFields.to}
                            bsSize={bsSize}
                            name={'filterPriceTo'}
                            value={filterPriceTo}
                            onChange={handleInput}
                            type={'text'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Status</Label>
                        <Input
                            bsSize={bsSize}
                            name={'filterStatus'}
                            value={filterStatus}
                            onChange={handleInput}
                            type={'select'}
                        >
                            <option value={''}>-</option>
                            {Object.keys(relatedPropertiesElements.listingStatuses).map((key, i) => (
                                <option key={i} value={relatedPropertiesElements.listingStatuses[key]}>
                                    {key}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <div className={'button'}>
                        <Button type={'submit'}>Apply</Button>
                    </div>
                </Form>
                <div className={'exports'}>
                    <span>Export to:</span>
                    <Button onClick={() => onExport('pdf')}>PDF</Button>
                    <Button onClick={() => onExport('csv')}>CSV</Button>
                    <Button onClick={() => onExport('xls')}>XLS</Button>
                </div>
            </div>
            <AcreBootstrapTable
                keyField={'id'}
                columns={columns}
                data={properties}
                paginationOptions={paginationOptions}
                remote={{
                    filter: false,
                    pagination: true,
                    sort: false,
                    cellEdit: false,
                }}
                onTableChange={onTableChange}
                loading={arePropertiesLoading || requestChangePropertyStatusIsSending}
                selectRow={selectRowOptions}
            />
        </React.Fragment>
    );
};

Properties.propTypes = {
    onTableChange: PropTypes.func.isRequired,
    onApplyFilters: PropTypes.func.isRequired,
    fetchProperties: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    toggleAnswerDeleteModal: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataField: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            isDummyField: PropTypes.bool,
            headerStyle: PropTypes.object,
            formatter: PropTypes.func,
        }),
    ).isRequired,
    selectRowOptions: PropTypes.shape({
        mode: PropTypes.string,
        selected: PropTypes.array,
        onSelect: PropTypes.func,
        onSelectAll: PropTypes.func,
    }).isRequired,
    paginationOptions: PropTypes.shape({
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        totalSize: PropTypes.number,
        showTotal: PropTypes.bool,
        hideSizePerPage: PropTypes.bool,
    }).isRequired,
    filterState: PropTypes.string.isRequired,
    filterCounty: PropTypes.string.isRequired,
    filterSizeFrom: PropTypes.string.isRequired,
    filterSizeTo: PropTypes.string.isRequired,
    filterPriceFrom: PropTypes.string.isRequired,
    filterPriceTo: PropTypes.string.isRequired,
    filterStatus: PropTypes.string.isRequired,
    isAnswerDeleteModalOpen: PropTypes.bool.isRequired,
    propertyIdToDelete: PropTypes.number,
    statisticsModalStats: PropTypes.object.isRequired,
    isStatisticsModalOpen: PropTypes.bool.isRequired,
    statisticsModalToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    properties: state.adminProperties.data,
    arePropertiesLoading: state.adminProperties.areLoading,
    relatedPropertiesElements: state.relatedPropertiesElements.data,
    requestChangePropertyStatusIsSending: state.requestChangePropertyStatus.isSending,
});

export default connect(mapStateToProps, null)(Properties);
