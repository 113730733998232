import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';

const DeleteImageModal = ({ isOpen, handlerConfirmation, handlerCancel }) => {
    return (
        <div>
            <Modal isOpen={isOpen}>
                <ModalHeader>Are you sure you want to delete this image?</ModalHeader>
                <ModalFooter>
                    <Button color='danger' onClick={handlerConfirmation}>
                        Delete
                    </Button>{' '}
                    <Button color='secondary' onClick={handlerCancel}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteImageModal;
