import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchBackendPropertiesFiltersAction } from 'redux/actions/propertiesSearchParamsActions';
import ACREAPI from 'utils/services/ACREAPI';

function* backendPropertiesFiltersSaga() {
    try {
        yield put(fetchBackendPropertiesFiltersAction.request());

        const response = yield call(ACREAPI.properties().availableFilters);

        yield put(fetchBackendPropertiesFiltersAction.success({ ...response.data }));
    } catch (e) {
        yield put(fetchBackendPropertiesFiltersAction.failure());
    } finally {
        yield put(fetchBackendPropertiesFiltersAction.fulfill());
    }
}

export default function* backendPropertiesFiltersSagaWatcher() {
    yield takeLatest(fetchBackendPropertiesFiltersAction.trigger, backendPropertiesFiltersSaga);
}
