import ACREAPI from 'utils/services/ACREAPI';
import { Mutex } from 'async-mutex';

const ENDPOINT_URL_PREFIX = 'property-categories/';
// Global variable to store cached responses.
const responseCache = {};

class PropertyCategories {
    // static index = () => {
    //     return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX);
    // };
    static mutex = new Mutex();

    static index = async () => {
        // Generate a unique key for caching
        const cacheKey = 'index';

        // Acquire the lock
        const release = await this.mutex.acquire();
        try {
            // If the response for the given key exists in the cache, return it
            if (responseCache[cacheKey]) {
                return responseCache[cacheKey];
            }
            // Otherwise, fetch the data from the API
            const response = await ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX);
            // Cache the response
            responseCache[cacheKey] = response.data;
            return response.data;
        } finally {
            // Release the lock
            release();
        }
    };
    static featured = () => {
        return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + 'featured');
    };
}

export default PropertyCategories;
