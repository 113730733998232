import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchAllRolesAction } from 'redux/actions/rolesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* fetchAllRolesSaga(action) {
    try {
        yield put(fetchAllRolesAction.request());

        const response = yield call(ACREAPI.roles().index, action.payload);

        yield put(fetchAllRolesAction.success(response.data.result));
    } catch (e) {
        notifiFail({
            message: 'Roles load has been failed',
        });

        yield put(fetchAllRolesAction.failure());
    } finally {
        yield put(fetchAllRolesAction.fulfill());
    }
}

export default function* fetchAllRolesSagaWatcher() {
    yield takeLatest(fetchAllRolesAction.trigger, fetchAllRolesSaga);
}
