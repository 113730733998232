import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { storePropertyAction } from 'redux/actions/propertiesActions';
import { clearPropertyFormAction } from 'redux/actions/propertyFormActions';
import ACREAPI from 'utils/services/ACREAPI';
import routes from '../../domain/routes';

function* storePropertySaga(action) {
    try {
        yield put(storePropertyAction.request());

        yield call(ACREAPI.properties().store, action.payload);

        yield put(storePropertyAction.success());
        yield put(push(routes.panel.properties.main));
        yield put(clearPropertyFormAction());

        notifiSuccess({
            message: 'Property has been created',
        });
    } catch (e) {
        const message =
            e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : 'Saving of property has been failed';

        notifiFail({
            message,
        });

        yield put(storePropertyAction.failure());
    } finally {
        yield put(storePropertyAction.fulfill());
    }
}

export default function* storePropertySagaWatcher() {
    yield takeLatest(storePropertyAction.trigger, storePropertySaga);
}
