import AcreSpinner from 'components/Common/AcreSpinner';
import jwt_decode from 'jwt-decode';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginAction } from 'redux/actions/authActions';
import { getUserFromSession, issetNotExpiredSession, removeSession, setUserInSession } from 'utils/functions/session';
import ACREAPI from 'utils/services/ACREAPI';
import UserConverter from './domain/api2domain/UserConverter';

const MILLISECONDS_MINUTE = 1000 * 60;
const SECONDS_FIVE_MINUTES = 300;
const USER_LOCAL_STORAGE_VALUE_NAME = 'userSession';

class SetupApp extends Component {
    constructor(props) {
        super(props);

        this.userTokenRefreshInterval = null;
        this.state = {
            isLoading: true,
        };
    }

    componentDidMount() {
        this.listenOnTokenWillChange();

        if (issetNotExpiredSession()) {
            const user = getUserFromSession();

            this.props.loginUserBySession({ token: user.token });
            this.refreshUserToken();
        } else {
            this.setState({
                isLoading: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.startUserTokenRefreshInterval();
        }
    }

    /**
     * When other tab of this website will be opened and will refresh the token, then
     * this will update redux user session
     */
    listenOnTokenWillChange = () => {
        const props = this.props;

        window.addEventListener('storage', function (e) {
            if (e.key === USER_LOCAL_STORAGE_VALUE_NAME && issetNotExpiredSession()) {
                const user = getUserFromSession();
                props.loginUserBySession({ token: user.token });
            }
        });
    };

    /**
     * Refreshes user token and set session
     */
    refreshUserToken = () => {
        ACREAPI.auth()
            .refreshToken()
            .then((response) => {
                const token = response.data.token;

                let rawUserData = response.data.user;
                rawUserData.token = token;

                const converter = new UserConverter();
                const user = converter.toDomain(rawUserData);

                this.props.loginUserBySession({ token: token, ...rawUserData });
                setUserInSession(user);
            })
            .catch((err) => {
                removeSession();
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    /**
     * Set interval which checks every minute that token need to be refreshed, if yes - it will refresh it
     */
    startUserTokenRefreshInterval = () => {
        if (this.props.user.token) {
            const tokenPartials = jwt_decode(this.props.user.token);
            const tokenExpirationDate = new Date(0);
            tokenExpirationDate.setUTCSeconds(tokenPartials.exp);

            clearInterval(this.userTokenRefreshInterval);
            this.userTokenRefreshInterval = setInterval(() => {
                const nowDate = new Date(0);
                nowDate.setUTCSeconds(Math.floor(new Date().getTime() / 1000)); // convert to seconds

                const diff = (tokenExpirationDate - nowDate) / 1000; // diff in seconds

                if (diff <= SECONDS_FIVE_MINUTES) {
                    clearInterval(this.userTokenRefreshInterval);
                    this.refreshUserToken();
                }
            }, MILLISECONDS_MINUTE);
        }
    };

    render() {
        return this.state.isLoading ? <AcreSpinner /> : this.props.children;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = {
    loginUserBySession: loginAction.success,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupApp);
