import 'common/styles/modules/page.scss';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './PrivacyPolicy.scss';
import env from 'utils/functions/env';

class PrivacyPolicy extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <Title size={2} alignment={ALIGN_LEFT} withoutMarginTop={true}>
                        Privacy Policy
                    </Title>
                    <div className='header-content'>
                        <p>
                            Effective Date:<b> May 16, 2019</b>
                        </p>
                        <p>
                            Notice Version:<b> 1.0</b>
                        </p>
                        <span>Data Controller Contact Information:</span>
                        <div>Landzilla, LLC – d/b/a {env('NAME')}</div>
                        <div>312 Arizona Ave,</div>
                        <div>Santa Monica, CA 90401</div>
                        <div>info@landzilla.com</div>
                    </div>
                    <div className='content'>
                        <h5 className='small-header'>Our Website is for U.S. and Canadian Users and Customers Only</h5>
                        <b>Commitment to Privacy </b>
                        <p>
                            Our company has a strong commitment to providing information tailored to your individual
                            needs while providing excellent service to all of our visitors and customers, including
                            respecting concerns about privacy. We understand that you may have questions about whether
                            and how we collect and use information. This privacy notice details the steps we take to
                            respect your privacy concerns. If, after reviewing this privacy notice, you have any
                            questions or privacy concerns, please send an email to info@landzilla.com
                        </p>
                        <p>
                            This document governs the privacy notice of our website &nbsp;
                            <a target='_blank' rel='nofollow noopener noreferrer' href='https://www.landzilla.com'>
                                https://www.landzilla.com
                            </a>
                            . Our privacy notice tells you what personal data and nonpersonal data we may collect from
                            you, how we collect them, how we protect them, how we share them, how you can access and
                            change them, and how you can limit our sharing of them. Our privacy notice also explains
                            certain legal rights that you have with respect to your personal data. Any capitalized terms
                            not defined herein will have the same meaning as where they are defined elsewhere on our
                            website.
                        </p>
                        <b>Definitions</b>
                        <p>‘NONPERSONAL DATA’ (NPD) is information that is in no way personally identifiable.</p>
                        <p>
                            ‘PERSONAL DATA’ (PD) means any information relating to an identified or identifiable natural
                            person (‘data subject’); an identifiable natural person is one who can be identified
                            directly or indirectly by reference to an identifier such as a name, an identification
                            number, location data, an online identifier, or to one or more factors specific to the
                            physical, physiological, genetic, mental, economic, cultural, or social identity of that
                            natural person. PD is in many ways the same as Personally Identifiable Information (PII).
                            However, PD is broader in scope and covers more data.
                        </p>
                        <u>Topics Covered in Our Privacy Notice</u>
                        <span>YOUR RIGHTS</span>
                        <span>INFORMATION WE COLLECT AND HOW WE COLLECT IT</span>
                        <span>HOW YOUR INFORMATION IS USED AND SHARED</span>
                        <span>RETAINING AND DESTROYING YOUR PD</span>
                        <span>UPDATING YOUR PD</span>
                        <span>REVOKING YOUR CONSENT FOR USING YOUR PD</span>
                        <span>PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES</span>
                        <span>DO NOT TRACK SETTINGS</span>
                        <span>LINKS TO OTHER WEBSITES</span>
                        <span>PROTECTING CHILDREN’S PRIVACY</span>
                        <span>OUR EMAIL POLICY</span>
                        <span>OUR SECURITY POLICY</span>
                        <span>USE OF YOUR CREDIT CARD</span>
                        <span>CHANGES TO OUR PRIVACY NOTICE</span>
                        <p>
                            <u>YOUR RIGHTS</u>
                        </p>
                        <div className='using-our-services'>
                            When using our services and submitting PD to us, you have certain rights.
                            <ul>
                                <li>
                                    The Right to Be Informed - You have the right to be informed about the PD that we
                                    collect from you and how we process them.
                                </li>
                                <li>
                                    The Right of Access - You have the right to get confirmation that your PD are being
                                    processed and you have the ability to access your PD.
                                </li>
                                <li>
                                    The Right to Erasure (Right to Be Forgotten) - You have the right to request the
                                    removal or deletion of your PD if there is no compelling reason for us to continue
                                    processing them.
                                </li>
                                <li>
                                    The Right to Restrict Processing - You have the right to ‘block’ or restrict the
                                    processing of your PD. When your PD are restricted, we are permitted to store your
                                    data, but not to process them further.
                                </li>
                                <li>
                                    Automated Individual Decision-Making and Profiling - You have the right not to be
                                    subject to a decision based solely on automated processing, including profiling,
                                    which produces legal effects regarding you or similarly significantly affects you.
                                </li>
                            </ul>
                        </div>
                        <p>
                            <u>INFORMATION WE COLLECT AND HOW WE COLLECT IT</u>
                        </p>
                        <span>
                            Generally, you control the amount and type of information that you provide to us when using
                            our website.
                        </span>
                        <div className='automatic-information-section'>
                            <h5 className='small-header'>Automatic Information</h5>We automatically receive information
                            from your web browser or mobile device. This information may include the name of the website
                            from which you entered our website, if any, as well as the name of the website you’ll visit
                            when you leave our website. This information may also include the IP address of your
                            computer/the proxy server you use to access the Internet, your Internet service provider’s
                            name, your web browser type, the type of mobile device, your computer operating system, and
                            data about your browsing activity when using our website. We use all this information to
                            analyze trends among our users to help improve our website.
                        </div>
                        <div className='using-our-website-section'>
                            <h5 className='small-header'>When Entering and Using Our Website</h5>When you enter and use
                            our website and agree to accept cookies, some of these cookies may contain your PD.
                        </div>
                        <div className='our-use-cookies-section'>
                            <h5 className='small-header'>Our Use of Cookies</h5>Our website uses cookies. A cookie is a
                            small piece of data or a text file that is downloaded to your computer or mobile device when
                            you access certain websites. Cookies may contain text that can be read by the web server
                            that delivered the cookie to you. The text contained in the cookie generally consists of a
                            sequence of letters and numbers that uniquely identifies your computer or mobile device; it
                            may contain other information as well.
                            <p>
                                By agreeing to accept our use of cookies you are giving us and the third parties with
                                which we partner permission to place, store, and access some or all the cookies
                                described below on your computer.
                            </p>
                            <ul>
                                <li>
                                    Strictly Necessary Cookies - These cookies are necessary for proper functioning of
                                    the website, such as displaying content, logging in, validating your session,
                                    responding to your request for services, and other functions. Most web browsers can
                                    be set to disable the use of cookies. If you disable these cookies, you may not be
                                    able to access features on our website correctly or at all.
                                </li>
                                <li>
                                    Performance Cookies - These cookies collect information about the use of the
                                    website, such as pages visited, traffic sources, users’ interests, content
                                    management, and other website measurements.
                                </li>
                                <li>
                                    Functional Cookies - These cookies enable the website to remember users’ choices,
                                    such as their language, usernames, and other choices while using the website. They
                                    can also be used to deliver services, such as letting a user create a blog post,
                                    listen to audios, or watch videos on the website.
                                </li>
                                <li>
                                    Media Cookies - These cookies can be used to improve a website’s performance and
                                    provide special features and content. They can be placed by us or third parties who
                                    provide services to us.
                                </li>
                                <li>
                                    Advertising or Targeting Cookies - These cookies are usually placed and used by
                                    advertising companies to develop a profile of your browsing interests and serve
                                    advertisements on other websites that are related to your interests. You will see
                                    less advertising if you disable these cookies.
                                </li>
                                <li>
                                    Session Cookies - These cookies allow websites to link the actions of a user during
                                    a browser session. They may be used for a variety of purposes, such as remembering
                                    what a user has put in their shopping cart as they browse a website. Session cookies
                                    also permit users to be recognized as they navigate a website so that any item or
                                    page changes they make are remembered from page to page. Session cookies expire
                                    after a browser session; they are not stored long term.
                                </li>
                                <li>
                                    Persistent Cookies - These cookies are stored on a user’s device between browser
                                    sessions, which allows the user’s preferences or actions across a website or across
                                    different websites to be remembered. Persistent cookies may be used for several
                                    purposes, including remembering users’ choices and preferences when using a website
                                    or to target advertising to them.
                                </li>
                                <li>
                                    We may also use cookies for:
                                    <ul>
                                        <li>identifying the areas of our website that you have visited</li>
                                        <li>personalizing content that you see on our website</li>
                                        <li>our website analytics</li>
                                        <li>remarketing our products or services to you</li>
                                        <li>remembering your preferences, settings, and login details</li>
                                        <li>targeted advertising and serving ads relevant to your interests</li>
                                        <li>affiliate marketing</li>
                                        <li>allowing you to post comments</li>
                                        <li>allowing you to share content with social networks.</li>
                                    </ul>
                                </li>
                            </ul>
                            <div>
                                Most web browsers can be set to disable the use of cookies. However, if you disable
                                cookies, you may not be able to access features on our website correctly or at all.
                            </div>
                        </div>
                        <div className='web-beacons-section'>
                            <h5 className='small-header'>Web Beacons</h5>We may also use a technology called web beacons
                            to collect general information about your use of our website and your use of special
                            promotions or newsletters. The information we collect by web beacons allows us to
                            statistically monitor the number of people who open our emails. Web beacons also help us to
                            understand the behavior of our customers and users.
                        </div>
                        <div className='user-registration-section'>
                            <h5 className='small-header'>At User Registration</h5>When you register as a user, we
                            collect your name, address, email address, phone number, user generated passwords, email
                            preferences, and property search preferences.
                        </div>
                        <div className='buying-section'>
                            <h5 className='small-header'>When Buying Products or Services</h5>If you buy products or
                            services from us, we collect your name, mailing address, email address, phone number,
                            billing information, billing address, names on title, proof of income, yearly income, and
                            employment information.
                        </div>
                        <div className='hotjar-secrion'>
                            <h5 className='small-header'>Hotjar.com</h5>We use www.Hotjar.com. Hotjar is a technology
                            service that helps us better understand our users’ experience - how much time they spend on
                            which pages, which links they click on, what they do and don’t like, etc. This enables us to
                            build and maintain our service with user feedback. Hotjar uses cookies and other
                            technologies to collect data on our users’ behavior and their devices (in particular the IP
                            addresses of their devices captured and stored only in anonymized form); the screen size,
                            type, unique identifiers of devices; browser information; geographic location (country
                            only); and languages used to display our website. Hotjar stores this information in a
                            pseudonymized user profile. Neither Hotjar nor we use this information to identify
                            individual users or to match it with further data on individual users.
                            <ul>
                                <li>
                                    For more details please see Hotjar’s privacy policy by visiting: &nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='https://www.hotjar.com/legal/policies/privacy'
                                    >
                                        https://www.hotjar.com/legal/policies/privacy.
                                    </a>
                                </li>
                                <li>
                                    You can opt out of the creation of a user profile, Hotjar’s storing of data about
                                    your usage of our website, and Hotjar’s use of tracking cookies on other websites by
                                    visiting: &nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='https://www.hotjar.com/legal/compliance/opt-out'
                                    >
                                        https://www.hotjar.com/legal/compliance/opt-out.
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='contact-forms-section'>
                            <h5 className='small-header'>Website Chat Software or Contact Forms</h5>Our website contains
                            chat software or contact forms that enable visitors to communicate with us live online or
                            offline by email. In some cases visitors can communicate with us without buying our products
                            and services. When you use our chat software or contact forms, we may collect some or all
                            the following information: your email address, first name, last name, location, and any
                            other information you willingly choose to give us. You should limit the information you give
                            to us to one that is necessary to answer your questions.
                        </div>
                        <div className='google-section'>
                            <h5 className='small-header'>Google Analytics</h5>Our website uses Google Analytics to
                            collect information about the use of our website. Google Analytics collects information from
                            users such as age, gender, interests, demographics, how often they visit our website, what
                            pages they visit, and what other websites they have used before coming to our website. We
                            use the information we get from Google Analytics to analyze traffic, improve our marketing,
                            advertising, and website. Google Analytics collects only the IP address assigned to you on
                            the date you visit our website, not your name or other identifying information. We do not
                            combine the information collected using Google Analytics with PD. Although Google Analytics
                            plants a permanent cookie on your web browser to identify you as a unique user the next time
                            you visit our website, the cookie cannot be used by anyone but Google. Google also uses
                            specific identifiers to help collect information about the use of our website.
                            <ul>
                                <li>
                                    For more information on how Google collects and processes your data visit:&nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='https://www.google.com/policies/privacy/partners/'
                                    >
                                        https://www.google.com/policies/privacy/partners/
                                    </a>
                                </li>
                                <li>
                                    You can prevent Google Analytics from using your information by opting out at this
                                    link: &nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='https://tools.google.com/dlpage/gaoptout'
                                    >
                                        https://tools.google.com/dlpage/gaoptout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='analytics'>
                            <h5 className='small-header'>Analytics</h5>Our website uses analytics and crash reporting
                            services from companies in addition to Google to collect information about the use of our
                            website. Analytics collects information such as how often users visit our website, what
                            pages they visit, when they do so, what other websites they used before coming to our
                            website, and their IP addresses. We use the information we get from analytics to improve our
                            services.
                        </div>
                        <div className='google-remarketing-section'>
                            <h5 className='small-header'>Google Remarketing</h5>Our website uses a remarketing
                            advertising service. Our remarketing service is provided by Google and other companies that
                            show our ads on websites across the Internet. With remarketing you may see ads for our
                            products you have previously looked at. As an example, suppose you visit a website that
                            sells computers, but you do not buy a computer on your first visit. The website’s owner
                            might like to encourage you to revisit his/her website and buy a computer by showing you
                            his/her ads again on other websites that you visit. We use remarketing for similar purposes.
                            For this to happen Google will read a cookie that is already in your browser or place a
                            cookie in your browser when you visit our website or other websites using remarketing. You
                            can opt out of Google’s use of cookies and remarketing at this link: &nbsp;
                            <a
                                target='_blank'
                                rel='nofollow noopener noreferrer'
                                href='https://support.google.com/ads/answer/2662922?hl=en'
                            >
                                https://support.google.com
                            </a>
                            or you can opt out using the Network Advertising Initiative opt out page at: &nbsp;
                            <a
                                target='_blank'
                                rel='nofollow noopener noreferrer'
                                href='http://optout.networkadvertising.org/#!/'
                            >
                                http://optout.networkadvertising.org
                            </a>
                        </div>
                        <div className='facebook-remarketing'>
                            <h5 className='small-header'>Facebook Remarketing</h5>Third parties, including Facebook, may
                            use first-party cookies, third-party cookies, web beacons, and other storage technologies to
                            collect or receive information from our services and elsewhere on the Internet, and use that
                            information to provide measurement services and target ads. With Facebook remarketing you
                            may see our ads on Facebook after you have used our services. For this to happen Facebook
                            uses unique cookies that are activated and placed in a visitor’s browser when they land on a
                            webpage. Facebook lookalike audience targeting allows us to show ads on Facebook to people
                            who are similar to those who have already visited or made a purchase from our services. To
                            opt out of Facebook’s collection and use of information for ad targeting visit: &nbsp;
                            <a
                                target='_blank'
                                rel='nofollow noopener noreferrer'
                                href='https://www.facebook.com/help/568137493302217'
                            >
                                https://www.facebook.com/help/568137493302217
                            </a>
                        </div>
                        <div className='ad-roll-remarketing'>
                            <h5 className='small-header'>AdRoll Remarketing</h5>An AdRoll pixel has been installed on
                            our website. This, along with AdRoll’s third-party cookies, allows AdRoll to collect data
                            and provide targeted advertising, specifically retargeting advertising on other websites or
                            devices, depending on your online activity.
                            <ul>
                                <li>
                                    You may opt out of receiving targeted advertisements from AdRoll by visiting the NAI
                                    website optout page here: &nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='http://www.networkadvertising.org/choices/'
                                    >
                                        http://www.networkadvertising.org/choices/
                                    </a>
                                </li>
                                <li>
                                    or the DAA optout page here: &nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='http://www.aboutads.info/'
                                    >
                                        http://www.aboutads.info/
                                    </a>
                                </li>
                                <li>
                                    or for EU users the EDAA optout page here: &nbsp;
                                    <a
                                        target='_blank'
                                        rel='nofollow noopener noreferrer'
                                        href='http://youronlinechoices.eu/'
                                    >
                                        http://youronlinechoices.eu/
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='what-happens-section'>
                            <h5 className='small-header'>What Happens If You Don’t Give Us Your PD</h5>If you do not
                            provide us with enough PD, we may not be able to provide you all our products and services.
                            However, you can access and use some parts of our website without giving us your PD.
                            <p>
                                <u>HOW YOUR INFORMATION IS USED AND SHARED</u>
                            </p>
                            We use the information we receive from you to:
                            <ul>
                                <li>provide our products and services you have requested or purchased from us</li>
                                <li>personalize and customize our content</li>
                                <li>make improvements to our website</li>
                                <li>contact you with updates to our website, products, and services</li>
                                <li>resolve problems and disputes</li>
                                <li>
                                    contact you with marketing and advertising that we believe may be of interest to
                                    you.
                                </li>
                            </ul>
                        </div>
                        <div className='communications-and-emails-section'>
                            <h5 className='small-header'>Communications and Emails</h5>When we communicate with you
                            about our website, we will use the email address you provided when you registered as a user
                            or customer. We may also send you emails with promotional information about our website or
                            offers from us or our affiliates unless you have opted out of receiving such information.
                            You can change your contact preferences at any time through your account or by contacting us
                            using the contact information at the top of this privacy notice.
                        </div>
                        <div className='sharing-information-section'>
                            <h5 className='small-header'>
                                Sharing Information with Affiliates and Other Third Parties
                            </h5>
                            We do not sell or rent your PD to third parties for marketing purposes. However, for data
                            aggregation purposes we may use your NPD, which might be sold to other parties at our
                            discretion. Any such data aggregation would not contain any of your PD. We may give your PD
                            to third-party service providers whom we hire to provide services to us. These third-party
                            service providers may include but are not limited to payment processors, web analytics
                            companies, advertising networks, call centers, data management services, help desk
                            providers, accountants, law firms, auditors, shopping cart and email service providers, and
                            shipping companies.
                        </div>
                        <div className='sharing-pd-section'>
                            <h5 className='small-header'>Sharing Your PD With Other Users</h5>Some of your user personal
                            information, including your location, first and last name, business type, LinkedIn and
                            Facebook profiles, current place of work, job position, business name, business address,
                            profile picture, email address, friend connections, spoken languages, business phone number,
                            mobile number, business URL, username, and any image or video content that you have uploaded
                            to our website or mobile app may be displayed to other users to help user interaction within
                            our services or address your request for our services.
                            <p>
                                Your privacy settings in your account may let you limit which users can see your PD in
                                your user profile and what information in your profile is visible to others. You
                                understand that any content you upload to your public user profile, including PD, or
                                content that you disclose online in a way that other users can see, including discussion
                                boards, messaging, or other communication mechanisms becomes publicly accessible and can
                                be used by anyone.
                            </p>
                            <p>
                                We do not filter or monitor what is posted on discussion boards. If you post on these
                                discussion boards or other communication devices, you should use care when exposing any
                                PD, as such information is not protected by our privacy notice nor are we liable if you
                                disclose your PD through such postings.
                            </p>
                            <p>
                                If you sign into our services through a third-party social networking service or
                                website, your “friends” list from that service or website might be automatically
                                imported to our services. We do not have any control over the privacy notices and
                                business practices of other third-party services or websites.
                            </p>
                        </div>
                        <div className='sharing-pd-lookalike-section'>
                            <h5 className='small-header'>
                                Sharing Your PD for Lookalike or Similar Audience Marketing
                            </h5>
                            We may share your PD with third parties for similar audience marketing purposes. Similar
                            audience marketing is also called lookalike audience marketing. The third parties we share
                            your PD with for this type of marketing include Facebook and/or Google. Using your PD for
                            similar audience marketing or lookalike audience marketing helps us find new audiences
                            (users and customers) based on similar interests to yours. This helps us improve our
                            marketing services. Your PD is only shared with Facebook and Google for this type of
                            marketing. By using our website and agreeing to our privacy notice you are giving consent
                            for your PD to be used for the marketing purposes described within this section.
                        </div>
                        <div className='login-social-media-section'>
                            <h5 className='small-header'>
                                Sharing Your Information When You Login Using Social Media Websites
                            </h5>
                            We may share your information with third parties such as Facebook.com, Twitter.com,
                            YouTube.com, Instagram.com, and Google.com. If you decide to login to our website using
                            these third parties or other social media websites, you are agreeing to let us use and store
                            your profile information from those websites to make better use of any social media features
                            on our website. This sharing of information helps us provide you a better experience when
                            using our website and services. It also provides us with useful information such as visitor
                            traffic. If you use any of the social sharing icons on our website to share our information,
                            you may also be sharing your personal information through social media websites.
                        </div>
                        <div className='business-partners-section'>
                            <h5 className='small-header'>Sharing Information With Business Partners</h5>We may share
                            your PD with our business partners. The business partners include general business partners,
                            affiliates, and joint venture partners. We share this information with them so that they can
                            send you information about our products and services as well as their products and services.
                            When you choose to take part in our services and/or offerings, you are authorizing us to
                            provide your email address and other PD to our business partners. Please understand that
                            when we share your PD with our business partners, your PD becomes subject to our business
                            partners’ as well as our privacy notice.
                        </div>
                        <div className='text-messaging-section'>
                            <h5 className='small-header'>Text Messaging and Push Notifications</h5>If you provide a
                            mobile telephone number to us, you are giving your consent and authorize us or a third party
                            to send you text messages and push notifications. You are not required to give us your
                            consent for these text messages and push notifications. However, withholding your consent
                            may interfere or prevent us from providing some or all of our services to you. You can stop
                            receiving text messages and push notifications at any time by contacting us.
                        </div>
                        <div className='legally-required-section'>
                            <h5 className='small-header'>Legally Required Releases of Information</h5>We may be legally
                            required to disclose your PD if such disclosure is (a) required by subpoena, law, or other
                            legal process; (b) necessary to assist law enforcement officials or governmental enforcement
                            agencies; (c) necessary to investigate violations of or otherwise enforce our terms and
                            conditions; (d) necessary to protect us from legal action or claims from third parties,
                            including you and/or other users; or (e) necessary to protect the legal rights,
                            personal/real property, or personal safety of our company, users, employees, and affiliates.
                        </div>
                        <div className='disclosures-section'>
                            <h5 className='small-header'>Disclosures to Successors</h5>If our business is sold or merges
                            in whole or in part with another business that would become responsible for providing the
                            website to you, we retain the right to transfer your PD to the new business. The new
                            business would retain the right to use your PD according to the terms of this privacy notice
                            as well as to any changes to this privacy notice as instituted by the new business. We also
                            retain the right to transfer your PD if our company files for bankruptcy and some or all of
                            our assets are sold to another individual or business.
                        </div>
                        <div className='destroying-pd-section'>
                            <p>
                                <u>RETAINING AND DESTROYING YOUR PD</u>
                            </p>
                            We retain information that we collect from you (including your PD) only for as long as we
                            need it for legal, business, or tax purposes. Your information may be retained in
                            electronic, paper, or a combination of both forms. When your information is no longer
                            needed, we will destroy, delete, or erase it.
                        </div>
                        <div className='updating-pd-section'>
                            <p>
                                <u>UPDATING YOUR PD</u>
                            </p>
                            You can update your PD using services found on our website. If no such services exist, you
                            can contact us using the contact information found at the top of this privacy notice and we
                            will help you. However, we may keep your PD as needed to enforce our agreements and to
                            comply with any legal obligations.
                        </div>
                        <div className='revoking-pd-section'>
                            <p>
                                <u>REVOKING YOUR CONSENT FOR USING YOUR PD</u>
                            </p>
                            You have the right to revoke your consent for us to use your PD at any time. Such optout
                            will not affect disclosures otherwise permitted by law including but not limited to: (I)
                            disclosures to affiliates and business partners, (II) disclosures to third-party service
                            providers that provide certain services for our business, such as payment processors, web
                            analytics companies, advertising networks, call centers, data management services, help desk
                            providers, accountants, law firms, auditors, shopping cart and email service providers, and
                            shipping companies, (III) disclosures to third parties as necessary to fulfill your
                            requests, (IV) disclosures to governmental agencies or law enforcement departments, or as
                            otherwise required to be made under applicable law, (V) previously completed disclosures to
                            third parties, or (VI) disclosures to third parties in connection with subsequent contests
                            or promotions you may choose to enter, or third-party offers you may choose to accept. If
                            you want to revoke your consent for us to use your PD, please contact us through the contact
                            information at the top of this privacy notice.
                        </div>
                        <div className='protecting-section'>
                            <p>
                                <u>PROTECTING THE PRIVACY RIGHTS OF THIRD PARTIES</u>
                            </p>
                            If any postings you make on our website contain information about third parties, you agree
                            to make sure that you have permission to include that information. While we are not legally
                            liable for the actions of our users, we will remove any postings about which we are
                            notified, if such postings violate the privacy rights of others.
                        </div>
                        <div className='not-track-settings-section'>
                            <p>
                                <u>DO NOT TRACK SETTINGS</u>
                            </p>
                            Some web browsers have settings that enable you to request that our website not track your
                            movement within our website. Our website does not obey such settings when transmitted to and
                            detected by our website. You can turn off tracking features and other security settings in
                            your browser by referring to your browser’s user manual.
                        </div>
                        <div className='links-other-websites-section'>
                            <p>
                                <u>LINKS TO OTHER WEBSITES</u>
                            </p>
                            Our website may contain links to other websites. These websites are not under our control
                            and are not subject to our privacy notice. These websites will likely have their own privacy
                            notices. We have no responsibility for these websites and we provide links to these websites
                            solely for your convenience. You acknowledge that your use of and access to these websites
                            are solely at your risk. It is your responsibility to check the privacy notices of these
                            websites to see how they treat your PD.
                        </div>
                        <div className='protecting-childrens-section'>
                            <p>
                                <u>PROTECTING CHILDREN’S PRIVACY</u>
                            </p>
                            Even though our website is not designed for use by anyone under the age of{' '}
                            <span className='red'>18</span>, we realize that a child under the age of{' '}
                            <span className='red'>18</span> may attempt to access our website. We do not knowingly
                            collect PD from children under the age of <span className='red'>18</span>. If you are a
                            parent or guardian and believe that your child is using our website, please contact us.
                            Before we remove any information we may ask for proof of identification to prevent malicious
                            removal of account information. If we discover that a child is accessing our website, we
                            will delete his/her information within a reasonable period of time. You acknowledge that we
                            do not verify the age of our users nor have any liability to do so.
                        </div>
                        <div className='email-policy-section'>
                            <p>
                                <u>OUR EMAIL POLICY</u>
                            </p>
                            You can always opt out of receiving email correspondence from us or our affiliates. We will
                            not sell, rent, or trade your email address to any unaffiliated third party without your
                            permission except in the sale or transfer of our business, or if our company files for
                            bankruptcy.
                        </div>
                        <div className='security-policy-section'>
                            <p>
                                <u>OUR SECURITY POLICY</u>
                            </p>
                            We have built our website using industry-standard security measures and authentication tools
                            to protect the security of your PD. We and the third parties who provide services to us also
                            maintain technical and physical safeguards to protect your PD. Unfortunately we cannot
                            guarantee prevention of loss or misuse of your PD or secure data transmission over the
                            Internet because of its nature. We strongly urge you to protect any password you may have
                            for our website and not share it with anyone.
                        </div>
                        <div className='credit-card-section'>
                            <p>
                                <u>USE OF YOUR CREDIT CARD</u>
                            </p>
                            You may have to provide a credit card to buy products and services from our website. We use
                            third-party billing services and have no control over them. We use commercially reasonable
                            efforts to ensure that your credit card number is kept strictly confidential by using only
                            third-party billing services that use industry-standard encryption technology to protect
                            your credit card number from unauthorized use. However, you understand and agree that we are
                            in no way responsible for any misuse of your credit card number.
                        </div>
                        <div className='change-privacy-notice-section'>
                            <p>
                                <u>CHANGES TO OUR PRIVACY NOTICE</u>
                            </p>
                            We reserve the right to change this privacy notice at any time. If our company decides to
                            change this privacy notice, we will post those changes on our website so that our users and
                            customers are always aware of what information we collect, use, and disclose. If at any time
                            we decide to disclose or use your PD in a method different from that specified at the time
                            it was collected, we will provide advance notice by email sent to the email address on file
                            in your account. Otherwise we will use and disclose our users’ and customers’ PD in
                            agreement with the privacy notice in effect when the information was collected. In all cases
                            your continued use of our website, services, and products after any change to this privacy
                            notice will constitute your acceptance of such change. If you have questions about our
                            privacy notice, please contact us through the information at the top of this privacy notice.
                        </div>
                    </div>
                    <div className='copyright'>
                        Copyright © This Privacy Notice is protected under United States and foreign copyrights. The
                        copying, redistribution, use or publication by you, is strictly prohibited.
                    </div>
                </Col>
            </Row>
        );
    }
}

export default PrivacyPolicy;
