import React from 'react';
import { PropTypes } from 'utils/wrappers';
import NotificationContainer from './Part/NotificationContainer';
import './styles/FloatingNotifications.scss';

const FloatingNotifications = ({ notifications }) => {
    return (
        <div className={'notification-wrapper'}>
            {notifications.map((noti, i) => (
                <NotificationContainer key={noti.id} data={noti} />
            ))}
        </div>
    );
};

FloatingNotifications.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            styles: PropTypes.object,
            color: PropTypes.string,
            message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
        }),
    ).isRequired,
};

export default FloatingNotifications;
