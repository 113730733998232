import ShareButtons from 'components/Common/ShareButtons';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import DOMPurify from 'dompurify';
import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/Description.module.scss';
import classesTitle from '../styles/Title.module.scss';

const Description = ({ description, className }) => {
    if (description.length === 0) {
        return null;
    }

    const cssClass = joinNotEmpty([classes.description, className], ' ');

    return (
        <div className={cssClass}>
            <div className={classes['title-wrapper']}>
                <ShareButtons />
                <Title size={2} alignment={ALIGN_LEFT} className={classesTitle.title}>
                    Property Description
                </Title>
            </div>
            <div className={classes.text} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
        </div>
    );
};

Description.propTypes = {
    description: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Description;
