import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendOnePropertyContactMessageAction } from 'redux/actions/propertiesActions';
import { STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { handleInput } from 'utils/functions/inputHelpers';
import { hasError } from 'utils/functions/validation';
import { PropTypes } from 'utils/wrappers';
import validator from 'validator';
import { phoneNumberRegex } from '../../../../../../../domain/constants/regexes';
import ContactForm from './ContactForm';

class ContactFormContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);
        this.hasError = hasError.bind(this);

        this.fieldsNames = {
            name: 'name',
            email: 'email',
            phoneNumber: 'phoneNumber',
            message: 'message',
        };

        this.state = {
            propertySlug: props.property.slug,
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
            errors: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps && this.props.contactMessage.status === STATUS_SUCCESS) {
            this.setState({
                name: '',
                email: '',
                phoneNumber: '',
                message: '',
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const errors = this.validate();

        this.setState(
            {
                errors: errors,
            },
            () => {
                if (this.state.errors.length > 0) {
                    notifiFail({
                        title: 'Verify data entered in the contact form:',
                        message: this.state.errors,
                        duration: 0,
                    });

                    return;
                }

                this.props.sendMessage(this.state);
            },
        );
    };

    validate = () => {
        let errors = [];
        const { phoneNumber, email, name, message } = this.state;

        if (!validator.isLength(name, { min: 1, max: 255 })) {
            errors.push({ field: this.fieldsNames.name, message: 'Name is required' });
        }

        if (!validator.isLength(email, { min: 1, max: 255 })) {
            errors.push({ field: this.fieldsNames.email, message: 'Email is required' });
        } else if (!validator.isEmail(email)) {
            errors.push({ field: this.fieldsNames.email, message: 'Email is incorrect' });
        }

        if (!validator.isLength(phoneNumber, { min: 1 })) {
            errors.push({ field: this.fieldsNames.phoneNumber, message: 'Phone number is required' });
        } else if (!validator.matches(phoneNumber, phoneNumberRegex)) {
            errors.push({
                field: this.fieldsNames.phoneNumber,
                message: 'The phone number has incorrect format, correct e.g. 112-345-6789',
            });
        }

        if (!validator.isLength(message, { min: 5 })) {
            errors.push({
                field: this.fieldsNames.message,
                message: 'Message is required (at least 5 characters)',
            });
        }

        return errors;
    };

    render() {
        return (
            <ContactForm
                fieldsNames={this.fieldsNames}
                hasError={this.hasError}
                handleInput={this.handleInput}
                handleSubmit={this.handleSubmit}
                {...this.state}
            />
        );
    }
}

ContactFormContainer.propTypes = {
    property: PropTypes.shape({
        slug: PropTypes.string.isRequired,
    }).isRequired,
    sendMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        contactMessage: state.onePropertyContactMessage,
    };
};

const mapDispatchToProps = {
    sendMessage: sendOnePropertyContactMessageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer);
