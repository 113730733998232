import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { onePropertyAction } from 'redux/actions/propertiesActions';
import handleResponseStatus from 'redux/utils/handleResponseStatus';
import ACREAPI from 'utils/services/ACREAPI';

function* onePropertySaga(action) {
    try {
        yield put(onePropertyAction.request());
        const response = yield call(ACREAPI.properties().oneProperty, action.payload);
        yield put(onePropertyAction.success(response.data.listing));
    } catch (error) {
        notifiFail({
            message: 'Loading of property has failed',
            duration: 3000,
        });

        yield put(onePropertyAction.failure());
        yield handleResponseStatus(error);
    } finally {
        yield put(onePropertyAction.fulfill());
    }
}

export default function* onePropertySagaWatcher() {
    yield takeLatest(onePropertyAction.TRIGGER, onePropertySaga);
}
