/**
 * Helper for managing - getting env variables
 *
 * @param name
 * @param raw
 * @returns {any}
 */

const env = (name, raw) => {
    if (raw) {
        return process.env[name] ? process.env[name] : undefined;
    }

    return process.env['REACT_APP_' + name] ? process.env['REACT_APP_' + name] : undefined;
};

export default env;
