import React from 'react';
import { Pagination as StrapPagination } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import './styles/Pagination.scss';

const Pagination = ({ elements }) => {
    return <StrapPagination>{elements}</StrapPagination>;
};

Pagination.propTypes = {
    elements: PropTypes.array.isRequired,
};

export default Pagination;
