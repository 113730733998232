import { searchedPropertiesAction, TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION } from 'redux/actions/propertiesActions';

const defaultState = {
    properties: [],
    pagination: null,
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case searchedPropertiesAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case searchedPropertiesAction.SUCCESS:
            return {
                ...state,
                properties: action.payload.data,
                pagination: action.payload.pagination,
            };
        case searchedPropertiesAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        case TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION:
            const isFavourite = action.payload.isFavourite;
            const propertyId = action.payload.propertyId;

            const propertiesUpdated = state.properties.map((property) => {
                if (property.id === propertyId) {
                    property.is_favorite = isFavourite;
                }

                return property;
            });

            return {
                ...state,
                properties: propertiesUpdated,
            };
        default:
            return {
                ...state,
            };
    }
};
