import getFileType from 'utils/functions/getFileType';
import pdf from '../images/pdf.svg';
import text from '../images/text.svg';
import image from '../images/image.svg';

export default (fileName) => {
    switch (getFileType(fileName)) {
        case 'pdf':
            return pdf;
        case 'bmp':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'psd':
            return image;
        default:
            return text;
    }
};
