import Article from 'domain/blog/Article';
import ACREAPI from 'utils/services/ACREAPI';
import { ADMIN_ENDPOINT_URL_PREFIX } from 'utils/services/ACREAPI/ACREAPI';

const ENDPOINT_URL_PREFIX = 'blog/';

export default class Articles {
    static requestGuest = (endpointSuffix, type, data = {}) => {
        switch (type) {
            case 'get':
                return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + endpointSuffix, {
                    params: data,
                });
            default:
                return ACREAPI.guestInstance().post(ENDPOINT_URL_PREFIX + endpointSuffix, data);
        }
    };

    static index = (page = 1) => {
        return Articles.requestGuest('', 'get', { page: page });
    };

    static show = (slug) => {
        return Articles.requestGuest(slug, 'get'); // /blog/{slug}
    };

    static recommended = (num = 3) => {
        return Articles.requestGuest('post/recommend', 'get', { num: num });
    };

    static adminIndex = (page = 1) => {
        return ACREAPI.authInstance().get(ADMIN_ENDPOINT_URL_PREFIX + 'all-posts?page=' + page);
    };

    static adminRead = (articleSlug) => {
        return Articles.requestGuest(articleSlug, 'get');
    };

    static adminCreate = ({ title, content, slug, description, allowComments, allowSomethings, images }) => {
        const article = new Article(0, title, slug, content, description, allowComments, allowSomethings, images);
        const formData = article.toFormData();

        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + 'post/create', formData);
    };

    static adminUpdate = ({ articleId, title, slug, description, content, allowComments, allowSomethings, images }) => {
        const article = new Article(0, title, slug, content, description, allowComments, allowSomethings, images);
        const formData = article.toFormData();

        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + 'post/' + articleId, formData);
    };

    static adminDelete = (articleId) => {
        return ACREAPI.authInstance().delete(ENDPOINT_URL_PREFIX + 'post/' + articleId);
    };

    static adminDeleteImage = (imageId, isNew) => {
        if (isNew) {
            return true;
        }

        return ACREAPI.authInstance().delete(ENDPOINT_URL_PREFIX + 'post/image/' + imageId);
    };
}
