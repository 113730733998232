import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { updateUserRoleAction } from 'redux/actions/usersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* requestUpdateUserRoleSaga(action) {
    try {
        yield put(updateUserRoleAction.request());

        yield call(ACREAPI.users().updateRole, action.payload);

        notifiSuccess({
            message: "User's role has been updated",
        });

        yield put(updateUserRoleAction.success());
    } catch (e) {
        const message = e.response && e.response.data ? e.response.data.message : "User's update has been failed";

        notifiFail({
            message: message,
        });

        yield put(updateUserRoleAction.failure());
    } finally {
        yield put(updateUserRoleAction.fulfill());
    }
}

export default function* requestUpdateUserRoleSagaWatcher() {
    yield takeLatest(updateUserRoleAction.trigger, requestUpdateUserRoleSaga);
}
