import Location from 'components/Common/Property/Location';
import ToggleFavouriteButton from 'components/Common/Property/ToggleFavouriteButton';
import Title from 'components/Common/Title';
import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/Top.module.scss';

const Top = ({ propertyId, propertyName, location, isFavourite, className }) => {
    const cssClass = joinNotEmpty([classes.top, className], ' ');
    const onGoToMapClick = (event) => {
        event.preventDefault();

        const map = document.getElementById('google-map');
        map.scrollIntoView({ behavior: 'smooth' });
    };

    const goToMapLink = (
        <a href='#' onClick={onGoToMapClick} className={classes['go-to-map']}>
            See on map
        </a>
    );

    const saveButton = (
        <ToggleFavouriteButton propertyId={propertyId} isFavourite={isFavourite} size='xs' className={classes.save} />
    );

    const fullAddress = (
        <Location
            address={location.address}
            county={location.county}
            state={location.state}
            zip={location.zip}
            city={location.city}
        />
    );
    return (
        <div className={cssClass}>
            <div className={classes.title}>
                <Title size={1} alignment='left' className={classes['property-name']}>
                    {propertyName}
                </Title>
                {saveButton}
            </div>
            <div className={classes['location-with-icon']}>
                <i className={classes['pointer-icon'] + ' fas fa-map-marker-alt'} />
                <div className={classes.location}>
                    <span className={classes.address}>{fullAddress}</span>
                    {goToMapLink}
                    <div className={classes.options}>
                        {goToMapLink}
                        {saveButton}
                    </div>
                </div>
            </div>
        </div>
    );
};

Top.propTypes = {
    propertyId: PropTypes.number.isRequired,
    fpid: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    location: PropTypes.shape({
        address: PropTypes.string.isRequired,
        city: PropTypes.string,
        county: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
    }).isRequired,
    isFavourite: PropTypes.bool,
    className: PropTypes.string,
};

export default Top;
