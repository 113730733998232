export const PREVIOUS_BLOG_FEATURED_ARTICLE_SLIDE = 'PREVIOUS_BLOG_FEATURED_ARTICLE_SLIDE';
export const NEXT_BLOG_FEATURED_ARTICLE_SLIDE = 'NEXT_BLOG_FEATURED_ARTICLE_SLIDE';
export const SET_BLOG_FEATURED_ARTICLE_SLIDE = 'SET_BLOG_FEATURED_ARTICLE_SLIDE';

export const setToPreviousSlide = () => {
    return {
        type: PREVIOUS_BLOG_FEATURED_ARTICLE_SLIDE,
    };
};

export const setToNextSlide = () => {
    return {
        type: NEXT_BLOG_FEATURED_ARTICLE_SLIDE,
    };
};

export const setSlide = (index) => {
    return {
        type: SET_BLOG_FEATURED_ARTICLE_SLIDE,
        payload: index + 1,
    };
};
