import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { connect } from 'react-redux';
import { isEmptyArray } from 'utils/functions/arrays';
import { uppercaseFirstLetter } from 'utils/functions/string';
import { PropTypes } from 'utils/wrappers';
import styles from './Seller.module.scss';

const Seller = ({ seller }) => {
    return (
        <div>
            <Title size={3} alignment={ALIGN_LEFT} withoutMarginTop>
                {seller.title}
            </Title>
            <div>{seller.head}</div>
            <div>{seller.address}</div>
            {isEmptyArray(seller.telephones) ? null : (
                <React.Fragment>
                    <Title size={5} alignment={ALIGN_LEFT}>
                        Telephones
                    </Title>
                    <table className={styles['contact-table']}>
                        <tbody>
                            {seller.telephones.map((telephone, index) => (
                                <tr key={index}>
                                    <td>{uppercaseFirstLetter(telephone.type).replace('_', ' ')}</td>
                                    <td>{telephone.number}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </React.Fragment>
            )}
            {isEmptyArray(seller.emails) ? null : (
                <React.Fragment>
                    <Title size={5} alignment={ALIGN_LEFT}>
                        Emails
                    </Title>
                    <table className={styles['contact-table']}>
                        <tbody>
                            {seller.emails.map((emailObj, index) => (
                                // TODO: change after backend rebuilding to simple array not object (email.email is bad)
                                <tr key={index}>
                                    <td>{emailObj.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </React.Fragment>
            )}
        </div>
    );
};

Seller.propTypes = {
    seller: PropTypes.shape({
        head: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        telephones: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string.isRequired,
                number: PropTypes.string.isRequired,
            }),
        ).isRequired,
        emails: PropTypes.arrayOf(
            PropTypes.shape({
                email: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => ({
    seller: state.seller.data,
});

export default connect(mapStateToProps, null)(Seller);
