import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import { applyFilters, clearFilters, setActivePage, setFilter } from 'redux/actions/propertiesSearchParamsActions';
import { fetchAllStatesAction } from 'redux/actions/statesActions';
import { isEmptyArray } from 'utils/functions/arrays';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import { QueryString } from 'utils/wrappers';
import routes from '../../../domain/routes';
import PropertiesState from './PropertiesState';

class PropertiesStateContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            propertiesState: null,
            stateSlug: null,
        };
    }

    componentDidMount() {
        const paramsFromUrl = QueryString.parse(history.location.search);
        const page = paramsFromUrl.page ? paramsFromUrl.page : 1;
        this.props.setActivePage(page);

        if (!isEmptyArray(this.props.propertiesStates)) {
            this.changedSlug();
        }

        this.unlisten = history.listen(() => {
            this.changedSlug();

            const paramsFromUrl = QueryString.parse(window.location.search);
            const page = paramsFromUrl.page ? paramsFromUrl.page : 1;

            this.props.setActivePage(page);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.propertiesStates !== prevProps.propertiesStates && this.props.propertiesStates) {
            this.changedSlug();
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    changedSlug = () => {
        const match = matchPath(window.location.pathname, {
            path: routes.properties.state,
        });

        if (match && match.params) {
            this.setState(
                {
                    stateSlug: match.params.state,
                },
                () => {
                    this.setPropertiesState(this.state.stateSlug);
                },
            );
        }
    };

    setPropertiesState = (slug) => {
        this.setState(
            {
                propertiesState: this.props.propertiesStates.filter((state) => state.slug === slug)[0],
            },
            () => {
                this.getProperties();
            },
        );
    };

    getProperties = () => {
        this.props.clearFilters();
        this.props.setFilter(true, 'state', this.state.propertiesState.name);
        this.props.applyFilters();
        this.props.searchProperties();
    };

    onPageChange = (page) => {
        this.setPage(page);
        window.scrollTo(0, 0);
    };

    setPage = (page) => {
        history.push(routeWithQuery({ page: page }, true));
    };

    render() {
        return this.state.propertiesState ? (
            <PropertiesState propertiesState={this.state.propertiesState} onPageChange={this.onPageChange} />
        ) : (
            <AcreSpinner wrapped />
        );
    }
}

const mapStateToProps = (state) => ({
    propertiesStates: state.states.data,
    searchedProperties: state.searchedProperties,
});

const mapDispatchToProps = {
    clearFilters: clearFilters,
    getStates: fetchAllStatesAction,
    searchProperties: searchedPropertiesAction,
    setFilter: setFilter,
    applyFilters: applyFilters,
    setActivePage: setActivePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesStateContainer);
