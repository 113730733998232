import sortingArrow from 'components/Page/Properties/images/filter-hide-arrow.svg';
import React from 'react';

export default [
    { label: 'Default', value: 'default', icon: null },
    { label: 'Price', value: 'price:asc', icon: <img src={sortingArrow} alt={'^'} /> },
    { label: 'Price', value: 'price:desc', icon: <img className={'sorting-desc'} src={sortingArrow} alt={'v'} /> },
    { label: 'Size', value: 'acreage:asc', icon: <img src={sortingArrow} alt={'^'} /> },
    { label: 'Size', value: 'acreage:desc', icon: <img className={'sorting-desc'} src={sortingArrow} alt={'v'} /> },
];
