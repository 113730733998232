import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { deletePropertyAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* deletePropertySaga(action) {
    try {
        yield put(deletePropertyAction.request());

        yield call(ACREAPI.properties().delete, action.payload);

        notifiSuccess({
            message: 'Property successfully deleted',
        });

        yield put(deletePropertyAction.success());
    } catch (e) {
        const message =
            e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : 'Deletion of property has been failed';

        notifiFail({
            message: message,
        });

        yield put(deletePropertyAction.failure());
    } finally {
        yield put(deletePropertyAction.fulfill());
    }
}

export default function* deletePropertySagaWatcher() {
    yield takeLatest(deletePropertyAction.trigger, deletePropertySaga);
}
