import AcreSpinner from 'components/Common/AcreSpinner';
import React from 'react';
import { Button } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const SpinnerButton = ({ isLoading, children, size = '', ...rest }) => {
    return (
        <Button {...rest} disabled={isLoading} size={size}>
            {isLoading ? <AcreSpinner wrapped={false} size={size} /> : children}
        </Button>
    );
};

SpinnerButton.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['', 'sm', 'xs']),
};

export default SpinnerButton;
