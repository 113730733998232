import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPasswordAction } from 'redux/actions/authActions';
import { handleInput } from 'utils/functions/inputHelpers';
import history from 'utils/history';
import routes from '../../../domain/routes';
import { QueryString } from 'utils/wrappers';
import PasswordReset from './PasswordReset';

class PasswordResetContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            token: '',
            password: '',
            passwordConfirmation: '',
        };
    }

    componentDidMount() {
        const params = QueryString.parse(window.location.search);

        if (!params.token) {
            notifiFail({
                message: 'The reset password token is incorrect!',
            });

            history.push(routes.home);
            return;
        }

        this.setState({
            token: params.token,
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        const { token, password, passwordConfirmation } = this.state;

        this.setState(
            {
                passwordConfirmation: '',
                password: '',
            },
            () => {
                this.props.resetPassword({ token, password, passwordConfirmation });
            },
        );
    };

    render() {
        return (
            <PasswordReset
                handleInput={this.handleInput}
                onSubmit={this.onSubmit}
                password={this.state.password}
                passwordConfirmation={this.state.passwordConfirmation}
            />
        );
    }
}

const mapDispatchToProps = {
    resetPassword: resetPasswordAction,
};

export default connect(null, mapDispatchToProps)(PasswordResetContainer);
