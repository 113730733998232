import Carousel from 'components/Common/Carousel';
import Title from 'components/Common/Title';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import TestimonialBox from './Fragment/TestimonialBox';
import classes from './styles/Testimonials.module.scss';

const Testimonials = ({ testimonials }) => {
    return (
        <section className={'homepage-section ' + classes['testimonials-section']}>
            <Container>
                <Row>
                    <Col>
                        <Title size={2}>What our clients have to say about us</Title>
                    </Col>
                </Row>
                <Row className='d-md-none'>
                    <Col>
                        <Carousel slides={testimonials} />
                    </Col>
                </Row>
                <Row className='d-none d-md-flex'>
                    {testimonials.map((testimonial, index) => {
                        return (
                            <Col key={index} className={classes['testimonial-wrapper']} md={4}>
                                {testimonial}
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};

Testimonials.propTypes = {
    testimonials: PropTypes.arrayOf(TestimonialBox).isRequired,
};

export default Testimonials;
