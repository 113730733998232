import { FloatingNotificationsContainer } from 'components/Common/FloatingNotifications';
import AuthRoute from 'components/Common/Route/AuthRoute';
import LayoutRoute from 'components/Common/Route/LayoutRoute';
import ScrollToTopContainer from 'components/Common/ScrollToTop';
import AccountConfirmationContainer from 'components/Page/AccountConfirmation';
import Article from 'components/Page/Article';
import Disclaimer from 'components/Page/Disclaimer';
import FAQContainer from 'components/Page/FAQ';
import ListingPurchase from 'components/Page/ListingPurshase/ListingPurchase';
import AccountContainer from 'components/Page/Panel/Account';
import PanelBlogCreateArticle from 'components/Page/Panel/Blog/Create/CreateArticle';
import PanelBlogUpdateArticle from 'components/Page/Panel/Blog/Update/UpdateArticle';
import CreateProperty from 'components/Page/Panel/CreateProperty';
import EditPropertyContainer from 'components/Page/Panel/EditProperty';
import SavedPropertiesContainer from 'components/Page/Panel/FavouriteProperties';
import PaymentsContainer from 'components/Page/Panel/Payments';
import PropertiesContainer from 'components/Page/Panel/Properties';
import UsersContainer from 'components/Page/Panel/Users';
import PasswordResetContainer from 'components/Page/PasswordReset';
import PrivacyPolicy from 'components/Page/PrivacyPolicy';
import Properties from 'components/Page/Properties';
import PropertiesCategoryContainer from 'components/Page/PropertiesCategory/PropertiesCategoryContainer';
import PropertiesStateContainer from 'components/Page/PropertiesState/PropertiesStateContainer';
import PropertyContainer from 'components/Page/Property/PropertyContainer';
import ResponseError400 from 'components/Page/ResponseError/ResponseError400';
import ResponseError404 from 'components/Page/ResponseError/ResponseError404';
import SellerContainer from 'components/Page/Seller';
import TermsOfService from 'components/Page/TermsOfService';
import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { ROLE_ADMIN, ROLE_CONTENT_MANAGER } from 'utils/functions/authorization';
import BlogContainer from './components/Page/Blog';
import ContactUs from './components/Page/ContactUs';
import HomeContainer from './components/Page/Home';
import PanelBlogList from './components/Page/Panel/Blog/List';
import intlConfig from 'configuration/i18n';
import routes from './domain/routes';
import { store } from 'redux/store';
import SetupApp from './SetupApp';
import history from './utils/history';
import ReactTooltip from 'react-tooltip';
import * as Sentry from '@sentry/react';
import ResponseError500 from 'components/Page/ResponseError/ResponseError500';
import Error500Page from 'components/Page/Error/Error500Page';
import LogoutRoute from 'components/Common/Route/LogoutRoute';
import Logs from 'components/Page/Panel/Logs';
import { get_title } from 'domain/seo/title';

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <IntlProvider {...intlConfig}>
                    <SetupApp>
                        <ConnectedRouter history={history}>
                            <ScrollToTopContainer>
                                <Sentry.ErrorBoundary fallback={Error500Page}>
                                    <Switch>
                                        <LogoutRoute path={routes.logout} withPageWrapper={false} />
                                        <LayoutRoute
                                            path={routes.home}
                                            exact
                                            component={HomeContainer}
                                            withPageWrapper={false}
                                        />
                                        <LayoutRoute
                                            path={routes.blog.main}
                                            exact
                                            component={BlogContainer}
                                            withPageWrapper={true}
                                        />
                                        <LayoutRoute
                                            path={routes.blog.article}
                                            component={Article}
                                            withPageWrapper={false}
                                        />
                                        <LayoutRoute path={routes.faq} component={FAQContainer} title={'FAQ'} />
                                        <LayoutRoute
                                            path={routes.contactUs}
                                            component={ContactUs}
                                            title={'Contact Us'}
                                            pageWrapperClassName='static-page contact-us'
                                        />
                                        <LayoutRoute
                                            path={routes.termsOfService}
                                            component={TermsOfService}
                                            title={'Terms of Service'}
                                            pageWrapperClassName='static-page terms-of-service'
                                        />
                                        <LayoutRoute
                                            path={routes.disclaimer}
                                            component={Disclaimer}
                                            title={'Disclaimer'}
                                            pageWrapperClassName='static-page disclaimer'
                                        />
                                        <LayoutRoute
                                            path={routes.privacyPolicy}
                                            component={PrivacyPolicy}
                                            title={'Privacy Policy'}
                                            pageWrapperClassName='static-page'
                                        />
                                        <LayoutRoute
                                            path={routes.registrationConfirmation}
                                            component={AccountConfirmationContainer}
                                        />
                                        <LayoutRoute
                                            path={routes.properties.main}
                                            component={Properties}
                                            pageWrapperClassName='properties-wrapper'
                                        />
                                        <LayoutRoute
                                            path={routes.properties.state}
                                            exact
                                            component={PropertiesStateContainer}
                                        />
                                        <LayoutRoute path={routes.resetPassword} component={PasswordResetContainer} />
                                        <LayoutRoute path={routes.seller} component={SellerContainer} />
                                        <LayoutRoute path={routes.properties.one} component={PropertyContainer} />
                                        <LayoutRoute path={routes.listingPurchase} component={ListingPurchase} />
                                        <AuthRoute path={routes.panel.account} component={AccountContainer} />
                                        <AuthRoute
                                            path={routes.panel.properties.edit}
                                            component={EditPropertyContainer}
                                            rolesWithAccess={[ROLE_ADMIN]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.properties.create}
                                            component={CreateProperty}
                                            rolesWithAccess={[ROLE_ADMIN]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.savedProperties}
                                            component={SavedPropertiesContainer}
                                        />
                                        <AuthRoute
                                            path={routes.panel.properties.main}
                                            component={PropertiesContainer}
                                            rolesWithAccess={[ROLE_ADMIN]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.users.main}
                                            component={UsersContainer}
                                            rolesWithAccess={[ROLE_ADMIN]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.payments.main}
                                            component={PaymentsContainer}
                                            rolesWithAccess={[ROLE_ADMIN]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.blog.update}
                                            component={PanelBlogUpdateArticle}
                                            rolesWithAccess={[ROLE_ADMIN, ROLE_CONTENT_MANAGER]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.blog.create}
                                            component={PanelBlogCreateArticle}
                                            rolesWithAccess={[ROLE_ADMIN, ROLE_CONTENT_MANAGER]}
                                        />
                                        <AuthRoute
                                            path={routes.panel.blog.main}
                                            component={PanelBlogList}
                                            rolesWithAccess={[ROLE_ADMIN, ROLE_CONTENT_MANAGER]}
                                        />

                                        <AuthRoute
                                            path={routes.panel.logs}
                                            component={Logs}
                                            rolesWithAccess={[ROLE_ADMIN, ROLE_CONTENT_MANAGER]}
                                        />

                                        <LayoutRoute path={routes.error.badRequest} component={ResponseError400} />
                                        <LayoutRoute path={routes.error.notFound} component={ResponseError404} />
                                        <LayoutRoute
                                            path={routes.error.internalServerError}
                                            component={ResponseError500}
                                        />
                                        <LayoutRoute
                                            title={get_title()}
                                            path={routes.properties.category}
                                            component={PropertiesCategoryContainer}
                                        />
                                        <LayoutRoute component={ResponseError404} />
                                    </Switch>
                                    <FloatingNotificationsContainer />
                                </Sentry.ErrorBoundary>
                            </ScrollToTopContainer>
                        </ConnectedRouter>
                    </SetupApp>
                    <ReactTooltip place='top' type='dark' effect='solid' />
                </IntlProvider>
            </Provider>
        );
    }
}

export default App;
