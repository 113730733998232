import React from 'react';
import { Badge } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const BadgeWithText = ({ badge, text }) => {
    return (
        <h2 className='text-center'>
            <Badge>{badge}</Badge> {text}
        </h2>
    );
};

BadgeWithText.propTypes = {
    badge: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default BadgeWithText;
