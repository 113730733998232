import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from '../../styles/ContactForm.module.scss';
import Wrapper from '../Wrapper';
//import ReCaptchaWrapper from '../../../ReCaptcha/ReCaptcha';

class ContactForm extends Component {
    render() {
        const {
            title,
            isSending,
            handleInput,
            handleSubmit,
            // handleGoogleRecaptcha,
            phoneNumber,
            email,
            name,
            message,
            hasError,
            fieldsNames,
            // recaptchaRef,
        } = this.props;

        return (
            <Wrapper title={title}>
                <Form className={classes['contact-form']} onSubmit={handleSubmit} style={{ width: 242 }}>
                    <FormGroup>
                        <Input
                            type='text'
                            name={fieldsNames.name}
                            placeholder='Name'
                            value={name}
                            onChange={handleInput}
                            className={classes['text-field']}
                            invalid={hasError(fieldsNames.name)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type='text'
                            name={fieldsNames.email}
                            placeholder='Email'
                            value={email}
                            onChange={handleInput}
                            className={classes['text-field']}
                            invalid={hasError(fieldsNames.email)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type='text'
                            name={fieldsNames.phoneNumber}
                            placeholder='Phone number'
                            value={phoneNumber.trim()}
                            onChange={handleInput}
                            className={classes['text-field']}
                            invalid={hasError(fieldsNames.phoneNumber)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type='textarea'
                            name={fieldsNames.message}
                            placeholder='Message'
                            value={message}
                            onChange={handleInput}
                            className={classes['text-field']}
                            invalid={hasError(fieldsNames.message)}
                        />
                    </FormGroup>
                    {/* <ReCaptchaWrapper recaptchaRef={recaptchaRef} handleGoogleRecaptcha={handleGoogleRecaptcha} /> */}
                    <SpinnerButton isLoading={isSending} color='primary' className={classes['submit-button']}>
                        Send Message
                    </SpinnerButton>
                </Form>
            </Wrapper>
        );
    }
}

ContactForm.propTypes = {
    title: PropTypes.string.isRequired,
    isSending: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    // handleGoogleRecaptcha: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    hasError: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    // recaptcha: PropTypes.string.isRequired,
    fieldsNames: PropTypes.object.isRequired,
    // recaptchaRef: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isSending: state.contactMessage.isSending,
    };
};

export default connect(mapStateToProps, null)(ContactForm);
