import React, { Component } from 'react';
import history from 'utils/history';
import { PropTypes, QueryString } from 'utils/wrappers';
import Modal from './Modal';

class ModalContainer extends Component {
    componentDidMount() {
        const params = QueryString.parse(window.location.search);

        this.showByUrl(params);

        // listen when url change and handle it
        history.listen((location) => {
            const params = QueryString.parse(location.search);

            this.showByUrl(params);
        });
    }

    showByUrl = (params) => {
        const { hideModal, showModal, modalUriName, isOpen } = this.props;

        if (params[modalUriName] && params[modalUriName] === 'show' && !isOpen) {
            showModal();
        } else {
            hideModal();
        }
    };

    toggle = () => {
        const { hideModal, showModal, isOpen } = this.props;

        if (isOpen) {
            hideModal();
        } else {
            showModal();
        }
    };

    render() {
        const { isOpen, title, subtitle, body, additionalModalClass } = this.props;

        return (
            <Modal
                additionalModalClass={additionalModalClass}
                toggle={this.toggle}
                isOpen={isOpen}
                title={title}
                subtitle={subtitle}
                body={body}
            />
        );
    }
}

ModalContainer.propTypes = {
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    modalUriName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    additionalModalClass: PropTypes.string,
};

export default ModalContainer;
