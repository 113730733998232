import PropertiesCategory from 'components/Page/PropertiesCategory/PropertiesCategory';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import { applyFilters, clearFilters, setActivePage, setFilter } from 'redux/actions/propertiesSearchParamsActions';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import { QueryString } from 'utils/wrappers';
import routes from '../../../domain/routes';

class PropertiesCategoryContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: null,
            slug: null,
        };
    }

    componentDidMount() {
        const paramsFromUrl = QueryString.parse(history.location.search);
        const page = paramsFromUrl.page ? paramsFromUrl.page : 1;
        this.props.setActivePage(page);
        this.changedSlug();

        this.unlisten = history.listen(() => {
            this.changedSlug();

            const paramsFromUrl = QueryString.parse(window.location.search);
            const page = paramsFromUrl.page ? paramsFromUrl.page : 1;

            this.props.setActivePage(page);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.categories !== prevProps.categories) {
            this.setCategory(this.state.slug);
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }

    changedSlug = () => {
        const match = matchPath(window.location.pathname, {
            path: routes.properties.category,
        });

        if (match && match.params) {
            this.setState(
                {
                    slug: match.params.slug,
                },
                () => {
                    this.setCategory(this.state.slug);
                },
            );
        }
    };

    setCategory = (slug) => {
        this.setState(
            {
                category: this.props.categories.filter((category) => category.slug === slug)[0],
            },
            () => {
                this.getProperties();
            },
        );
    };

    getProperties = () => {
        this.props.clearFilters();
        this.props.setFilter(true, 'category', this.state.slug);
        this.props.applyFilters();
        this.props.searchProperties();
    };

    onPageChange = (page) => {
        this.setPage(page);
        window.scrollTo(0, 0);
    };

    setPage = (page) => {
        history.push(routeWithQuery({ page: page }, true));
    };

    render() {
        return <PropertiesCategory category={this.state.category} onPageChange={this.onPageChange} />;
    }
}

const mapStateToProps = (state) => ({
    categories: state.propertyCategories.data,
    searchedProperties: state.searchedProperties,
});

const mapDispatchToProps = {
    clearFilters: clearFilters,
    searchProperties: searchedPropertiesAction,
    setFilter: setFilter,
    applyFilters: applyFilters,
    setActivePage: setActivePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesCategoryContainer);
