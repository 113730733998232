import React from 'react';
import classes from 'components/Page/Property/Part/Gallery/Fragment/styles/MainImage.module.scss';
import { Youtube } from 'utils/services/youtube';
import YoutubeIframe from 'components/Page/Property/Part/Gallery/Fragment/YoutubeIframe';

const MediaContainer = ({ mainImageUrl, withArrows }) => {
    const videoId = Youtube.getVideoId(mainImageUrl);

    if (videoId) {
        return <YoutubeIframe videoId={videoId} className={withArrows ? classes['video-with-arrows'] : ''} />;
    } else {
        return <img src={mainImageUrl} className={withArrows ? classes['with-arrows'] : null} alt='' />;
    }
};

export default MediaContainer;
