import 'common/styles/modules/page.scss';
import Title from 'components/Common/Title';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import env from 'utils/functions/env';
import routes from '../../../domain/routes';
import './TermsOfService.module.scss';

class TermsOfService extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <Title size={2} alignment={'left'} withoutMarginTop={true}>
                        Terms and Conditions
                    </Title>
                    <div className='header-content'>
                        <div className='address'>
                            <div>Landzilla, LLC – d/b/a {env('NAME')}</div>
                            <div>312 Arizona Ave, Santa Monica, CA 90401</div>
                            <div>info@landzilla.com</div>
                        </div>
                    </div>
                    <div className='content'>
                        <p className='our-website-section'>
                            <h5 className='small-header'>
                                Our Website is for U.S. and Canadian Users and Customers Only
                            </h5>
                            <p>Effective Date:</p>This web page represents a legal document and is the Terms and
                            Conditions (Agreement) for our website,&nbsp;
                            <a target='_blank' rel='nofollow noopener noreferrer' href='https://www.landzilla.com'>
                                https://www.landzilla.com
                            </a>
                            &nbsp;(Website). By using our Website, you agree to fully comply with and be bound by the
                            following Agreement each time you use our Website.
                        </p>
                        <p className='definitions-section'>
                            <h5 className='small-header'>Definitions</h5>The terms “us”, “we”, and “our” refer to
                            Landzilla, LLC d/b/a landzilla.com, hereinafter called {env('NAME')} the owner of this
                            Website. A “Visitor” is someone who merely browses our Website. A “Member” is someone who
                            has registered with our Website to use our Services. The term “User” is a collective
                            identifier that refers to either a Visitor or a Member. The term “Product” refers to any
                            products we sell.
                            <p>
                                All text, information, graphics, design, and data offered through our Website or
                                Services, whether produced by our Members or by us, are collectively known as our
                                “Content”. We distinguish content posted by our Members as “Member Content”.
                            </p>
                        </p>
                        <p className='acceptance-of-agreement-section'>
                            <h5 className='small-header'>Acceptance of Agreement</h5>
                            This Agreement is between you and {env('NAME')}.
                            <p>
                                THIS AGREEMENT CONTAINS WARRANTY DISCLAIMERS AND OTHER PROVISIONS THAT LIMIT OUR
                                LIABILITY TO YOU. PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY AND IN THEIR
                                ENTIRETY, AS USING, ACCESSING, AND/OR BROWSING OUR WEBSITE CONSTITUTES ACCEPTANCE OF
                                THESE TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO BE BOUND TO EACH AND EVERY TERM AND
                                CONDITION SET FORTH HEREIN, PLEASE EXIT OUR WEBSITE IMMEDIATELY AND DO NOT USE, ACCESS,
                                AND/OR BROWSE IT FURTHER.
                            </p>
                            <p>
                                Except as otherwise noted, this Agreement constitutes the entire and only Agreement
                                between you and {env('NAME')} and supersedes all other Agreements, representations,
                                warranties, and understandings with respect to our Website, Services, and the subject
                                matter contained herein. However, for you to use our Website and/or Services, you may
                                also be required to agree to additional terms and conditions. Those additional terms and
                                conditions will be incorporated into this Agreement unless otherwise stated
                            </p>
                        </p>
                        <p className='privacy-notice-section'>
                            <h5 className='small-header'>Privacy Notice</h5>Our Privacy Notice is considered part of
                            this Agreement and is available on this website. You must review our Privacy Notice by
                            clicking on this <Link to={routes.privacyPolicy}>link</Link>. If you do not accept and agree
                            to be bound by all the terms of this Agreement, including the&nbsp;
                            <a target='_blank' rel='nofollow noopener noreferrer' href='https://www.landzilla.com'>
                                https://www.landzilla.com
                            </a>
                            &nbsp;Privacy Notice, do not use this Website or our Services.
                        </p>
                        <p className='arbitration-section'>
                            <h5 className='small-header'>Arbitration</h5>Any legal controversy or claim arising from or
                            relating to this Agreement and/or our Service, excluding legal action taken by us to collect
                            or recover damages for – or obtain any injunction relating to – website operations,
                            intellectual property, and our Service, will be settled solely by binding arbitration in
                            accordance with the commercial arbitration rules of the American Arbitration Association.
                            Any such controversy or claim will be arbitrated on an individual basis, and will not be
                            consolidated in any arbitration with any claim or controversy of any other party. The
                            arbitration will be conducted in Los Angeles, California, and judgment on the arbitration
                            award may be entered into any court having jurisdiction thereof. You or we may seek any
                            interim or preliminary relief from a court of competent jurisdiction in Los Angeles,
                            California necessary to protect our or your rights or property pending the completion of
                            arbitration. Each party will bear half the arbitration fees and costs.
                        </p>
                        <p className='jurisdiction-section'>
                            <h5 className='small-header'>Choice of Law and Jurisdiction</h5>This Agreement will be
                            treated as if it were executed and performed in Los Angeles, California, and will be
                            governed by and construed in accordance with the laws of the state of California without
                            regard to conflict of law provisions. In addition, you agree to submit to the personal
                            jurisdiction and venue of such courts. Any cause of action by you with respect to our
                            Website or Service must be instituted within one (1) year after the cause of action arose or
                            be forever waived and barred.
                        </p>
                        <p className='limited-license-section'>
                            <h5 className='small-header'>Limited License</h5>
                            {env('NAME')} grants you a nonexclusive, nontransferable, revocable license to access and
                            use our Website and Services strictly in accordance with this Agreement. Your use of our
                            Website and Services is solely for internal, personal, noncommercial purposes unless
                            otherwise provided for in this Agreement. No printout or electronic version of any part of
                            our Website or Services may be used by you in any litigation or arbitration matter
                            whatsoever under any circumstances.
                        </p>
                        <p className='legal-compliance-section'>
                            <h5 className='small-header'>Legal Compliance</h5>You agree to comply with all applicable
                            domestic and international laws, statutes, ordinances, and regulations regarding your use of
                            our Website, Content, Services, and any software provided therein.
                        </p>
                        <p className='relationship-section'>
                            <h5 className='small-header'>Our Relationship to You</h5>This Agreement in no way creates
                            any agency, partnership, joint venture, or employee-employer or franchisor-franchisee
                            relationship between you and {env('NAME')}.
                        </p>
                        <p className='intellectual-section'>
                            <h5 className='small-header'>Our Intellectual Property</h5>Our Website may contain our
                            service marks or trademarks as well as those of our affiliates or other companies in the
                            form of words, graphics, and logos. Your use of our Website or Services does not constitute
                            any right or license for you to use our service marks or trademarks without the prior
                            written permission of {env('NAME')}.
                            <p>
                                Our Content, as found within our Website and Services, is protected under United States
                                and foreign copyrights. The copying, redistribution, use, or publication by you of any
                                such Content is strictly prohibited. Your use of our Website and Services does not grant
                                you any ownership rights to our Content.
                            </p>
                        </p>
                        <p className='membership-section'>
                            <h5 className='small-header'>Eligibility and Registration for Membership</h5>
                            To use our Services, you must register with our Website to become a Member. Your Membership
                            is not transferable or assignable and is void where prohibited. Our Website and Services are
                            intended solely for Users who are at least (18) years of age or older. Any registration by,
                            use of, or access to our Website by anyone under that age is unauthorized, unlicensed, and
                            in violation of these Terms and Conditions. By using our Website and/or Services, you
                            represent and warrant that you are (18) years of age or older and agree to abide by all the
                            terms and conditions of this Agreement. {env('NAME')} has sole right and discretion to
                            determine whether to accept a Member and may reject a Member’s registration with or without
                            explanation.
                            <p>
                                When you complete the registration process, you will receive a password that will allow
                                you to access our Services. You agree to maintain the confidentiality of your password
                                and are fully responsible for all liability and damages resulting from your failure to
                                maintain that confidentiality as well as all activities that occur through the use of
                                your password.
                            </p>
                            <p>
                                You agree to immediately notify us of any unauthorized use of your password or any other
                                breach of security. You agree that our Website cannot and will not be liable for any
                                loss or damage arising from your failure to comply with password security as discussed
                                herein.
                            </p>
                        </p>
                        <p className='disclaimer-section'>
                            <h5 className='small-header'>Disclaimer</h5>You acknowledge that nothing contained in our
                            Website will constitute financial, investment, legal, or real estate advice and that no
                            professional relationship of any kind is created between you and {env('NAME')} or our
                            Members. You agree that you will not make any financial, investment, legal, and/or other
                            decision based in whole or in part on anything contained in our Website or Services.
                            <p>
                                No content on our Website is intended to be an offer to sell nor a solicitation of
                                offers to buy real estate in states or jurisdictions where registration is required
                                prior to any offer being made or in any other jurisdiction where prohibited by law.
                            </p>
                            <p>
                                You understand that {env('NAME')} refers potential buyers to potential sellers only and
                                will not be representing you in any capacity as a buyer or a seller. And no content on
                                our Website shall be construed as an implied agency relationship. You assume full
                                responsibility for the use of the information on our Website and agree to comply with
                                all applicable local, state, and federal laws governing professional licensing, business
                                practices, real estate sales, agency, referrals, advertising and all other aspects of
                                doing business in the United States, or any other jurisdiction, is your sole
                                responsibility.
                            </p>
                        </p>
                        <p className='risk-section'>
                            <h5 className='small-header'>Assumption of Risk</h5>By using our Website and/or buying our
                            Products you represent that you understand and agree that buying real estate can involve
                            substantial risk and is not appropriate for all people. You understand that neither
                            {env('NAME')}, nor its officers managers, partners or agents are expert in matters of
                            surveying, engineering, structural, environmental or other physical conditions of real
                            property. You understand it is your duty and responsibility to engage in a thorough due
                            diligence investigation about any real estate you are interested in buying..
                            <p>Your due diligence includes the following:</p>
                            <ul>
                                <li>
                                    research building feasibility with county and local government planning, building,
                                    and zoning departments to make sure your intended use of the land and/or building
                                    plans are permitted
                                </li>
                                <li>
                                    research utilities to see if they exist and can be obtained on the property you’re
                                    interested in buying
                                </li>
                                <li>to inspect the property you’re interested in buying</li>
                                <li>
                                    to consult with a legal, tax, financial or other professional before buying any real
                                    estate
                                </li>
                                <li>having an independent legal review of your real estate purchase documents.</li>
                            </ul>
                            <p>
                                {env('NAME')} makes no warranties or guarantees about any third party we introduce to
                                you, including lenders, sellers of real property, developers of real property, property
                                managers, agents, and other product or service providers. You agree that you are solely
                                responsible for doing your own due diligence on all real property you are interested in
                                buying. No content on our Website, or communication from us will be interpreted as a
                                substitute for any portion of your due diligence process.
                            </p>
                        </p>
                        <p className='our-content-section'>
                            <h5 className='small-header'>Our Content</h5>We do not represent or otherwise warrant that
                            our Website will be error-free, or that we will correct any errors. We do not represent or
                            otherwise warrant that the information available on or through our Website will be correct,
                            accurate, timely, or otherwise reliable. {env('NAME')} reserves the right at our sole
                            discretion to change any content, software, and other items used or contained in our Website
                            or Services at any time without notice.
                        </p>
                        <p className='errors-section'>
                            <h5 className='small-header'>Errors, Corrections, and Changes</h5>We do not represent or
                            otherwise warrant that our Website will be error-free or free from viruses or other harmful
                            components, or that we will correct any errors. We do not represent or otherwise warrant
                            that the information available on or through our Website will be correct, accurate, timely,
                            or otherwise reliable. {env('NAME')} reserves the right at our sole discretion to change any
                            content, software, and other items used or contained in our Website or Services at any time
                            without notice.
                        </p>
                        <p className='published-section'>
                            <h5 className='small-header'>Content Published by Third Parties</h5>Our Website publishes
                            content supplied by third parties, Users, advertisers, merchants, and sponsors, and business
                            partners. Accordingly, {env('NAME')} has no editorial control over such content. Any
                            opinions or other information or content expressed or made available by third parties,
                            including information providers, Users, or any other user of our Website, are those of the
                            respective author(s) and not of {env('NAME')}. {env('NAME')} does not guarantee the
                            accuracy, completeness, merchantability, or fitness for any particular purpose nor the
                            legality of any content provided by any of these parties.
                            <p>
                                You understand that we do not operate or control the products or services offered by
                                third-party merchants. These merchants are responsible for all aspects of order
                                processing, fulfillment, billing, and customer service. We are not a party to the
                                transactions entered into between you and merchants. You agree that use of or purchase
                                from such merchants is AT YOUR SOLE RISK AND WITHOUT WARRANTIES OF ANY KIND BY US. All
                                rules, legal documents (including privacy policies), and operating procedures of
                                merchants will apply to you while on any merchant websites.
                            </p>
                        </p>
                        <p className='warranty-section'>
                            <h5 className='small-header'>Warranty Disclaimer</h5>
                            {env('NAME')} is not responsible or liable in any manner for any Content posted on our
                            Website or in connection with our Services, whether posted or caused by Members of our
                            Website, or by {env('NAME')}. Although we provide rules for Member conduct and postings, we
                            do not control and are not responsible for what Members post, transmit, or share on our
                            Website or Services, and are not responsible for any offensive, inappropriate, obscene,
                            unlawful, or otherwise objectionable content you may encounter using our Website or
                            Services. {env('NAME')} is not responsible for the online or offline conduct of any User of
                            our Website or Services.
                            <p>
                                Our Website or Services may be temporarily unavailable from time to time for maintenance
                                or other reasons. {env('NAME')} assumes no responsibility for any error, omission,
                                interruption, deletion, defect, delay in operation or transmission, communications line
                                failure, theft or destruction, unauthorized access to, or alteration of Member
                                communications.
                            </p>
                            <p>
                                {env('NAME')} is not responsible for any technical malfunction or other problems of any
                                telephone network or service, computer system, server or provider, computer or mobile
                                phone equipment, or software, or for any failure of email on account of technical
                                problems or traffic congestion on the Internet, or for any combination thereof –
                                including injury or damage to Members’ or any other person’s computer, mobile phone, or
                                other hardware or software – related to or resulting from the use or downloading of
                                materials in connection with our Website or Services, including, without limitation, any
                                software provided through our Website or Services.
                            </p>
                            <p>
                                Under no circumstances will {env('NAME')} be responsible for any loss or damage,
                                including any loss or damage, personal injury, or death resulting from anyone’s use of
                                our Website or Services, or any interactions between Users of our Website or Services,
                                whether online or offline.
                            </p>
                            <p>
                                Reference to any products, services, processes, or other information by trade name,
                                trademark, manufacturer, supplier, or otherwise does not constitute or imply
                                endorsement, sponsorship, recommendation, or any affiliation with our Website by third
                                parties or by any of the equipment or programming associated with or utilized by our
                                Services.
                            </p>
                            <p>
                                THE INFORMATION, CONTENT, AND DOCUMENTS FROM OR THROUGH OUR WEBSITE ARE PROVIDED
                                ‘AS-IS’, ‘AS AVAILABLE’, WITH ‘ALL FAULTS’, AND ALL EXPRESS OR IMPLIED WARRANTIES ARE
                                DISCLAIMED (INCLUDING, BUT NOT LIMITED TO, THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF
                                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE). OUR WEBSITE AND SERVICES MAY
                                CONTAIN BUGS, ERRORS, PROBLEMS, OR OTHER LIMITATIONS.
                            </p>
                            <p>
                                {env('NAME')}, INCLUDING ALL OUR AFFILIATES, HAS NO LIABILITY WHATSOEVER FOR YOUR USE OF
                                OUR WEBSITE OR SERVICES. {env('NAME')} CANNOT GUARANTEE AND DOES NOT PROMISE ANY
                                SPECIFIC RESULTS FROM THE USE OF OUR WEBSITE OR SERVICES, INCLUDING, BUT NOT LIMITED TO,
                                RELATED SOFTWARE. {env('NAME')} DOES NOT REPRESENT OR WARRANT THAT OUR CONTENT,
                                SERVICES, OR ANY SOFTWARE FOUND WITHIN ARE ACCURATE, COMPLETE, RELIABLE, CURRENT,
                                ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD
                                EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY SUCH CONTENT OR SOFTWARE AND USE
                                INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND REMOVE VIRUSES. ALL RESPONSIBILITY OR
                                LIABILITY FOR ANY DAMAGES CAUSED BY VIRUSES SOMEHOW ATTRIBUTED TO OUR CONTENT, SERVICES,
                                AND RELATED SOFTWARE IS DISCLAIMED.
                            </p>
                            <p>
                                WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE
                                OBTAIN CONTENT AND RELATED SOFTWARE FROM OR THROUGH OUR WEBSITE OR SERVICES AT YOUR OWN
                                RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO
                                YOUR MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA, OR OTHER HARM OF ANY KIND THAT MAY
                                RESULT. WE AND ALL OUR AFFILIATES ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
                                OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS,
                                LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT
                                (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE
                                POSSIBILITY OF SUCH DAMAGES. THE NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE
                                FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND {env('NAME')}.
                            </p>
                            <p>
                                OUR WEBSITE AND SERVICES WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. NO ADVICE OR
                                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US THROUGH OUR WEBSITE OR
                                SERVICES WILL CREATE ANY WARRANTY, REPRESENTATION, OR GUARANTEE NOT EXPRESSLY STATED IN
                                THIS AGREEMENT.
                            </p>
                        </p>
                        <p className='limitation-section'>
                            <h5 className='small-header'>Limitation of Liability</h5>IN NO EVENT WILL {env('NAME')}
                            OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY
                            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING FOR
                            ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF OUR WEBSITE, CONTENT, SERVICES, OR
                            ANY RELATED SOFTWARE ACCESSED THROUGH OR DOWNLOADED FROM OUR WEBSITE OR SERVICES, EVEN IF
                            {env('NAME')} IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, {env('NAME')}’S LIABILITY TO YOU
                            FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE
                            LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR WEBSITE AND/OR SERVICES ACCESSED DURING
                            THE PREVIOUS MONTH OF YOUR MEMBERSHIP PRIOR TO THE EVENT GIVING RISE TO LIABILITY.
                        </p>
                        <p className='member-section'>
                            <h5 className='small-header'>Member Conduct</h5>Members may post their content to our
                            Website through our Services (Member Content). Members and Visitors understand that by using
                            our Website or Service, they may be exposed to content that is offensive, indecent, or
                            objectionable. We have no control over Member Content and do not in any way guarantee its
                            quality, accuracy, or integrity. {env('NAME')} is not responsible for the monitoring or
                            filtering of any Member Content. Should any Member Content be found illegal,
                            {env('NAME')} will submit all necessary information to relevant authorities.
                            <p>
                                If any Member Content is reported to {env('NAME')} as being offensive or inappropriate,
                                we may ask the Member to retract or otherwise modify the questionable content within 24
                                hours of being notified by {env('NAME')}. If the Member fails to meet such a request,{' '}
                                {env('NAME')} has full authority to restrict the Member’s ability to post Member Content
                                OR to immediately terminate the Member’s membership without further notice to the
                                Member.
                            </p>
                            <p>
                                Without limiting the foregoing, we have sole discretion to remove any Member Content
                                that violates this Agreement or that is otherwise objectionable in our sole discretion.
                                Members are responsible for complying with all applicable federal and state laws for
                                their content, including copyright and trademark laws. Members will respect copyright
                                and trademark laws.
                            </p>
                            <p>
                                You warrant that you will not use our Services to infringe on the intellectual property
                                rights of others in any way. In accordance with the DMCA and other applicable law, we
                                have adopted a policy of terminating Members whom we deem, in our sole discretion, to be
                                infringers of others’ intellectual property rights.
                            </p>
                        </p>
                        <p className='member-following-section'>
                            <h5 className='small-header'>
                                As a Member, you agree not to use our Services to do any of the following:
                            </h5>
                            <u>Upload, post, or otherwise transmit any Member Content that:</u>
                            <ol>
                                <li>Violates any local, state, federal, or international laws</li>
                                <li>
                                    Infringes on any patent, trademark, trade secret, copyright, or other proprietary
                                    rights of any party
                                </li>
                                <li>
                                    Harms, threatens, defames, promotes violence or illegal activities, or is otherwise
                                    vulgar, obscene, abusive, harassing, tortuous, libelous, invasive of another’s
                                    privacy, hateful, or racially, ethically, or otherwise objectionable
                                </li>
                                <li>
                                    Links directly or indirectly to any materials to which you do not have a right to
                                    link
                                </li>
                                <li>
                                    Contains any private information of any third party, including, without limitation,
                                    addresses, phone numbers, email addresses, Social Security numbers, and credit card
                                    numbers
                                </li>
                                <li>
                                    Contains software viruses or any other computer code, files, or programs designed to
                                    interrupt, destroy, or limit the functionality of any computer software or hardware
                                    or telecommunications equipment, or to extract information from our Website or
                                    Services
                                </li>
                                <li>
                                    Contains any unsolicited or unauthorized advertising, solicitations, promotional
                                    materials, junk mail, spam, chain letters, pyramid schemes, or any other form of
                                    solicitation
                                </li>
                                <li>
                                    In the sole judgment of {env('NAME')} is objectionable or restricts or inhibits any
                                    other person from using or enjoying our Website or Services, or which may expose
                                    {env('NAME')}, our affiliates, or our Users to any harm or liability of any type
                                </li>
                            </ol>
                            <p>
                                <u>Use our Content to:</u>
                            </p>
                            <ol>
                                <li>Develop a competing website</li>
                                <li>
                                    Create compilations or derivative works as defined under United States copyright
                                    laws
                                </li>
                                <li>
                                    Redistribute it in any manner, including, but not limited to, sale, license, lease,
                                    rental, subscription, or any other distribution mechanism
                                </li>
                                <li>
                                    Decompile, disassemble, or reverse engineer our Website, Services, and any related
                                    software
                                </li>
                                <li>
                                    Use our Website or Services in any manner that violates this Agreement or any local,
                                    state, federal, or international laws
                                </li>
                            </ol>
                        </p>
                        <p className='information-section'>
                            <h5 className='small-header'>Use of Information</h5>You grant {env('NAME')} a license to use
                            the information and materials you post to our Website. By posting, displaying, transmitting,
                            performing, or otherwise distributing information or other content (“Member Content”) to our
                            Website, you are granting {env('NAME')}, its officers, directors, employees, agents,
                            consultants, representatives, and affiliates, a license to use the Member Content in
                            connection with the operation of the business of {env('NAME')}, its directors, employees,
                            officers, affiliates, representatives, consultants, and agents, including, without
                            limitation, a right to distribute, copy, transmit, publicly display, reproduce, translate,
                            edit, and reformat Member Content. You understand and agree that you will not be compensated
                            for any Member Content. By posting Member Content on our Website or Service, you warrant and
                            represent that you own the rights to the Member Content or are authorized to post, display,
                            distribute, perform, or transmit Member Content.
                        </p>
                        <p className='unlawful-activity-section'>
                            <h5 className='small-header'>Unlawful Activity</h5>We reserve the right to investigate
                            complaints or reported violations of this Agreement and to take any action we deem
                            appropriate, including, but not limited to, reporting any suspected unlawful activity to law
                            enforcement officials, regulators, or other third parties and disclosing any information
                            necessary or appropriate to such persons or entities relating to your profile, email
                            addresses, usage history, posted materials, IP addresses, and traffic information.
                        </p>
                        <p className='linking-website-section'>
                            <h5 className='small-header'>Linking to Our Website</h5>You may provide links to our Website
                            provided that (a) you do not remove or obscure any portion of our Website by framing or
                            otherwise, (b) your website does not engage in illegal or pornographic activities, and (c)
                            you cease providing links to our Website immediately upon our request.
                        </p>
                        <p className='links-to-other-websites-section'>
                            <h5 className='small-header'>Links to Other Websites</h5>
                            Our Website may from time to time contain links to third-party websites. Inclusion of links
                            for any website on our Website does not mean that we endorse, guarantee, warrant, or
                            recommend the services, information, content, and/or data of such third-party websites.
                            {env('NAME')} has no control over the legal documents and privacy practices of third-party
                            websites; you access any third-party websites at your own risk. We recommend that you review
                            the privacy notice and terms and conditions of those websites to fully understand what
                            information is collected and how it is used.
                        </p>
                        <p className='payments-section'>
                            <h5 className='small-header'>Payments</h5>You represent and warrant that if you are
                            purchasing something from us, (I) any payment information you supply is true and complete,
                            (II) charges incurred by you will be honored by your bank or credit card company, (III) you
                            will pay the charges incurred by you at the posted prices, including any applicable taxes,
                            and (IV) if your initial payment method is dishonored, you will still pay the incurred
                            charges, including any surcharge we may incur due to the dishonored payment.
                        </p>
                        <p className='refund-policy-section'>
                            <h5 className='small-header'>Refund Policy</h5>
                            <u>Processing Fees</u> – Processing fees paid by the buyer to purchase a property from our
                            website will be refunded if requested in writing by the buyer within 24 hours from when the
                            payment is processed. Once the processing fees are refunded, the buyer can no longer
                            complete the purchase and no longer reserves the property associated with the fees.
                            <p>
                                <u>Listing Fees</u> – Listing fees and subscription fees paid to sell or advertise a
                                property on our website are non-refundable.
                            </p>
                        </p>
                        <p className='termination-membership-section'>
                            <h5 className='small-header'>Termination of Membership</h5>
                            Your membership with us is effective until terminated by you or us. Your rights under these
                            Terms and Conditions will terminate without our notice if you fail to comply with any term
                            of these Terms and Conditions. Upon termination, you will stop representing yourself as a
                            registered Member or Client. You must delete or destroy any information or content
                            (including all copies) obtained from our Website. Certain provisions of this Agreement,
                            including, but not limited to, copyrights, indemnity, trademarks, limitation of liability,
                            warranty, and jurisdictional issues will survive the termination of this Agreement.
                        </p>
                        <p className='indemnification-section'>
                            <h5 className='small-header'>Indemnification</h5>You agree to indemnify, defend, and hold us
                            and our partners, agents, officers, directors, employees, subcontractors, successors,
                            assigns, third-party suppliers of information and documents, attorneys, advertisers, product
                            and service providers, and affiliates free from any liability, loss, claim, and expense,
                            including reasonable attorney’s fees, related to your violation of this Agreement or use of
                            our Website or Services.
                        </p>
                        <p className='severability-and-survival-section'>
                            <h5 className='small-header'>Severability and Survival</h5>
                            Should any part of this Agreement be held invalid or unenforceable, that portion will be
                            construed consistent with applicable law and the remaining portions will remain in full
                            force and effect. To the extent that any Content is in conflict or inconsistent with this
                            Agreement, this Agreement will take precedence. Our failure to enforce any provision of this
                            Agreement will not be deemed a waiver of such a provision, nor of the right to enforce such
                            a provision. Our rights under this Agreement will survive any termination of this Agreement.
                        </p>
                        <p className='changes-terms-and-conditions-section'>
                            <h5 className='small-header'>Changes to Our Terms and Conditions</h5>We reserve the right to
                            change these Terms and Conditions at any time by giving you advance notice of the changes by
                            email or in writing. We will also post these changes on our website. These changes will
                            become effective 30 days after receipt of the notice. To avoid doubt, no unilateral
                            amendment will retroactively change agreed dispute-resolution provisions of these Terms and
                            Conditions, if any, including, for example, arbitration provisions for then-pending disputes
                            unless the parties expressly agree otherwise. Your continued use of our Website, Services,
                            and Products after any change to these Terms and Conditions and our notifying you will
                            constitute your acceptance of such change. If you do not agree with the changes to these
                            Terms and Conditions, you can choose to discontinue the use of our Website, Services, and
                            Products.
                        </p>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default TermsOfService;
