import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchSellerForPanelAction } from 'redux/actions/sellersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* fetchSellerForPanelSaga(action) {
    try {
        yield put(fetchSellerForPanelAction.request());

        const response = yield call(ACREAPI.sellers().fetchForPanel, action.payload);

        yield put(fetchSellerForPanelAction.success(response.data.result));
    } catch (e) {
        const message =
            e.response && e.response.data ? e.response.data.result : 'Loading of seller account has been failed';

        notifiFail({
            message: message,
        });

        yield put(fetchSellerForPanelAction.failure());
    } finally {
        yield put(fetchSellerForPanelAction.fulfill());
    }
}

export default function* fetchSellerForPanelSagaWatcher() {
    yield takeLatest(fetchSellerForPanelAction.trigger, fetchSellerForPanelSaga);
}
