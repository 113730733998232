import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/SocialMedia.module.scss';

const SocialMedia = ({ icons }) => {
    return (
        <React.Fragment>
            <h3 className={classes.title}>Follow Us</h3>
            <div className={classes.icons}>
                {icons.map((icon, index) => {
                    return (
                        <Link key={index} to={{ pathname: icon.url }} target={'_blank'}>
                            <i className={icon.name} />
                        </Link>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

SocialMedia.propTypes = {
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
};

export default SocialMedia;
