import { isEmptyObject } from 'utils/functions/objects';
import User from '../user/User';

class UserConverter {
    toDomain(source): User {
        if (isEmptyObject(source) || !source) {
            return null;
        }
        return new User(source.id, source.fname, source.lname, source.token);
    }
}

export default UserConverter;
