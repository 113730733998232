import axios from 'axios';
import env from 'utils/functions/env';

export default class GoogleMapsAPI {
    static reverse = ({ latitude, longitude }) => {
        const apiKey = env('GOOGLE_MAPS_API_KEY');

        return axios.get(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&key=' + apiKey,
        );
    };

    static explodeAddressComponents = (addressComponents) => {
        const city = addressComponents.filter((component) => component.types.indexOf('locality') !== -1)[0];
        const address = addressComponents.filter((component) => component.types.indexOf('route') !== -1)[0];
        const state = addressComponents.filter(
            (component) => component.types.indexOf('administrative_area_level_1') !== -1,
        )[0];
        const county = addressComponents.filter((component) => {
            return (
                component.types.indexOf('administrative_area_level_2') !== -1 ||
                component.types.indexOf('neighborhood') !== -1
            );
        })[0];
        const number = addressComponents.filter((component) => component.types.indexOf('street_number') !== -1)[0];
        const postalCode = addressComponents.filter((component) => component.types.indexOf('postal_code') !== -1)[0];

        return {
            city: city ? city.long_name : '',
            state: state ? state.long_name : '',
            county: county ? county.long_name : '',
            address: (number ? number.long_name : '') + ' ' + (address ? address.long_name : ''),
            zip: postalCode ? postalCode.long_name : '',
        };
    };
}
