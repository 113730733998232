import BadgeWithText from 'components/Common/BadgeWithText';
import OneColumnWrapper from 'components/Common/OneColumnWrapper';
import React from 'react';
import { PropTypes } from 'utils/wrappers';

const ResponseError = ({ responseStatus, errorText }) => {
    return (
        <OneColumnWrapper>
            <BadgeWithText badge={responseStatus} text={errorText} />
        </OneColumnWrapper>
    );
};

ResponseError.propTypes = {
    responseStatus: PropTypes.string.isRequired,
    errorText: PropTypes.string.isRequired,
};

export default ResponseError;
