import { all } from 'redux-saga/effects';
import activateAccountSagaWatcher from 'redux/sagas/activateAccountSaga';
import allStatesSagaWatcher from 'redux/sagas/allStatesSaga';
import articlesSagaWatcher from 'redux/sagas/articlesSaga';
import backendPropertiesFiltersSagaWatcher from 'redux/sagas/backendPropertiesFiltersSaga';
import contactMessageSagaWatcher from 'redux/sagas/contactMessageSaga';
import deleteUserSagaWatcher from 'redux/sagas/deleteUserSaga';
import editedUserSagaWatcher from 'redux/sagas/editedUserSaga';
import editUserSagaWatcher from 'redux/sagas/editUserSaga';
import exportAdminPropertiesSagaWatcher from 'redux/sagas/exportAdminPropertiesSaga';
import exportUsersSagaWatcher from 'redux/sagas/exportUsersSaga';
import featuredArticlesSagaWatcher from 'redux/sagas/featuredArticlesSaga';
import featuredPropertiesSagaWatcher from 'redux/sagas/featuredPropertiesSaga';
import featuredPropertyCategoriesSagaWatcher from 'redux/sagas/featuredPropertyCategoriesSaga';
import fetchAdminPropertiesSagaWatcher from 'redux/sagas/fetchAdminPropertiesSaga';
import fetchAllRolesSagaWatcher from 'redux/sagas/fetchAllRolesSaga';
import fetchAllUsersSagaWatcher from 'redux/sagas/fetchAllUsersSaga';
import fetchSellerForPanelSagaWatcher from 'redux/sagas/fetchSellerForPanelSaga';
import fetchSellerSagaWatcher from 'redux/sagas/fetchSellerSaga';
import getRelatedPropertiesElementsSagaWatcher from 'redux/sagas/getRelatedPropertiesElementsSaga';
import loginSagaWatcher from 'redux/sagas/loginSaga';
import loginSocialSagaWatcher from 'redux/sagas/loginSocialSaga';
import onePropertyContactMessageSagaWatcher from 'redux/sagas/onePropertyContactMessageSaga';
import onePropertySagaWatcher from 'redux/sagas/onePropertySaga';
import profileSagaWatcher from 'redux/sagas/profileSaga';
import propertyCategoriesSagaWatcher from 'redux/sagas/propertyCategoriesSaga';
import requestPasswordResetSagaWatcher from 'redux/sagas/requestPasswordResetSaga';
import requestUpdateUserRoleSagaWatcher from 'redux/sagas/requestUpdateUserRoleSaga';
import resetPasswordSagaWatcher from 'redux/sagas/resetPasswordSaga';
import searchedPropertiesSagaWatcher from 'redux/sagas/searchedPropertiesSaga';
import showPhoneSagaWatcher from 'redux/sagas/showPhoneSaga';
import subscribeNewsletterSagaWatcher from 'redux/sagas/subscribeNewsletterSaga';
import togglePropertyIsFeaturedSagaWatcher from 'redux/sagas/togglePropertyIsFeaturedSaga';
import updateProfileSagaWatcher from 'redux/sagas/updateProfileSaga';
import updateSellerSagaWatcher from 'redux/sagas/updateSellerSaga';
import {
    adminArticleCreateSagaWatcher,
    adminArticleDeleteImageSagaWatcher,
    adminArticleDeleteSagaWatcher,
    adminArticleReadSagaWatcher,
    adminArticlesFetchSagaWatcher,
    adminArticleUpdateSagaWatcher,
} from './adminArticleCrudSaga';
import changePropertyStatusSagaWatcher from './changePropertyStatusSaga';
import deletePropertySagaWatcher from './deletePropertySaga';
import fetchFavouritePropertiesSagaWatcher from './fetchFavouritePropertiesSaga';
import fetchPropertyAdminSagaWatcher from './fetchPropertyAdminSaga';
import getFullAddressFromGoogleMapsSagaWatcher from './getFullAddressFromGoogleMapsSaga';
import isFavouritePropertySagaWatcher from './isFavouritePropertySaga';
import paymentsSagaWatcher from './paymentsSaga';
import sellersSagaWatcher from './sellersSaga';
import storePropertySagaWatcher from './storePropertySaga';
import toggleIsFavouritePropertySagaWatcher from './toggleIsFavouritePropertySaga';
import updatePropertySagaWatcher from './updatePropertySaga';
import submitCheckoutPaymentSagaWatcher from 'redux/sagas/checkoutPaymentSaga';

/**
 * Collect all root sagas from sagas files
 * @returns {IterableIterator<*>}
 */
export default function* rootSaga() {
    yield all([
        articlesSagaWatcher(),
        loginSagaWatcher(),
        activateAccountSagaWatcher(),
        featuredArticlesSagaWatcher(),
        contactMessageSagaWatcher(),
        featuredPropertiesSagaWatcher(),
        searchedPropertiesSagaWatcher(),
        backendPropertiesFiltersSagaWatcher(),
        requestPasswordResetSagaWatcher(),
        resetPasswordSagaWatcher(),
        onePropertySagaWatcher(),
        onePropertyContactMessageSagaWatcher(),
        featuredPropertyCategoriesSagaWatcher(),
        propertyCategoriesSagaWatcher(),
        fetchSellerSagaWatcher(),
        fetchAllUsersSagaWatcher(),
        deleteUserSagaWatcher(),
        exportUsersSagaWatcher(),
        editUserSagaWatcher(),
        editedUserSagaWatcher(),
        fetchAllRolesSagaWatcher(),
        requestUpdateUserRoleSagaWatcher(),
        profileSagaWatcher(),
        updateProfileSagaWatcher(),
        allStatesSagaWatcher(),
        loginSocialSagaWatcher(),
        fetchAdminPropertiesSagaWatcher(),
        exportAdminPropertiesSagaWatcher(),
        getRelatedPropertiesElementsSagaWatcher(),
        updateSellerSagaWatcher(),
        fetchSellerForPanelSagaWatcher(),
        deletePropertySagaWatcher(),
        getFullAddressFromGoogleMapsSagaWatcher(),
        sellersSagaWatcher(),
        storePropertySagaWatcher(),
        changePropertyStatusSagaWatcher(),
        fetchPropertyAdminSagaWatcher(),
        updatePropertySagaWatcher(),
        adminArticlesFetchSagaWatcher(),
        adminArticleCreateSagaWatcher(),
        adminArticleReadSagaWatcher(),
        adminArticleUpdateSagaWatcher(),
        adminArticleDeleteSagaWatcher(),
        adminArticleDeleteImageSagaWatcher(),
        paymentsSagaWatcher(),
        toggleIsFavouritePropertySagaWatcher(),
        isFavouritePropertySagaWatcher(),
        fetchFavouritePropertiesSagaWatcher(),
        submitCheckoutPaymentSagaWatcher(),
        togglePropertyIsFeaturedSagaWatcher(),
        subscribeNewsletterSagaWatcher(),
        showPhoneSagaWatcher(),
    ]);
}
