import React from 'react';
import { connect } from 'react-redux';
import {
    handlePropertyFormInputAction,
    handlePropertyFormSwitchInputAction,
    onClickCategoryCheckboxAction,
} from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/PropertyForm.module.scss';
import Title from 'components/Common/Title';
import { Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import { bsSize, fields } from '../../data/fieldsProps';
import { store } from 'redux/store';

const GeneralFields = ({
    handleInput,
    handleSwitchInput,
    handleCheckBox,
    propertyId,
    fpid,
    zoningDesc,
    apn,
    propertiesRelatedElements,
    sellerId,
    sellers,
    propertyType,
    categories,
    zoning,
    size,
    roadAccess,
    isFeatured,
}) => {
    const catList = () => {
        const c = store.getState().propertyCategories?.data;
        if (c && c.length > 1) {
            return c;
        }
        return [
            { id: 1, name: 'Cheap Land for Sale' },
            { id: 2, name: 'Owner Financed Land' },
            { id: 4, name: 'Residential Lots' },
            { id: 5, name: 'Recreational Land' },
            { id: 6, name: 'Ranches/Larger Acreage' },
        ];
    };
    return (
        <React.Fragment>
            <Row form>
                <Col>
                    <Title size={5} underline className={styles['form-title']}>
                        General
                    </Title>
                </Col>
            </Row>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label>{fields.propertyId}</Label>
                        <Input
                            type={'text'}
                            name={'propertyId'}
                            placeholder={fields.propertyId}
                            value={propertyId}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.fpid}</Label>
                        <Input
                            type={'text'}
                            name={'fpid'}
                            placeholder={fields.fpid}
                            value={fpid}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>{' '}
                    <FormGroup>
                        <Label>{fields.apn}</Label>
                        <Input
                            type={'text'}
                            name={'apn'}
                            placeholder={fields.apn}
                            value={apn}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.seller}</Label>
                        <Input
                            type={'select'}
                            name={'sellerId'}
                            placeholder={fields.seller}
                            value={sellerId}
                            onChange={handleInput}
                            bsSize={bsSize}
                        >
                            <option value={''}>-</option>
                            {sellers.map((seller, index) => (
                                <option key={index} value={seller.id}>
                                    {seller.title} ({seller.head})
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.propertyType}</Label>
                        <Input
                            type={'select'}
                            name={'propertyType'}
                            placeholder={fields.propertyType}
                            value={propertyType}
                            onChange={handleInput}
                            bsSize={bsSize}
                        >
                            <option value={''}>-</option>
                            {Object.keys(propertiesRelatedElements.propertyTypes).map((key, index) => (
                                <option value={propertiesRelatedElements.propertyTypes[key]} key={index}>
                                    {key}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.categories}</Label>
                        {catList().map((o, index) => (
                            <Col md={9} key={'cat_' + index}>
                                <Label check className={styles['category-form-check-label']}>
                                    <Input
                                        type={'checkbox'}
                                        name={'categories'}
                                        onChange={handleCheckBox}
                                        value={o.id}
                                        checked={categories && categories.indexOf(o.id) !== -1}
                                    />
                                    {o.name}
                                </Label>
                            </Col>
                        ))}
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.size}</Label>
                        <Input
                            type={'text'}
                            name={'size'}
                            placeholder={fields.size}
                            value={size}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>{fields.isFeatured}</Label>
                        <CustomInput
                            type='switch'
                            id='is-featured'
                            name='isFeatured'
                            checked={isFeatured}
                            onChange={handleSwitchInput}
                            className={styles['switch']}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.roadAccess}</Label>
                        <Input
                            type={'select'}
                            name={'roadAccess'}
                            placeholder={fields.roadAccess}
                            value={roadAccess}
                            onChange={handleInput}
                            bsSize={bsSize}
                        >
                            <option value={''}>-</option>
                            {Object.keys(propertiesRelatedElements.roadAccess).map((key, index) => (
                                <option value={propertiesRelatedElements.roadAccess[key]} key={index}>
                                    {key}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.zoning}</Label>
                        <Input
                            type={'select'}
                            name={'zoning'}
                            placeholder={fields.zoning}
                            value={zoning}
                            onChange={handleInput}
                            bsSize={bsSize}
                        >
                            <option value={''}>-</option>
                            {Object.keys(propertiesRelatedElements.zonings).map((key, index) => (
                                <option value={propertiesRelatedElements.zonings[key]} key={index}>
                                    {key}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.zoningDesc}</Label>
                        <Input
                            type={'textarea'}
                            name={'zoningDesc'}
                            placeholder={fields.zoningDesc}
                            value={zoningDesc}
                            onChange={handleInput}
                            bsSize={bsSize}
                            className={styles['textarea-field']}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

GeneralFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    propertyId: PropTypes.string.isRequired,
    // values of selected options like: value='1' are strings
    roadAccess: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    zoning: PropTypes.string.isRequired,
    sellerId: PropTypes.number.isRequired,
    fpid: PropTypes.string,
    apn: PropTypes.string.isRequired,
    // values of selected options like: value='1' are strings
    propertyType: PropTypes.string.isRequired,
    categories: PropTypes.array,
    zoningDesc: PropTypes.string,
    propertiesRelatedElements: PropTypes.shape({
        zonings: PropTypes.object,
        roadAccess: PropTypes.object,
        propertyTypes: PropTypes.object,
    }),
    sellers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            head: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
    handleSwitchInput: handlePropertyFormSwitchInputAction,
    handleCheckBox: onClickCategoryCheckboxAction,
};

const mapStateToProps = (state) => ({
    propertyId: state.propertyForm.propertyId,
    isFeatured: state.propertyForm.isFeatured,
    roadAccess: state.propertyForm.roadAccess,
    size: state.propertyForm.size,
    zoning: state.propertyForm.zoning,
    sellerId: state.propertyForm.sellerId,
    fpid: state.propertyForm.fpid,
    apn: state.propertyForm.apn,
    propertyType: state.propertyForm.propertyType,
    categories: state.propertyForm.categories,
    zoningDesc: state.propertyForm.zoningDesc,
    propertiesRelatedElements: state.relatedPropertiesElements.data,
    sellers: state.sellers.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralFields);
