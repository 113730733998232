import { useEffect } from 'react';
import routes from '../../../domain/routes';
import { useHistory } from 'react-router-dom';
import { logoutAction } from 'redux/actions/authActions';
import { useStore } from 'react-redux';

const LogoutRoute = (props) => {
    const store = useStore();
    const history = useHistory();
    useEffect(() => {
        if (history.location.pathname === props.path) {
            store.dispatch(logoutAction());
            history.push(routes.home);
        }
    }, [history, props.path, store]);
    return '';
};

export default LogoutRoute;
