import ACREAPI from 'utils/services/ACREAPI';
import { ADMIN_ENDPOINT_URL_PREFIX } from 'utils/services/ACREAPI/ACREAPI';

const ENDPOINT_URL_PREFIX = 'seller/';

class Sellers {
    static index = () => {
        return ACREAPI.authInstance().get(ADMIN_ENDPOINT_URL_PREFIX + 'all-sellers');
    };

    static show = (slug) => {
        return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + slug);
    };

    static fetchForPanel = (userId) => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + 'panel/' + userId);
    };

    static update = ({ id, data }) => {
        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + id + '/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };
}

export default Sellers;
