import BadgeWithText from 'components/Common/BadgeWithText';
import { STATUS_404 } from 'domain/constants/responseStatus';
import React from 'react';
import { PropTypes } from 'utils/wrappers';

const Badge404 = ({ text }) => {
    return <BadgeWithText badge={STATUS_404.toString()} text={text} />;
};

Badge404.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Badge404;
