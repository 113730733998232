export const uppercaseFirstLetter = (value) => {
    if (!value || !value.length) return '';
    return value.toString().charAt(0).toUpperCase() + value.slice(1);
};

export const lowercaseFirstLetter = (value) => {
    if (!value || !value.length) return '';
    return value.toString().charAt(0).toLowerCase() + value.slice(1);
};

export const UCWords = (str) =>
    str
        .toString()
        .split(/[^a-zA-Z]+/)
        .map((word) => uppercaseFirstLetter(word))
        .join(' ');

export const parseQuery = (str) => {
    const query = {};
    const pairs = (str[0] === '?' ? str.substr(1) : str).split('&');
    pairs.forEach((a, i) => {
        const [k, v] = a.split(/=/);
        query[decodeURIComponent(k)] = decodeURIComponent(v || '');
    });
    return query;
};
