import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginAction, loginSocialAction } from 'redux/actions/authActions';
import { hideLoginModal } from 'redux/actions/modalsActions';
import { handleInput } from 'utils/functions/inputHelpers';
import { FacebookLoginAPI, TYPE_FACEBOOK } from 'utils/services/facebookLoginAPI';
import { TYPE_GOOGLE } from 'utils/services/googleLoginAPI';
import { PropTypes } from 'utils/wrappers';
import LoginModal from './LoginModal';
import { DEFAULT_VALIDATOR_MESSAGES, recaptchaValidator } from 'utils/functions/validation';

const recaptchaRef = React.createRef();

class LoginModalContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);
        this.fbAPI = null;

        this.state = {
            login: '',
            password: '',
            recaptcha: '',
            errors: [],
        };
    }

    componentDidMount() {
        this.fbAPI = new FacebookLoginAPI();
    }

    onFacebookFail = () => {
        notifiFail({ message: 'Sign in with Facebook has failed' });
    };

    onFacebookClick = () => {
        this.fbAPI.login((data) => {
            this.props.loginBySocial({
                socialId: data.user.id,
                firstName: data.user.first_name,
                lastName: data.user.last_name,
                email: data.user.email,
                accessToken: data.res.authResponse.accessToken,
                type: TYPE_FACEBOOK,
            });
        }, this.onFacebookFail);
    };

    onGoogleClick = (data) => {
        if (data.error) {
            notifiFail({ message: 'Sign in with Google has failed' });

            return;
        }

        this.props.loginBySocial({
            socialId: data.profileObj.googleId,
            firstName: data.profileObj.givenName,
            lastName: data.profileObj.familyName,
            email: data.profileObj.email,
            accessToken: data.tokenId,
            type: TYPE_GOOGLE,
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors }, () => {
            if (this.state.errors.length > 0) {
                notifiFail({
                    message: errors,
                    title: 'Validation problems:',
                    duration: 0,
                });
                return;
            }

            this.props.loginUser({
                login: this.state.login,
                password: this.state.password,
                recaptcha: this.state.recaptcha,
            });
            this.setState({ password: '', recaptcha: '' });
            recaptchaRef.current.reset();
        });
    };

    validate = () => {
        let errors = [];
        const { recaptcha } = this.state;

        if (!recaptchaValidator(recaptcha)) {
            errors.push({ field: 'recaptcha', message: DEFAULT_VALIDATOR_MESSAGES.recaptcha });
        }

        return errors;
    };

    render() {
        return (
            <LoginModal
                handleInput={this.handleInput}
                onSubmit={this.onSubmit}
                onFacebookClick={this.onFacebookClick}
                onGoogleClick={this.onGoogleClick}
                login={this.state.login}
                password={this.state.password}
                recaptchaRef={recaptchaRef}
            />
        );
    }
}

LoginModalContainer.propTypes = {
    loginUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    loginUser: loginAction,
    loginBySocial: loginSocialAction,
    hideLoginModal: hideLoginModal,
};

export default connect(null, mapDispatchToProps)(LoginModalContainer);
