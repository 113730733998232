import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { sendContactMessageAction } from 'redux/actions/contactMessageActions';
import ACREAPI from 'utils/services/ACREAPI';

function* contactMessageSaga(action) {
    try {
        yield put(sendContactMessageAction.request());

        yield call(ACREAPI.contactMessages().send, action.payload);

        notifiSuccess({
            message: 'Your message has been sent!',
        });

        yield put(sendContactMessageAction.success());
    } catch (e) {
        notifiFail({
            message: 'Your message cannot be sent',
        });

        yield put(sendContactMessageAction.failure());
    } finally {
        yield put(sendContactMessageAction.fulfill());
    }
}

export default function* contactMessageSagaWatcher() {
    yield takeLatest(sendContactMessageAction.trigger, contactMessageSaga);
}
