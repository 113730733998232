import React, { Component } from 'react';
import Header from './Header';
import routes from 'domain/routes';

class HeaderContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navbar: {
                isOpen: false,
            },
        };
    }

    toggleNavbar = () =>
        this.setState({
            navbar: {
                isOpen: !this.state.navbar.isOpen,
            },
        });

    hideNavbar = () => {
        if (!this.state.navbar.isOpen) {
            return;
        }

        this.setState({
            navbar: {
                isOpen: false,
            },
        });
    };

    getMenuItems = () => {
        return [
            { name: 'Home', to: routes.home },
            { name: 'Listings', to: routes.properties.main },
            { name: 'Blog', to: routes.blog.main },
            { name: 'FAQ', to: routes.faq },
        ];
    };

    getTogglerClass = () => {
        if (this.state.navbar.isOpen) {
            return 'is-opened';
        }

        return null;
    };

    render() {
        return (
            <Header
                toggleNavbar={this.toggleNavbar}
                hideNavbar={this.hideNavbar}
                getTogglerClass={this.getTogglerClass}
                navbarIsOpen={this.state.navbar.isOpen}
                menuItems={this.getMenuItems()}
            />
        );
    }
}

export default HeaderContainer;
