import env from 'utils/functions/env';
import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';
import { isRecaptchaEnabled } from 'utils/featureFlags';

class FakeReCaptcha extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.ref.current = this;
    }

    reset() {}

    render() {
        return <div style={{ display: 'none' }} />;
    }
}

const ReCaptchaWrapper = ({ recaptchaRef, handleGoogleRecaptcha, variant, style }) => {
    const styles = variant === 'small' ? { transform: 'scale(0.8)', transformOrigin: '0 0', ...style } : {};
    if (isRecaptchaEnabled()) {
        return (
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={env('GOOGLE_RECAPTCHA_SITE_KEY')}
                onChange={handleGoogleRecaptcha}
                style={styles}
            />
        );
    } else {
        return <FakeReCaptcha ref={recaptchaRef} />;
    }
};

ReCaptchaWrapper.defaultProps = {
    variant: 'small',
    style: {},
};

export default ReCaptchaWrapper;
