import React, { Component } from 'react';
import { PropTypes } from 'utils/wrappers';
import Carousel from './Carousel';

class CarouselContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSlideIndex: 0,
        };
    }

    showPreviousSlide = () => {
        const maximum = this.props.slides.length - 1;
        const previous = this.state.currentSlideIndex - 1;
        const previousSlideIndex = previous < 0 ? maximum : previous;

        this.setState({
            currentSlideIndex: previousSlideIndex,
        });
    };

    showNextSlide = () => {
        const maximum = this.props.slides.length - 1;
        const next = this.state.currentSlideIndex + 1;
        const nextSlideIndex = next > maximum ? 0 : next;

        this.setState({
            currentSlideIndex: nextSlideIndex,
        });
    };

    render() {
        return (
            <Carousel
                slides={this.props.slides}
                currentSlideIndex={this.state.currentSlideIndex}
                showPreviousSlide={this.showPreviousSlide}
                showNextSlide={this.showNextSlide}
            />
        );
    }
}

CarouselContainer.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default CarouselContainer;
