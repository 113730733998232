import ACREAPI from 'utils/services/ACREAPI';
import { ADMIN_ENDPOINT_URL_PREFIX } from 'utils/services/ACREAPI/ACREAPI';
import { ENDPOINT_URL_PREFIX as USERS_ENDPOINT_URL_PREFIX } from './Users';

const ENDPOINT_URL_PREFIX = 'land-for-sale/';
// Global variable to store cached responses.
const responseCache = {};
const crypto = require('crypto');
const { Mutex } = require('async-mutex');
// Create a mutex instance
const mutex = new Mutex();

class Properties {
    static changeStatus = ({ id, statusId }) => {
        return ACREAPI.authInstance().put(ENDPOINT_URL_PREFIX + id + '/status', {
            statusId,
        });
    };

    static toggleIsFeatured = ({ id }) => {
        return ACREAPI.authInstance().put(ENDPOINT_URL_PREFIX + id + '/is-featured');
    };

    static store = (data) => {
        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + 'create', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    static update = ({ id, data }) => {
        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + id, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    static featured = () => {
        return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + 'featured');
    };

    static searched = async (filters, sorting, search, page = 1) => {
        // Generate a unique key based on input variables
        const cacheKey = crypto
            .createHash('md5')
            .update(JSON.stringify({ filters, sorting, search, page }))
            .digest('hex');

        // Acquire the lock
        const release = await mutex.acquire();

        try {
            // If the response for the given key exists in the cache, return it
            if (responseCache[cacheKey]) {
                return responseCache[cacheKey];
            }
            // Otherwise, fetch the data from the API
            const response = await ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + 'search', {
                params: {
                    ...filters,
                    sort: sorting,
                    search,
                    page,
                },
            });
            // Cache the response
            responseCache[cacheKey] = response;

            return response;
        } finally {
            // Release the lock
            release();
        }
    };

    static availableFilters = () => {
        return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + 'filters');
    };

    static states = () => {
        return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + 'states');
    };

    static adminIndex = ({
        page = 1,
        filterState = '',
        filterCounty = '',
        filterSizeFrom = '',
        filterSizeTo = '',
        filterPriceFrom = '',
        filterPriceTo = '',
        filterStatus = '',
    }) => {
        return ACREAPI.authInstance().get(ADMIN_ENDPOINT_URL_PREFIX + 'listing-search?page=' + page, {
            params: {
                filterState,
                filterCounty,
                filterSizeFrom,
                filterSizeTo,
                filterPriceFrom,
                filterPriceTo,
                filterStatus,
            },
        });
    };

    static adminExport = ({ type, ids }) => {
        return ACREAPI.authInstance().post(
            ADMIN_ENDPOINT_URL_PREFIX + 'listing-export',
            {
                type,
                id: ids,
            },
            {
                responseType: 'blob',
            },
        );
    };

    static getRelatedPropertiesElements = () => {
        return ACREAPI.guestInstance().get(ENDPOINT_URL_PREFIX + 'properties');
    };

    static oneProperty = (slug) => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + slug);
    };

    static fetchPropertyAdmin = (id) => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + id);
    };

    static delete = (id) => {
        return ACREAPI.authInstance().delete(ENDPOINT_URL_PREFIX + id);
    };

    static sendOnePropertyContactMessage = ({ propertySlug, name, email, message, phoneNumber }) => {
        return ACREAPI.guestInstance().post(ENDPOINT_URL_PREFIX + propertySlug + '/send-contact-message', {
            name: name,
            email: email,
            message: message,
            phone_number: phoneNumber,
        });
    };

    static toggleIsFavouriteProperty = (propertyId) => {
        return ACREAPI.authInstance().post(USERS_ENDPOINT_URL_PREFIX + 'favorite/action', {
            listing_id: propertyId,
        });
    };

    static isFavouriteProperty = (propertySlug) => {
        return ACREAPI.authInstance().get(USERS_ENDPOINT_URL_PREFIX + 'favorite/is/' + propertySlug);
    };
}

export default Properties;
