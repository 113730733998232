import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import styles from './styles/PasswordReset.module.scss';

const PasswordReset = ({ onSubmit, handleInput, password, passwordConfirmation, isSending }) => {
    return (
        <Container>
            <Row className={'justify-content-center'}>
                <Col xs={4}>
                    <Title size={2}>Set new password</Title>
                    <Form onSubmit={onSubmit} className={styles.form}>
                        <FormGroup>
                            <label>Password</label>
                            <Input type={'password'} name={'password'} value={password} onChange={handleInput} />
                        </FormGroup>
                        <FormGroup>
                            <label>Password confirmation</label>
                            <Input
                                type={'password'}
                                name={'passwordConfirmation'}
                                value={passwordConfirmation}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <SpinnerButton isLoading={isSending} color={'primary'} block>
                            Save
                        </SpinnerButton>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

PasswordReset.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    isSending: PropTypes.bool.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    isSending: state.resetedPassword.isSending,
});

export default connect(mapStateToProps, null)(PasswordReset);
