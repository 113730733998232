import React, { useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from './ShareButtons.module.scss';
import ReactTooltip from 'react-tooltip';

const ShareButtons = ({ className }) => {
    const currentUrl = window.location.href;
    const cssClass = joinNotEmpty([classes.share, className], ' ');
    const reference = useRef();

    const socialIcon = {
        size: 36,
        rounded: true,
    };

    return (
        <div className={cssClass}>
            <div className={classes.text}>Share:</div>
            <div className={classes.icons}>
                <div className={classes.icon + ' ' + classes.facebook}>
                    <FacebookShareButton url={currentUrl}>
                        <FacebookIcon size={socialIcon.size} round={socialIcon.rounded} />
                    </FacebookShareButton>
                </div>
                <div className={classes.icon + ' ' + classes.twitter}>
                    <TwitterShareButton url={currentUrl}>
                        <TwitterIcon size={socialIcon.size} round={socialIcon.rounded} />
                    </TwitterShareButton>
                </div>
                <div className={classes.icon + ' ' + classes.envelope}>
                    <EmailShareButton url={currentUrl}>
                        <EmailIcon size={socialIcon.size} round={socialIcon.rounded} />
                    </EmailShareButton>
                </div>
                <div className={classes.icon + ' ' + classes.link}>
                    <CopyToClipboard text={currentUrl} onCopy={() => ReactTooltip.show(reference.current)}>
                        <span ref={reference} data-event='click' data-tip='Link has been copied'>
                            <i className='fas fa-link' />
                        </span>
                    </CopyToClipboard>
                </div>
            </div>
        </div>
    );
};

ShareButtons.propTypes = {
    className: PropTypes.string,
};

export default ShareButtons;
