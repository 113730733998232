import React from 'react';
import { PropTypes } from 'utils/wrappers';
import CarouselNavigation from './Navigation';
import CarouselSlider from './Slider/CarouselSlider';

const Carousel = ({ slides, currentSlideIndex, showPreviousSlide, showNextSlide }) => {
    if (slides.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <CarouselSlider slides={slides} currentSlideIndex={currentSlideIndex} />
            {slides.length === 1 ? null : (
                <CarouselNavigation onClickLeft={showPreviousSlide} onClickRight={showNextSlide} />
            )}
        </React.Fragment>
    );
};

Carousel.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.element).isRequired,
    currentSlideIndex: PropTypes.number.isRequired,
    showPreviousSlide: PropTypes.func.isRequired,
    showNextSlide: PropTypes.func.isRequired,
};

export default Carousel;
