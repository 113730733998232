import routes from 'domain/routes';
import React from 'react';
import ArticleFormContainer from '../Form';

const UpdateArticle = () => {
    return (
        <ArticleFormContainer
            moduleTitle='Update article'
            moduleTitleActions={[
                {
                    name: 'Back to list',
                    url: routes.panel.blog.main,
                },
            ]}
        />
    );
};

export default UpdateArticle;
