import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import ContactFormContainer from './Fragment/ContactForm';
import classes from './styles/ContactBox.module.scss';
import ShowPhoneButton from './Fragment/ShowPhoneButton';

const ContactBox = ({ property, seller, className }) => {
    const cssClass = joinNotEmpty([classes['contact-box'], className], ' ');

    return (
        <div className={cssClass}>
            <div className={classes.wrapper}>
                {!seller || seller.name === null ? null : (
                    <div className={classes.seller}>
                        {seller.logoPath === null ? null : (
                            <img className={classes.logo} src={seller.logoPath} alt={seller.name} />
                        )}
                        <div className={classes.name}>{seller.name}</div>
                    </div>
                )}

                {!seller ? null : (
                    <div className={classes.phone}>
                        <div className={classes.separator}>
                            <div className={classes['horizontal-line']} />
                            <div className={classes.title}>Call Now</div>
                            <div className={classes['horizontal-line']} />
                        </div>
                        <div className={classes['phone-number']}>
                            <ShowPhoneButton sellerId={seller.id} listingId={property.id} />
                        </div>
                        <div className={classes.separator}>
                            <div className={classes['horizontal-line']} />
                            <div className={classes.title}>or get in touch</div>
                            <div className={classes['horizontal-line']} />
                        </div>
                    </div>
                )}

                <ContactFormContainer property={property} />
            </div>
        </div>
    );
};

ContactBox.propTypes = {
    property: PropTypes.object.isRequired,
    seller: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        logoPath: PropTypes.string,
    }),
    className: PropTypes.string,
};

export default ContactBox;
