import sorting from 'data/properties/sorting';
import {
    APPLY_FILTERS,
    CLEAR_FILTERS,
    SET_ACTIVE_PAGE,
    SET_FILTER,
    SET_SEARCH,
    SET_SORTING,
} from 'redux/actions/propertiesSearchParamsActions';
import { Lodash } from 'utils/wrappers';

const defaultState = {
    filters: {},
    appliedFilters: {},
    sorting: sorting[0].value, // first value is default
    search: '',
    activePage: 1,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_FILTER:
            const filters = state.filters;
            const filterName = action.payload.name;
            const filterValue = action.payload.value;

            if (action.payload.isChecked) {
                if (Array.isArray(filterValue)) {
                    filters[filterName] = filterValue;
                } else {
                    if (!filters[filterName]) {
                        filters[filterName] = [];
                    }

                    filters[filterName].push(filterValue);
                }
            } else {
                const index = filters[filterName].indexOf(filterValue);
                if (index !== -1) {
                    filters[filterName].splice(index, 1);
                }
            }

            return {
                ...state,
                filters: {
                    ...filters,
                },
            };
        case APPLY_FILTERS:
            return {
                ...state,
                appliedFilters: Lodash.cloneDeep(state.filters),
            };
        case CLEAR_FILTERS:
            return {
                ...state,
                filters: {},
                appliedFilters: {},
            };
        case SET_SORTING:
            return {
                ...state,
                sorting: action.payload,
            };
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
            };
        case SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload,
            };
        default:
            return { ...state };
    }
};
