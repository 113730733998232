export default class {
    static toFrontend = (data) => {
        return {
            actuallyLogoUri: data.avatar && data.avatar.public_uri ? data.avatar.public_uri : '',
            title: data.title,
            address: data.address,
            telephones: data.telephones.map((telephone) => telephone.number),
            emails: data.emails.map((email) => email.email),
        };
    };

    static toApi: FormData = (data) => {
        const formData = new FormData();

        formData.append('image', data.image);
        formData.append('title', data.title);
        formData.append('address', data.address);

        data.telephones.forEach((telephone) => {
            formData.append('telephones[]', telephone);
        });
        data.emails.forEach((email) => {
            formData.append('email[]', email);
        });

        return formData;
    };
}
