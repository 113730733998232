import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { featuredArticlesAction } from 'redux/actions/articlesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* featuredArticlesSaga(action) {
    try {
        yield put(featuredArticlesAction.request());
        const response = yield call(ACREAPI.articles().recommended, action.payload);
        yield put(featuredArticlesAction.success(response.data.recommendPosts.data));
    } catch (error) {
        notifiFail({
            message: 'Loading of featured news has failed',
            duration: 3000,
        });

        yield put(featuredArticlesAction.failure());
    } finally {
        yield put(featuredArticlesAction.fulfill());
    }
}

export default function* featuredArticlesSagaWatcher() {
    yield takeLatest(featuredArticlesAction.TRIGGER, featuredArticlesSaga);
}
