import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { articlesAction } from 'redux/actions/articlesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* articlesSaga(action) {
    try {
        yield put(articlesAction.request());

        const response = yield call(ACREAPI.articles().index, action.payload);

        yield put(articlesAction.success(response.data.posts));
    } catch (e) {
        notifiFail({
            message: "Error: Couldn't load articles",
            duration: 0,
        });

        yield put(articlesAction.failure());
    } finally {
        yield put(articlesAction.fulfill());
    }
}

export default function* articlesSagaWatcher() {
    yield takeLatest(articlesAction.TRIGGER, articlesSaga);
}
