import React from 'react';
import { Button } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from './CarouselNavigation.module.scss';

const CarouselNavigation = ({ onClickLeft, onClickRight }) => {
    return (
        <div className={classes['carousel-navigation']}>
            {/*
             * Attention.
             * Buttons have to be rendered as links. It's required to avoid jerking of the whole box with buttons
             * while clicking on them in Apple Safari browser.
             */}
            <Button outline className={classes.button} onClick={onClickLeft} tag='a'>
                <i className='fas fa-chevron-left' />
            </Button>
            <Button outline className={classes.button} onClick={onClickRight} tag='a'>
                <i className='fas fa-chevron-right' />
            </Button>
        </div>
    );
};

CarouselNavigation.propTypes = {
    onClickLeft: PropTypes.func.isRequired,
    onClickRight: PropTypes.func.isRequired,
};

export default CarouselNavigation;
