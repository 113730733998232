import React from 'react';
import GoogleMapService from 'utils/services/GoogleMap';
import {
    GOOGLE_MAPS_DEFAULT_ZOOM,
    GOOGLE_MAPS_ZOOM_MAX,
    GOOGLE_MAPS_ZOOM_MIN,
} from 'utils/services/GoogleMap/Constants';
import { PropTypes } from 'utils/wrappers';
import classes from './GoogleMap.module.scss';

const GoogleMap = ({ latitude, longitude, zoom }) => {
    const url = new GoogleMapService(latitude, longitude, zoom).getUrl(true);

    if (url === null) {
        return null;
    }

    return (
        <iframe
            className={classes.iframe}
            src={url}
            scrolling='no'
            frameBorder='0'
            marginHeight='0'
            marginWidth='0'
            title={'map_' + latitude + '_' + longitude}
            allowFullScreen
        />
    );
};

GoogleMap.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    zoom: (props, propName, componentName) => {
        const zoom = props[propName];

        if (!GoogleMapService.isValidZoom(zoom)) {
            return new Error(
                "Invalid property '" +
                    propName +
                    "' supplied to '" +
                    componentName +
                    "'. It should be in range" +
                    ' of ' +
                    GOOGLE_MAPS_ZOOM_MIN +
                    ' to ' +
                    GOOGLE_MAPS_ZOOM_MAX +
                    ', but is ' +
                    zoom +
                    '.',
            );
        }
    },
};

GoogleMap.defaultProps = {
    zoom: GOOGLE_MAPS_DEFAULT_ZOOM,
};

export default GoogleMap;
