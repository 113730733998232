import ACREAPI from 'utils/services/ACREAPI';

export default class ContactMessages {
    static prefix = () => {
        return 'contact-messages/';
    };

    static send = ({ name, email, message, phoneNumber, recaptcha }) => {
        return ACREAPI.guestInstance().post(this.prefix(), {
            name,
            email,
            message,
            phone_number: phoneNumber,
            recaptcha: recaptcha,
        });
    };
}
