import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/Links.module.scss';
import Wrapper from './Wrapper';

const Links = ({ title, links }) => {
    if (links === undefined || links.length === 0) {
        return null;
    }
    return (
        <Wrapper title={title}>
            <ul className={classes['list-of-links']}>
                {links.map((link, index) => {
                    const prefix = link.name.match(/^Land for sale /) ? 'Land for sale ' : '';
                    const postfix = prefix ? link.name.replace(/^Land for sale /, '') : link.name;
                    return (
                        <li key={index} className={classes['list-item']}>
                            <Link to={link.url} className={classes.link}>
                                <span>{prefix}</span>
                                {postfix}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </Wrapper>
    );
};

Links.propTypes = {
    title: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default Links;
