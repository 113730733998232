import HtmlHead from 'components/Common/HtmlHead';
import ModalContainer from 'components/Common/Modal';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap'; // Button
import {
    hideRegistrationModal,
    loginModalActionType,
    registrationModalActionType,
    showRegistrationModal,
} from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import routes from 'domain/routes';
import env from 'utils/functions/env';
import { PropTypes } from 'utils/wrappers';
import './RegistrationModal.scss';
import { ReCaptchaWrapper } from 'components/Common/ReCaptcha';

const RegistrationModal = ({
    isOpen,
    showRegistrationModal,
    hideRegistrationModal,
    onSubmit,
    hasError,
    handleInput,
    handleCheckbox,
    email,
    password,
    passwordConfirmation,
    firstName,
    lastName,
    areRegulationsChecked,
    isLoading,
    fieldsNames,
    recaptchaRef,
}) => {
    const loginUrl = routeWithQuery({ [generateModalUriParam(loginModalActionType)]: 'show' });

    return (
        <React.Fragment>
            {isOpen ? <HtmlHead title={'Create Account'} /> : null}
            <ModalContainer
                showModal={showRegistrationModal}
                hideModal={hideRegistrationModal}
                additionalModalClass={'registration-modal'}
                isOpen={isOpen}
                modalUriName={generateModalUriParam(registrationModalActionType)}
                title={'Create Account'}
                subtitle={
                    <div>
                        Already have an Account? <Link to={loginUrl}>Sign In</Link>
                    </div>
                }
                body={
                    <Container>
                        <Row>
                            <Col lg={6} className={'form'}>
                                <Form onSubmit={onSubmit}>
                                    <FormGroup>
                                        <Input
                                            type={'text'}
                                            placeholder={'First Name'}
                                            name={fieldsNames.firstName}
                                            onChange={handleInput}
                                            value={firstName}
                                            invalid={hasError(fieldsNames.firstName)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type={'text'}
                                            placeholder={'Last Name'}
                                            name={fieldsNames.lastName}
                                            onChange={handleInput}
                                            value={lastName}
                                            invalid={hasError(fieldsNames.lastName)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type={'text'}
                                            placeholder={'Email'}
                                            name={fieldsNames.email}
                                            onChange={handleInput}
                                            value={email}
                                            invalid={hasError(fieldsNames.email)}
                                        />
                                    </FormGroup>
                                    <FormGroup className={'password-group'}>
                                        <Input
                                            type={'password'}
                                            placeholder={'Password'}
                                            name={fieldsNames.password}
                                            onChange={handleInput}
                                            value={password}
                                            invalid={hasError(fieldsNames.password)}
                                        />
                                    </FormGroup>
                                    <FormGroup className={'password-group'}>
                                        <Input
                                            type={'password'}
                                            placeholder={'Password Confirmation'}
                                            name={fieldsNames.passwordConfirmation}
                                            onChange={handleInput}
                                            value={passwordConfirmation}
                                            invalid={hasError(fieldsNames.passwordConfirmation)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            type={'checkbox'}
                                            name={fieldsNames.areRegulationsChecked}
                                            onChange={handleCheckbox}
                                            checked={areRegulationsChecked}
                                            invalid={hasError(fieldsNames.areRegulationsChecked)}
                                        />{' '}
                                        I agree to {env('NAME')}'s <Link to={routes.termsOfService}>Terms of Use</Link>{' '}
                                        and <Link to={routes.privacyPolicy}>Privacy Policy</Link>.
                                    </FormGroup>
                                    <ReCaptchaWrapper
                                        recaptchaRef={recaptchaRef}
                                        handleGoogleRecaptcha={(val) =>
                                            handleInput({ target: { name: fieldsNames.recaptcha, value: val } })
                                        }
                                    />
                                    <SpinnerButton
                                        isLoading={isLoading}
                                        color='primary'
                                        className={'submit'}
                                        type={'submit'}
                                    >
                                        Create Account
                                    </SpinnerButton>
                                </Form>
                            </Col>
                            <Col lg={6} className={'promo'}>
                                <h4>Why Create an Account?</h4>
                                <div className={'one-promo'}>
                                    <div className={'icon'}>
                                        <i className='fas fa-chart-line' />
                                    </div>
                                    <div className={'text'}>
                                        <h5>Be the First to Know</h5>
                                        <div>
                                            Set up search preferences and find out first when a property that meets your
                                            preferences is listed.
                                        </div>
                                    </div>
                                </div>
                                <div className={'one-promo'}>
                                    <div className={'icon'}>
                                        <i className='far fa-star' />
                                    </div>
                                    <div className={'text'}>
                                        <h5>Save Time – Save Listings and Searches</h5>
                                        <div>
                                            Trying to find the right property? Save properties you like so you can come
                                            back to them later.
                                        </div>
                                    </div>
                                </div>
                                <div className={'one-promo'}>
                                    <div className={'icon'}>
                                        <i className='far fa-flag' />
                                    </div>
                                    <div className={'text'}>
                                        <h5>One Step Closer</h5>
                                        <div>
                                            Creating an account is the first step in owning your own land. Make
                                            purchases using your account and manage existing purchases.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
            />
        </React.Fragment>
    );
};

RegistrationModal.propTypes = {
    adminUse: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    showRegistrationModal: PropTypes.func.isRequired,
    hideRegistrationModal: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    handleCheckbox: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    hasError: PropTypes.func.isRequired,
    fieldsNames: PropTypes.object.isRequired,

    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    areRegulationsChecked: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.registrationModal.isOpen,
    };
};

const mapDispatchToProps = {
    showRegistrationModal,
    hideRegistrationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationModal);
