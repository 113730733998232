import AcreSpinner from 'components/Common/AcreSpinner';
import ArticleCard from 'components/Common/ArticleCard';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import ACREAPI from 'utils/services/ACREAPI';
import RecommendedArticles from './RecommendedArticles';

class RecommendedArticlesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState(
            {
                isLoading: true,
            },
            () => {
                ACREAPI.articles()
                    .recommended(3)
                    .then((res) => {
                        this.setState({
                            articles: res.data.recommendPosts.data,
                        });
                    })
                    .catch((err) => {
                        notifiFail({
                            message: "Error: Couldn't load recommended articles",
                            duration: 0,
                        });
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            },
        );
    }

    getArticlesBoxes(articles) {
        return articles.map((article) => {
            return <ArticleCard key={article.id} article={article} />;
        });
    }

    render() {
        const { articles, isLoading } = this.state;

        if (isLoading) {
            return <AcreSpinner />;
        }

        const articlesBoxes = this.getArticlesBoxes(articles);
        return <RecommendedArticles articles={articlesBoxes} />;
    }
}

export default RecommendedArticlesContainer;
