import TitleWithActions from 'components/Common/Panel/TitleWithActions';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import AcreEditorState from 'domain/wysiwyg/EditorState';
import { ContentState, EditorState, Modifier } from 'draft-js';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Editor, PropTypes } from 'utils/wrappers';
import './styles/ArticleForm.editor.scss';
import classes from './styles/ArticleForm.module.scss';
import { stateFromHTML } from 'draft-js-import-html';

const MB_2_SIZE = 2048000;

const ArticleForm = ({
    title,
    slug,
    description,
    contentEditorState,
    allowComments,
    allowSomethings,
    images,
    moduleTitle,
    moduleTitleActions,
    fieldsNames,
    createImagesList,
    handleEditorStateChange,
    handleFormSubmit,
    handleInputChange,
    handleImageDrop,
    hasError,
    isProcessed,
}) => {
    const imagesList = createImagesList(images);
    const uploadImageCallBack = async (file) => {
        const url = 'https://landzilla.com/static/media/landzilla-logo.065b8597.svg';
        /*
        const fileNameParts = file.name.split('.');
        const storage = getStorage(app);
        const storageRef = ref(
            storage,
            uuidv4() + fileNameParts[fileNameParts.length - 1],
        );
        let imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        };
        const metadata = {
            contentType: file.type,
        };

        const snapshot = await uploadBytes(storageRef, file, metadata);
        const url = await getDownloadURL(storageRef);
        console.log(url, snapshot);
        */
        return new Promise((resolve, reject) => {
            resolve({ data: { link: url } });
        });
    };

    const toolbar = {
        image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: true, mandatory: false },
            defaultSize: {
                height: 'auto',
                width: 'auto',
            },
        },
    };
    return (
        <React.Fragment>
            <TitleWithActions actions={moduleTitleActions}>{moduleTitle}</TitleWithActions>
            <Form onSubmit={handleFormSubmit} className={classes['article-form']}>
                <FormGroup row>
                    <Label for='title' sm={1}>
                        {fieldsNames.title}
                    </Label>
                    <Col sm={11}>
                        <Input
                            type='text'
                            name='title'
                            id='title'
                            value={title}
                            onChange={handleInputChange}
                            invalid={hasError(fieldsNames.title)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='slug' sm={1}>
                        {fieldsNames.slug}
                    </Label>
                    <Col sm={11}>
                        <Input
                            type='text'
                            name='slug'
                            id='slug'
                            value={slug}
                            onChange={handleInputChange}
                            invalid={hasError(fieldsNames.slug)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='description' sm={1}>
                        {fieldsNames.description}
                    </Label>
                    <Col sm={11}>
                        <Input
                            type={'textarea'}
                            name='description'
                            id='description'
                            value={description}
                            onChange={handleInputChange}
                            invalid={hasError(fieldsNames.description)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='content' sm={1}>
                        {fieldsNames.content}
                    </Label>
                    <Col sm={11}>
                        <Editor
                            handlePastedText={(text, html) => {
                                const blockMap = stateFromHTML(html).blockMap;
                                const newState = Modifier.replaceWithFragment(
                                    contentEditorState.getCurrentContent(),
                                    contentEditorState.getSelection(),
                                    blockMap,
                                );
                                handleEditorStateChange(
                                    EditorState.push(contentEditorState, newState, 'insert-fragment'),
                                );
                                return true;
                            }}
                            toolbar={toolbar}
                            editorState={contentEditorState}
                            editorClassName='react-draft-wysiwyg-editor'
                            onEditorStateChange={handleEditorStateChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row className={classes.dropzone}>
                    <Label for='content' sm={1}>
                        {fieldsNames.images}
                    </Label>
                    <Col sm={11}>
                        <Dropzone
                            onDrop={handleImageDrop}
                            accept={['image/jpg', 'image/gif', 'image/jpeg', 'image/png']}
                            maxSize={MB_2_SIZE}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className={classes['drop-area']}>
                                            Drag 'n' drop some images here, or click to select images
                                            <div className={classes.requirements + ' text-muted small'}>
                                                <div className={classes.title}>Requirements:</div>
                                                <ul className={classes.list}>
                                                    <li>*.jpeg, *.jpg, *.gif or *.png images</li>
                                                    <li>size: max. 2 MB</li>
                                                    <li>width: min. 1280 px</li>
                                                    <li>height: min. 720 px</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {imagesList === null ? null : <aside>{imagesList}</aside>}
                                </section>
                            )}
                        </Dropzone>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={6}>
                        <CustomInput
                            type='switch'
                            id='allow-comments'
                            name='allowComments'
                            label={fieldsNames.allowComments}
                            checked={allowComments}
                            onChange={handleInputChange}
                            className={classes.switch}
                        />
                    </Col>
                    <Col sm={6}>
                        <CustomInput
                            type='switch'
                            id='allow-somethings'
                            name='allowSomethings'
                            label={fieldsNames.allowSomethings}
                            checked={allowSomethings}
                            onChange={handleInputChange}
                            className={classes.switch}
                        />
                    </Col>
                </FormGroup>
                <Row>
                    <Col sm={12} className='text-right'>
                        <SpinnerButton color='primary' size='sm' isLoading={isProcessed}>
                            Save
                        </SpinnerButton>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

ArticleForm.propTypes = {
    title: PropTypes.string,
    contentEditorState: PropTypes.objectOf(ContentState),
    allowComments: PropTypes.bool,
    allowSomethings: PropTypes.bool,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            thumbnailUrl: PropTypes.string.isRequired,
            fullSizeUrl: PropTypes.string.isRequired,
        }),
    ),
    moduleTitle: PropTypes.string,
    moduleTitleActions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
    fieldsNames: PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        allowComments: PropTypes.string.isRequired,
        allowSomethings: PropTypes.string.isRequired,
        images: PropTypes.string.isRequired,
    }).isRequired,
    createImagesList: PropTypes.func,
    handleEditorStateChange: PropTypes.func,
    handleFormSubmit: PropTypes.func,
    handleInputChange: PropTypes.func,
    handleImageDrop: PropTypes.func,
    hasError: PropTypes.func,
    isProcessed: PropTypes.bool,
};

ArticleForm.defaultProps = {
    title: '',
    contentEditorState: AcreEditorState.createNew(),
    allowComments: false,
    allowSomethings: false,
    images: [],
    moduleTitle: '',
    moduleTitleActions: [],
    createImagesList: () => false,
    handleEditorStateChange: () => false,
    handleFormSubmit: () => false,
    handleInputChange: () => false,
    handleImageDrop: () => false,
    hasError: () => false,
    isProcessed: false,
};

export default ArticleForm;
