import ResponseError from 'components/Page/ResponseError/index';
import { STATUS_500 } from 'domain/constants/responseStatus';
import React from 'react';

const ResponseError500 = () => {
    return (
        <ResponseError responseStatus={STATUS_500.toString()} errorText='You have encountered an unexpected error' />
    );
};

export default ResponseError500;
