import React from 'react';
import Store from 'utils/store';

const store = new Store();
let id = 0;

/**
 * item structure {
 *     message: string,
 *     title: string // only if message is array or object - then this is string before ul element
 *     icon: string, // fontawesome full icon string(classes, ex.: 'fas fa-smth'),
 *     styles: object, // of custom alert styles
 *     color: string, // one of Bootstrap 4 alert's color, ex.: primary, success etc.
 *     duration: int, // duration - if <= 0 then notification never hide and will be visible until user clicks the close button
 * }
 * @param item
 * @param onClose - callback fired when the notification is closed(by click or by natural time)
 * @param onClick - callback fired when smb click the notification
 * @param onDismiss - callback fired when smb click a close icon on the notification
 */
function notifi(item, onClose, onClick, onDismiss) {
    id += 1;

    if (Array.isArray(item.message) && item.message.length > 0) {
        item.message = (
            <React.Fragment>
                {item.title}
                <ul>
                    {item.message.map((messageItem, i) => (
                        <li key={i}>{typeof item.message[0] === 'object' ? messageItem.message : messageItem}</li>
                    ))}
                </ul>
            </React.Fragment>
        );
    }

    store.add(Object.assign({ id, onClose, onClick, onDismiss }, item));
}

/**
 * Shot negative - fail notification
 *
 * @param item
 * @param onClose
 * @param onClick
 * @param onDismiss
 */
function notifiFail(item, onClose, onClick, onDismiss) {
    const preparedItem = { ...item, color: 'danger', icon: 'fas fa-exclamation-circle' };
    notifi(preparedItem, onClose, onClick, onDismiss);
}

/**
 * Shot positive - success notification
 *
 * @param item
 * @param onClose
 * @param onClick
 * @param onDismiss
 */
function notifiSuccess(item, onClose, onClick, onDismiss) {
    const preparedItem = { ...item, color: 'success', icon: 'fas fa-check' };
    notifi(preparedItem, onClose, onClick, onDismiss);
}

/**
 * Shot info - neutral notification
 *
 * @param item
 * @param onClose
 * @param onClick
 * @param onDismiss
 */
function notifiInfo(item, onClose, onClick, onDismiss) {
    const preparedItem = { ...item, color: 'info', icon: 'fas fa-info' };
    notifi(preparedItem, onClose, onClick, onDismiss);
}

export { notifi, notifiFail, notifiInfo, notifiSuccess, store };
