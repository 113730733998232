import { STATUS_400, STATUS_404 } from 'domain/constants/responseStatus';
import { matchPath } from 'react-router';
import routes from 'domain/routes';

/**
 * Route helper - gets route and replaces parameters with params from object
 *
 * @param route
 * @param params
 * @returns {*}
 */
export const route = (route, params) => {
    let formattedRoute = route;

    Object.keys(params).map((key) => {
        const reg = new RegExp(':' + key, 'gi');
        formattedRoute = formattedRoute.replace(reg, params[key]);

        return key;
    });

    return formattedRoute;
};

/**
 * Check that current url is in given routes
 * @param routes - object of string objects or string
 */
export const isCurrentUrlMatchedBy = (routes) => {
    if (typeof routes === 'string') {
        if (matchPath(window.location.pathname, { path: routes }) !== null) {
            return true;
        }
    } else if (typeof routes === 'object') {
        let itIs = false;

        Object.keys(routes).forEach((routeKey) => {
            if (matchPath(window.location.pathname, { path: routes[routeKey] }) !== null) {
                itIs = true;
            }
        });

        return itIs;
    }

    return false;
};

export const getRouteParameters = (route) => {
    if (typeof route !== 'string' || route === '' || route === null || route === undefined) {
        return null;
    }

    const matched = matchPath(window.location.pathname, { path: route });

    if (matched === null) {
        const message = "Oops, no parameters found for '" + route + "' route in current url. Does the route exist?";
        throw new Error(message);
    }

    return matched.params;
};

export const getRouteByResponseStatus = (responseStatus) => {
    switch (responseStatus) {
        case STATUS_400:
            return routes.error.badRequest;

        case STATUS_404:
            return routes.error.notFound;

        default:
            return null;
    }
};
