import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import { setActivePage, setSearch } from 'redux/actions/propertiesSearchParamsActions';
import { handleInput } from 'utils/functions/inputHelpers';
import { route } from 'utils/functions/routesHelper';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import routes from '../../../../../domain/routes';
import { PropTypes, QueryString } from 'utils/wrappers';
import Searching from './Searching';
import { applyFilters } from 'redux/actions/propertiesSearchParamsActions';
import { uppercaseFirstLetter } from 'utils/functions/string';
import filtersConst from '../../../../../domain/constants/filtersConst';

class SearchingContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            search: '',
        };
    }

    componentDidMount() {
        const params = QueryString.parse(history.location.search);

        if (params.search) {
            this.setSearchLocalAndRedux(params.search);
        }

        history.listen((location) => {
            if (!location.search) {
                this.setSearchLocalAndRedux('');
            }
        });
    }

    applyFiltersToUrl = (filters, withActual = true) => {
        const preparedFilters = {};

        Object.keys(filters).map((key) => {
            const capitalizedName = uppercaseFirstLetter(key);
            preparedFilters[filtersConst.FILTER_PREFIX + capitalizedName] = filters[key];

            return true;
        });

        history.push(
            routeWithQuery(
                preparedFilters,
                withActual,
                route(routes.properties.main, { saleType: this.props.filters.saleType }),
            ),
        );
    };

    onApplyFilters = () => {
        this.props.setSearch(this.state.search);

        const filters = { ...this.props.filters };
        delete filters.saleType;

        this.props.applyFilters();
        this.applyFiltersToUrl(filters);

        this.props.searchProperties();
    };

    // onSearchApply = (e) => {
    //     e.preventDefault();
    //
    //     this.props.setSearch(this.state.search);
    //     this.props.setActivePage(1);
    //
    //     //this.props.searchProperties();
    //     this.props.applyFilters();
    //
    //     history.push(
    //         routeWithQuery(
    //             { search: this.state.search },
    //             true,
    //             route(routes.properties.main, { saleType: this.props.propertiesSearchParams.filters.saleType }),
    //         ),
    //     );
    // };

    setSearchLocalAndRedux = (searchValue) => {
        this.setState({
            search: searchValue,
        });

        this.props.setSearch(searchValue);
    };

    render() {
        return (
            <Searching handleInput={this.handleInput} onSearchApply={this.onApplyFilters} search={this.state.search} />
        );
    }
}

SearchingContainer.propTypes = {
    propertiesSearchParams: PropTypes.shape({
        filters: PropTypes.object.isRequired,
        appliedFilters: PropTypes.object.isRequired,
        sorting: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    setSearch: PropTypes.func.isRequired,
    searchProperties: PropTypes.func.isRequired,
    setActivePage: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setSearch: setSearch,
    searchProperties: searchedPropertiesAction,
    setActivePage: setActivePage,
    applyFilters: applyFilters,
};

const mapStateToProps = (state) => ({
    propertiesSearchParams: state.propertiesSearchParams,
    filters: state.propertiesSearchParams.filters,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchingContainer);
