import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { handlePropertyFormInputAction } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';
import GoogleMapMarker from 'components/Page/Property/Part/GoogleMap';

const LocalizationFields = ({
    handleInput,
    onResolveLocalization,
    fullAddressOrGeo,
    zip,
    city,
    county,
    state,
    address,
}) => {
    return (
        <React.Fragment>
            <Row form>
                <Col>
                    <Title size={5} underline className={styles['form-title']}>
                        Localization
                    </Title>
                    <div className={styles['tip']}>Input coords and click button below to fill fields</div>
                    <FormGroup>
                        <Label>{fields.fullAddressOrGeo}</Label>
                        <InputGroup size={bsSize}>
                            <Input
                                type={'text'}
                                name={'fullAddressOrGeo'}
                                placeholder={'latitude,longitude, i.e. 38.889715,-77.009109'}
                                value={fullAddressOrGeo}
                                onChange={handleInput}
                            />
                            <InputGroupAddon addonType={'append'}>
                                <Button type={'button'} color={'primary'} onClick={onResolveLocalization}>
                                    Resolve location
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row form className={'mb-3'}>
                <GoogleMapMarker
                    fullAddressOrGeo={fullAddressOrGeo}
                    handlerChangeMarkerPosition={(latLng) => {
                        handleInput({ target: { name: 'fullAddressOrGeo', value: `${latLng.lat},${latLng.lng}` } });
                    }}
                />
            </Row>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label>{fields.address}</Label>
                        <Input
                            type={'text'}
                            name={'address'}
                            placeholder={fields.address}
                            value={address}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.state}</Label>
                        <Input
                            type={'text'}
                            name={'state'}
                            placeholder={fields.state}
                            value={state}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.county}</Label>
                        <Input
                            type={'text'}
                            name={'county'}
                            placeholder={fields.county}
                            value={county}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>{fields.city}</Label>
                        <Input
                            type={'text'}
                            name={'city'}
                            placeholder={fields.city}
                            value={city}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.zip}</Label>
                        <Input
                            type={'text'}
                            name={'zip'}
                            placeholder={fields.zip}
                            value={zip}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

LocalizationFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onResolveLocalization: PropTypes.func.isRequired,
    fullAddressOrGeo: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
};

const mapStateToProps = (state) => ({
    fullAddressOrGeo: state.propertyForm.fullAddressOrGeo,
    zip: state.propertyForm.zip,
    city: state.propertyForm.city,
    county: state.propertyForm.county,
    state: state.propertyForm.state,
    address: state.propertyForm.address,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationFields);
