import env from 'utils/functions/env';

const TYPE_FACEBOOK = 'facebook';

const STATUS_CONNECTED = 'connected';
const STATUS_NOT_AUTHORIZED = 'not_authorized';
const STATUS_UNKNOWN = 'unknown';

class FacebookLoginAPI {
    constructor() {
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: env('FACEBOOK_APP_ID'),
                cookie: true,
                xfbml: true,
                version: 'v5.0',
            });
        };
    }

    getLoginStatus = (callback, notAuthorizedCallback = null, unknownCallback = null) => {
        const classObject = this;

        window.FB.getLoginStatus(function (res) {
            classObject._getStatusAndUser(res, callback, notAuthorizedCallback, unknownCallback);
        });
    };

    login = (callback, notAuthorizedCallback = null, unknownCallback = null) => {
        const classObject = this;

        window.FB.login(
            function (res) {
                classObject._getStatusAndUser(res, callback, notAuthorizedCallback, unknownCallback);
            },
            { scope: 'public_profile,email' },
        );
    };

    _getStatusAndUser = (response, callback, notAuthorizedCallback = null, unknownCallback = null) => {
        if (response.status === STATUS_CONNECTED) {
            window.FB.api('/me', { fields: ['email', 'first_name', 'last_name'] }, function (user) {
                callback({
                    res: response,
                    user: user,
                });
            });
        } else if (response.status === STATUS_NOT_AUTHORIZED && notAuthorizedCallback) {
            notAuthorizedCallback(response);
        } else if (response.status === STATUS_UNKNOWN && unknownCallback) {
            unknownCallback(response);
        }
    };
}

export { TYPE_FACEBOOK, STATUS_CONNECTED, STATUS_NOT_AUTHORIZED, STATUS_UNKNOWN, FacebookLoginAPI };
