import HtmlHead from 'components/Common/Panel/HtmlHead';
import Sidebar from 'components/Common/Panel/Layout/Part/Sidebar';
import FooterContainer from 'components/Common/Footer/FooterContainer';
import HeaderContainer from 'components/Common/Header';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import './styles/Layout.scss';

const Layout = ({ children, title }) => {
    return (
        <React.Fragment>
            <HtmlHead title={title} />
            <HeaderContainer />
            <Container className={'layout-container'}>
                <Row>
                    <Col md={12} lg={2}>
                        <Sidebar />
                    </Col>
                    <Col md={12} lg={10}>
                        <div className={'content-wrapper'}>{children}</div>
                    </Col>
                </Row>
            </Container>
            <FooterContainer />
        </React.Fragment>
    );
};

export default Layout;
