import React from 'react';
import { Helmet } from 'react-helmet';

const HtmlHead = () => {
    return (
        <Helmet>
            <meta name={'robots'} content={'noindex'} />
        </Helmet>
    );
};

export default HtmlHead;
