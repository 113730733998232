import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setFilter } from 'redux/actions/propertiesSearchParamsActions';
import FilterCheckboxes from './FilterCheckboxes';

class FilterCheckboxesContainer extends Component {
    componentDidMount() {
        // Call checkedHandler when the component is mounted
        if (this.props?.filters[this.props.filterSlug]?.length) {
            this.props.checkedHandler(this.props.filters[this.props.filterSlug].length);
        }
    }
    toggleCheckbox = (e, element) => {
        this.props.setFilter(e.target.checked, this.props.filterSlug, String(element.value));
        this.props.checkedHandler(this.props.filters[this.props.filterSlug].length);
    };

    isElementChecked = (filterSlug, elementValue) => {
        return !!(
            this.props.filters[filterSlug] && this.props.filters[filterSlug].indexOf(String(elementValue)) !== -1
        );
    };

    render() {
        return (
            <FilterCheckboxes
                {...this.props}
                toggleCheckbox={this.toggleCheckbox}
                isElementChecked={this.isElementChecked}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.propertiesSearchParams.filters,
});

const mapDispatchToProps = {
    setFilter: setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCheckboxesContainer);
