import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { submitCheckoutPaymentAction } from 'redux/actions/checkoutActions';
import { hideCheckoutModal } from 'redux/actions/modalsActions';
import { store } from 'redux/store';
import ACREAPI from 'utils/services/ACREAPI';
import routes from '../../domain/routes';

function* submitCheckoutPaymentSaga(action) {
    try {
        yield put(submitCheckoutPaymentAction.request());

        yield call(ACREAPI.checkout().create, action.payload);

        store.dispatch(hideCheckoutModal());

        yield put(submitCheckoutPaymentAction.success());

        yield put(push(routes.listingPurchase));
        notifiSuccess({
            message: 'Listing has been purchased',
        });
    } catch (e) {
        const message = e.response && e.response.data ? e.response.data.message : 'Cart checkout has failed';

        notifiFail({
            message: message,
        });

        yield put(submitCheckoutPaymentAction.failure());
    } finally {
        yield put(submitCheckoutPaymentAction.fulfill());
    }
}

export default function* submitCheckoutPaymentSagaWatcher() {
    yield takeLatest(submitCheckoutPaymentAction.trigger, submitCheckoutPaymentSaga);
}
