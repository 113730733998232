import ACREAPI from 'utils/services/ACREAPI';
import { ADMIN_ENDPOINT_URL_PREFIX } from 'utils/services/ACREAPI/ACREAPI';

export const ENDPOINT_URL_PREFIX = 'user/';

export default class Users {
    static index = (page = 1) => {
        return ACREAPI.authInstance().get(ADMIN_ENDPOINT_URL_PREFIX + 'all-users?page=' + page);
    };

    static search = ({ search, page = 1 }) => {
        return ACREAPI.authInstance().get(ADMIN_ENDPOINT_URL_PREFIX + 'user-search?page=' + page, {
            params: {
                name: search,
            },
        });
    };

    static edit = ({
        id,
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        phoneNumber,
        faxNumber,
        tollFreeNumber,
        address,
        addressSecond,
        city,
        state,
        zip,
    }) => {
        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + id, {
            fname: firstName,
            lname: lastName,
            password,
            password_confirmation: passwordConfirmation,
            email,
            telephones: {
                phone: phoneNumber,
                fax: faxNumber,
                toll_free: tollFreeNumber,
            },
            address: {
                address_first: address,
                address_second: addressSecond,
                state,
                city,
                zip,
            },
        });
    };

    static updateRole = ({ id, roleId }) => {
        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + id + '/role', {
            roleId,
        });
    };

    static delete = (id) => {
        return ACREAPI.authInstance().delete(ENDPOINT_URL_PREFIX + id);
    };

    static export = ({ ids, type }) => {
        return ACREAPI.authInstance().post(
            ADMIN_ENDPOINT_URL_PREFIX + 'user-export',
            { id: ids, type },
            { responseType: 'blob' },
        );
    };

    static show = (id) => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + id);
    };

    static me = () => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + 'profile');
    };

    static updateProfile = ({ id, data }) => {
        return ACREAPI.authInstance().post(ENDPOINT_URL_PREFIX + id, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };
}
