import Carousel from 'components/Common/Carousel';
import React from 'react';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from './styles/CarouselImages.module.scss';

const CarouselImages = ({ slidesImages }) => {
    return isNullOrEmptyArray(slidesImages) ? null : (
        <div className={classes.carousel}>
            <Carousel slides={slidesImages} />
        </div>
    );
};

CarouselImages.propTypes = {
    slidesImages: PropTypes.arrayOf(PropTypes.element),
};

CarouselImages.defaultProps = {
    slidesImages: [],
};

export default CarouselImages;
