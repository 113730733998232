import { PropTypes } from 'utils/wrappers';

const CarouselSlider = ({ slides, currentSlideIndex }) => {
    return slides.map((slide, index) => {
        if (index !== currentSlideIndex) {
            return null;
        }

        return slide;
    });
};

CarouselSlider.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.element).isRequired,
    currentSlideIndex: PropTypes.number.isRequired,
};

export default CarouselSlider;
