import Title from 'components/Common/Title';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import LandDealsBox from './Fragment/LandDealBox';

const LandDeals = ({ boxes }) => {
    if (boxes === undefined || boxes.length === 0) {
        return null;
    }

    return (
        <section className='homepage-section'>
            <Container>
                <Row>
                    <Col>
                        <Title size={2}>Land Deals with Pre-approved Financing</Title>
                    </Col>
                </Row>
                <Row>
                    {boxes.map((box, index) => {
                        return (
                            <LandDealsBox
                                key={index}
                                iconCssClass={box.iconCssClass}
                                title={box.title}
                                description={box.description}
                            />
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};

LandDeals.propTypes = {
    boxes: PropTypes.arrayOf(
        PropTypes.shape({
            iconCssClass: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    ),
};

export default LandDeals;
