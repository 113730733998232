import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from './CtaButton.module.scss';

const CtaButton = ({ text, url }) => {
    return (
        <Row className={classes['see-all-button-container']}>
            <Col className='d-flex justify-content-center'>
                <Button color='primary' tag={Link} to={url} className={'d-lg-none'}>
                    {text}
                </Button>
                <Button color='primary' tag={Link} to={url} size={'lg'} className={'d-none d-lg-block'}>
                    {text}
                </Button>
            </Col>
        </Row>
    );
};

CtaButton.propTypes = {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default CtaButton;
