import PropertyCard, { propertyPropType } from 'components/Common/Property/PropertyCard';
import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';

const FavouriteProperties = ({ favouriteProperties }) => {
    if (isNullOrEmptyArray(favouriteProperties)) {
        return (
            <Alert color={'info'} className='text-center mb-0'>
                Sorry, you haven't any saved properties.
            </Alert>
        );
    }

    return (
        <div>
            {favouriteProperties.map((property) => (
                <PropertyCard property={property} key={property.slug} />
            ))}
        </div>
    );
};

FavouriteProperties.propTypes = {
    favouriteProperties: PropTypes.arrayOf(propertyPropType).isRequired,
};

const mapStateToProps = (state) => ({
    favouriteProperties: state.favouriteProperties.properties,
});

export default connect(mapStateToProps, null)(FavouriteProperties);
