class Location {
    constructor(address, city, county, state, zip) {
        this.address = address;
        this.city = city;
        this.county = county;
        this.state = state;
        this.zip = zip;
    }
    withCity = () => {
        return (
            [this.address, this.city, `${this.state} ${this.zip}`].join(', ') +
            (this.county ? ' (' + this.county + (this.county.match(/County/) ? ')' : ' County)') : '')
        );
    };
    format = () => {
        if (!this.isValid()) {
            return null;
        }
        return (
            [this.address, `${this.state} ${this.zip}`].join(', ') +
            (this.county ? ' (' + this.county + (this.county.match(/County/) ? ')' : ' County)') : '')
        );
    };

    formatWithoutAddress = () => {
        return `${this.state}, ${this.county}`;
    };

    formatWithoutZip = () => {
        return `${this.address}, ${this.state} - ${this.county}`;
    };

    isValid = () => {
        return this.address && this.county && this.state && this.zip;
    };
}

export default Location;
