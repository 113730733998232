import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFullAddressAction } from 'redux/actions/googleMapsActions';
import { PropTypes } from 'utils/wrappers';
import LocalizationFields from './LocalizationFields';

class LocalizationFieldsContainer extends Component {
    onResolveLocalization = () => {
        const [latitude, longitude] = this.props.fullAddressOrGeo.split(',');
        if (latitude && longitude) {
            this.props.getFullAddress({ longitude, latitude });
        } else {
            notifiFail({
                message: 'Input data is invalid',
            });
        }
    };

    render() {
        return <LocalizationFields onResolveLocalization={this.onResolveLocalization} />;
    }
}

LocalizationFieldsContainer.propTypes = {
    getFullAddress: PropTypes.func.isRequired,
    fullAddressOrGeo: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    fullAddressOrGeo: state.propertyForm.fullAddressOrGeo,
});

const mapDispatchToProps = {
    getFullAddress: getFullAddressAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalizationFieldsContainer);
