import { loginAction, loginSocialAction, LOGOUT_ACTION } from 'redux/actions/authActions';

const defaultState = {
    token: null,
    role: null,
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case loginSocialAction.REQUEST:
        case loginAction.REQUEST:
            return { ...state, isLoading: true };
        case loginSocialAction.SUCCESS:
        case loginAction.SUCCESS:
            return { ...state, ...action.payload };
        case loginSocialAction.FULFILL:
        case loginAction.FULFILL:
            return { ...state, isLoading: false };
        case LOGOUT_ACTION:
            return defaultState;
        default:
            return { ...state };
    }
};
