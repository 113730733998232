import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginModalActionType } from 'redux/actions/modalsActions';
import { toggleIsFavouritePropertyAction } from 'redux/actions/propertiesActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { joinNotEmpty } from 'utils/functions/arrays';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import { PropTypes } from 'utils/wrappers';
import classes from './styles/ToggleFavouriteButton.module.scss';

class ToggleFavouriteButton extends Component {
    handleToggleIsFavouriteProperty = (event) => {
        event.preventDefault();
        const isLoggedIn = this.props.user.token && this.props.user.role;

        if (isLoggedIn) {
            return this.props.toggleIsFavouriteProperty(this.props.propertyId);
        }

        const modalParameter = generateModalUriParam(loginModalActionType);
        const loginParameters = { [modalParameter]: 'show' };

        const loginUrl = routeWithQuery(loginParameters);
        history.push(loginUrl);
    };

    render() {
        let title = null;
        let text = this.props.isFavourite ? 'Remove' : 'Save';
        let isLoading = false;

        if (this.props.iconOnly) {
            title = this.props.isFavourite ? 'Remove from favourites' : 'Add to favourites';
            text = null;
        }

        if (this.props.isToggling[this.props.propertyId] !== undefined) {
            isLoading = this.props.isToggling[this.props.propertyId];
        }

        const iconCssClass = joinNotEmpty(
            [
                classes['heart-icon'],
                'fa-heart',
                this.props.isFavourite ? 'fas' : 'far',
                this.props.isFavourite ? classes.green : '',
                this.props.iconOnly ? classes['icon-only'] : '',
            ],
            ' ',
        );

        return (
            <SpinnerButton
                outline
                size={this.props.size}
                className={this.props.className}
                onClick={this.handleToggleIsFavouriteProperty}
                isLoading={isLoading}
                title={title}
            >
                <i className={iconCssClass} />
                {text}
            </SpinnerButton>
        );
    }
}

ToggleFavouriteButton.propTypes = {
    propertyId: PropTypes.number.isRequired,
    isFavourite: PropTypes.bool,
    size: PropTypes.oneOf(['', 'sm', 'xs']),
    iconOnly: PropTypes.bool,
    className: PropTypes.string,
    toggleIsFavouriteProperty: PropTypes.func.isRequired,
    isToggling: PropTypes.object,
    user: PropTypes.shape({
        token: PropTypes.string,
        role: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
    }).isRequired,
};

ToggleFavouriteButton.defaultProps = {
    iconOnly: false,
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        isToggling: state.toggleIsFavouriteProperty.isToggling,
    };
};

const mapDispatchToProps = {
    toggleIsFavouriteProperty: toggleIsFavouritePropertyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleFavouriteButton);
