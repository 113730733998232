/**
 * Manage a user session stored in local storage
 *
 * @param token
 */

import User from 'domain/user/User';
import { Base64 } from 'js-base64';
import jwt_decode from 'jwt-decode';

const sessionName = 'userSession';

export const setUserInSession = (user: User) => {
    localStorage.setItem(sessionName, Base64.encode(JSON.stringify(user)));
};

export const getUserFromSession = (): ?User => {
    return issetSession() ? JSON.parse(Base64.decode(localStorage.getItem(sessionName))) : null;
};

export const removeSession = () => {
    localStorage.removeItem(sessionName);
};

export const issetSession = () => {
    return !!localStorage.getItem(sessionName);
};

export const isSessionTokenExpired = () => {
    const decoded = jwt_decode(getUserFromSession().token);

    return decoded.exp < (Date.now() - 60 * 1000) / 1000;
};

export const issetNotExpiredSession = () => {
    return issetSession() && !isSessionTokenExpired();
};
