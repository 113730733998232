import AcreSpinner from 'components/Common/AcreSpinner';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
// import classes from 'components/Page/Panel/Blog/Form/styles/ArticleForm.module.scss';
import routes from 'domain/routes';
import AcreEditorState from 'domain/wysiwyg/EditorState';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    adminArticleAddImageAction,
    adminArticleCreateAction,
    adminArticleDeleteImageAction,
    adminArticleFormEditorChangeAction,
    adminArticleNewAction,
    adminArticleReadAction,
    adminArticleReorderImagesAction,
    adminArticleUpdateAction,
    adminSetArticleReorderedImagesAction,
} from 'redux/actions/adminArticleCrudActions';
import { formFieldChangeAction } from 'redux/actions/formActions';
// import { IN_LEFT, IN_RIGHT } from 'redux/actions/propertyFormActions';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import generateRandomId from 'utils/functions/generateRandomId';
import { getRouteParameters } from 'utils/functions/routesHelper';
import { hasError } from 'utils/functions/validation';
import { PropTypes } from 'utils/wrappers';
import validator from 'validator';
import ArticleForm from './ArticleForm';
import DeleteImageModal from 'components/Page/Panel/Blog/Form/DeleteImageModal';
import SortableArticleImagesContainer from 'components/Page/Panel/Blog/Form/Part/SortableArticleImagesContainer';

class ArticleFormContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            image: null,
        };

        this.fieldsNames = {
            title: 'Title',
            slug: 'Slug',
            description: 'Description',
            content: 'Content',
            allowComments: 'Allow comments',
            allowSomethings: 'Allow somethings else for this article',
            images: 'Images',
        };

        this.hasError = hasError.bind(this);
        this.editorState = new AcreEditorState(this.props.article.contentEditorState);
    }

    componentDidMount() {
        if (this.isArticleUpdateAction()) {
            const { articleSlug } = getRouteParameters(routes.panel.blog.update);
            this.props.adminArticleRead(articleSlug);

            return;
        }

        this.props.adminArticleNew();
    }

    handleFormSubmit = (event) => {
        event.preventDefault();
        const errors = this.validate();

        this.setState(
            {
                ...this.state,
                errors: errors,
            },
            () => {
                if (this.state.errors.length > 0) {
                    notifiFail({
                        message: this.state.errors,
                        duration: 0,
                        title: 'Verify entered data:',
                    });

                    return;
                }

                const article = this.prepareArticle();

                if (this.isArticleUpdateAction()) {
                    article.articleId = this.props.article.id;
                    this.props.adminArticleUpdate(article);

                    return;
                }

                this.props.adminArticleCreate(article);
            },
        );
    };

    handleImageDrop = (acceptedFiles, rejectedFiles) => {
        let rejectedFilesNames = [];

        acceptedFiles.map((file) => {
            const fileUrl = URL.createObjectURL(file);
            const id = generateRandomId();

            Object.assign(file, {
                id: id,
                thumbnailUrl: fileUrl,
                fullSizeUrl: fileUrl,
                isNew: true,
            });

            this.props.adminArticleAddImage(file);
            return true;
        });

        rejectedFiles.forEach((file) => {
            rejectedFilesNames.push(file.name);
        });

        if (rejectedFilesNames.length > 0) {
            notifiFail({
                title: 'Sorry, these files cannot be accepted:',
                message: rejectedFilesNames,
                duration: 5000,
            });
        }
    };

    createImagesList = (images) =>
        isNullOrEmptyArray(images) ? null : (
            <SortableArticleImagesContainer
                images={images}
                reorderUploadedImage={this.props.adminArticleReorderImages}
                removeUploadedImage={(image) => this.setState({ ...this.state, image: image })}
                onDragEnd={(images) => this.props.adminSetArticleReorderedImages(images)}
            />
        );

    // createListElement = (image, index) => {
    //     let imageUrl = image.thumbnailUrl;
    //
    //     if (imageUrl === null || imageUrl === undefined) {
    //         imageUrl = image.path;
    //     }
    //
    //     return (
    //         <div key={index} className={classes['list-item']}>
    //             <img src={imageUrl} alt='' />
    //             <div className={classes['icons']}>
    //                 <i
    //                     onClick={() => this.props.adminArticleReorderImages(image.id, IN_LEFT)}
    //                     title={'Move left'}
    //                     className={'fas fa-arrow-circle-left ' + classes['arrow-icon']}
    //                 />
    //                 <i
    //                     onClick={() => this.props.adminArticleReorderImages(image.id, IN_RIGHT)}
    //                     title={'Move right'}
    //                     className={'fas fa-arrow-circle-right ' + classes['arrow-icon']}
    //                 />
    //                 <i
    //                     className={'fa fa-times-circle ' + classes['delete-icon']}
    //                     title='Delete image'
    //                     onClick={() => this.setState({ ...this.state, image: image })}
    //                 />
    //             </div>
    //         </div>
    //     );
    // };

    prepareArticle = () => {
        const images = this.props.article.images;
        let newImages = [];

        if (!isNullOrEmptyArray(images)) {
            newImages = images.filter((image) => image.isNew);
        }

        return {
            ...this.props.article,
            content: this.editorState.use(this.props.article.contentEditorState).toJson(),
            images: newImages,
        };
    };

    isArticleUpdateAction = () => {
        const { match } = this.props;
        const currentRoute = match.path;

        return currentRoute === routes.panel.blog.update;
    };

    validate = () => {
        let errors = [];
        let content = this.editorState.use(this.props.article.contentEditorState).toText();
        const minLengthError = ' must be at least 3 character length';

        if (!validator.isLength(this.props.article.title, { min: 3, max: 255 })) {
            errors.push({
                field: this.fieldsNames.title,
                message: this.fieldsNames.title + minLengthError,
            });
        }

        if (!validator.isLength(content, { min: 3 })) {
            errors.push({
                field: this.fieldsNames.content,
                message: this.fieldsNames.content + minLengthError,
            });
        }

        return errors;
    };

    render() {
        return this.props.article.isLoading ? (
            <AcreSpinner wrapped={true} />
        ) : (
            <>
                <ArticleForm
                    moduleTitle={this.props.moduleTitle}
                    moduleTitleActions={this.props.moduleTitleActions}
                    fieldsNames={this.fieldsNames}
                    createImagesList={this.createImagesList}
                    handleEditorStateChange={this.props.adminArticleFormEditorChange}
                    handleFormSubmit={this.handleFormSubmit}
                    handleInputChange={this.props.formFieldChange}
                    handleImageDrop={this.handleImageDrop}
                    hasError={this.hasError}
                    isProcessed={this.props.isProcessed}
                    {...this.props.article}
                />
                <DeleteImageModal
                    isOpen={!!this.state.image}
                    handlerConfirmation={() => {
                        this.props.adminArticleDeleteImage(this.state.image);
                        this.setState({ ...this.state, image: null });
                    }}
                    handlerCancel={() => this.setState({ ...this.state, image: null })}
                />
            </>
        );
    }
}

ArticleFormContainer.propTypes = {
    moduleTitle: PropTypes.string,
    moduleTitleActions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
};

ArticleFormContainer.defaultProps = {
    moduleTitle: '',
    moduleTitleActions: [],
};

const mapStateToProps = (state) => ({
    article: state.adminArticleNewRead,
    isProcessed: state.adminArticleCreate.isSending || state.adminArticleUpdate.isSending,
});

const mapDispatchToProps = {
    formFieldChange: formFieldChangeAction,
    adminArticleFormEditorChange: adminArticleFormEditorChangeAction,
    adminArticleNew: adminArticleNewAction,
    adminArticleCreate: adminArticleCreateAction,
    adminArticleRead: adminArticleReadAction,
    adminArticleUpdate: adminArticleUpdateAction,
    adminArticleAddImage: adminArticleAddImageAction,
    adminArticleDeleteImage: adminArticleDeleteImageAction,
    adminArticleReorderImages: adminArticleReorderImagesAction,
    adminSetArticleReorderedImages: adminSetArticleReorderedImagesAction,
};

const ArticleFormContainerWithRouter = withRouter(ArticleFormContainer);
export default connect(mapStateToProps, mapDispatchToProps)(ArticleFormContainerWithRouter);
