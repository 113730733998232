import AcreSpinner from 'components/Common/AcreSpinner';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';
import { fetchAllStatesAction } from 'redux/actions/statesActions';
import { isEmptyArray } from 'utils/functions/arrays';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import Footer from './Footer';

class FooterContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            states: [],
            linksColumns: [],
        };
    }

    componentDidMount() {
        this.prepareCategoriesLinks();
        this.prepareStatesLinks();
        this.props.fetchAllPropertyCategories();
        this.props.fetchAllStates();
        this.getOtherLinks();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.categories !== this.props.categories && !isEmptyArray(this.props.categories)) {
            this.prepareCategoriesLinks();
        }

        if (prevProps.states !== this.props.states && !isEmptyArray(this.props.states)) {
            this.prepareStatesLinks();
        }
    }

    /**
     * Prepare lands' categories links to format [name, link] by category's name and slug
     */
    prepareCategoriesLinks = () => {
        const links = [];

        this.props.categories.forEach((category) => {
            links.push({
                name: category.name,
                url: route(routes.properties.category, { slug: category.slug }),
            });
        });

        this.setState({
            categories: links,
        });
    };

    /**
     * Prepare lands' states links to format [name, link] by state's name
     */
    prepareStatesLinks = () => {
        const links = [];

        this.props.states.forEach((state) => {
            links.push({
                name: state.title,
                url: route(routes.properties.state, {
                    state: state.slug,
                }),
            });
        });

        this.setState({
            states: links,
        });
    };

    getOtherLinks = () => {
        this.setState({
            linksColumns: [
                {
                    title: 'Support',
                    links: [
                        // { name: 'About Us', url: routes.aboutUs },
                        { name: 'Faqs', url: routes.faq },
                        { name: 'Privacy Policy', url: routes.privacyPolicy },
                        { name: 'Terms of service', url: routes.termsOfService },
                        { name: 'Disclaimer', url: routes.disclaimer },
                        { name: 'Contact Us', url: routes.contactUs },
                    ],
                },
            ],
        });
    };

    render() {
        return this.props.categoriesAreLoading || this.props.statesAreLoading ? (
            <AcreSpinner wrapped={true} />
        ) : (
            <Footer
                linksColumns={this.state.linksColumns}
                states={this.state.states}
                categories={this.state.categories}
            />
        );
    }
}

FooterContainer.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }),
    ).isRequired,
    categoriesAreLoading: PropTypes.bool.isRequired,
    states: PropTypes.arrayOf(PropTypes.object).isRequired,
    statesAreLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    categoriesAreLoading: state.propertyCategories.areLoading,
    categories: state.propertyCategories.data,
    statesAreLoading: state.states.areLoading,
    states: state.states.data,
});

const mapDispatchToProps = {
    fetchAllPropertyCategories: fetchAllPropertyCategoriesAction,
    fetchAllStates: fetchAllStatesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
