import { store } from 'redux/store';
import { isEmptyArray } from 'utils/functions/arrays';
import { isEmptyString } from 'utils/functions/miscellaneous';

const ROLE_ADMIN = 'admin';
const ROLE_SELLER = 'seller';
const ROLE_BUYER = 'buyer';
const ROLE_CONTENT_MANAGER = 'content_manager';

const isAdmin = () => {
    return isRole(ROLE_ADMIN);
};

const isSeller = () => {
    return isRole(ROLE_SELLER);
};

const isBuyer = () => {
    return isRole(ROLE_BUYER);
};

const isContentManager = () => {
    return isRole(ROLE_CONTENT_MANAGER);
};

const isRole = (role) => {
    const { user } = store.getState();

    return user.token && user.role && user.role === role;
};

const isInOneOfRoles = (groups) => {
    const userGroups = groups.filter((role) => {
        return isRole(role);
    });

    return !isEmptyArray(userGroups);
};

const isLogged = () => {
    const { user } = store.getState();

    return !isEmptyString(user.token) && !isEmptyString(user.role);
};

export {
    isLogged,
    isRole,
    isAdmin,
    isBuyer,
    isContentManager,
    isSeller,
    isInOneOfRoles,
    ROLE_CONTENT_MANAGER,
    ROLE_BUYER,
    ROLE_SELLER,
    ROLE_ADMIN,
};
