import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import {
    handlePropertyFormInputAction,
    onClickAddLinkAction,
    removeItemAction,
} from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';
import ImportantLinks from '../ImportantLinks';

const ImportantLinksFields = ({
    handleInput,
    importantLinkUrl,
    importantLinkDesc,
    importantLinks,
    removeItem,
    onClickAddLink,
}) => {
    return (
        <Row form>
            <Col>
                <Title size={5} underline className={styles['form-title']}>
                    Important Links
                </Title>
                <FormGroup>
                    <Label>{fields.urlField}</Label>
                    <Input
                        type={'text'}
                        placeholder={fields.urlField}
                        name={'importantLinkUrl'}
                        value={importantLinkUrl}
                        onChange={handleInput}
                        bsSize={bsSize}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>{fields.urlDesc}</Label>
                    <Input
                        type={'textarea'}
                        name={'importantLinkDesc'}
                        placeholder={fields.urlDesc}
                        value={importantLinkDesc}
                        onChange={handleInput}
                        bsSize={bsSize}
                    />
                </FormGroup>
                <Button type={'button'} color={'primary'} onClick={onClickAddLink}>
                    Add Link
                </Button>
                <ImportantLinks importantLinks={importantLinks} removeItem={removeItem} />
            </Col>
        </Row>
    );
};

ImportantLinksFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    onClickAddLink: PropTypes.func.isRequired,
    importantLinkUrl: PropTypes.string.isRequired,
    importantLinkDesc: PropTypes.string.isRequired,
    importantLinks: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
    removeItem: removeItemAction,
    onClickAddLink: onClickAddLinkAction,
};

const mapStateToProps = (state) => ({
    importantLinkUrl: state.propertyForm.importantLinkUrl,
    importantLinkDesc: state.propertyForm.importantLinkDesc,
    importantLinks: state.propertyForm.importantLinks,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportantLinksFields);
