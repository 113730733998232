import filters from 'data/properties/filters';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import {
    applyFilters,
    clearFilters,
    fetchBackendPropertiesFiltersAction,
    setFilter,
} from 'redux/actions/propertiesSearchParamsActions';
import { fetchAllPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';
import { route } from 'utils/functions/routesHelper';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import { lowercaseFirstLetter, uppercaseFirstLetter } from 'utils/functions/string';
import history from 'utils/history';
import { PropTypes, QueryString } from 'utils/wrappers';
import filtersConst from '../../../../../domain/constants/filtersConst';
import SearchToolbar from 'components/Page/Properties/Part/SearchToolbar/SearchToolbar';

class SearchToolbarContainer extends Component {
    constructor(props) {
        super(props);

        this.listen = null;

        this.state = {
            availableFilters: [],
            areFiltersVisible: false,
        };
    }

    componentDidMount() {
        this.props.fetchBackendPropertiesFilters();
        this.props.fetchAllPropertyCategories();

        this.setState({
            availableFilters: filters,
        });

        this.setFiltersFromUrl();
        this.props.applyFilters();

        this.unlisten = history.listen((location) => {
            const match = matchPath(location.pathname, {
                path: routes.properties.main,
            });

            if (match) {
                this.props.clearFilters();
                this.setFiltersFromUrl();
                this.props.applyFilters();
                this.props.searchProperties();
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
        this.props.clearFilters();
    }

    toggleFiltersVisibility = () => {
        this.setState({
            areFiltersVisible: !this.state.areFiltersVisible,
        });
    };

    setFiltersFromUrl = () => {
        const filtersFromUrl = QueryString.parse(history.location.search);
        Object.keys(filtersFromUrl).map((filterName) => {
            if (filterName.startsWith(filtersConst.FILTER_PREFIX)) {
                const preparedFilterName = lowercaseFirstLetter(filterName.slice(filtersConst.FILTER_PREFIX.length));

                this.props.setFilter(true, preparedFilterName, filtersFromUrl[filterName]);
            }

            return true;
        });
    };

    onClearFilters = () => {
        this.props.clearFilters();
        this.props.searchProperties();

        const paramsFromUrl = QueryString.parse(history.location.search);
        Object.keys(paramsFromUrl).map((filterName) => {
            if (filterName.startsWith(filtersConst.FILTER_PREFIX)) {
                delete paramsFromUrl[filterName];
            }

            return true;
        });

        history.push(
            routeWithQuery(
                paramsFromUrl,
                false,
                route(routes.properties.main, { saleType: this.props.filters.saleType }),
            ),
        );
    };

    applyFiltersToUrl = (filters, withActual = true) => {
        const preparedFilters = {};

        Object.keys(filters).map((key) => {
            const capitalizedName = uppercaseFirstLetter(key);
            preparedFilters[filtersConst.FILTER_PREFIX + capitalizedName] = filters[key];

            return true;
        });

        history.push(
            routeWithQuery(
                preparedFilters,
                withActual,
                route(routes.properties.main, { saleType: this.props.filters.saleType }),
            ),
        );
    };

    onApplyFilters = () => {
        const filters = { ...this.props.filters };
        delete filters.saleType;
        this.props.applyFilters();
        this.applyFiltersToUrl(filters);
        this.props.searchProperties();
    };

    render() {
        return this.state.availableFilters ? (
            <SearchToolbar
                availableFilters={this.state.availableFilters}
                areFiltersVisible={this.state.areFiltersVisible}
                onClearFilters={this.onClearFilters}
                onApplyFilters={this.onApplyFilters}
                toggleFiltersVisibility={this.toggleFiltersVisibility}
            />
        ) : null;
    }
}

SearchToolbarContainer.propTypes = {
    filters: PropTypes.object.isRequired,
    clearFilters: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    searchProperties: PropTypes.func.isRequired,
    fetchBackendPropertiesFilters: PropTypes.func.isRequired,
    fetchAllPropertyCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    filters: state.propertiesSearchParams.filters,
});

const mapDispatchToProps = {
    clearFilters: clearFilters,
    setFilter: setFilter,
    applyFilters: applyFilters,
    searchProperties: searchedPropertiesAction,
    fetchBackendPropertiesFilters: fetchBackendPropertiesFiltersAction,
    fetchAllPropertyCategories: fetchAllPropertyCategoriesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchToolbarContainer);
