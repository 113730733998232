import { createBrowserHistory } from 'history';
import { QueryString } from 'utils/wrappers';

/**
 * Create history object
 */
const history = createBrowserHistory();
export default history;

/**
 * Add param and change page
 *
 * @param addParams
 */
export const addParamAndPush = (addParams) => {
    let currentParams = QueryString.parse(window.location.search);

    if (Object.keys(addParams).length > 1) {
        // array
        Object.keys(addParams).forEach((key) => {
            currentParams[key] = addParams[key];
        });

        history.push(window.location.pathname + '?' + QueryString.stringify(currentParams));
    } else {
        const key = Object.keys(addParams)[0];

        // if not exist or exists but with different value
        if (!currentParams[key] || (currentParams[key] && currentParams[key] !== addParams[key])) {
            currentParams[key] = addParams[key];

            history.push(window.location.pathname + '?' + QueryString.stringify(currentParams));
        }
    }
};

/**
 * Remove params and change page
 *
 * @param keys
 */
export const deleteParamAndPush = (keys) => {
    let currentParam = QueryString.parse(window.location.search);

    if (keys.length instanceof Array) {
        const copyOfCurrentParams = [...currentParam];
        // array
        keys.map((key) => {
            if (currentParam[key]) {
                delete currentParam[key];
            }

            return key;
        });

        if (currentParam !== copyOfCurrentParams) {
            history.push(window.location.pathname + '?' + QueryString.stringify(currentParam));
        }
    } else {
        if (currentParam[keys]) {
            delete currentParam[keys];

            history.push(window.location.pathname + '?' + QueryString.stringify(currentParam));
        }
    }
};
