import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { activateAccountAction } from 'redux/actions/authActions';
import ACREAPI from 'utils/services/ACREAPI';

function* activateAccountSaga(action) {
    try {
        yield put(activateAccountAction.request());

        const response = yield call(ACREAPI.auth().confirmAccount, action.payload);

        notifiSuccess({
            message: 'Your account has been activated!',
        });

        yield put(activateAccountAction.success(response.data));
    } catch (e) {
        notifiFail({
            message: 'Account activation failed',
        });

        yield put(activateAccountAction.failure());
    } finally {
        yield put(activateAccountAction.fulfill());
    }
}

export default function* activateAccountSagaWatcher() {
    yield takeLatest(activateAccountAction.TRIGGER, activateAccountSaga);
}
