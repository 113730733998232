import routes from 'domain/routes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { isAdmin, isInOneOfRoles, ROLE_ADMIN, ROLE_CONTENT_MANAGER } from 'utils/functions/authorization';
import { isCurrentUrlMatchedBy } from 'utils/functions/routesHelper';
import '../../styles/Sidebar.scss';

const Sidebar = () => {
    return (
        <Nav vertical className={'panel-side-navbar'}>
            {isAdmin() ? (
                <NavItem>
                    <NavLink
                        tag={Link}
                        to={routes.panel.properties.main}
                        active={isCurrentUrlMatchedBy(routes.panel.properties)}
                    >
                        <i className={'fas fa-th-list'} /> Properties
                    </NavLink>
                </NavItem>
            ) : null}
            {isInOneOfRoles([ROLE_ADMIN, ROLE_CONTENT_MANAGER]) ? (
                <NavItem>
                    <NavLink tag={Link} to={routes.panel.blog.main} active={isCurrentUrlMatchedBy(routes.panel.blog)}>
                        <i className='fas fa-newspaper' /> Blog
                    </NavLink>
                </NavItem>
            ) : null}
            {isAdmin() ? (
                <NavItem>
                    <NavLink
                        tag={Link}
                        to={routes.panel.payments.main}
                        active={isCurrentUrlMatchedBy(routes.panel.payments)}
                    >
                        <i className='fas fa-money-bill-wave' /> Payments
                    </NavLink>
                </NavItem>
            ) : null}
            {isAdmin() ? (
                <NavItem>
                    <NavLink tag={Link} to={routes.panel.users.main} active={isCurrentUrlMatchedBy(routes.panel.users)}>
                        <i className='fas fa-users' /> Users
                    </NavLink>
                </NavItem>
            ) : null}
            <NavItem>
                <NavLink tag={Link} to={routes.panel.account} active={isCurrentUrlMatchedBy(routes.panel.account)}>
                    <i className={'fas fa-user'} /> Account
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={routes.panel.savedProperties}
                    active={isCurrentUrlMatchedBy(routes.panel.savedProperties)}
                >
                    <i className={'fas fa-heart'} /> Saved Properties
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} to={routes.panel.logs}>
                    <i className={'fas fa-list'} /> Application Logs
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink to={routes.logout}>
                    <i className={'fas fa-sign-out-alt'} /> Logout
                </NavLink>
            </NavItem>
        </Nav>
    );
};

const SidebarWithRouter = withRouter(Sidebar);
export default connect(null, {})(SidebarWithRouter);
