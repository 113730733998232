import Image from 'components/Common/Image';
import React from 'react';
import { isNullOrEmptyArray } from 'utils/functions/arrays';

class ImageFactory {
    static createImage = (
        url: String,
        componentId: String = '',
        className: String = '',
        alternateText: String = '',
    ) => {
        return <Image key={componentId} url={url} className={className} alternateText={alternateText} />;
    };

    static createImages = (urls: Array, className: String = '') => {
        if (isNullOrEmptyArray(urls)) {
            return null;
        }

        return urls.map((url, index) => {
            return ImageFactory.createImage(url, index, className);
        });
    };
}

export default ImageFactory;
