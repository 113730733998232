import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { isEmptyObject } from 'utils/functions/objects';

export const DEFAULT_EMPTY_VALUE_REPLACEMENT = '-';

/**
 * Returns replacement of empty value if given value is empty. Otherwise - given value.
 *
 * @param value The value to check
 * @param replacement (optional) Custom replacement of empty value. If is set to null, the replacement is retrieved
 * from configuration (default behaviour).
 * @return {string}
 */
export const getReplacementIfEmpty = (value, replacement = DEFAULT_EMPTY_VALUE_REPLACEMENT) => {
    if (isEmptyString(value) || isNullOrEmptyArray(value) || isEmptyObject(value) || value === 'null') {
        return replacement;
    }

    return value;
};

/**
 * Returns information if given value is an empty string
 *
 * @param value The value to check
 * @returns {boolean}
 */
export const isEmptyString = (value) => {
    return value === null || value === undefined || value === '';
};

/**
 * Returns file name from given path
 *
 * @param path A path that contains file name
 * @return {string}
 */
export const getFileNameFromPath = (path) => {
    const pattern = /[\w.\- +=!@$&()?]+\.\w+$/;
    const result = pattern.exec(path);

    if (isNullOrEmptyArray(result)) {
        return null;
    }

    return result[0];
};
