const priceFilter = {
    title: 'Price',
    slug: 'price',
    values: [
        { label: 'Up to $10k', value: ',10000' },
        { label: '$10k - $25k', value: '10001,25000' },
        { label: '$25k - $50k', value: '25001,50000' },
        { label: '$50k - $100k', value: '50001,100000' },
        { label: 'Over $100k', value: '100001,' },
    ],
};

const sizeFilter = {
    title: 'Size',
    slug: 'size',
    values: [
        { label: 'Up to 5 Acres', value: ',5' },
        { label: '5 - 20 Acres', value: '5,20' },
        { label: '20 - 50 Acres', value: '20,50' },
        { label: '50 - 100 Acres', value: '50,100' },
        { label: 'Over 100 Acres', value: '100,' },
    ],
};

const SALE_TYPE_FILTER = 'saleType';

export { SALE_TYPE_FILTER, priceFilter, sizeFilter };

export default [priceFilter, sizeFilter];
