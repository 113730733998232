import React from 'react';
import MainImage from 'components/Page/Property/Part/Gallery/Fragment/MainImage';

const MediaMainContainer = ({ thumbnail, mainImageUrl, withArrows, onClickPrevious, onClickNext }) => {
    const url = thumbnail && 'video' === thumbnail.type ? thumbnail.videoUrl : mainImageUrl;

    return (
        <MainImage
            mainImageUrl={url}
            withArrows={withArrows}
            onClickPrevious={onClickPrevious}
            onClickNext={onClickNext}
        />
    );
};

export default MediaMainContainer;
