import AcreSpinner from 'components/Common/AcreSpinner';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { getRouteParameters } from 'utils/functions/routesHelper';
import ACREAPI from 'utils/services/ACREAPI';
import ArticleContent from './ArticleContent';
import { Helmet } from 'react-helmet';
import { articleJsonLD } from 'domain/seo/JsonLD/jsonld';

class ArticleContentContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            article: null,
            isLoading: true,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.loadArticle();
        }
    }

    componentDidMount() {
        this.loadArticle();
    }

    loadArticle = () => {
        const { articleSlug } = getRouteParameters(routes.blog.article);

        this.setState(
            {
                isLoading: true,
            },
            () => {
                ACREAPI.articles()
                    .show(articleSlug)
                    .then((response) => {
                        const rawData = response.data.post;
                        // const article = ArticleConverter.toDomain(rawData);

                        this.setState({
                            article: {
                                ...rawData,
                            },
                            isLoading: false,
                        });
                    })
                    .catch((err) => {
                        notifiFail({
                            message: "Error: Couldn't load the article",
                            duration: 0,
                        });
                    })
                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            },
        );
    };

    render() {
        const { isLoading, article } = this.state;

        return isLoading ? (
            <AcreSpinner />
        ) : (
            <React.Fragment>
                <Helmet>
                    <script id='articleJsonLD' type='application/ld+json'>
                        {articleJsonLD(article)}
                    </script>
                </Helmet>
                <ArticleContent article={article} isLoading={isLoading} />
            </React.Fragment>
        );
    }
}

export default ArticleContentContainer;
