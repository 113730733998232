import ArticleConverter from 'domain/api2domain/ArticleConverter';
import AcreEditorState from 'domain/wysiwyg/EditorState';
import {
    ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE,
    ADMIN_ARTICLE_FORM_EDITOR_CHANGE_ACTION_TYPE,
    ADMIN_ARTICLE_NEW_ACTION_TYPE,
    adminArticleCreateAction,
    adminArticleDeleteAction,
    adminArticleDeleteImageAction,
    adminArticleReadAction,
    adminArticlesFetchAction,
    adminArticleUpdateAction,
    convertAPIArticleImageToFileObjectAction,
    REORDER_ADMIN_ARTICLE_IMAGES_ACTION,
    SET_REORDERED_ADMIN_ARTICLE_IMAGES_ACTION,
} from 'redux/actions/adminArticleCrudActions';
import { FORM_FIELD_CHANGE_ACTION_TYPE } from 'redux/actions/formActions';
import { handleFormFieldChange } from 'redux/reducers/handleFormFieldChange';
import { store } from 'redux/store';
import { generateSimpleRequestReducer } from 'redux/utils/requestWithoutData';

const articlesDefaultState = {
    data: [],
    pagination: {},
    areLoading: true,
};

const oneArticleDefaultState = {
    title: '',
    slug: '',
    description: '',
    contentEditorState: AcreEditorState.createNew(),
    allowComments: false,
    allowSomethings: false,
    images: [],
};

const adminArticlesFetchActionReducer = (state = articlesDefaultState, action) => {
    switch (action.type) {
        case adminArticlesFetchAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case adminArticlesFetchAction.SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
            };
        case adminArticlesFetchAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        default:
            return {
                ...state,
            };
    }
};

const adminArticleNewReadActionReducer = (state = oneArticleDefaultState, action) => {
    switch (action.type) {
        case FORM_FIELD_CHANGE_ACTION_TYPE:
            return handleFormFieldChange(state, action);

        case ADMIN_ARTICLE_FORM_EDITOR_CHANGE_ACTION_TYPE:
            return {
                ...state,
                contentEditorState: action.payload.contentEditorState,
            };

        case ADMIN_ARTICLE_NEW_ACTION_TYPE:
            return {
                ...oneArticleDefaultState,
                images: [],
            };

        case adminArticleReadAction.REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case adminArticleReadAction.SUCCESS:
            const article = ArticleConverter.toDomain(action.payload);
            const baseImages = [...article.images];
            article.images = []; // clear images
            // preparing images to editable form
            ArticleConverter.prepareImagesToEdit(baseImages, (image) =>
                store.dispatch(convertAPIArticleImageToFileObjectAction(image)),
            );

            return {
                ...state,
                ...article,
                contentEditorState: AcreEditorState.loadFromHtml(article.getContent()),
                isLoading: false,
            };

        case adminArticleReadAction.FULFILL:
            return {
                ...state,
                isLoading: false,
            };

        case ADMIN_ARTICLE_ADD_IMAGE_ACTION_TYPE:
            const image = action.payload.image;
            const images = state.images || [];

            images.push(image);

            return {
                ...state,
                images: images,
            };

        case adminArticleDeleteImageAction.REQUEST:
            return {
                ...state,
            };

        case adminArticleDeleteImageAction.SUCCESS:
            const imageId = action.payload;

            const allImages = state.images || [];
            const restImages = allImages.filter((image) => image.id !== imageId);

            return {
                ...state,
                images: restImages,
            };
        case REORDER_ADMIN_ARTICLE_IMAGES_ACTION:
        case SET_REORDERED_ADMIN_ARTICLE_IMAGES_ACTION:
            return {
                ...state,
                images: action.payload,
            };
        case adminArticleDeleteImageAction.FULFILL:
            return {
                ...state,
            };

        default:
            return {
                ...state,
            };
    }
};

const adminArticleCreateActionReducer = generateSimpleRequestReducer(adminArticleCreateAction);
const adminArticleUpdateActionReducer = generateSimpleRequestReducer(adminArticleUpdateAction);
const adminArticleDeleteActionReducer = generateSimpleRequestReducer(adminArticleDeleteAction);

export {
    adminArticlesFetchActionReducer,
    adminArticleNewReadActionReducer,
    adminArticleCreateActionReducer,
    adminArticleUpdateActionReducer,
    adminArticleDeleteActionReducer,
};
