import Layout from 'components/Common/Panel/Layout';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { loginModalActionType } from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { isEmptyArray } from 'utils/functions/arrays';
import {
    isInOneOfRoles,
    ROLE_ADMIN,
    ROLE_BUYER,
    ROLE_CONTENT_MANAGER,
    ROLE_SELLER,
} from 'utils/functions/authorization';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import routes from '../../../domain/routes';
import { PropTypes } from 'utils/wrappers';

const AuthRoute = ({ component: Component, title, user, rolesWithAccess, ...rest }) => {
    const userIsInOneOfRoles =
        rolesWithAccess && !isEmptyArray(rolesWithAccess) ? isInOneOfRoles(rolesWithAccess) : true;

    return (
        <Route
            {...rest}
            render={(props) =>
                user.token && user.role && userIsInOneOfRoles ? (
                    <Layout>
                        <Component {...props} key='AuthRoute' />
                    </Layout>
                ) : (
                    <Redirect
                        to={routeWithQuery(
                            { [generateModalUriParam(loginModalActionType)]: 'show' },
                            false,
                            routes.home,
                        )}
                    />
                )
            }
        />
    );
};

AuthRoute.propTypes = {
    component: PropTypes.any.isRequired,
    user: PropTypes.shape({
        token: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
    }).isRequired,
    rolesWithAccess: PropTypes.arrayOf(PropTypes.oneOf([ROLE_SELLER, ROLE_ADMIN, ROLE_BUYER, ROLE_CONTENT_MANAGER])),
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(AuthRoute);
