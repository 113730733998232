import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import '../../styles/Sorting.scss';

const Sorting = ({ sorting, sortingOptions, onSortingChange }) => {
    return (
        <div className={'sorting-wrapper'}>
            <div className={'label'}>Sort:</div>
            <UncontrolledDropdown>
                <DropdownToggle tag='a' className='nav-link' caret>
                    {sorting.icon} {sorting.label}
                </DropdownToggle>
                <DropdownMenu>
                    {sortingOptions.map((option, index) => (
                        <DropdownItem key={index} onClick={() => onSortingChange(option)}>
                            {option.icon} {option.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

const sortingShape = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.string]),
};

Sorting.propTypes = {
    sortingOptions: PropTypes.arrayOf(PropTypes.shape(sortingShape)).isRequired,
    onSortingChange: PropTypes.func.isRequired,
    sorting: PropTypes.shape(sortingShape).isRequired,
};

export default Sorting;
