import { GOOGLE_MAPS_DEFAULT_ZOOM, GOOGLE_MAPS_ZOOM_MAX, GOOGLE_MAPS_ZOOM_MIN } from './Constants';

class GoogleMap {
    constructor(latitude, longitude, zoom = GOOGLE_MAPS_DEFAULT_ZOOM) {
        this.latitude = latitude;
        this.longitude = longitude;
        this.zoom = zoom;
    }

    static isValidZoom = (zoom) => {
        zoom = Number(zoom);
        return !isNaN(zoom) && zoom >= GOOGLE_MAPS_ZOOM_MIN && zoom <= GOOGLE_MAPS_ZOOM_MAX;
    };

    getUrl = (embedded = false) => {
        if (!GoogleMap.isValidZoom(this.zoom) || !this.latitude || !this.longitude) {
            return null;
        }

        let result = 'https://maps.google.com/maps?hl=en&z=' + this.zoom + '&q=' + this.latitude + ',' + this.longitude;

        if (embedded) {
            result += '&output=embed';
        }

        return result;
    };
}

export default GoogleMap;
