import { featuredArticlesAction } from 'redux/actions/articlesActions';

const defaultState = {
    articles: [],
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case featuredArticlesAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case featuredArticlesAction.SUCCESS:
            return {
                ...state,
                articles: action.payload,
            };
        case featuredArticlesAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        default:
            return {
                ...state,
            };
    }
};
