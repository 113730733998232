import React from 'react';
import { Button, Col, Form, Input, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/Newsletter.module.scss';
//import { ReCaptchaWrapper } from 'components/Common/ReCaptcha';

const Newsletter = ({ handleInput, onSubmitSubscribeForm, email, recaptchaRef }) => {
    return (
        <React.Fragment>
            <h3 className={classes.title}>Subscribe to get our news</h3>
            <div className={classes.subtitle}>
                Get the latest news, exclusive offers, and the first look at our newest properties.
            </div>
            <Row>
                <Col className={classes['form-wrapper']} sm={8} md={6} lg={12}>
                    <Form className={classes['newsletter-form']} onSubmit={onSubmitSubscribeForm}>
                        <div className={classes['fields-container']}>
                            <Input
                                type='text'
                                name='email'
                                value={email}
                                onChange={handleInput}
                                className={classes['email-input']}
                                placeholder='Enter Your Email'
                            />
                            {/* <ReCaptchaWrapper
                                variant={'default'}
                                recaptchaRef={recaptchaRef}
                                handleGoogleRecaptcha={(val) =>
                                    handleInput({ target: { name: 'recaptcha', value: val } })
                                }
                            /> */}
                        </div>
                        <Button color='light' className={classes['subscribe-button']}>
                            Subscribe
                        </Button>
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    );
};

Newsletter.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onSubmitSubscribeForm: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
};

export default Newsletter;
