import { fetchAllPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';

const defaultState = {
    data: [],
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchAllPropertyCategoriesAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case fetchAllPropertyCategoriesAction.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchAllPropertyCategoriesAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        default:
            return { ...state };
    }
};
