class Store {
    // callbacks from subscribing classes
    subscribers = [];
    // in this situations - notifications
    items = [];

    subscribe(callback) {
        this.subscribers.push(callback);
    }

    unsubscribe(callback) {
        this.subscribers = this.subscribers.filter((subscriber) => (subscriber !== callback ? subscriber : undefined));
    }

    fetch() {
        this.subscribers.forEach((subscriber) => subscriber());
    }

    add(item) {
        this.items.push(item);
        this.fetch();
    }

    remove(item) {
        this.items = this.items.filter((storeItem) => (storeItem !== item ? storeItem : undefined));
        this.fetch();
    }

    get data() {
        return this.items;
    }
}

export default Store;
