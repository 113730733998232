import FooterContainer from 'components/Common/Footer/FooterContainer';
import HtmlHead from 'components/Common/HtmlHead';
import Newsletter from 'components/Common/Newsletter';
import PageWrapper from 'components/Common/PageWrapper';
import React from 'react';
import { PropTypes } from 'utils/wrappers';
import ForgotPasswordModalContainer from '../ForgotPasswordModal';
import Header from '../Header';
import LoginModalContainer from '../LoginModal';
import RegistrationModalContainer from '../RegistrationModal';
import CheckoutModalContainer from '../CheckoutModal';

const Layout = ({ children, title, pageWrapperClassName = '', withPageWrapper = true }) => {
    const b = [];
    const wrapped = withPageWrapper ? (
        <PageWrapper className={pageWrapperClassName} breadcrumbs={b}>
            {children}
        </PageWrapper>
    ) : (
        children
    );
    return (
        <React.Fragment>
            <HtmlHead title={title} />
            <Header />
            {wrapped}
            <Newsletter />
            <FooterContainer />
            <LoginModalContainer />
            <RegistrationModalContainer />
            <ForgotPasswordModalContainer />
            <CheckoutModalContainer />
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    title: PropTypes.string,
    pageWrapperClassName: PropTypes.string,
    withPageWrapper: PropTypes.bool,
};

export default Layout;
