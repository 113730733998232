import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import routes from 'domain/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    adminArticleCreateAction,
    adminArticleDeleteAction,
    adminArticleDeleteImageAction,
    adminArticleReadAction,
    adminArticlesFetchAction,
    adminArticleUpdateAction,
} from 'redux/actions/adminArticleCrudActions';
import handleResponseStatus from 'redux/utils/handleResponseStatus';
import redirectTo from 'redux/utils/redirectTo';
import ACREAPI from 'utils/services/ACREAPI';

function* adminArticlesFetchSaga(action) {
    try {
        yield put(adminArticlesFetchAction.request());
        const response = yield call(ACREAPI.articles().adminIndex, action.payload);
        yield put(adminArticlesFetchAction.success(response.data));
    } catch (error) {
        notifiFail({
            message: 'Loading of articles has failed',
        });

        yield put(adminArticlesFetchAction.failure());
    } finally {
        yield put(adminArticlesFetchAction.fulfill());
    }
}

function* adminArticlesFetchSagaWatcher() {
    yield takeLatest(adminArticlesFetchAction.TRIGGER, adminArticlesFetchSaga);
}

function* adminArticleCreateSaga(action) {
    try {
        yield put(adminArticleCreateAction.request());
        yield call(ACREAPI.articles().adminCreate, action.payload);

        notifiSuccess({
            message: 'Article has been saved',
        });

        yield put(adminArticleCreateAction.success());
        yield redirectTo(routes.panel.blog.main);
    } catch (error) {
        const defaultMessage = "Article hasn't been saved. Please verify images and requirements for them.";
        const message = error.response && error.response.data.message ? error.response.data.message : defaultMessage;

        notifiFail({
            message: message,
        });

        yield put(adminArticleCreateAction.failure());
    } finally {
        yield put(adminArticleCreateAction.fulfill());
    }
}

function* adminArticleReadSaga(action) {
    try {
        yield put(adminArticleReadAction.request());
        const response = yield call(ACREAPI.articles().adminRead, action.payload);
        yield put(adminArticleReadAction.success(response.data.post));
    } catch (error) {
        notifiFail({
            message: "Article hasn't been loaded",
        });

        yield put(adminArticleReadAction.failure());
        yield handleResponseStatus(error);
    } finally {
        yield put(adminArticleReadAction.fulfill());
    }
}

function* adminArticleUpdateSaga(action) {
    try {
        yield put(adminArticleUpdateAction.request());
        yield call(ACREAPI.articles().adminUpdate, action.payload);

        notifiSuccess({
            message: 'Article has been saved',
        });

        yield put(adminArticleUpdateAction.success());
        yield redirectTo(routes.panel.blog.main);
    } catch (error) {
        const defaultMessage = "Article hasn't been saved. Please verify images and requirements for them.";
        const message = error.response && error.response.data.message ? error.response.data.message : defaultMessage;

        notifiFail({
            message: message,
        });

        yield put(adminArticleUpdateAction.failure());
    } finally {
        yield put(adminArticleUpdateAction.fulfill());
    }
}

function* adminArticleDeleteSaga(action) {
    try {
        yield put(adminArticleDeleteAction.request());
        yield call(ACREAPI.articles().adminDelete, action.payload);

        notifiSuccess({
            message: 'Article has been deleted',
        });

        yield put(adminArticleDeleteAction.success());
    } catch (error) {
        notifiFail({
            message: "Article hasn't been deleted",
        });

        yield put(adminArticleDeleteAction.failure());
    } finally {
        yield put(adminArticleDeleteAction.fulfill());
    }
}

function* adminArticleDeleteImageSaga(action) {
    try {
        const image = action.payload;
        const imageId = image.id;
        const isNew = image.isNew;

        yield put(adminArticleDeleteImageAction.request());
        yield call(ACREAPI.articles().adminDeleteImage, imageId, isNew);

        notifiSuccess({
            message: 'Image of article has been deleted',
        });

        yield put(adminArticleDeleteImageAction.success(imageId));
    } catch (error) {
        notifiFail({
            message: "Image of article hasn't been deleted",
        });

        yield put(adminArticleDeleteImageAction.failure());
    } finally {
        yield put(adminArticleDeleteImageAction.fulfill());
    }
}

function* adminArticleCreateSagaWatcher() {
    yield takeLatest(adminArticleCreateAction.trigger, adminArticleCreateSaga);
}

function* adminArticleReadSagaWatcher() {
    yield takeLatest(adminArticleReadAction.trigger, adminArticleReadSaga);
}

function* adminArticleUpdateSagaWatcher() {
    yield takeLatest(adminArticleUpdateAction.trigger, adminArticleUpdateSaga);
}

function* adminArticleDeleteSagaWatcher() {
    yield takeLatest(adminArticleDeleteAction.trigger, adminArticleDeleteSaga);
}

function* adminArticleDeleteImageSagaWatcher() {
    yield takeLatest(adminArticleDeleteImageAction.trigger, adminArticleDeleteImageSaga);
}

export {
    adminArticlesFetchSagaWatcher,
    adminArticleCreateSagaWatcher,
    adminArticleReadSagaWatcher,
    adminArticleUpdateSagaWatcher,
    adminArticleDeleteSagaWatcher,
    adminArticleDeleteImageSagaWatcher,
};
