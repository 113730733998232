import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchSellerAction } from 'redux/actions/sellersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* fetchSellerSaga(action) {
    try {
        yield put(fetchSellerAction.request());

        const response = yield call(ACREAPI.sellers().show, action.payload);

        yield put(fetchSellerAction.success(response.data.seller));
    } catch (e) {
        const message =
            e.response && e.response.data ? e.response.data.message : "Getting seller's data  has been failed";

        notifiFail({
            message: message,
            duration: 5000,
        });

        yield put(fetchSellerAction.failure());
    } finally {
        yield put(fetchSellerAction.fulfill());
    }
}

export default function* fetchSellerSagaWatcher() {
    yield takeLatest(fetchSellerAction.trigger, fetchSellerSaga);
}
