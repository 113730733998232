import AcreBootstrapTable from 'components/Common/AcreBootstrapTable';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import styles from './Payments.module.scss';

const Payments = ({
    handleInput,
    payments,
    columns,
    paginationOptions,
    onTableChange,
    areLoadingPayments,
    phrase,
    onSearch,
}) => {
    return (
        <div>
            <div className={styles['search-bar']}>
                <form onSubmit={onSearch}>
                    <InputGroup>
                        <Input
                            name={'phrase'}
                            placeholder={'Type name, email, phone date or listing ID'}
                            value={phrase}
                            onChange={handleInput}
                            type={'text'}
                        />
                        <InputGroupAddon addonType='append'>
                            <Button color='primary'>
                                <i className={'fas fa-search'} />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </form>
            </div>

            <AcreBootstrapTable
                keyField={'id'}
                columns={columns}
                data={payments}
                paginationOptions={paginationOptions}
                remote={{
                    filter: false,
                    pagination: true,
                    sort: false,
                    cellEdit: false,
                }}
                onTableChange={onTableChange}
                loading={areLoadingPayments}
            />
        </div>
    );
};

Payments.propTypes = {
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
            user_id: PropTypes.number,
            buyer_first_name: PropTypes.string,
            buyer_last_name: PropTypes.string,
            listing_id: PropTypes.number,
            transaction_id: PropTypes.string,
            price: PropTypes.number,
            total_price: PropTypes.number,
            created_at: PropTypes.string,
        }),
    ).isRequired,
    areLoadingPayments: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    payments: state.payments.data,
    areLoadingPayments: state.payments.isLoading,
});

export default connect(mapStateToProps, null)(Payments);
