import React, { Component } from 'react';
import { getNextIndex, getPreviousIndex, isEmptyArray, isNullOrEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import Gallery from './Gallery';
import videoPlaceholder from 'common/images/video-placeholder.jpg';
import { Youtube } from 'utils/services/youtube';
import MediaFactory from '../../../../../domain/factory/MediaFactory';

class GalleryContainer extends Component {
    constructor(props) {
        super(props);
        const images = props.images.filter((image) => !!image);
        const videos = props.videos
            .filter((video) => null !== Youtube.getVideoId(video.url))
            .map((video) => ({ ...video, type: 'video' }));

        this.state = {
            images: images,
            videos: videos,
            medias: [].concat(images, videos),
            thumbnails: [],
            activeImageIndex: 0,
        };

        this.areThumbnailsVisible = this.areThumbnailsVisible.bind(this);
        this.displayPreviousImage = this.displayPreviousImage.bind(this);
        this.displayNextImage = this.displayNextImage.bind(this);
        this.displaySelectedImage = this.displaySelectedImage.bind(this);
    }

    componentDidMount() {
        this.setThumbnails();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            ((prevProps.images !== null && prevProps.images.length !== 0) || prevProps.videos.length !== 0) &&
            this.state.thumbnails.length === 0
        ) {
            this.setThumbnails();
        }
    }

    hasImages() {
        return !isNullOrEmptyArray(this.state.images) && this.state.images[0] !== undefined;
    }

    hasMedias() {
        return !isEmptyArray(this.state.medias);
    }

    getSlidesUrls() {
        if (!this.hasMedias()) {
            return [];
        }

        return this.state.medias.map((imageOrVideo) => {
            if ('video' === imageOrVideo.type) {
                return imageOrVideo.url;
            } else {
                return imageOrVideo.fullSizeUrl;
            }
        });
    }

    getMainImageUrl(index) {
        if (!this.hasMedias()) {
            return null;
        }

        const videoOrImage = this.state.medias[index];

        return videoOrImage.fullSizeUrl || videoOrImage.url;
    }

    setThumbnails() {
        if (!this.hasMedias()) {
            return null;
        }

        const thumbnails = this.state.images.map((image) => {
            return {
                url: image.thumbnailUrl,
                ref: React.createRef(),
            };
        });
        const thumbnailsVideo = this.state.videos.map((video) => {
            return {
                url: videoPlaceholder,
                videoUrl: video.url,
                type: 'video',
                ref: React.createRef(),
            };
        });
        thumbnails.push(...thumbnailsVideo);

        this.setState({
            thumbnails: thumbnails,
        });
    }

    areThumbnailsVisible() {
        if (this.hasMedias()) {
            return isNullOrEmptyArray(this.state.medias) || this.state.medias.length !== 1;
        }

        return false;
    }

    displayPreviousImage() {
        const index = this.getMediaIndex(false);
        this.markActiveImage(index);
    }

    displayNextImage() {
        const index = this.getMediaIndex(true);
        this.markActiveImage(index);
    }

    displaySelectedImage(imageIndex) {
        this.markActiveImage(imageIndex);
    }

    markActiveImage(index) {
        this.setState({
            activeImageIndex: index,
        });

        this.scrollToActiveImage(index);
    }

    scrollToActiveImage(index) {
        const thumbnail = this.state.thumbnails[index];
        const element = thumbnail.ref.current;
        const container = element.parentNode;

        const parentLeft = element.offsetParent.offsetLeft;
        const elementLeft = element.offsetLeft;

        container.scrollLeft = elementLeft - parentLeft;
    }

    getMediaIndex(nextImage) {
        if (nextImage) {
            return getNextIndex(this.state.medias, this.state.activeImageIndex);
        }

        return getPreviousIndex(this.state.medias, this.state.activeImageIndex);
    }

    render() {
        const hasImages = this.hasMedias();
        const mainImageUrl = this.getMainImageUrl(this.state.activeImageIndex);

        const slidesUrls = this.getSlidesUrls();
        const slidesImages = MediaFactory.createMedias(slidesUrls);

        return (
            <Gallery
                hasImages={hasImages}
                slidesImages={slidesImages}
                mainImageUrl={mainImageUrl}
                thumbnails={this.state.thumbnails}
                activeThumbnailIndex={this.state.activeImageIndex}
                areThumbnailsVisible={this.areThumbnailsVisible()}
                onClickPrevious={this.displayPreviousImage}
                onClickNext={this.displayNextImage}
                onThumbnailClick={this.displaySelectedImage}
                className={this.props.className}
            />
        );
    }
}

GalleryContainer.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            thumbnailUrl: PropTypes.string.isRequired,
            fullSizeUrl: PropTypes.string.isRequired,
        }),
    ),
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
        }),
    ),
    className: PropTypes.string,
};

GalleryContainer.defaultProps = {
    images: [],
    videos: [],
    className: '',
};

export default GalleryContainer;
