import AcreSpinner from 'components/Common/AcreSpinner';
import ArticleCard from 'components/Common/ArticleCard';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { featuredArticlesAction } from 'redux/actions/articlesActions';
import { PropTypes } from 'utils/wrappers';
import FeaturedNews from './FeaturedNews';
import './styles/FeaturedNews.scss';

const DEFAULT_MAXIMUM = 3;

class FeaturedNewsContainer extends Component {
    componentDidMount() {
        const maximum = this.props.maximum || DEFAULT_MAXIMUM;
        this.props.getFeaturedNews(maximum);
    }

    getFeaturedNewsBoxes(articles) {
        return articles.map((article) => {
            return <ArticleCard key={article.id} article={article} />;
        });
    }

    render() {
        const { articles, areLoading } = this.props;

        if (areLoading) {
            return <AcreSpinner />;
        }

        const articlesBoxes = this.getFeaturedNewsBoxes(articles);
        return <FeaturedNews articles={articlesBoxes} />;
    }
}

FeaturedNewsContainer.propTypes = {
    articles: PropTypes.array.isRequired,
    areLoading: PropTypes.bool.isRequired,
    getFeaturedNews: PropTypes.func.isRequired,
    maximum: PropTypes.number,
};

const mapDispatchToProps = {
    getFeaturedNews: featuredArticlesAction,
};

const mapStateToProps = (state) => {
    return {
        articles: state.featuredArticles.articles,
        areLoading: state.featuredArticles.areLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedNewsContainer);
