import { fetchProfileAction } from 'redux/actions/userActions';

const defaultState = {
    data: {},
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchProfileAction.REQUEST:
            return { ...state, isLoading: true };
        case fetchProfileAction.SUCCESS:
            return { ...state, data: action.payload };
        case fetchProfileAction.FULFILL:
            return { ...state, isLoading: false };
        default:
            return { ...state };
    }
};
