import LocationClass from 'domain/property/Location';
import { PropTypes } from 'utils/wrappers';

const Location = ({ address, city, county, state, zip, withoutZip }) => {
    const location = new LocationClass(address, city, county, state, zip);
    if (address === '') {
        return location.formatWithoutAddress();
    }
    if (withoutZip) {
        return location.formatWithoutZip();
    }
    if (city) {
        return location.withCity();
    }
    return location.format();
};

Location.propTypes = {
    address: PropTypes.string.isRequired,
    city: PropTypes.string,
    county: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    withoutZip: PropTypes.bool,
};

Location.defaultProps = {
    withoutZip: false,
};

export default Location;
