import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardImg, CardSubtitle, CardTitle } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const AcreCard = ({ className, beforeImage, imagePath, imageUrl, beforeTitle, title, subtitle, children, footer }) => {
    let image = <CardImg top src={imagePath} alt={typeof title === 'object' ? null : title} />;

    if (imageUrl) {
        image = <Link to={imageUrl}>{image}</Link>;
    }

    return (
        <Card className={className}>
            {beforeImage || null}
            {image}
            <CardBody>
                {beforeTitle || null}
                <CardTitle>{title}</CardTitle>
                {subtitle ? <CardSubtitle>{subtitle}</CardSubtitle> : null}
                {children || null}
            </CardBody>
            {footer ? <CardFooter>{footer}</CardFooter> : null}
        </Card>
    );
};

AcreCard.propTypes = {
    className: PropTypes.string,
    beforeImage: PropTypes.element,
    imagePath: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    beforeTitle: PropTypes.element,
    title: PropTypes.element.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.element,
    footer: PropTypes.element,
};

export default AcreCard;
