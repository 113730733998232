import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { sendRequestResetPasswordAction } from 'redux/actions/authActions';
import ACREAPI from 'utils/services/ACREAPI';

function* requestPasswordResetSaga(action) {
    try {
        yield put(sendRequestResetPasswordAction.request());

        const response = yield call(ACREAPI.auth().forgottenPasswordSendEmail, action.payload);

        notifiSuccess({
            message: response.data.message,
        });

        yield put(sendRequestResetPasswordAction.success());
    } catch (e) {
        const message = e.response && e.response.data.message ? e.response.data.message : 'Sending error, sorry';

        notifiFail({
            message: message,
        });

        yield put(sendRequestResetPasswordAction.failure());
    } finally {
        yield put(sendRequestResetPasswordAction.fulfill());
    }
}

export default function* requestPasswordResetSagaWatcher() {
    yield takeLatest(sendRequestResetPasswordAction.trigger, requestPasswordResetSaga);
}
