import React from 'react';
import { Col } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/Wrapper.module.scss';

const Wrapper = ({ title, children }) => {
    if (children === null) {
        return null;
    }

    return (
        <Col className={classes.column} md={6} lg={3}>
            <div className={classes.title}>{title}</div>
            {children}
        </Col>
    );
};

Wrapper.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
};

export default Wrapper;
