import React from 'react';
import { Col } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import style from '../styles/LandDealBox.module.scss';

const LandDealBox = ({ iconCssClass, title, description }) => {
    return (
        <Col className={style['land-deal-box-wrapper']} lg={6}>
            <div className={style['land-deal-box']}>
                <div className={style.icon}>
                    <i className={iconCssClass} />
                </div>
                <div className={style.title}>{title}</div>
                <div className={style.description}>{description}</div>
            </div>
        </Col>
    );
};

LandDealBox.propTypes = {
    iconCssClass: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default LandDealBox;
