import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from 'components/Common/Panel/PropertyForm/styles/PropertyForm.module.scss';
import { IN_LEFT, IN_RIGHT } from 'utils/constans/orderingDirections';

const SortableImage = ({ id, image, index, onClickChangeToPrimary, reorderUploadedImage, removeUploadedImage }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className={'col-md-4'} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <div className={styles['image']}>
                <img src={image.blob} alt={''} />
                <div className={styles['image-data']}>
                    <div className={styles['primary']}>
                        {index === 0 ? (
                            'Primary image'
                        ) : (
                            <span onClick={(e) => onClickChangeToPrimary({ e, id: image.id })}>Change to primary</span>
                        )}
                    </div>
                    <div className={styles['icons']}>
                        <i
                            onClick={() => reorderUploadedImage(image.id, IN_LEFT)}
                            className={'fas fa-arrow-circle-left'}
                        />
                        <i
                            onClick={() => reorderUploadedImage(image.id, IN_RIGHT)}
                            className={'fas fa-arrow-circle-right'}
                        />
                        <i onClick={() => removeUploadedImage(image.id)} className={'fas fa-trash-alt'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SortableImage;
