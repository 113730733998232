import React from 'react';
import { Alert } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import '../styles/Notification.scss';

const Notification = ({ data, isVisible, handleOnClick, handleOnDismiss }) => {
    return (
        <Alert
            className={isVisible ? null : 'hide'}
            onClick={() => handleOnClick(data)}
            toggle={() => handleOnDismiss(data)}
            fade={false}
            style={data.styles}
            color={data.color}
        >
            {data.icon ? <i className={data.icon} /> : ''}
            {data.message}
        </Alert>
    );
};

Notification.propTypes = {
    data: PropTypes.shape({
        icon: PropTypes.string,
        styles: PropTypes.object,
        color: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    }).isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleOnClick: PropTypes.func.isRequired,
    handleOnDismiss: PropTypes.func.isRequired,
};

export default Notification;
