import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { changePropertyStatusAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* changePropertyStatusSaga(action) {
    try {
        yield put(changePropertyStatusAction.request());

        yield call(ACREAPI.properties().changeStatus, action.payload);

        notifiSuccess({
            message: "Property's status has been changed",
        });

        yield put(changePropertyStatusAction.success());
    } catch (e) {
        const message =
            e.response && e.response.data ? e.response.data.result : 'During changing status occurred error';

        notifiFail({
            message,
        });

        yield put(changePropertyStatusAction.failure());
    } finally {
        yield put(changePropertyStatusAction.fulfill());
    }
}

export default function* changePropertyStatusSagaWatcher() {
    yield takeLatest(changePropertyStatusAction.trigger, changePropertyStatusSaga);
}
