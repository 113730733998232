const FORM_FIELD_CHANGE_ACTION_TYPE = 'FORM_FIELD_CHANGE_ACTION_TYPE';

const formFieldChangeAction = (event) => {
    const formField = event.target;

    return {
        type: FORM_FIELD_CHANGE_ACTION_TYPE,
        payload: {
            type: formField.type,
            name: formField.name,
            value: formField.value,
        },
    };
};

export { FORM_FIELD_CHANGE_ACTION_TYPE, formFieldChangeAction };
