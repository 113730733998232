import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { activateAccountAction } from 'redux/actions/authActions';
import routes from '../../../domain/routes';
import { QueryString } from 'utils/wrappers';

class AccountConfirmationContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gotParam: false,
        };
    }

    componentDidMount() {
        const params = QueryString.parse(window.location.search);

        if (!params.token) {
            notifiFail({
                message: 'The activation token is incorrect!',
            });

            return;
        }

        this.props.activateAccount(params.token);

        this.setState({
            gotParam: true,
        });
    }

    render() {
        return this.state.gotParam ? <Redirect to={routes.home} /> : null;
    }
}

const mapDispatchToProps = {
    activateAccount: activateAccountAction,
};

export default connect(null, mapDispatchToProps)(AccountConfirmationContainer);
