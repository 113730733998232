import Title from 'components/Common/Title';
import React from 'react';
import { Modal as StrapModal, ModalBody, ModalHeader } from 'reactstrap';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import './styles/Modal.scss';

const Modal = ({ isOpen, toggle, title, body, subtitle, additionalModalClass }) => {
    const cssClass = joinNotEmpty(['modal-wrapper', additionalModalClass], ' ');

    return (
        <StrapModal isOpen={isOpen} className={cssClass}>
            <ModalHeader charCode='' toggle={toggle} tag='div'>
                <Title size={1}>{title}</Title>
                {subtitle ? <div className={'subtitle'}>{subtitle}</div> : null}
                <div className={'my-close'} onClick={toggle}>
                    <div className={'x'} />
                </div>
            </ModalHeader>
            <ModalBody>{body}</ModalBody>
        </StrapModal>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    additionalModalClass: PropTypes.string,
};

export default Modal;
