import { createRoutine } from 'redux-saga-routines';

const FETCH_FEATURED_PROPERTIES_PREFIX = 'FETCH_FEATURED_PROPERTIES';
const FETCH_ONE_PROPERTY_PREFIX = 'FETCH_ONE_PROPERTY_PREFIX';
const TOGGLE_IS_FAVOURITE_PROPERTY_PREFIX = 'TOGGLE_IS_FAVOURITE_PROPERTY_PREFIX';
const TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION = 'TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION';
const IS_FAVOURITE_PROPERTY_PREFIX = 'IS_FAVOURITE_PROPERTY_PREFIX';
const FETCH_SEARCHED_PROPERTIES_PREFIX = 'FETCH_SEARCHED_PROPERTIES_PREFIX';
const SEND_ONE_PROPERTY_CONTACT_MESSAGE = 'SEND_ONE_PROPERTY_CONTACT_MESSAGE';

const featuredPropertiesAction = createRoutine(FETCH_FEATURED_PROPERTIES_PREFIX);
const onePropertyAction = createRoutine(FETCH_ONE_PROPERTY_PREFIX);
const toggleIsFavouritePropertyAction = createRoutine(TOGGLE_IS_FAVOURITE_PROPERTY_PREFIX);
const isFavouritePropertyAction = createRoutine(IS_FAVOURITE_PROPERTY_PREFIX);
const searchedPropertiesAction = createRoutine(FETCH_SEARCHED_PROPERTIES_PREFIX);
const sendOnePropertyContactMessageAction = createRoutine(SEND_ONE_PROPERTY_CONTACT_MESSAGE);

const FETCH_ADMIN_PROPERTIES_ACTION_PREFIX = 'FETCH_ADMIN_PROPERTIES_ACTION';
const fetchAdminPropertiesAction = createRoutine(FETCH_ADMIN_PROPERTIES_ACTION_PREFIX);

const EXPORT_ADMIN_PROPERTIES_ACTION_PREFIX = 'EXPORT_ADMIN_PROPERTIES_ACTION';
const exportAdminPropertiesAction = createRoutine(EXPORT_ADMIN_PROPERTIES_ACTION_PREFIX);

const GET_RELATED_PROPERTIES_ELEMENTS_ACTION_PREFIX = 'GET_RELATED_PROPERTIES_ELEMENTS_ACTION';
const getRelatedPropertiesElementsAction = createRoutine(GET_RELATED_PROPERTIES_ELEMENTS_ACTION_PREFIX);

const DELETE_PROPERTY_ACTION_PREFIX = 'DELETE_PROPERTY_ACTION';
const deletePropertyAction = createRoutine(DELETE_PROPERTY_ACTION_PREFIX);

const STORE_PROPERTY_ACTION_PREFIX = 'STORE_PROPERTY_ACTION';
const storePropertyAction = createRoutine(STORE_PROPERTY_ACTION_PREFIX);

const UPDATE_PROPERTY_ACTION_PREFIX = 'UPDATE_PROPERTY_ACTION';
const updatePropertyAction = createRoutine(UPDATE_PROPERTY_ACTION_PREFIX);

const CHANGE_PROPERTY_STATUS_ACTION_PREFIX = 'CHANGE_PROPERTY_STATUS_ACTION';
const changePropertyStatusAction = createRoutine(CHANGE_PROPERTY_STATUS_ACTION_PREFIX);

const TOGGLE_PROPERTY_IS_FEATURED_ACTION_PREFIX = 'TOGGLE_PROPERTY_IS_FEATURED_ACTION_PREFIX';
const togglePropertyIsFeaturedAction = createRoutine(TOGGLE_PROPERTY_IS_FEATURED_ACTION_PREFIX);

const FETCH_PROPERTY_ADMIN_ACTION_PREFIX = 'FETCH_PROPERTY_ADMIN_ACTION';
const fetchPropertyAdminAction = createRoutine(FETCH_PROPERTY_ADMIN_ACTION_PREFIX);

export {
    TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION,
    featuredPropertiesAction,
    onePropertyAction,
    toggleIsFavouritePropertyAction,
    isFavouritePropertyAction,
    searchedPropertiesAction,
    sendOnePropertyContactMessageAction,
    togglePropertyIsFeaturedAction,
    fetchPropertyAdminAction,
    changePropertyStatusAction,
    updatePropertyAction,
    storePropertyAction,
    deletePropertyAction,
    getRelatedPropertiesElementsAction,
    exportAdminPropertiesAction,
    fetchAdminPropertiesAction,
};
