import React, { Component } from 'react';
import { connect } from 'react-redux';
import { articlesAction } from 'redux/actions/articlesActions';
import history from 'utils/history';
import { PropTypes, QueryString } from 'utils/wrappers';
import Blog from './Blog';

class BlogContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
        };
    }

    componentDidMount() {
        const { getArticles } = this.props;

        this.changePageByUrl(window.location.search, getArticles);

        history.listen((historyLocation) => {
            this.changePageByUrl(historyLocation.search, getArticles);
        });
    }

    /**
     * Dispatch when pagination page btn clicked
     *
     * Set activePage and change URL
     *
     * @param page
     */
    onPageChange = (page) => {
        this.setState(
            {
                activePage: page,
            },
            () => {
                const params = QueryString.parse(window.location.search);
                params.page = page;

                history.push(window.location.pathname + '?' + QueryString.stringify(params));
            },
        );
    };

    /**
     * Changes active site by url search params
     *
     * @param locationSearch
     * @param afterChangePageCallback
     */
    changePageByUrl = (locationSearch, afterChangePageCallback) => {
        const params = QueryString.parse(locationSearch);

        if (!params.page) {
            // if not exists then 1 - default first page
            params.page = 1;
        }

        this.setState({ activePage: parseInt(params.page) }, () => {
            afterChangePageCallback(this.state.activePage);
        });
    };

    render() {
        const { activePage } = this.state;
        const { pagination } = this.props;

        return pagination ? (
            <Blog activePage={activePage} onPageChange={this.onPageChange} pagination={pagination} />
        ) : null;
    }
}

BlogContainer.propTypes = {
    pagination: PropTypes.shape({
        total: PropTypes.number.isRequired,
    }).isRequired,
    getArticles: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        pagination: state.articles.pagination,
    };
};

const mapDispatchToProps = {
    getArticles: articlesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogContainer);
