import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { STATUS_404 } from 'domain/constants/responseStatus';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION,
    toggleIsFavouritePropertyAction,
} from 'redux/actions/propertiesActions';
import redirectToErrorPage from 'redux/utils/redirectToErrorPage';
import ACREAPI from 'utils/services/ACREAPI';

function* toggleIsFavouritePropertySaga(action) {
    const propertyId = action.payload;

    try {
        yield put(toggleIsFavouritePropertyAction.request(propertyId));
        const response = yield call(ACREAPI.properties().toggleIsFavouriteProperty, propertyId);
        const result = response.data.result;
        yield put(toggleIsFavouritePropertyAction.success());

        yield put({
            type: TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION,
            payload: {
                propertyId: propertyId,
                isFavourite: result.is_favourite,
            },
        });

        notifiSuccess({
            message: result.message,
        });
    } catch (error) {
        notifiFail({
            message: 'Toggle property as favourite has failed',
            duration: 3000,
        });

        yield put(toggleIsFavouritePropertyAction.failure());

        if (error.response !== undefined && error.response.status !== undefined && error.response.status === 404) {
            yield redirectToErrorPage(STATUS_404);
        }
    } finally {
        yield put(toggleIsFavouritePropertyAction.fulfill(propertyId));
    }
}

export default function* toggleIsFavouritePropertySagaWatcher() {
    yield takeLatest(toggleIsFavouritePropertyAction.TRIGGER, toggleIsFavouritePropertySaga);
}
