import { fetchFavouritePropertiesAction } from 'redux/actions/favouritePropertiesActions';
import { TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION } from 'redux/actions/propertiesActions';

const defaultState = {
    properties: [],
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchFavouritePropertiesAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case fetchFavouritePropertiesAction.SUCCESS:
            return {
                ...state,
                properties: action.payload,
            };
        case fetchFavouritePropertiesAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        case TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION:
            const propertyId = action.payload.propertyId;

            const propertiesUpdated = state.properties.filter((property) => {
                return property.id !== propertyId;
            });

            return {
                ...state,
                properties: propertiesUpdated,
            };
        default:
            return {
                ...state,
            };
    }
};
