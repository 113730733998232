import GoogleMap from 'components/Common/GoogleMap';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import GoogleMap2 from 'utils/services/GoogleMap';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/Map.module.scss';
import classesTitle from '../styles/Title.module.scss';

const GOOGLE_MAPS_ZOOM = 15;

const Map = ({ longitude, latitude, className }) => {
    const url = new GoogleMap2(latitude, longitude, GOOGLE_MAPS_ZOOM).getUrl();
    const cssClass = joinNotEmpty([classes.map, className], ' ');

    return (
        <div className={cssClass} id='google-map'>
            <Title size={2} alignment={ALIGN_LEFT} className={classesTitle.title}>
                See on map
            </Title>
            <div className={classes['map-container']}>
                <GoogleMap latitude={latitude} longitude={longitude} zoom={GOOGLE_MAPS_ZOOM} />
            </div>
            <a href={url} className={classes['open-google-maps']} target='_blank' rel='noopener noreferrer'>
                Open in Google Maps
            </a>
        </div>
    );
};

Map.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    className: PropTypes.string,
};

export default Map;
