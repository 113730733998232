import HtmlHead from 'components/Common/HtmlHead';
import ModalContainer from 'components/Common/Modal';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React from 'react';
// import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap'; // Button
import {
    forgotModalActionType,
    hideLoginModal,
    loginModalActionType,
    registrationModalActionType,
    showLoginModal,
} from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
// import env from 'utils/functions/env';
import { PropTypes } from 'utils/wrappers';
import './LoginModal.scss';
import { ReCaptchaWrapper } from 'components/Common/ReCaptcha';

const LoginModal = ({
    isOpen,
    showLoginModal,
    hideLoginModal,
    handleInput,
    onSubmit,
    onFacebookClick,
    onGoogleClick,
    login,
    password,
    userIsLoading,
    recaptchaRef,
}) => {
    const title = 'Sign In';

    return (
        <React.Fragment>
            {isOpen ? <HtmlHead title={title} /> : null}
            <ModalContainer
                showModal={showLoginModal}
                additionalModalClass={'login-modal'}
                hideModal={hideLoginModal}
                isOpen={isOpen}
                modalUriName={generateModalUriParam(loginModalActionType)}
                title={title}
                subtitle={
                    <div>
                        Need Account?{' '}
                        <Link to={routeWithQuery({ [generateModalUriParam(registrationModalActionType)]: 'show' })}>
                            Create It!
                        </Link>
                    </div>
                }
                body={
                    <div>
                        <div className={'form'}>
                            <Form onSubmit={onSubmit}>
                                <FormGroup>
                                    <Input
                                        name={'login'}
                                        onChange={handleInput}
                                        value={login}
                                        type={'text'}
                                        placeholder={'Email'}
                                    />
                                </FormGroup>
                                <FormGroup className={'password-group'}>
                                    <Input
                                        name={'password'}
                                        onChange={handleInput}
                                        value={password}
                                        type={'password'}
                                        placeholder={'Password'}
                                    />
                                </FormGroup>
                                <ReCaptchaWrapper
                                    recaptchaRef={recaptchaRef}
                                    handleGoogleRecaptcha={(val) =>
                                        handleInput({ target: { name: 'recaptcha', value: val } })
                                    }
                                />
                                <div className={'forgot'}>
                                    <Link
                                        to={routeWithQuery({ [generateModalUriParam(forgotModalActionType)]: 'show' })}
                                    >
                                        Forgotten Password
                                    </Link>
                                </div>
                                <SpinnerButton
                                    isLoading={userIsLoading}
                                    color='primary'
                                    className={'submit'}
                                    type={'submit'}
                                >
                                    Sign In
                                </SpinnerButton>
                                {/*
                                <div className={'more'}>or Sign Up with your Social Account</div>
                                <div className={'socials'}>
                                    <Button className={'social facebook'} type={'button'} onClick={onFacebookClick}>
                                        <i className='fab fa-facebook-f' />
                                        <span>Facebook</span>
                                    </Button>
                                    <GoogleLogin
                                        onSuccess={onGoogleClick}
                                        onFailure={onGoogleClick}
                                        clientId={env('GOOGLE_PROJECT_CLIENT_ID')}
                                        render={(props) => (
                                            <Button className={'social google'} type={'button'} onClick={props.onClick}>
                                                <i className='fab fa-google' />
                                                <span>Google</span>
                                            </Button>
                                        )}
                                    />
                                </div>
                                */}
                            </Form>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

LoginModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showLoginModal: PropTypes.func.isRequired,
    hideLoginModal: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onFacebookClick: PropTypes.func.isRequired,
    onGoogleClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.loginModal.isOpen,
        userIsLoading: state.user.isLoading,
    };
};

const mapDispatchToProps = {
    showLoginModal,
    hideLoginModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
