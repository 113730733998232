import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { fetchSellerAction } from 'redux/actions/sellersActions';
import routes from '../../../domain/routes';
import Seller from './Seller';

class SellerContainer extends Component {
    componentDidMount() {
        const match = matchPath(window.location.pathname, {
            path: routes.seller,
        });

        this.props.fetchSeller(match.params.slug);
    }

    render() {
        return this.props.sellerIsLoading ? <AcreSpinner wrapped /> : <Seller />;
    }
}

const mapStateToProps = (state) => ({
    sellerIsLoading: state.seller.isLoading,
});

const mapDispatchToProps = {
    fetchSeller: fetchSellerAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerContainer);
