import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IN_LEFT, IN_RIGHT } from 'utils/constans/orderingDirections';
import classes from 'components/Page/Panel/Blog/Form/styles/ArticleForm.module.scss';

const SortableArticleImage = ({ id, image, reorderUploadedImage, removeUploadedImage }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    let imageUrl = image.thumbnailUrl;

    if (imageUrl === null || imageUrl === undefined) {
        imageUrl = image.path;
    }

    return (
        <div
            className={`${classes['list-item']} col-md-4`}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <img src={imageUrl} alt='' />
            <div className={classes['icons']}>
                <i
                    onClick={() => reorderUploadedImage(image.id, IN_LEFT)}
                    title={'Move left'}
                    className={'fas fa-arrow-circle-left ' + classes['arrow-icon']}
                />
                <i
                    onClick={() => reorderUploadedImage(image.id, IN_RIGHT)}
                    title={'Move right'}
                    className={'fas fa-arrow-circle-right ' + classes['arrow-icon']}
                />
                <i
                    className={'fa fa-times-circle ' + classes['delete-icon']}
                    title='Delete image'
                    onClick={() => removeUploadedImage(image)}
                />
            </div>
        </div>
    );
};

export default SortableArticleImage;
