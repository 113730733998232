import getConfiguredIntl from 'utils/functions/intl';

class Price {
    constructor(price) {
        this.price = price;
    }

    format = () => {
        if (!this.isValid()) {
            return null;
        }

        const intl = getConfiguredIntl();

        return intl.formatNumber(this.price, {
            format: 'USD',
            style: 'currency',
            maximumFractionDigits: 0,
        });
    };

    formatWithFraction = () => {
        if (!this.isValid()) {
            return null;
        }

        const intl = getConfiguredIntl();

        return intl.formatNumber(this.price, {
            format: 'USD',
            style: 'currency',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    isValid = () => {
        return this.price >= 0;
    };
}

export default Price;
