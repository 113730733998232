import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import { setActivePage } from 'redux/actions/propertiesSearchParamsActions';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import routes from '../../../../../domain/routes';
import { PropTypes, QueryString } from 'utils/wrappers';
import List from './List';

class ListContainer extends Component {
    constructor(props) {
        super(props);

        this.unlisten = history.listen((location) => {
            const match = matchPath(location.pathname, {
                path: routes.properties.main,
            });

            if (match) {
                const paramsFromUrl = QueryString.parse(location.search);
                const page = paramsFromUrl.page ? paramsFromUrl.page : 1;

                this.props.setActivePage(page);
                this.props.searchProperties();
            }
        });
    }

    componentDidMount() {
        const paramsFromUrl = QueryString.parse(history.location.search);
        const page = paramsFromUrl.page ? paramsFromUrl.page : 1;

        this.setPage(page);
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchedProperties.pagination !== this.props.searchedProperties.pagination) {
            if (this.props.activePage > this.props.searchedProperties.pagination.total_pages) {
                this.setPage(this.props.searchedProperties.pagination.total_pages);
            }
        }
    }

    onPageChange = (page) => {
        this.setPage(page);
        window.scrollTo(0, 0);
    };

    setPage = (page) => {
        history.push(routeWithQuery({ page: page }, true, routes.properties.main));
    };

    render() {
        return this.props.searchedProperties.areLoading ? (
            <AcreSpinner wrapped={true} />
        ) : (
            <List onPageChange={this.onPageChange} />
        );
    }
}

ListContainer.propTypes = {
    filters: PropTypes.shape({
        saleType: PropTypes.arrayOf(PropTypes.string),
    }),
    searchedProperties: PropTypes.shape({
        areLoading: PropTypes.bool.isRequired,
        pagination: PropTypes.shape({
            total: PropTypes.number.isRequired,
        }),
    }).isRequired,
    activePage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    searchProperties: PropTypes.func.isRequired,
    setActivePage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    searchedProperties: state.searchedProperties,
    filters: state.propertiesSearchParams.filters,
    activePage: state.propertiesSearchParams.activePage,
});

const mapDispatchToProps = {
    searchProperties: searchedPropertiesAction,
    setActivePage: setActivePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
