import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { LOGOUT_ACTION } from 'redux/actions/authActions';
import env from 'utils/functions/env';
import history from 'utils/history';
import reducers from './reducers';
import { runSagaMiddleware, sagaMiddleware } from './sagaMiddleware';

const rootReducer = (appReducer) => (state, action) => {
    // on LOGOUT_ACTION application resets reducer state
    if (action.type === LOGOUT_ACTION) {
        const newState = appReducer(undefined, {});
        newState.router = state.router;
        return newState;
    }

    return appReducer(state, action);
};

export const store =
    env('ENV') !== 'production'
        ? createStore(
              rootReducer(reducers(history)),
              composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
          )
        : createStore(
              rootReducer(reducers(history)),
              compose(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
          );

runSagaMiddleware();
