import sorting from 'data/properties/sorting';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchedPropertiesAction } from 'redux/actions/propertiesActions';
import { setActivePage, setSorting } from 'redux/actions/propertiesSearchParamsActions';
import { handleInput } from 'utils/functions/inputHelpers';
import { route } from 'utils/functions/routesHelper';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import { PropTypes, QueryString } from 'utils/wrappers';
import Sorting from './Sorting';

class SortingContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            sorting: sorting[0],
        };
    }

    componentDidMount() {
        const params = QueryString.parse(history.location.search);

        if (params.sorting) {
            const firstAndOneElement = 0;
            const sortingFromUrl = sorting.filter((sortingObject) => sortingObject.value === params.sorting)[
                firstAndOneElement
            ];

            this.setSortingLocalAndRedux(sortingFromUrl);
        }

        history.listen((location) => {
            if (!location.search) {
                this.setSortingLocalAndRedux(sorting[0]);
            }
        });
    }

    setSortingLocalAndRedux = (sortingObject) => {
        this.setState({
            sorting: sortingObject,
        });

        this.props.setSorting(sortingObject.value);
    };

    onSortingChange = (sortingObject) => {
        this.setSortingLocalAndRedux(sortingObject);

        this.props.searchProperties();

        history.push(
            routeWithQuery(
                { sorting: sortingObject.value },
                true,
                route(routes.properties.main, { saleType: this.props.propertiesSearchParams.filters.saleType }),
            ),
        );
    };

    render() {
        return <Sorting sortingOptions={sorting} sorting={this.state.sorting} onSortingChange={this.onSortingChange} />;
    }
}

SortingContainer.propTypes = {
    propertiesSearchParams: PropTypes.shape({
        filters: PropTypes.object.isRequired,
        appliedFilters: PropTypes.object.isRequired,
        sorting: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    setSorting: PropTypes.func.isRequired,
    searchProperties: PropTypes.func.isRequired,
    setActivePage: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setSorting: setSorting,
    searchProperties: searchedPropertiesAction,
    setActivePage: setActivePage,
};

const mapStateToProps = (state) => ({
    propertiesSearchParams: state.propertiesSearchParams,
});

export default connect(mapStateToProps, mapDispatchToProps)(SortingContainer);
