import OneColumnWrapper from 'components/Common/OneColumnWrapper';
import PageWrapper from 'components/Common/PageWrapper';
import Title from 'components/Common/Title';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ArticleContent from './Part/ArticleContent';
// import ArticleSidebar from './Part/ArticleSidebar';
import RecommendedArticles from './Part/RecommendedArticles';
import classes from './styles/Article.module.scss';

class Article extends Component {
    render() {
        return (
            <React.Fragment>
                <section className={classes.main}>
                    <PageWrapper>
                        <Row className={'justify-content-center'}>
                            <Col xs={12} md={12} lg={12} className={classes.article}>
                                <ArticleContent />
                            </Col>
                            {/*<Col xs={12} md={4} lg={3} className={classes.sidebar}>*/}
                            {/*    <ArticleSidebar />*/}
                            {/*</Col>*/}
                        </Row>
                    </PageWrapper>
                </section>
                <section className={classes.recommended + ' container'}>
                    <OneColumnWrapper withContainer={false}>
                        <Title size={2} className={classes.title}>
                            Recommended articles
                        </Title>
                    </OneColumnWrapper>
                    <Row>
                        <RecommendedArticles />
                    </Row>
                </section>
            </React.Fragment>
        );
    }
}

export default Article;
