import React from 'react';
import ReactDOM from 'react-dom';
import './common/styles/bootstrap/main.scss';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import env from 'utils/functions/env';

Sentry.init({
    dsn: env('SENTRY_DSN') || '',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
});

ReactDOM.render(<App />, document.getElementById('root'));
