//import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import '../../styles/Searching.scss';

const Searching = ({ handleInput, search, onSearchApply, searchedPropertiesLoading }) => {
    return (
        <div className={'search-wrapper'}>
            <InputGroup className={'search-group'}>
                <InputGroupAddon addonType='prepend'>
                    <InputGroupText className={'search-icon'}>
                        <i className={'fas fa-search'} />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    placeholder={'Search Properties'}
                    name={'search'}
                    className={'search-input'}
                    onChange={handleInput}
                    value={search}
                />
            </InputGroup>
        </div>
    );
};

Searching.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onSearchApply: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired,
    searchedPropertiesLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    searchedPropertiesLoading: state.searchedProperties.areLoading,
});

export default connect(mapStateToProps, null)(Searching);
