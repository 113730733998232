import { fetchAllRolesAction } from 'redux/actions/rolesActions';

const defaultState = {
    areLoading: true,
    data: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchAllRolesAction.REQUEST:
            return { ...state, areLoading: true };
        case fetchAllRolesAction.SUCCESS:
            return { ...state, data: action.payload };
        case fetchAllRolesAction.FULFILL:
            return { ...state, areLoading: false };
        default:
            return { ...state };
    }
};
