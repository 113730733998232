import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { handlePropertyFormInputAction } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';

const SubdivisionFields = ({ handleInput, subdivisionName, subdivisionDues }) => {
    return (
        <React.Fragment>
            <Row form>
                <Col>
                    <Title size={5} underline className={styles['form-title']}>
                        Subdivision
                    </Title>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label>{fields.subdivisionName}</Label>
                                <Input
                                    type={'text'}
                                    placeholder={fields.subdivisionName}
                                    name={'subdivisionName'}
                                    value={subdivisionName}
                                    onChange={handleInput}
                                    bsSize={bsSize}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>{fields.subdivisionDues}</Label>
                                <Input
                                    type={'text'}
                                    placeholder={fields.subdivisionDues}
                                    name={'subdivisionDues'}
                                    value={subdivisionDues}
                                    onChange={handleInput}
                                    bsSize={bsSize}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

SubdivisionFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    subdivisionName: PropTypes.string,
    subdivisionDues: PropTypes.string,
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
};

const mapStateToProps = (state) => ({
    subdivisionName: state.propertyForm.subdivisionName,
    subdivisionDues: state.propertyForm.subdivisionDues,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubdivisionFields);
