import ArticleDate from 'components/Common/ArticleDate/ArticleDate';
import Badge404 from 'components/Common/Badge404';
import HtmlHead from 'components/Common/HtmlHead';
import ShareButtons from 'components/Common/ShareButtons';
import Title from 'components/Common/Title';
import ArticleConverter from 'domain/api2domain/ArticleConverter';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React, { Component } from 'react';
import { PropTypes } from 'utils/wrappers';
import classes from '../../styles/ArticleContent.module.scss';

class ArticleContent extends Component {
    render() {
        const { article } = this.props;
        const articleConverted = ArticleConverter.toDomain(article);

        if (articleConverted === null) {
            return null;
        }

        const title = articleConverted.getTitle();
        const mainImage = articleConverted.getMainImage();
        const isImageAvailable = mainImage !== null;
        const style = { maxWidth: '600px' };

        return article ? (
            <React.Fragment>
                <HtmlHead title={title} description={articleConverted.getDescription()} />
                <Title size={1} alignment={ALIGN_LEFT} withoutMarginTop>
                    {title}
                </Title>
                <ArticleDate date={article.updated_at} />
                {!isImageAvailable ? null : (
                    <React.Fragment>
                        <img src={mainImage.getThumbnailUrl()} alt={title} className={classes.image + ' d-lg-none'} />
                        <img
                            src={mainImage.getFullSizeUrl()}
                            alt={title}
                            className={classes.image + ' d-none d-lg-block'}
                            style={style}
                        />
                    </React.Fragment>
                )}
                <div dangerouslySetInnerHTML={{ __html: articleConverted.getContent() }} className={classes.content} />
                <ShareButtons className={classes.share} />
            </React.Fragment>
        ) : (
            <Badge404 text="The article doesn't exist" />
        );
    }
}

ArticleContent.propTypes = {
    article: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                preview: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default ArticleContent;
