import noImage from 'common/images/no-image.svg';
import ModalContainer from 'components/Common/Modal';
import Price from 'components/Common/Property/Price';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import routes from 'domain/routes';
import React from 'react';
import { Braintree } from 'react-braintree-fields';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { checkoutModalActionType, hideCheckoutModal, showCheckoutModal } from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import env from 'utils/functions/env';
import { PropTypes } from 'utils/wrappers';
import './CheckoutModal.scss';
import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import ACREAPI from 'utils/services/ACREAPI';

const FIRST = 0;

const CheckoutModal = ({
    isOpen,
    showCheckoutModal,
    hideCheckoutModal,
    isSending,
    property,
    form,
    handleInput,
    onSetToken,
}) => {
    const title = 'Reserve Listing';
    const subtitle = property.listingId;
    const tosLink = <Link to={routes.termsOfService}>Terms of Service</Link>;
    const privacyLink = <Link to={routes.privacyPolicy}>Privacy Policy</Link>;
    const price = <Price value={property.finances.price} />;
    const monthlyPayment = <Price value={property.finances.monthlyPayment} withFraction={true} />;
    const processingFee = <Price value={property.finances.processingFee} />;
    const image = !isNullOrEmptyArray(property.images) ? property.images[FIRST].thumbnailUrl : noImage;

    const onSubmit = function (SyntheticEvent) {
        SyntheticEvent.preventDefault();
        const form = SyntheticEvent.target;
        const payload = {
            propertySlug: property.slug,
            name: form[0].value + ' ' + form[2].value,
            email: form[1].value,
            message: 'Buy Inquiry for listing ID: ' + property.listingId + ' ($' + property.finances.price + ')',
            phoneNumber: form[3].value,
        };
        const sender = ACREAPI.properties().sendOnePropertyContactMessage;
        sender(payload)
            .then(() => {
                hideCheckoutModal();
                notifiSuccess({ message: 'Thank you! Your message to seller has been sent' });
            })
            .catch((error) => {
                const er = error?.response?.data?.result ?? false;
                // Error handling:
                if (er) {
                    for (let y in er) {
                        notifiFail({ message: er[y].pop() });
                        if (y === 'name') form[0].focus();
                        else if (y === 'email') form[1].focus();
                        else if (y === 'phone_number') form[3].focus();
                    }
                } else {
                    notifiFail({ message: "Your message to seller hasn't been sent" });
                }
            });
    };

    const body = (
        <Row className='text-left'>
            <Col lg={12} xl={6}>
                <Row className='ml-3 mr-1 my-5'>
                    <Col md={12} className='text-lg mb-3 font-weight-bold'>
                        {property.name}
                    </Col>
                    <Col md={5} className={'thumbnail-and-finance'}>
                        <div>
                            <img src={image} alt='Thumbnail' className='img-fluid' />
                        </div>
                        <div className={'processing-fee-container'}>
                            <div className='price'>{processingFee}</div>
                            <div className='label'>Processing Fee</div>
                            <div className='subtitle'>Total due now</div>
                        </div>
                    </Col>
                    <Col md={7} className='prop-list'>
                        <Row>
                            <Col md={6}>Sold by:</Col>
                            <Col md={6} className='font-weight-bold'>
                                {property.seller.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>Address:</Col>
                            <Col md={6} className='font-weight-bold'>
                                {property.location.address}, {property.location.county}, {property.location.state}{' '}
                                {property.location.zip}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>Listing ID:</Col>
                            <Col md={6} className='overflow-ellipsis font-weight-bold'>
                                {property.listingId}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>Purchase Price:</Col>
                            <Col md={6} className='font-weight-bold'>
                                {price}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>Financial Term:</Col>
                            <Col md={6} className='font-weight-bold'>
                                {property.financialTerm}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>Monthly Payment:</Col>
                            <Col md={6} className='font-weight-bold'>
                                {monthlyPayment}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col lg={12} xl={6}>
                <Braintree
                    authorization={env('BRAINTREE_TOKENIZATION_KEY')}
                    getTokenRef={(ref) => onSetToken(ref)}
                    styles={{ input: { 'font-size': '16px' } }}
                >
                    <form onSubmit={onSubmit}>
                        <Row className='mx-3 my-5'>
                            <Col md={12} className='text-lg mb-3 font-weight-bold'>
                                Your contact details
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>First Name</Label>
                                    <Input
                                        name='fname'
                                        placeholder='First name'
                                        value={form.fname}
                                        onChange={handleInput}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        value={form.email}
                                        onChange={handleInput}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Last Name</Label>
                                    <Input
                                        name='lname'
                                        placeholder='Last name'
                                        value={form.lname}
                                        onChange={handleInput}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Phone Number</Label>
                                    <Input
                                        type='phone_number'
                                        name='phone_number'
                                        placeholder='Phone number'
                                        value={form.phone_number}
                                        onChange={handleInput}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12} className='mt-4'>
                                <SpinnerButton isLoading={isSending} color={'primary'} type={'submit'} block>
                                    Submit Inquiry
                                </SpinnerButton>
                            </Col>
                            <Col md={12} className='mt-4'>
                                By clicking the "Submit Inquiry" button you confirm that you have reviewed and agree to
                                the {tosLink} &nbsp;and {privacyLink} of this website.
                            </Col>
                        </Row>
                    </form>
                </Braintree>
            </Col>
        </Row>
    );

    return (
        <ModalContainer
            showModal={showCheckoutModal}
            additionalModalClass={'checkout-modal'}
            hideModal={hideCheckoutModal}
            isOpen={isOpen}
            modalUriName={generateModalUriParam(checkoutModalActionType)}
            title={title}
            subtitle={subtitle}
            body={body}
        />
    );
};

CheckoutModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    showCheckoutModal: PropTypes.func.isRequired,
    hideCheckoutModal: PropTypes.func.isRequired,
    isSending: PropTypes.bool.isRequired,
    property: PropTypes.shape({
        name: PropTypes.string.isRequired,
        location: PropTypes.shape({
            address: PropTypes.string.isRequired,
            county: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            zip: PropTypes.string.isRequired,
        }).isRequired,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                thumbnailUrl: PropTypes.string.isRequired,
                fullSizeUrl: PropTypes.string.isRequired,
            }),
        ),
        listingId: PropTypes.string.isRequired,
        financialTerm: PropTypes.string,
        finances: PropTypes.shape({
            price: PropTypes.number.isRequired,
            monthlyPayment: PropTypes.number.isRequired,
            processingFee: PropTypes.number.isRequired,
        }).isRequired,
        seller: PropTypes.shape({
            name: PropTypes.string,
        }),
    }).isRequired,
    form: PropTypes.object.isRequired,
    handleInput: PropTypes.func.isRequired,
    onCheckoutSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.checkoutModal.isOpen,
        isSending: state.checkout.isSending,
    };
};

const mapDispatchToProps = {
    showCheckoutModal,
    hideCheckoutModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal);
