import Title from 'components/Common/Title';
import classes from 'components/Page/Property/styles/Categories.module.scss';
import classesTitle from 'components/Page/Property/styles/Title.module.scss';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import { LISTINGS } from 'domain/constants/saleType';
import routes from 'domain/routes';
import React from 'react';
import { isNullOrEmptyArray, joinNotEmpty } from 'utils/functions/arrays';
import { route } from 'utils/functions/routesHelper';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import { PropTypes } from 'utils/wrappers';

const Categories = ({ categories, className }) => {
    if (isNullOrEmptyArray(categories)) {
        return null;
    }

    const cssClass = joinNotEmpty([classes.categories, className], ' ');

    return (
        <div className={cssClass}>
            <Title size={2} alignment={ALIGN_LEFT} className={classesTitle.title}>
                Categories
            </Title>

            {categories.map((category, index) => {
                const url = routeWithQuery(
                    {
                        filterCategory: category.slug,
                    },
                    false,
                    route(routes.properties.main, {
                        saleType: LISTINGS,
                    }),
                );

                return (
                    <div key={index} className={classes.category}>
                        <a href={url} className={classes.link}>
                            {category.name}
                        </a>
                        <span className={classes.separator}>,</span>
                    </div>
                );
            })}
        </div>
    );
};

Categories.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }),
    ),
};

export default Categories;
