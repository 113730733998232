import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { handlePropertyFormInputAction, onClickUtilityCheckboxAction } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/PropertyForm.module.scss';

const UtilitiesFields = ({ propertiesRelatedElements, selectedUtilities, onClickUtilityCheckbox }) => {
    propertiesRelatedElements.utilities.None = 0;
    const bulkUpdate = (on) => {
        let i = 1;
        let el = null;
        while ((el = document.getElementById('propertyUtilities_' + i))) {
            if (on !== el.checked) {
                el.click();
            }
            i++;
        }
    };
    const onChange = (e) => {
        if (e.currentTarget.value === '0') {
            return bulkUpdate(!e.currentTarget.checked);
        }
        return onClickUtilityCheckbox(null, e);
    };
    const isChecked = (key) => {
        if (key === 'None') {
            return selectedUtilities.length < 1;
        } else {
            return selectedUtilities.indexOf(propertiesRelatedElements.utilities[key]) !== -1;
        }
    };
    return (
        <React.Fragment>
            <Row form>
                <Col>
                    <Title size={5} underline className={styles['form-title']}>
                        Utilities
                    </Title>
                    <FormGroup check inline>
                        <Row form>
                            {Object.keys(propertiesRelatedElements.utilities).map((key, index) => (
                                <Col md={3} key={index}>
                                    <Label check className={styles['property-form-check-label']}>
                                        <Input
                                            type={'checkbox'}
                                            name={'utilities'}
                                            id={'propertyUtilities_' + propertiesRelatedElements.utilities[key]}
                                            value={propertiesRelatedElements.utilities[key]}
                                            onChange={onChange}
                                            checked={isChecked(key)}
                                        />
                                        {key}
                                    </Label>
                                </Col>
                            ))}
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

UtilitiesFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onClickUtilityCheckbox: PropTypes.func.isRequired,
    selectedUtilities: PropTypes.arrayOf(PropTypes.number).isRequired,
    propertiesRelatedElements: PropTypes.shape({
        utilities: PropTypes.object.isRequired,
    }).isRequired,
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
    onClickUtilityCheckbox: onClickUtilityCheckboxAction,
};

const mapStateToProps = (state) => ({
    selectedUtilities: state.propertyForm.selectedUtilities,
    propertiesRelatedElements: state.relatedPropertiesElements.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(UtilitiesFields);
