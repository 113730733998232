import PropertyConverter from 'domain/api2domain/PropertyConverter';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submitCheckoutPaymentAction } from 'redux/actions/checkoutActions';
import { isNullOrEmptyObject } from 'utils/functions/objects';
import { PropTypes } from 'utils/wrappers';
import CheckoutModal from './CheckoutModal';

class CheckoutModalContainer extends Component {
    constructor(props) {
        super(props);

        this.initialFormState = {
            fname: '',
            lname: '',
            email: '',
            phone_number: '',
            tokener: null,
        };

        this.state = {
            isSubmitting: false,

            form: this.initialFormState,
        };
    }

    componentDidMount() {
        this.setState({
            fname: '',
            lname: '',
            email: '',
            phone_number: '',
            tokener: null,
        });
    }

    onCheckoutInput(e) {
        const form = { ...this.state.form };

        form[e.target.name] = e.target.value;

        this.setState({ form });
    }

    onCheckoutSubmit(payload) {
        this.props.submitCheckoutPayment({
            ...payload,
            listing_id: this.props.property.id,
            ...this.state.form,
        });
    }

    render() {
        if (this.props.isPropertyLoading || isNullOrEmptyObject(this.props.property)) {
            return null;
        }

        const converter = new PropertyConverter();
        const propertyPrepared = converter.toDomain(this.props.property);

        return (
            <CheckoutModal
                property={propertyPrepared}
                form={this.state.form}
                handleInput={(e) => this.onCheckoutInput(e)}
                onCheckoutSubmit={(e) => this.onCheckoutSubmit(e)}
                onSetToken={(fn) => this.setState({ tokener: fn })}
                getToken={this.state.tokener}
            />
        );
    }
}

CheckoutModalContainer.propTypes = {
    isPropertyLoading: PropTypes.bool.isRequired,
    property: PropTypes.object.isRequired,
    submitCheckoutPayment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isPropertyLoading: state.oneProperty.isLoading,
        property: state.oneProperty.propertyData,
    };
};

const mapDispatchToProps = {
    submitCheckoutPayment: submitCheckoutPaymentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModalContainer);
