import { LISTINGS } from 'domain/constants/saleType';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllStatesAction } from 'redux/actions/statesActions';
import { handleInput } from 'utils/functions/inputHelpers';
import { route } from 'utils/functions/routesHelper';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import history from 'utils/history';
import { PropTypes } from 'utils/wrappers';
import SearchForm from './SearchForm';

class SearchFormContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: '',
            state: '',
            price: '',
            size: '',
        };

        this.handleInput = handleInput.bind(this);
    }

    componentDidMount() {
        this.props.fetchStates();
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let values = {};

        if (this.state.address !== '') {
            values.search = this.state.address;
        }

        if (this.state.state !== '') {
            values.filterState = this.state.state;
        }

        if (this.state.price !== '') {
            values.filterPrice = this.state.price;
        }

        if (this.state.size !== '') {
            values.filterSize = this.state.size;
        }

        history.push(routeWithQuery(values, false, route(routes.properties.main, { saleType: LISTINGS })));
    };

    handleStateChange = (currentState) => {
        this.setState({
            state: currentState?.value ?? '',
        });
    };

    handlePriceChange = (currentPrice) => {
        this.setState({
            price: currentPrice.value,
        });
    };

    handleSizeChange = (currentSize) => {
        this.setState({
            size: currentSize.value,
        });
    };

    render() {
        return (
            <SearchForm
                states={this.props.states}
                handleSubmit={this.handleSubmit}
                handleAddressChange={this.handleInput}
                handleStateChange={this.handleStateChange}
                handlePriceChange={this.handlePriceChange}
                handleSizeChange={this.handleSizeChange}
            />
        );
    }
}

SearchFormContainer.propTypes = {
    states: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
    return {
        states: state.states.data,
        statesAreLoading: state.states.areLoading,
    };
};

const mapDispatchToProps = {
    fetchStates: fetchAllStatesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormContainer);
