import UserConverter from 'domain/api2domain/UserConverter';
import { PropTypes } from 'utils/wrappers';

const UserFromApi = ({ apiData }) => {
    const converter = new UserConverter();
    const user = converter.toDomain(apiData);

    if (user === null) {
        return null;
    }

    return user.toString();
};

UserFromApi.propTypes = {
    apiData: PropTypes.object,
};

export default UserFromApi;
