import React from 'react';
import FeaturedNews from './Part/FeaturedNews';
import FeaturedProperties from './Part/FeaturedProperties';
import LandDeals from './Part/LandDeals';
import PopularCategories from './Part/PopularCategories';
import Testimonials from './Part/Testimonials';
import Top from './Part/Top';
import './styles/Home.scss';

const Home = () => {
    return (
        <React.Fragment>
            <Top />
            <FeaturedProperties />
            <PopularCategories />
            <LandDeals />
            <FeaturedNews />
            <Testimonials />
        </React.Fragment>
    );
};

export default Home;
