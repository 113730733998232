import React from 'react';
import { isEmptyString } from 'utils/functions/miscellaneous';
import { PropTypes } from 'utils/wrappers';
import classes from './styles/MainImage.module.scss';
import MediaContainer from 'components/Page/Property/Part/Gallery/Fragment/MediaContainer';

const MainImage = ({ mainImageUrl, withArrows, onClickPrevious, onClickNext }) => {
    return isEmptyString(mainImageUrl) ? null : (
        <div className={classes['main-image']}>
            <div className={getArrowClass(withArrows, true)} onClick={onClickPrevious}>
                <i className={classes.icon + ' fas fa-chevron-left'} />
            </div>
            <MediaContainer mainImageUrl={mainImageUrl} withArrows={withArrows} />
            <div className={getArrowClass(withArrows, false)} onClick={onClickNext}>
                <i className={classes.icon + ' fas fa-chevron-right'} />
            </div>
        </div>
    );
};

const getArrowClass = (withArrows, left) => {
    let prefix = 'right';

    if (left) {
        prefix = 'left';
    }

    let result = classes[prefix + '-arrow'];

    if (!withArrows) {
        result += ' ' + classes['without-arrows'];
    }

    return result;
};

MainImage.propTypes = {
    mainImageUrl: PropTypes.string,
    withArrows: PropTypes.bool,
    onClickPrevious: PropTypes.func,
    onClickNext: PropTypes.func,
};

MainImage.defaultProps = {
    mainImageUrl: '',
    withArrows: true,
    onClickPrevious: () => true,
    onClickNext: () => true,
};

export default MainImage;
