import React from 'react';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/FormListElements.module.scss';

const Videos = ({ videos, removeItem }) => {
    return isNullOrEmptyArray(videos) ? null : (
        <div className={styles['items']}>
            {videos.map((video, index) => (
                <div className={styles['item']} key={index}>
                    <div className={styles['item-no']}>
                        <div>{index + 1}</div>
                    </div>
                    <div className={styles['item-data']}>
                        <div className={styles['item-title']}>
                            <a href={video.link} target={'_blank'} rel='noreferrer'>
                                {video.link}
                            </a>
                        </div>
                        <div className={styles['item-desc']}>{video.desc}</div>
                    </div>
                    <div
                        className={styles['remove-btn']}
                        onClick={() => removeItem({ id: video.id, stateItem: 'videos' })}
                    >
                        <i className={'fas fa-times'} />
                    </div>
                </div>
            ))}
        </div>
    );
};

Videos.propTypes = {
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
        }),
    ).isRequired,
    removeItem: PropTypes.func.isRequired,
};

export default Videos;
