/**
 * Place for your routes
 */
export default {
    //
    home: '/',
    blog: {
        main: '/blog',
        article: '/blog/:articleSlug',
    },
    properties: {
        main: '/lands-for-sale',
        one: '/land-for-sale/:state/:slug', // Attention! This route is used in API too
        state: '/land-for-sale/:state',
        category: '/:slug',
    },
    faq: '/faq',
    contactUs: '/contact-us',
    aboutUs: '/about-us',
    disclaimer: '/disclaimer',
    privacyPolicy: '/privacy-policy',
    termsOfService: '/terms-of-service',
    // IMPORTANT! This routing is used in API - if you change it here, change it on API too.
    registrationConfirmation: '/confirm',
    // IMPORTANT! This routing is used in API - if you change it here, change it on API too.
    resetPassword: '/forgot-password',
    panel: {
        blog: {
            main: '/panel/blog',
            create: '/panel/blog/create',
            update: '/panel/blog/update/:articleSlug',
        },
        properties: {
            main: '/panel/properties',
            create: '/panel/properties/create',
            edit: '/panel/properties/edit/:id',
        },
        account: '/panel/account',
        savedProperties: '/panel/saved-properties',
        users: {
            main: '/panel/users',
        },
        payments: {
            main: '/panel/payments',
        },
        logs: '/panel/logs',
    },
    seller: '/seller/:slug',
    error: {
        badRequest: '/an-error-occurred',
        internalServerError: '/500',
        notFound: '/page-not-found',
    },
    listingPurchase: '/listing-purchase',
    logout: '/logout',
};
