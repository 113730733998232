import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/polyfill';
import getConfiguredIntl from 'utils/functions/intl';

class Size {
    constructor(size) {
        this.size = size;
    }

    format = (withUnit = true) => {
        if (this.size < 0) {
            return null;
        }

        let options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };

        if (withUnit) {
            options.unit = 'acre';
            options.unitDisplay = 'long';
            options.style = 'unit';
        }

        const intl = getConfiguredIntl();
        return intl.formatNumber(this.size, options);
    };
}

export default Size;
