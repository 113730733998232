import React, { Component } from 'react';
import { PaginationItem, PaginationLink } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import Pagination from './Pagination';

class PaginationContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 1,
            totalItemsNum: 0,
            pagesNum: 0,
            halfRange: 0,
            range: 0,
        };
    }

    componentDidMount() {
        const { totalItemsNum, itemsPerPage, activePage } = this.props;

        this.setState(
            {
                activePage: parseInt(activePage),
                pagesNum: Math.ceil(totalItemsNum / itemsPerPage),
                range: this.calculateRange(),
            },
            () => {
                this.setState({
                    halfRange: Math.floor(this.state.range / 2),
                });
            },
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const { totalItemsNum, itemsPerPage, activePage } = this.props;

            this.setState(
                {
                    activePage: parseInt(activePage),
                    totalItemsNum: parseInt(totalItemsNum),
                    range: this.calculateRange(),
                },
                () => {
                    this.setState({
                        pagesNum: Math.ceil(this.state.totalItemsNum / itemsPerPage),
                        halfRange: Math.floor(this.state.range / 2),
                    });
                },
            );
        }
    }

    calculateRange = () => {
        const { rangeDisplayed } = this.props;

        let range = rangeDisplayed;
        if (!rangeDisplayed) {
            // default displayedRange
            range = 10;
        }

        return range;
    };

    onChangePage = (e) => {
        let page = e.currentTarget.dataset.page;

        if (page === 'next') {
            page = this.state.activePage + 1;
        } else if (page === 'prev') {
            page = this.state.activePage - 1;
        } else {
            page = parseInt(page);
        }

        this.setState(
            {
                activePage: page,
            },
            () => {
                this.props.onPageChange(page);
            },
        );
    };

    calculateStartPage = () => {
        let start = this.state.activePage - this.state.halfRange;

        return start <= 0 ? 1 : start;
    };

    calculateEndPage = () => {
        let end = this.state.activePage + this.state.halfRange;

        return end > this.state.pagesNum ? this.state.pagesNum : end;
    };

    getElements = () => {
        const { activePage } = this.state;

        let elements = [];

        if (activePage !== 1) {
            elements.push(
                <PaginationItem key={0}>
                    <PaginationLink onClick={this.onChangePage} className={'arrow-container'} data-page={'prev'}>
                        <i className={'arrow left'} />
                    </PaginationLink>
                </PaginationItem>,
            );
        }

        for (let i = this.calculateStartPage(); i <= this.calculateEndPage(); i++) {
            elements.push(
                <PaginationItem key={i}>
                    <PaginationLink
                        onClick={this.onChangePage}
                        className={i === activePage ? 'active' : ''}
                        data-page={i}
                    >
                        {i}
                    </PaginationLink>
                </PaginationItem>,
            );
        }

        if (activePage !== this.state.pagesNum) {
            elements.push(
                <PaginationItem key={this.state.pagesNum + 1}>
                    <PaginationLink onClick={this.onChangePage} className={'arrow-container'} data-page={'next'}>
                        <i className={'arrow right'} />
                    </PaginationLink>
                </PaginationItem>,
            );
        }

        if (1 === elements.length) {
            return [];
        }

        return elements;
    };

    render() {
        return <Pagination elements={this.getElements()} />;
    }
}

PaginationContainer.propTypes = {
    onPageChange: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    activePage: PropTypes.number.isRequired,
    totalItemsNum: PropTypes.number.isRequired,
    rangeDisplayed: PropTypes.number,
};

export default PaginationContainer;
