import AcreBootstrapTable from 'components/Common/AcreBootstrapTable';
import TitleWithActions from 'components/Common/Panel/TitleWithActions';
import routes from 'domain/routes';
import React from 'react';
import { PropTypes } from 'utils/wrappers';

const BlogList = ({ columns, paginationOptions, onTableChange, articles, areLoading }) => {
    const actions = [
        {
            name: 'Create article',
            url: routes.panel.blog.create,
        },
    ];

    return (
        <React.Fragment>
            <TitleWithActions actions={actions}>Blog</TitleWithActions>
            <AcreBootstrapTable
                keyField='id'
                columns={columns}
                data={articles}
                paginationOptions={paginationOptions}
                onTableChange={onTableChange}
                remote={{
                    filter: false,
                    pagination: true,
                    sort: false,
                    cellEdit: false,
                }}
                loading={areLoading}
            />
        </React.Fragment>
    );
};

BlogList.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataField: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            isDummyField: PropTypes.bool,
            headerStyle: PropTypes.object,
            formatter: PropTypes.func,
        }),
    ).isRequired,
    paginationOptions: PropTypes.shape({
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        totalSize: PropTypes.number,
        showTotal: PropTypes.bool,
        hideSizePerPage: PropTypes.bool,
    }).isRequired,
    onTableChange: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
    areLoading: PropTypes.bool,
};

BlogList.defaultProps = {
    areLoading: false,
};

export default BlogList;
