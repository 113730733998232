import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import styles from './AcreSpinner.module.scss';

const AcreSpinner = ({ wrapped = true, size = '' }) => {
    const classes = [styles['acre-spinner'], styles[size], wrapped ? ' ' + styles.wrapped : ''];

    const cssClass = joinNotEmpty(classes, ' ');
    const spinner = <Spinner className={cssClass} />;

    if (wrapped) {
        return (
            <Container>
                <Row className={'justify-content-center'}>
                    <Col className='text-center' xs={1}>
                        {spinner}
                    </Col>
                </Row>
            </Container>
        );
    }

    return spinner;
};

AcreSpinner.propTypes = {
    wrapped: PropTypes.bool,
    size: PropTypes.oneOf(['', 'sm', 'xs']),
};

export default AcreSpinner;
