import React from 'react';
import { PropTypes } from 'utils/wrappers';
import arrow from '../../../../images/filter-hide-arrow.svg';
import '../../../../styles/FilterBox.scss';
import FilterCheckboxesContainer from '../FilterCheckboxes';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const FilterBox = ({ content, title, checked, checkedHandler, filterSlug }) => {
    return (
        <UncontrolledDropdown>
            <DropdownToggle tag='a' className='nav-link'>
                {title}
                <span className={checked > 0 ? '' : 'hidden'}>{checked}</span>
                <img src={arrow} alt={'toggle'} />
            </DropdownToggle>
            <DropdownMenu>
                <div className={'content'}>
                    {Array.isArray(content) ? (
                        <FilterCheckboxesContainer
                            filterSlug={filterSlug}
                            checkedHandler={checkedHandler}
                            elements={content}
                        />
                    ) : (
                        content
                    )}
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

FilterBox.propTypes = {
    filterSlug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    checked: PropTypes.number,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
};

export default FilterBox;
