import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchFavouritePropertiesAction } from 'redux/actions/favouritePropertiesActions';
import { PropTypes } from 'utils/wrappers';
import SavedProperties from './FavouriteProperties';

class FavouritePropertiesContainer extends Component {
    componentDidMount() {
        this.props.fetchFavouriteProperties();
    }

    render() {
        const { areLoading } = this.props;

        return areLoading ? <AcreSpinner wrapped /> : <SavedProperties />;
    }
}

FavouritePropertiesContainer.propTypes = {
    fetchFavouriteProperties: PropTypes.func.isRequired,
    areLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    areLoading: state.favouriteProperties.areLoading,
});

const mapDispatchToProps = {
    fetchFavouriteProperties: fetchFavouritePropertiesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavouritePropertiesContainer);
