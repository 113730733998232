import {
    NEXT_BLOG_FEATURED_ARTICLE_SLIDE,
    PREVIOUS_BLOG_FEATURED_ARTICLE_SLIDE,
    SET_BLOG_FEATURED_ARTICLE_SLIDE,
} from 'redux/actions/blogSliderAction';

const DEFAULT_TOTAL_SLIDES = 3;

const defaultState = {
    activeSlide: 1,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case PREVIOUS_BLOG_FEATURED_ARTICLE_SLIDE:
            const prev = state.activeSlide - 1 < 1 ? DEFAULT_TOTAL_SLIDES : state.activeSlide - 1;

            return {
                activeSlide: prev,
            };
        case NEXT_BLOG_FEATURED_ARTICLE_SLIDE:
            const next = state.activeSlide + 1 > DEFAULT_TOTAL_SLIDES ? 1 : state.activeSlide + 1;

            return {
                activeSlide: next,
            };
        case SET_BLOG_FEATURED_ARTICLE_SLIDE:
            return { activeSlide: action.payload };
        default:
            return { ...state };
    }
};
