import defaultAvatar from 'common/images/avatar-default.jpg';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import accountStyles from '../../styles/Account.module.scss';
import styles from '../../styles/SellerForm.module.scss';

const SellerForm = ({
    handleInput,
    onSubmit,
    handleArrayInput,
    handleLogoChange,
    removeItem,
    addInput,
    title,
    address,
    telephones,
    emails,
    actuallyLogoUri,
    saveRequestIsSending,
}) => {
    return (
        <Form onSubmit={onSubmit}>
            <Row form>
                <Col>
                    <Title size={4} alignment={ALIGN_LEFT}>
                        Seller Details
                    </Title>
                </Col>
            </Row>
            <Row form>
                <Col lg={2}>
                    <p>
                        <img className={accountStyles.avatar} src={actuallyLogoUri || defaultAvatar} alt={'-'} />
                    </p>
                    <FormGroup>
                        <Label>Logo</Label>
                        <Input
                            bsSize={'sm'}
                            type={'file'}
                            accept={'image/*'}
                            name={'image'}
                            onChange={handleLogoChange}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Label>Company name</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'title'}
                            value={title}
                            onChange={handleInput}
                            placeholder={'Company name'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Address</Label>
                        <Input
                            bsSize={'sm'}
                            type={'textarea'}
                            name={'address'}
                            value={address}
                            onChange={handleInput}
                            placeholder={'Address'}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col lg={4}>
                    <FormGroup>
                        <Label>
                            Emails{' '}
                            <i className={'fas fa-plus-circle ' + styles['icon']} onClick={() => addInput('emails')} />
                        </Label>
                        {emails.map((email, index) => (
                            <div className={styles['input-array-item']} key={index}>
                                <Input
                                    bsSize={'sm'}
                                    type={'text'}
                                    name={'emails'}
                                    value={email}
                                    onChange={(e) => handleArrayInput(e, index)}
                                    placeholder={'Email'}
                                />
                                {index !== 0 ? (
                                    <i
                                        className={'fas fa-trash ' + styles['icon']}
                                        onClick={() => removeItem('emails', index)}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </FormGroup>
                </Col>
                <Col lg={4}>
                    <FormGroup>
                        <Label>
                            Telephones{' '}
                            <i
                                className={'fas fa-plus-circle ' + styles['icon']}
                                onClick={() => addInput('telephones')}
                            />
                        </Label>
                        {telephones.map((telephone, index) => (
                            <div className={styles['input-array-item']} key={index}>
                                <Input
                                    bsSize={'sm'}
                                    type={'text'}
                                    name={'telephones'}
                                    value={telephone}
                                    onChange={(e) => handleArrayInput(e, index)}
                                    placeholder={'Telephone'}
                                />
                                {index !== 0 ? (
                                    <i
                                        className={'fas fa-trash ' + styles['icon']}
                                        onClick={() => removeItem('telephones', index)}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col xs={12} sm={12} lg={8}>
                    <SpinnerButton isLoading={saveRequestIsSending} color={'primary'} type={'submit'} block>
                        Save seller
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
};

SellerForm.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    saveRequestIsSending: PropTypes.bool.isRequired,
    handleArrayInput: PropTypes.func.isRequired,
    addInput: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    actuallyLogoUri: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    emails: PropTypes.arrayOf(PropTypes.string).isRequired,
    telephones: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
    saveRequestIsSending: state.updateSellerRequest.isSending,
});

export default connect(mapStateToProps, null)(SellerForm);
