import HtmlHead from 'components/Common/HtmlHead';
import ModalContainer from 'components/Common/Modal';
import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { forgotModalActionType, hideForgotPasswordModal, showForgotPasswordModal } from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import { PropTypes } from 'utils/wrappers';
import './styles/ForgotPasswordModal.scss';
import { ReCaptchaWrapper } from 'components/Common/ReCaptcha';

const ForgotPasswordModal = ({
    isOpen,
    isSending,
    showForgotPasswordModal,
    hideForgotPasswordModal,
    email,
    onSubmit,
    handleInput,
    hasError,
    recaptchaRef,
}) => {
    const title = 'Forgotten Password';

    return (
        <React.Fragment>
            {isOpen ? <HtmlHead title={title} /> : null}
            <ModalContainer
                showModal={showForgotPasswordModal}
                additionalModalClass={'forgot-password-modal'}
                hideModal={hideForgotPasswordModal}
                isOpen={isOpen}
                modalUriName={generateModalUriParam(forgotModalActionType)}
                title={title}
                body={
                    <Container>
                        <Row>
                            <Col>
                                <Form onSubmit={onSubmit}>
                                    <FormGroup>
                                        <label>Enter account's email</label>
                                        <Input
                                            type={'text'}
                                            placeholder={'Email for recover'}
                                            name={'email'}
                                            value={email}
                                            onChange={handleInput}
                                            invalid={hasError('email')}
                                        />
                                    </FormGroup>
                                    <ReCaptchaWrapper
                                        recaptchaRef={recaptchaRef}
                                        handleGoogleRecaptcha={(val) =>
                                            handleInput({ target: { name: 'recaptcha', value: val } })
                                        }
                                    />
                                    <SpinnerButton
                                        color={'primary'}
                                        className={'submit'}
                                        type={'submit'}
                                        isLoading={isSending}
                                    >
                                        Send New Password
                                    </SpinnerButton>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                }
            />
        </React.Fragment>
    );
};

ForgotPasswordModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    showForgotPasswordModal: PropTypes.func.isRequired,
    hideForgotPasswordModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleInput: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.forgotPasswordModal.isOpen,
        isSending: state.requestPasswordReset.isSending,
    };
};

const mapDispatchToProps = {
    showForgotPasswordModal,
    hideForgotPasswordModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordModal);
