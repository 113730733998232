import React from 'react';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/FormListElements.module.scss';
import getAttachmentIconSrc from './helpers/getAttachmentIconSrc';

const UploadedFiles = ({ files, removeItem }) => {
    return isNullOrEmptyArray(files) ? null : (
        <div className={styles['items']}>
            {files.map((file, index) => (
                <div className={styles['item']} key={index}>
                    <div className={styles['item-no']}>
                        <img src={getAttachmentIconSrc(file.file.name)} alt={file.file.name} />
                    </div>
                    <div className={styles['item-data']}>
                        <div className={styles['item-description']}>{file.description}</div>
                        <div className={styles['item-title']}>{file.file.name}</div>
                    </div>
                    <div
                        className={styles['remove-btn']}
                        onClick={() => removeItem({ id: file.id, stateItem: 'uploadedFiles' })}
                    >
                        <i className={'fas fa-times'} />
                    </div>
                </div>
            ))}
        </div>
    );
};

UploadedFiles.propTypes = {
    files: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            file: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired, // file object
            description: PropTypes.string.isRequired,
        }),
    ).isRequired,
    removeItem: PropTypes.func.isRequired,
};

export default UploadedFiles;
