export const get_title = (isDesc = false) => {
    const [, path] = window.location.pathname.split('/');
    if (path === 'land-auctions') {
        window.location.href = '/lands-for-sale';
    }
    const cfg = {
        root: {
            title: 'Land for Sale, Cheap Land, Owner Financed Land – Landzilla.com',
            description:
                'Find deals on cheap land across the US. Pre-approved financing. Low Down Payments. We make owning land easy - Check out our available land for sale.',
        },
        'land-for-sale': {
            title: 'Land for sale – Vacant land, Rural Land, Residential Land - Landzilla.com',
            description:
                'View land deals across the US. We offer cheap land, vacant land, rural land, and residential land with owner financing - Own Land Now!',
        },
        'lands-for-sale': {
            title: 'Land for sale – Vacant land, Rural Land, Residential Land - Landzilla.com',
            description:
                'View land deals across the US. We offer cheap land, vacant land, rural land, and residential land with owner financing - Own Land Now!',
        },
        blog: {
            title: 'Landzilla Blog - Get Helpful Information and News about Buying Land',
            description:
                'Get helpful information, tips, and the latest news about buying, owning, and investing in land. Check out our latest articles - Learn More.',
        },
        'recreational-land': {
            title: 'Recreational Land for Sale - Buy Recreational Land in the US – Landzilla',
            description:
                'Check out our recreational land for sale. We offer great deals on recreational land. Pre-approved financing - Own Land Today',
        },
        'cheap-land-for-sale': {
            title: 'Owner Financed Land for Sale - Pre-Approved Financing - Landzilla.com',
            description:
                'See our cheap land for sale in the US. Find deals on cheap land with pre-approved financing. Low down payments - Own Land Today!',
        },
        'cheap-lands-for-sale': {
            title: 'Owner Financed Land for Sale - Pre-Approved Financing - Landzilla.com',
            description:
                'See our cheap land for sale in the US. Find deals on cheap land with pre-approved financing. Low down payments - Own Land Today!',
        },
        'owner-financed-land': {
            title: 'Owner Financed Land for Sale - Pre-Approved Financing - Landzilla.com',
            description:
                'Check out our Owner Financed Land across the US. Pre-Approved Financing - NO Credit Checks - Low Down Payments - Own Land Today!',
        },
        'residential-lots': {
            title: 'Residential Land for Sale, Vacant Land, Lots for Sale - Landzilla.com',
            description:
                'Find residential land for sale across the US. Pre-approved financing, Low Down Payments - Find great deals on residential and vacant land now.',
        },
        'rancheslarger-acreage': {
            title: 'Ranches for Sale – Cheap Ranch Land and Acreage – Landzilla.com',
            description:
                'Find cheap ranches and acreage for sale. Deal on larger acreage with owner financing. Pre-approved financing, low down payments – Own ranch land today',
        },
        faq: {
            title: 'Frequently Asked Questions – Landzilla.com',
            description: 'Landzilla Help Center - Find the answers to Frequently Asked Questions',
        },
    };
    const found = cfg[path] ? cfg[path] : cfg.root;
    return isDesc ? found.description : found.title;
};

export const get_description = () => get_title(true);
