import React, { Component } from 'react';
import topics from './data/topics';
import FAQ from './FAQ';
import './styles/FAQ.scss';

class FAQContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topics: [],
        };
    }

    jumpTo = (e, id) => {
        e.preventDefault();

        const element = document.getElementById(id);
        window.scrollTo(element.offsetLeft, element.offsetTop);
    };

    componentDidMount() {
        this.setState({
            topics: topics,
        });
    }

    render() {
        return <FAQ topics={this.state.topics} jumpTo={this.jumpTo} />;
    }
}

export default FAQContainer;
