import { convertToRaw } from 'draft-js';

export default class PrepareToStore {
    constructor({
        sellerId,
        isFeatured,
        propertyId,
        fpid,
        propertyType,
        saleType,
        purchasePrice,
        processingFee,
        financeTerm,
        rate,
        monthlyPayment,
        yearlyTaxes,
        title,
        desc,
        size,
        zoning,
        zoningDesc,
        roadAccess,
        categories,
        selectedUtilities,
        subdivisionName,
        subdivisionDues,
        importantLinks,
        uploadedFiles,
        uploadedImages,
        videos,
        fullAddressOrGeo,
        city,
        address,
        state,
        county,
        zip,
        apn,
    }) {
        this.sellerId = sellerId;
        this.isFeatured = isFeatured;
        this.propertyId = propertyId;
        this.fpid = fpid ?? '';
        this.propertyType = propertyType;
        this.saleType = saleType;
        this.purchasePrice = purchasePrice;
        this.processingFee = processingFee;
        this.financeTerm = financeTerm;
        this.rate = rate;
        this.monthlyPayment = monthlyPayment;
        this.yearlyTaxes = yearlyTaxes;
        this.title = title;
        this.desc = desc;
        this.size = size;
        this.zoning = zoning;
        this.zoningDesc = zoningDesc;
        this.roadAccess = roadAccess.toString(); // Invalid prop `roadAccess` of type
        this.categories = categories;
        this.selectedUtilities = selectedUtilities;
        this.subdivisionName = subdivisionName;
        this.subdivisionDues = subdivisionDues;
        this.importantLinks = importantLinks;
        this.videos = videos;
        this.uploadedFiles = uploadedFiles;
        this.uploadedImages = uploadedImages;
        this.fullAddressOrGeo = fullAddressOrGeo;
        this.city = city;
        this.address = address;
        this.state = state;
        this.county = county;
        this.zip = zip;
        this.apn = apn;
    }

    prepareGeolocation = () => {
        return this.fullAddressOrGeo.split(',');
    };

    prepareImages = () => {
        return this.uploadedImages.map((uploadedImage) => uploadedImage.image);
    };

    prepareDocs = () => {
        return this.uploadedFiles.map((uploadedFile) => uploadedFile.file);
    };

    prepareDocsDescriptions = () => {
        return this.uploadedFiles.map((uploadedFile) => uploadedFile.description);
    };

    prepareLinks = () => {
        return this.importantLinks.map((importantLink) => ({
            name: importantLink.link,
            description: importantLink.desc,
        }));
    };

    prepareVideos = () => {
        return this.videos.map((video) => ({ name: video.link, description: video.desc }));
    };

    prepareDesc = () => {
        return JSON.stringify(convertToRaw(this.desc.getCurrentContent()));
    };

    prepareFormData = () => {
        const [latitude, longitude] = this.prepareGeolocation();
        const formData = new FormData();
        formData.append('seller_id', this.sellerId);
        formData.append('is_featured', this.isFeatured ? '1' : '0');
        formData.append('apn', this.apn);
        formData.append('listing_id', this.propertyId);
        formData.append('fpid', this.fpid);
        formData.append('title', this.title);
        formData.append('description', this.prepareDesc());
        formData.append('zoning', this.zoning);
        formData.append('zoning_desc', this.zoningDesc);
        formData.append('property_type', this.propertyType);
        Array.from(this.categories ?? []).map((c) => formData.append('categories[]', c));
        Array.from(this.selectedUtilities ?? []).map((u) => formData.append('utilities[]', u));
        formData.append('acreage', this.size);
        formData.append('state', this.state);
        formData.append('city', this.city);
        formData.append('county', this.county);
        formData.append('zip', this.zip);
        formData.append('address', this.address);
        formData.append('road_access', this.roadAccess.toString());
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);
        // TODO: Refactor, looks confusing and inconsistent. "this.prepareImages(formData)" should be the final form
        this.prepareImages().map((image) => formData.append('image[]', image));
        this.prepareDocs().map((doc) => formData.append('doc[]', doc));
        this.prepareDocsDescriptions().map((doc) => formData.append('doc_description[]', doc));
        formData.append('sale_type', this.saleType);
        formData.append('monthly_payment', this.monthlyPayment);
        formData.append('price', this.purchasePrice);
        formData.append('processing_fee', this.processingFee);
        formData.append('financial_term', this.financeTerm);
        formData.append('percentage_rate', this.rate);
        formData.append('taxes', this.yearlyTaxes);
        formData.append('subdivision[name]', this.subdivisionName); // Obsolete, left for compatibility
        formData.append('subdivision[yearly_dues]', this.subdivisionDues); // Obsolete, left for compatibility
        formData.append('subdivision_name', this.subdivisionName); // instead if subdivision[name]
        formData.append('subdivision_yearly_dues', this.subdivisionDues); // instead if subdivision[yearly_dues]
        this.prepareLinks().forEach((link, index) => {
            formData.append('links[' + index + '][name]', link.name);
            formData.append('links[' + index + '][description]', link.description);
        });
        this.prepareVideos().forEach((video, index) => {
            formData.append('video[' + index + '][name]', video.name);
            formData.append('video[' + index + '][description]', video.description);
        });

        return formData;
    };
}
