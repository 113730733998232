import Title from 'components/Common/Title';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import {
    handlePropertyFormInputAction,
    onClickAddVideoAction,
    removeItemAction,
} from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';
import Videos from '../Videos';

const VideosFields = ({ onClickAddVideo, handleInput, removeItem, videoDesc, videoUrl, videos }) => {
    return (
        <Row form>
            <Col>
                <Title size={5} underline className={styles['form-title']}>
                    Videos
                </Title>
                <FormGroup>
                    <Label>{fields.urlField}</Label>
                    <Input
                        type={'text'}
                        placeholder={fields.urlField}
                        name={'videoUrl'}
                        value={videoUrl}
                        onChange={handleInput}
                        bsSize={bsSize}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>{fields.urlDesc}</Label>
                    <Input
                        type={'textarea'}
                        name={'videoDesc'}
                        placeholder={fields.urlDesc}
                        value={videoDesc}
                        onChange={handleInput}
                        bsSize={bsSize}
                    />
                </FormGroup>
                <Button type={'button'} color={'primary'} onClick={onClickAddVideo}>
                    Add video
                </Button>
                <Videos videos={videos} removeItem={removeItem} />
            </Col>
        </Row>
    );
};

VideosFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onClickAddVideo: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
    ).isRequired,
    videoUrl: PropTypes.string.isRequired,
    videoDesc: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
    onClickAddVideo: onClickAddVideoAction,
    removeItem: removeItemAction,
};

const mapStateToProps = (state) => ({
    videos: state.propertyForm.videos,
    videoUrl: state.propertyForm.videoUrl,
    videoDesc: state.propertyForm.videoDesc,
});

export default connect(mapStateToProps, mapDispatchToProps)(VideosFields);
