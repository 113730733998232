import 'common/styles/modules/page.scss';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './Disclaimer.module.scss';
import env from 'utils/functions/env';

class Disclaimer extends Component {
    render() {
        return (
            <Row>
                <Col>
                    <Title size={2} alignment={ALIGN_LEFT} withoutMarginTop={true}>
                        Disclaimer
                    </Title>
                    <p>
                        You acknowledge that nothing contained in our Website will constitute financial, investment,
                        legal, or real estate advice and that no professional relationship of any kind is created
                        between you and {env('NAME')} or our Members. You agree that you will not make any financial,
                        investment, legal, and/or other decision based in whole or in part on anything contained in our
                        Website or Services.
                    </p>
                    <p>
                        No content on our Website is intended to be an offer to sell nor a solicitation of offers to buy
                        real estate in states or jurisdictions where registration is required prior to any offer being
                        made or in any other jurisdiction where prohibited by law.
                    </p>
                    <p>
                        You understand that {env('NAME')} refers potential buyers to potential sellers only and will not
                        be representing you in any capacity as a buyer or a seller. And no content on our Website shall
                        be construed as an implied agency relationship. You assume full responsibility for the use of
                        the information on our Website and agree to comply with all applicable local, state, and federal
                        laws governing professional licensing, business practices, real estate sales, agency, referrals,
                        advertising and all other aspects of doing business in the United States, or any other
                        jurisdiction, is your sole responsibility.
                    </p>
                    <h5 className='small-header'>Assumption of Risk</h5>
                    <p>
                        By using our Website and/or buying our Products you represent that you understand and agree that
                        buying real estate can involve substantial risk and is not appropriate for all people. You
                        understand that neither {env('NAME')}, nor its officers managers, partners or agents are expert
                        in matters of surveying, engineering, structural, environmental or other physical conditions of
                        real property. You understand it is your duty and responsibility to engage in a thorough due
                        diligence investigation about any real estate you are interested in buying.
                    </p>
                    <p>Your due diligence includes the following:</p>
                    <ul>
                        <li>
                            research building feasibility with county and local government planning, building, and
                            zoning departments to make sure your intended use of the land and/or building plans are
                            permitted
                        </li>
                        <li>
                            research utilities to see if they exist and can be obtained on the property your interested
                            in buying
                        </li>
                        <li>to inspect the property your interested in buying</li>
                        <li>
                            to consult with a legal, tax, financial or other professional before buying any real estate
                        </li>
                        <li>having an independent legal review of your real estate purchase documents.</li>
                    </ul>
                    <p>
                        {env('NAME')} makes no warranties or guarantees about any third party we introduce to you,
                        including lenders, sellers of real property, developers of real property, property managers,
                        agents, and other product or service providers. You agree that you are solely responsible for
                        doing your own due diligence on all real property you are interested in buying. No content on
                        our Website, or communication from us will be interpreted as a substitute for any portion of
                        your due diligence process.
                    </p>
                    <h5 className='small-header'>Our Content</h5>
                    <p>
                        We do not represent or otherwise warrant that our Website will be error-free, or that we will
                        correct any errors. We do not represent or otherwise warrant that the information available on
                        or through our Website will be correct, accurate, timely, or otherwise reliable.
                        {env('NAME')} reserves the right at our sole discretion to change any content, software, and
                        other items used or contained in our Website or Services at any time without notice.
                    </p>
                </Col>
            </Row>
        );
    }
}

export default Disclaimer;
