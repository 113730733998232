import ACREAPI from 'utils/services/ACREAPI';

export default class ShowPhone {
    static prefix = () => {
        return 'land-for-sale/show-phone/';
    };

    static send = ({ listingId, sellerId, recaptcha }) => {
        return ACREAPI.guestInstance().post(this.prefix(), {
            listing_id: listingId,
            seller_id: sellerId,
            recaptcha: recaptcha,
        });
    };
}
