import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import UserConverter from 'domain/api2domain/UserConverter';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginAction } from 'redux/actions/authActions';
import { hideLoginModal } from 'redux/actions/modalsActions';
import { store } from 'redux/store';
import redirectTo from 'redux/utils/redirectTo';
import { setUserInSession } from 'utils/functions/session';
import history from 'utils/history';
import ACREAPI from 'utils/services/ACREAPI';

function* loginSaga(action) {
    try {
        yield put(loginAction.request());

        const response = yield call(ACREAPI.auth().login, action.payload);
        const token = response.data.token;

        let rawUserData = response.data.user;
        rawUserData.token = token;

        const converter = new UserConverter();
        const user = converter.toDomain(rawUserData);

        setUserInSession(user);
        store.dispatch(hideLoginModal());

        notifiSuccess({
            message: 'You are logged in',
        });

        yield put(loginAction.success({ token: token, ...response.data.user }));
        yield redirectTo(history.location);
    } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
            notifiFail({
                message: e.response.data.message,
            });
        } else {
            notifiFail({
                message: 'Login has been failed. Please contact with us to solve this problem.',
            });
        }

        console.log(e);

        yield put(loginAction.failure());
    } finally {
        yield put(loginAction.fulfill());
    }
}

export default function* loginSagaWatcher() {
    yield takeLatest(loginAction.TRIGGER, loginSaga);
}
