import { createRoutine } from 'redux-saga-routines';

export const SET_FILTER = 'SET_FILTER';
export const setFilter = (isChecked, name, value) => {
    return { type: SET_FILTER, payload: { name, isChecked, value } };
};

export const APPLY_FILTERS = 'APPLY_FILTERS';
export const applyFilters = () => {
    return { type: APPLY_FILTERS };
};

export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const clearFilters = () => {
    return { type: CLEAR_FILTERS };
};

export const SET_SORTING = 'SET_SORTING';
export const setSorting = (value) => {
    return { type: SET_SORTING, payload: value };
};

export const SET_SEARCH = 'SET_SEARCH';
export const setSearch = (value) => {
    return { type: SET_SEARCH, payload: value };
};

export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const setActivePage = (page) => {
    return { type: SET_ACTIVE_PAGE, payload: page };
};

export const FETCH_BACKEND_PROPERTIES_FILTERS = 'FETCH_BACKEND_PROPERTIES_FILTERS';
export const fetchBackendPropertiesFiltersAction = createRoutine(FETCH_BACKEND_PROPERTIES_FILTERS);
