import React from 'react';
import { PropTypes } from 'utils/wrappers';

const Image = ({ url, className, alternateText }) => {
    if (!url) {
        return null;
    }

    return <img src={url} className={className} alt={alternateText} />;
};

Image.propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    alternateText: PropTypes.string,
};

Image.defaultProps = {
    className: '',
    alternateText: '',
};

export default Image;
