import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchProfileAction, updateProfileAction } from 'redux/actions/userActions';
import ACREAPI from 'utils/services/ACREAPI';

function* updateProfileSaga(action) {
    try {
        yield put(updateProfileAction.request());

        const response = yield call(ACREAPI.users().updateProfile, action.payload);

        notifiSuccess({
            message: 'Profile has been changed',
        });

        yield put(updateProfileAction.success());
        yield put(fetchProfileAction.success(response.data.user));
    } catch (e) {
        const message = e.response && e.response.data ? e.response.data.message : 'Profile saving error';

        notifiFail({
            message: message,
        });

        yield put(updateProfileAction.failure());
    } finally {
        yield put(updateProfileAction.fulfill());
    }
}

export default function* updateProfileSagaWatcher() {
    yield takeLatest(updateProfileAction.trigger, updateProfileSaga);
}
