import { createRoutine } from 'redux-saga-routines';

const FETCH_ALL_USERS_ACTION_PREFIX = 'FETCH_ALL_USERS_ACTION';
export const fetchAllUsersAction = createRoutine(FETCH_ALL_USERS_ACTION_PREFIX);

const DELETE_USER_ACTION_PREFIX = 'DELETE_USER_ACTION';
export const deleteUserAction = createRoutine(DELETE_USER_ACTION_PREFIX);

const EXPORT_USERS_ACTION_PREFIX = 'EXPORT_USERS_ACTION';
export const exportUsersAction = createRoutine(EXPORT_USERS_ACTION_PREFIX);

const EDIT_USER_ACTION_PREFIX = 'EDIT_USER_ACTION';
export const editUserAction = createRoutine(EDIT_USER_ACTION_PREFIX);

const FETCH_USER_ACTION_PREFIX = 'FETCH_USER_ACTION';
export const fetchUserAction = createRoutine(FETCH_USER_ACTION_PREFIX);

const UPDATE_USER_ROLE_ACTION_PREFIX = 'UPDATE_USER_ROLE_ACTION';
export const updateUserRoleAction = createRoutine(UPDATE_USER_ROLE_ACTION_PREFIX);
