import React, { Component } from 'react';
import { PropTypes } from 'utils/wrappers';
import { store } from '../notifi';
import Notification from './Notification';

class NotificationContainer extends Component {
    constructor(props) {
        super(props);

        this.defaultDuration = 5000;
        this.removalDuration = 1500;

        this.state = {
            isVisible: true,
            duration: this.defaultDuration,
            timer: null,
        };
    }

    componentDidMount() {
        const { data } = this.props;

        // if duration is set, then start, else or equal 0 - will hide only by close icon click
        if (data.duration > 0 || data.duration === undefined) {
            this.setState(
                {
                    duration: data.duration ? data.duration : this.defaultDuration,
                },
                () => {
                    this.setState({
                        timer: setTimeout(() => {
                            this.hideNotification();
                        }, this.state.duration),
                    });
                },
            );
        }
    }

    hideNotification = () => {
        const { data } = this.props;

        this.setState(
            {
                isVisible: false,
            },
            () => {
                setTimeout(() => {
                    store.remove(data);

                    // callback from user at close time
                    if (data.onClose) {
                        data.onClose();
                    }
                }, this.removalDuration);
            },
        );
    };

    handleOnClick = (notiData) => {
        const { data } = this.props;

        if (data.onClick) {
            data.onClick(notiData);
        }
    };

    handleOnDismiss = (notiData) => {
        const { data } = this.props;

        this.setState({
            isVisible: false,
        });

        this.hideNotification();

        if (data.onDismiss) {
            data.onDismiss(notiData);
        }
    };

    render() {
        const { data } = this.props;

        return (
            <Notification
                isVisible={this.state.isVisible}
                data={data}
                handleOnClick={this.handleOnClick}
                handleOnDismiss={this.handleOnDismiss}
            />
        );
    }
}

NotificationContainer.propTypes = {
    data: PropTypes.shape({
        icon: PropTypes.string,
        styles: PropTypes.object,
        color: PropTypes.string,
        message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    }).isRequired,
};

export default NotificationContainer;
