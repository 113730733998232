import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import env from 'utils/functions/env';

const containerStyle = {
    width: '100%',
    height: '400px',
};
const id = 'google-map-location';

const convertGeoTextToPosition = (text) => {
    const filteredString = text.replace(/[^0-9.,-]/, '');
    const latLngChunk = filteredString.split(',', 2);

    if (2 !== latLngChunk.length || isNaN(latLngChunk[0]) || isNaN(latLngChunk[1])) {
        return { lat: 31.51073, lng: -96.4247 };
    }

    const lat = parseFloat(latLngChunk[0]);
    const lng = parseFloat(latLngChunk[1]);

    return { lat: lat, lng: lng };
};

function GoogleMapMarker({ fullAddressOrGeo, handlerChangeMarkerPosition }) {
    const position = convertGeoTextToPosition(fullAddressOrGeo);

    const { isLoaded } = useJsApiLoader({
        id: id,
        googleMapsApiKey: env('GOOGLE_MAPS_API_KEY'),
    });

    return isLoaded ? (
        <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={18} id={id}>
            <Marker
                draggable={true}
                position={position}
                onDragEnd={(e) => {
                    handlerChangeMarkerPosition(e.latLng.toJSON());
                }}
            />
        </GoogleMap>
    ) : (
        <></>
    );
}

export default GoogleMapMarker;
