import history from 'utils/history';
import { QueryString } from 'utils/wrappers';

/**
 * Return route or current location with params
 *
 * @param addParams
 * @param withActual
 * @param route
 */
export const routeWithQuery = (addParams, withActual = false, route = null) => {
    let params = QueryString.parse(window.location.search);

    if (withActual) {
        params = { ...params, ...addParams };
    } else {
        params = { ...addParams };
    }

    return (route ? route : history.location.pathname) + '?' + QueryString.stringify(params);
};
