import AcreSpinner from 'components/Common/AcreSpinner';
import Price from 'components/Common/Property/Price';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'reactstrap';
import {
    changePropertyStatusAction,
    exportAdminPropertiesAction,
    fetchAdminPropertiesAction,
    getRelatedPropertiesElementsAction,
    togglePropertyIsFeaturedAction,
} from 'redux/actions/propertiesActions';
import { STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { PAGINATION } from 'utils/constans/bootstrapTableChangeType';
import { handleInput } from 'utils/functions/inputHelpers';
import { route } from 'utils/functions/routesHelper';
import history from 'utils/history';
import { PropTypes } from 'utils/wrappers';
import Properties from './Properties';
import './Properties.scss';

class PropertiesContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.defaultStatistics = {
            phoneInquiries: 0,
            views: 0,
            statusChanges: [],
        };

        this.state = {
            selected: [],
            isAnswerDeleteModalOpen: false,
            isStatisticsModalOpen: false,
            statisticsModalStats: this.defaultStatistics,
            propertyIdToDelete: null,
            page: 1,
            filterState: '',
            filterCounty: '',
            filterSizeFrom: '',
            filterSizeTo: '',
            filterPriceFrom: '',
            filterPriceTo: '',
            filterStatus: '',
        };
    }

    componentDidMount() {
        this.props.getRelatedPropertiesElements();
        this.fetchProperties();
    }

    fetchProperties = () => {
        const {
            page,
            filterState,
            filterCounty,
            filterSizeFrom,
            filterSizeTo,
            filterPriceFrom,
            filterPriceTo,
            filterStatus,
        } = this.state;

        this.props.fetchProperties({
            page,
            filterState,
            filterCounty,
            filterSizeFrom,
            filterSizeTo,
            filterPriceFrom,
            filterPriceTo,
            filterStatus,
        });
    };

    onTableChange = (type, data) => {
        if (type === PAGINATION) {
            this.setState(
                {
                    page: data.page,
                },
                () => {
                    this.fetchProperties();
                },
            );
        }
    };

    onSelectAllRows = (isSelected, rows) => {
        const ids = rows.map((row) => row.id);

        if (isSelected) {
            this.setState(() => ({
                selected: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
            }));
        }
    };

    onSelectRow = (row, isSelected) => {
        if (isSelected) {
            this.setState(() => ({
                selected: [...this.state.selected, row.id],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((rowId) => rowId !== row.id),
            }));
        }
    };

    getSelectRowOptions = () => ({
        mode: 'checkbox',
        selected: this.state.selected,
        onSelect: this.onSelectRow,
        onSelectAll: this.onSelectAllRows,
    });

    getPaginationOptions = () => {
        const { propertiesPagination } = this.props;

        return {
            page: this.state.page,
            sizePerPage: propertiesPagination.per_page,
            totalSize: propertiesPagination.total,
            showTotal: true,
            hideSizePerPage: true,
        };
    };

    onEdit = (e, id) => {
        e.preventDefault();
        history.push(route(routes.panel.properties.edit, { id }));
    };

    onExport = (type) => {
        this.props.exportProperties({ type, ids: this.state.selected });
    };

    toggleAnswerDeleteModal = (e, id = null) => {
        this.setState({
            isAnswerDeleteModalOpen: !this.state.isAnswerDeleteModalOpen,
            propertyIdToDelete: id,
        });
    };

    toggleStatisticsModal = (e, stats) => {
        this.setState((prevState) => ({
            ...prevState,
            isStatisticsModalOpen: !prevState.isStatisticsModalOpen,
            statisticsModalStats: stats ? stats : this.defaultStatistics,
        }));
    };

    onChangeStatus = (e, row) => {
        this.props.changePropertyStatus({ id: row.id, statusId: e.target.value });
    };

    onToggleIsFeatured = (e, id) => {
        this.props.toggleIsFeatured({ id });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // update table after editing a property's status
        if (
            (prevProps.requestChangePropertyStatus.isSending !== this.props.requestChangePropertyStatus.isSending &&
                !this.props.requestChangePropertyStatus.isSending &&
                this.props.requestChangePropertyStatus.status === STATUS_SUCCESS) ||
            (prevProps.requestTogglePropertyIsFeatured.isSending !==
                this.props.requestTogglePropertyIsFeatured.isSending &&
                !this.props.requestTogglePropertyIsFeatured.isSending &&
                this.props.requestTogglePropertyIsFeatured.status === STATUS_SUCCESS)
        ) {
            this.fetchProperties();
        }
    }

    getColumns = () => [
        {
            dataField: 'title',
            text: 'Title',
        },
        {
            dataField: 'apn',
            text: 'APN',
        },
        {
            dataField: 'fpid',
            text: 'FPID',
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: (cell, row) => (
                <Form>
                    <Input
                        type={'select'}
                        bsSize={'sm'}
                        onChange={(e) => this.onChangeStatus(e, row)}
                        value={this.props.relatedPropertiesElements.listingStatuses[row.status]}
                    >
                        {Object.keys(this.props.relatedPropertiesElements.listingStatuses).map((key, i) => (
                            <option key={i} value={this.props.relatedPropertiesElements.listingStatuses[key]}>
                                {key}
                            </option>
                        ))}
                    </Input>
                </Form>
            ),
        },
        {
            dataField: 'geo.state',
            text: 'State',
        },
        {
            dataField: 'geo.county',
            text: 'County',
        },
        {
            dataField: 'geo.acreage',
            text: 'Size',
        },
        {
            dataField: 'price.price',
            text: 'Price',
            formatter: (cell, row) => {
                return (
                    <span>
                        <Price value={row.price ? row.price.price : 0} />
                    </span>
                );
            },
        },

        {
            dataField: 'seller.head',
            text: 'Seller',
        },

        {
            dataField: 'is_featured',
            text: 'Featured',
            formatter: (cell, row) => {
                return <span>{row.is_featured ? 'Yes' : 'No'}</span>;
            },
        },
        {
            isDummyField: true,
            dataField: 'dummy',
            text: '',
            formatter: (cell, row) => {
                const featuredIcon = row.is_featured ? 'fas' : 'far';
                const featuredTitle = row.is_featured ? 'Unset' : 'Set';

                return (
                    <div className='actions'>
                        <a
                            href='#'
                            onClick={(e) => this.onToggleIsFeatured(e, row.id)}
                            className='action'
                            title={featuredTitle + ' featured flag'}
                        >
                            <i className={featuredIcon + ' fa-star'} />
                        </a>
                        <a href='#' onClick={(e) => this.onEdit(e, row.id)} className='action' title={'Edit'}>
                            <i className={'fas fa-edit'} />
                        </a>
                        <a
                            href='#'
                            onClick={(e) => this.toggleStatisticsModal(e, row.stats)}
                            className='action'
                            title={'Listing statistics'}
                        >
                            <i className='fas fa-chart-bar' />
                        </a>
                        <a
                            href='#'
                            onClick={(e) => this.toggleAnswerDeleteModal(e, row.id)}
                            className='action'
                            title={'Delete'}
                        >
                            <i className={'fas fa-trash'} />
                        </a>
                    </div>
                );
            },
        },
    ];

    onApplyFilters = (e) => {
        e.preventDefault();

        this.setState(
            {
                page: 1,
            },
            () => {
                this.fetchProperties();
            },
        );
    };

    render() {
        const {
            filterState,
            filterCounty,
            filterSizeFrom,
            filterSizeTo,
            filterPriceFrom,
            filterPriceTo,
            filterStatus,
            isAnswerDeleteModalOpen,
            propertyIdToDelete,
            isStatisticsModalOpen,
            statisticsModalStats,
        } = this.state;

        const { isRelatedPropertiesElementsLoading } = this.props;

        return isRelatedPropertiesElementsLoading ? (
            <AcreSpinner wrapped />
        ) : (
            <Properties
                columns={this.getColumns()}
                selectRowOptions={this.getSelectRowOptions()}
                paginationOptions={this.getPaginationOptions()}
                onTableChange={this.onTableChange}
                onApplyFilters={this.onApplyFilters}
                onExport={this.onExport}
                filterState={filterState}
                filterCounty={filterCounty}
                filterSizeFrom={filterSizeFrom}
                filterSizeTo={filterSizeTo}
                filterPriceFrom={filterPriceFrom}
                filterPriceTo={filterPriceTo}
                filterStatus={filterStatus}
                isAnswerDeleteModalOpen={isAnswerDeleteModalOpen}
                propertyIdToDelete={propertyIdToDelete}
                handleInput={this.handleInput}
                toggleAnswerDeleteModal={this.toggleAnswerDeleteModal}
                fetchProperties={this.fetchProperties}
                statisticsModalToggle={this.toggleStatisticsModal}
                statisticsModalStats={statisticsModalStats}
                isStatisticsModalOpen={isStatisticsModalOpen}
            />
        );
    }
}

PropertiesContainer.propTypes = {
    propertiesPagination: PropTypes.shape({
        total: PropTypes.number,
        count: PropTypes.number,
        per_page: PropTypes.number,
        current_page: PropTypes.number,
        total_pages: PropTypes.number,
    }).isRequired,
    isRelatedPropertiesElementsLoading: PropTypes.bool.isRequired,
    fetchProperties: PropTypes.func.isRequired,
    getRelatedPropertiesElements: PropTypes.func.isRequired,
    exportProperties: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    propertiesPagination: state.adminProperties.pagination,
    isRelatedPropertiesElementsLoading: state.relatedPropertiesElements.isLoading,
    relatedPropertiesElements: state.relatedPropertiesElements.data,
    requestChangePropertyStatus: state.requestChangePropertyStatus,
    requestTogglePropertyIsFeatured: state.requestTogglePropertyIsFeatured,
});

const mapDispatchToProps = {
    fetchProperties: fetchAdminPropertiesAction,
    getRelatedPropertiesElements: getRelatedPropertiesElementsAction,
    exportProperties: exportAdminPropertiesAction,
    changePropertyStatus: changePropertyStatusAction,
    toggleIsFeatured: togglePropertyIsFeaturedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesContainer);
