import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { togglePropertyIsFeaturedAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* togglePropertyIsFeaturedSaga(action) {
    try {
        yield put(togglePropertyIsFeaturedAction.request());

        const response = yield call(ACREAPI.properties().toggleIsFeatured, action.payload);

        notifiSuccess({
            message: "Property's is featured flag has been changed",
        });

        yield put(togglePropertyIsFeaturedAction.success(response.data.result));
    } catch (e) {
        const message =
            e.response && e.response.data ? e.response.data.result : 'During changing is featured occurred error';

        notifiFail({
            message,
        });

        yield put(togglePropertyIsFeaturedAction.failure());
    } finally {
        yield put(togglePropertyIsFeaturedAction.fulfill());
    }
}

export default function* togglePropertyIsFeaturedSagaWatcher() {
    yield takeLatest(togglePropertyIsFeaturedAction.trigger, togglePropertyIsFeaturedSaga);
}
