import React from 'react';
import HtmlHead from 'components/Common/HtmlHead';
import { PropTypes } from 'utils/wrappers';
import { UCWords, parseQuery } from 'utils/functions/string';
import history from 'utils/history';
import env from 'utils/functions/env';
import { Helmet } from 'react-helmet';
import { breadcrumbJsonLD } from '../../../domain/seo/JsonLD/jsonld';

const Breadcrumbs = ({ children }) => {
    const [, one, two, three] = window.location.pathname.split('/');
    const x = one.match(/(lands?-for-sale|blog|faq)/);
    const y = one.match(/(cheap-lands?-for-sale|owner-financed-land|residential-lots|recreational-land)/);
    const z = one.match(/rancheslarger-acreage/);
    if (!x && !y && !z) return <></>;
    const Hndlr = (e) => {
        e.preventDefault();
        const pathname = e.target.getAttribute('href');
        if (window.location.pathname !== pathname) {
            history.push(pathname);
        }
    };
    const mKa = (a, t) => (
        <a onClick={Hndlr} key={t} href={a} title={a ? UCWords(t) : ''}>
            {UCWords(t)}
        </a>
    );
    const crumbs = [mKa('/', 'Home')];
    const lds = [{ name: env('NAME'), url: '/' }];
    if (z) {
        crumbs.push(mKa('/rancheslarger-acreage', 'Category Ranches/Larger Acreage'));
        lds.push({ name: 'Category Ranches/Larger Acreage', url: '/rancheslarger-acreage' });
    } else if (y) {
        crumbs.push(mKa('/' + one, 'Category ' + one));
        lds.push({ name: 'Category' + one, url: '/' + one });
    } else if (one === 'faq') {
        crumbs.push(mKa('/faq', 'FAQ'));
        lds.push({ name: 'FAQ', url: '/faq' });
    } else if (one === 'blog') {
        crumbs.push(mKa('/blog', 'Blog'));
        lds.push({ name: 'Blog', url: '/blog' });
        if (two) {
            crumbs.push(mKa('/blog/' + two, two));
            lds.push({ name: UCWords(two), url: '/blog' + two });
        }
    } else {
        crumbs.push(mKa('/lands-for-sale', 'Land for sale'));
        lds.push({ name: 'Land for sale', url: '/lands-for-sale' });
        if (two) {
            crumbs.push(mKa('/land-for-sale/' + two, two));
            lds.push({ name: UCWords(two), url: '/land-for-sale/' + two });
        }
        if (three) {
            crumbs.push(mKa(null, three));
            lds.push({ name: UCWords(three), url: window.location.pathname });
        } else if (window.location.search) {
            const q = parseQuery(window.location.search);
            if (q.search) {
                crumbs.push(mKa(null, 'Search results for "' + q.search + '"'));
                lds.push({
                    name: 'Search results for "' + q.search + '"',
                    url: '/lands-for-sale' + window.location.search,
                });
            }
        }
    }
    return (
        <React.Fragment>
            <HtmlHead />
            <Helmet>
                <script id='breadcrumbJsonLD' type='application/ld+json'>
                    {breadcrumbJsonLD(lds)}
                </script>
            </Helmet>
            <div className='breadcrumbs'>{crumbs}</div>
        </React.Fragment>
    );
};

Breadcrumbs.propTypes = {
    children: PropTypes.any,
};

export default Breadcrumbs;
