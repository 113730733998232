import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { sendOnePropertyContactMessageAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* onePropertyContactMessageSaga(action) {
    try {
        yield put(sendOnePropertyContactMessageAction.request());
        yield call(ACREAPI.properties().sendOnePropertyContactMessage, action.payload);

        notifiSuccess({
            message: 'Your message to seller has been sent',
        });

        yield put(sendOnePropertyContactMessageAction.success());
    } catch (e) {
        notifiFail({
            message: "Your message to seller hasn't been sent",
        });

        yield put(sendOnePropertyContactMessageAction.failure());
    } finally {
        yield put(sendOnePropertyContactMessageAction.fulfill());
    }
}

export default function* onePropertyContactMessageSagaWatcher() {
    yield takeLatest(sendOnePropertyContactMessageAction.trigger, onePropertyContactMessageSaga);
}
