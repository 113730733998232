import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchAllUsersAction } from 'redux/actions/usersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* fetchAllUsersSaga(action) {
    try {
        yield put(fetchAllUsersAction.request());

        const apiFunc = action.payload && action.payload.search ? ACREAPI.users().search : ACREAPI.users().index;
        const response = yield call(apiFunc, action.payload);

        yield put(fetchAllUsersAction.success(response.data));
    } catch (e) {
        notifiFail({
            message: 'Loading users has been failed',
        });

        yield put(fetchAllUsersAction.failure());
    } finally {
        yield put(fetchAllUsersAction.fulfill());
    }
}

export default function* fetchAllUsersSagaWatcher() {
    yield takeLatest(fetchAllUsersAction.trigger, fetchAllUsersSaga);
}
