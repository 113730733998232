import ACREAPI from 'utils/services/ACREAPI';

const ENDPOINT_URL_PREFIX = 'payment/';

export default class Payments {
    static index = ({ phrase = '', page = 1 }) => {
        return ACREAPI.authInstance().get(ENDPOINT_URL_PREFIX + '?page=' + page, {
            params: {
                phrase,
            },
        });
    };
}
