import Carousel from 'components/Common/Carousel';
import React, { Component } from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

class RecommendedArticles extends Component {
    render() {
        const { articles } = this.props;

        return articles.length > 0 ? (
            <React.Fragment>
                <Col className='d-sm-none'>
                    <Row>
                        <Carousel slides={articles} />
                    </Row>
                </Col>
                <Col className='d-none d-sm-block'>
                    <Row className='d-sm-flex justify-content-center'>
                        {articles.map((article) => {
                            return article;
                        })}
                    </Row>
                </Col>
            </React.Fragment>
        ) : (
            <Alert color={'info'}>No recommended articles found</Alert>
        );
    }
}

RecommendedArticles.propTypes = {
    articles: PropTypes.array.isRequired,
};

export default RecommendedArticles;
