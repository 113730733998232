import React from 'react';
import { Container } from 'reactstrap';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from './PageWrapper.module.scss';
import Breadcrumbs from './Breadcrumbs';

const PageWrapper = ({ className, children, breadcrumbs }) => {
    if (!children) {
        return null;
    }
    const cssClass = joinNotEmpty([classes['page-wrapper'], className], ' ');
    return (
        <Container className={cssClass}>
            <Breadcrumbs children={breadcrumbs} />
            {children}
        </Container>
    );
};

PageWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
    breadcrumbs: PropTypes.any,
};

export default PageWrapper;
