import { EditorState } from 'draft-js';
import {
    CLEAR_PROPERTY_FORM_ACTION,
    FILL_PROPERTY_FORM_ACTION,
    HANDLE_CHANGE_DESC_ACTION,
    HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION,
    HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION,
    HANDLE_CLICK_ADD_VIDEO_ACTION,
    HANDLE_CLICK_UTILITY_CHECKBOX_ACTION,
    HANDLE_CLICK_CATEGORY_CHECKBOX_ACTION,
    // HANDLE_FILE_CHANGE_ACTION,
    HANDLE_ON_CLICK_ADD_LINK_ACTION,
    HANDLE_PROPERTY_FORM_INPUT_ACTION,
    HANDLE_REMOVE_ITEM_ACTION,
    HANDLE_SET_ADDRESS_DATA_FROM_API_ACTION,
} from 'redux/actions/propertyFormActions';

export const defaultState = {
    sellerId: 0,
    propertyId: '',
    apn: '',
    isFeatured: false,
    propertyType: '0',
    saleType: 0,
    purchasePrice: '',
    processingFee: '',
    financeTerm: 0,
    financeTermYears: 0,
    financeTermMonths: 0,
    rate: '',
    monthlyPayment: '',
    yearlyTaxes: '',
    title: '',
    desc: EditorState.createEmpty(),
    size: '',
    zoning: '',
    zoningDesc: '',
    roadAccess: '0',
    selectedUtilities: [],
    subdivisionName: '',
    subdivisionDues: '',
    importantLinkUrl: '',
    importantLinkDesc: '',
    importantLinks: [],
    videoUrl: '',
    videoDesc: '',
    videos: [],
    uploadingFileInputValue: '',
    uploadedFile: null,
    uploadedFiles: [],
    fullAddressOrGeo: '',
    uploadedImages: [],
    city: '',
    address: '',
    state: '',
    county: '',
    zip: '',
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case HANDLE_PROPERTY_FORM_INPUT_ACTION:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case HANDLE_CHANGE_DESC_ACTION:
            return {
                ...state,
                desc: action.payload.desc,
            };
        case HANDLE_CHANGES_IN_UPLOADED_FILES_FILE_ACTION:
            return {
                ...state,
                uploadedFiles: action.payload,
                uploadedFile: null,
                uploadingFileInputValue: '',
            };
        case HANDLE_REMOVE_ITEM_ACTION:
            return {
                ...state,
                [action.payload.stateName]: action.payload.items,
            };
        // case HANDLE_FILE_CHANGE_ACTION:
        //     return {
        //         ...state,
        //         uploadingFileInputValue: action.payload.uploadingFileInputValue,
        //         uploadedFile: action.payload.uploadedFile,
        //     };
        case HANDLE_CHANGES_IN_UPLOADED_IMAGES_ACTION:
            return {
                ...state,
                uploadedImages: action.payload.uploadedImages,
            };
        case HANDLE_ON_CLICK_ADD_LINK_ACTION:
            return {
                ...state,
                importantLinks: action.payload,
                importantLinkUrl: '',
                importantLinkDesc: '',
            };
        case HANDLE_CLICK_CATEGORY_CHECKBOX_ACTION:
            return {
                ...state,
                categories: action.payload,
            };
        case HANDLE_CLICK_UTILITY_CHECKBOX_ACTION:
            return {
                ...state,
                selectedUtilities: action.payload,
            };
        case HANDLE_CLICK_ADD_VIDEO_ACTION:
            return {
                ...state,
                videos: action.payload,
                videoUrl: '',
                videoDesc: '',
            };
        case HANDLE_SET_ADDRESS_DATA_FROM_API_ACTION:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_PROPERTY_FORM_ACTION:
            return {
                ...defaultState,
                selectedUtilities: [],
                importantLinks: [],
                videos: [],
                uploadedFiles: [],
                uploadedImages: [],
            };
        case FILL_PROPERTY_FORM_ACTION:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return { ...state };
    }
};
