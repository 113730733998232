import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import routes from 'domain/routes';
import { call, put, takeLatest } from 'redux-saga/effects';
import { resetPasswordAction } from 'redux/actions/authActions';
import { loginModalActionType } from 'redux/actions/modalsActions';
import { generateModalUriParam } from 'redux/utils/modal';
import redirectTo from 'redux/utils/redirectTo';
import { routeWithQuery } from 'utils/functions/routeWithQuery';
import ACREAPI from 'utils/services/ACREAPI';

function* resetPasswordSaga(action) {
    try {
        yield put(resetPasswordAction.request());
        yield call(ACREAPI.auth().updatePassword, action.payload);

        notifiSuccess({
            message: 'Password changed',
        });

        const url = routeWithQuery({ [generateModalUriParam(loginModalActionType)]: 'show' }, false, routes.home);
        yield redirectTo(url);
        yield put(resetPasswordAction.success());
    } catch (e) {
        const message = e.response && e.response.data ? e.response.data.message : 'Setting new password error';

        notifiFail({
            message: message,
            duration: 5000,
        });

        yield put(resetPasswordAction.failure());
    } finally {
        yield put(resetPasswordAction.fulfill());
    }
}

export default function* resetPasswordSagaWatcher() {
    yield takeLatest(resetPasswordAction.trigger, resetPasswordSaga);
}
