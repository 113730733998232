import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { newsletterSubscribeAction } from 'redux/actions/newsletterActions';
import { handleInput } from 'utils/functions/inputHelpers';
import Newsletter from './Fragment/Newsletter';
import classes from './styles/NewsletterContainer.module.scss';
//import { DEFAULT_VALIDATOR_MESSAGES, recaptchaValidator } from 'utils/functions/validation';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import SocialMedia from 'components/Common/Newsletter/Fragment/SocialMedia';

// const recaptchaRef = React.createRef();

class NewsletterContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            socialMediaIcons: [],
            email: '',
            // recaptcha: '',
            errors: [],
        };
    }

    componentDidMount() {
        this.setState({
            socialMediaIcons: [
                {
                    name: 'fab fa-facebook-f',
                    url: 'https://www.facebook.com/',
                },
                {
                    name: 'fab fa-twitter',
                    url: 'https://twitter.com/',
                },
                {
                    name: 'fab fa-youtube',
                    url: 'https://www.youtube.com/',
                },
                {
                    name: 'fab fa-linkedin-in',
                    url: 'https://www.linkedin.com/',
                },
                {
                    name: 'fab fa-instagram',
                    url: 'https://www.instagram.com/',
                },
            ],
        });
    }

    onSubmitSubscribeForm = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors }, () => {
            if (this.state.errors.length > 0) {
                notifiFail({
                    message: errors,
                    title: 'Validation problems:',
                    duration: 0,
                });
                return;
            }

            this.props.subscribe({ email: this.state.email, recaptcha: this.state.recaptcha });
            this.setState({
                email: '',
                // recaptcha: '',
            });
            // recaptchaRef.current.reset();
        });
    };

    validate = () => {
        let errors = [];
        /*
        const { recaptcha } = this.state;
        if (!recaptchaValidator(recaptcha)) {
            errors.push({ field: 'recaptcha', message: DEFAULT_VALIDATOR_MESSAGES.recaptcha });
        }
        */
        return errors;
    };

    render() {
        return (
            <div className={'homepage-section ' + classes['newsletter-section']}>
                <Container>
                    <Row className={classes.wrapper}>
                        <Col className={classes['newsletter-box']} lg={6} xl={5}>
                            <Newsletter
                                email={this.state.email}
                                handleInput={this.handleInput}
                                onSubmitSubscribeForm={this.onSubmitSubscribeForm}
                                recaptchaRef={false}
                            />
                        </Col>
                        <Col className={classes['newsletter-box']} sm={10} md={10} lg={12} xl={2}></Col>
                        <Col className={classes['newsletter-box']} lg={6} xl={5}>
                            <SocialMedia icons={this.state.socialMediaIcons} />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapDispatchToProps = {
    subscribe: newsletterSubscribeAction,
};

export default connect(null, mapDispatchToProps)(NewsletterContainer);
