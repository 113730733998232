import axios from 'axios';
import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { convertFromRaw, EditorState } from 'draft-js';
import { store } from 'redux/store';
import generateRandomId from 'utils/functions/generateRandomId';

export default class PrepareToEdit {
    constructor(rawData) {
        this.rawData = rawData;
        this.numberMonthInYear = 12;
    }

    prepareDesc = (desc) => {
        try {
            desc = EditorState.createWithContent(convertFromRaw(JSON.parse(desc)));
        } catch (e) {
            desc = EditorState.createEmpty();
        }

        return desc;
    };

    static prepareDocs = (docs, addDocFunction) => {
        docs.forEach((doc) => {
            let blob = null;
            axios
                .get(doc.uri, {
                    responseType: 'blob',
                })
                .then((res) => {
                    blob = res.data;
                    const file = new File([blob], doc.name);

                    addDocFunction(file, doc.description);
                })
                .catch(() => {
                    notifiFail({
                        message: "Loading of property's files has been failed",
                    });
                });
        });
    };

    static prepareImages = (images, addImageFunction) => {
        images.forEach((image) => {
            let blob = null;
            axios
                .get(image.fullsize, {
                    responseType: 'blob',
                })
                .then((res) => {
                    blob = res.data;
                    const imageFile = new File([blob], image.name);

                    addImageFunction(imageFile);
                })
                .catch(() => {
                    notifiFail({
                        message: "Loading of property's images has been failed",
                    });
                });
        });
    };

    getFinanceTermMonths = (financeTerm) => {
        return financeTerm % this.numberMonthInYear;
    };

    getFinanceTermYears = (financeTerm) => {
        return (financeTerm - this.getFinanceTermMonths(financeTerm)) / this.numberMonthInYear;
    };

    prepareForForm = () => {
        const relatedPropertiesElements = store.getState().relatedPropertiesElements.data;
        const propertyType = this.rawData.property_type
            ? relatedPropertiesElements.propertyTypes[this.rawData.property_type].toString()
            : '';
        const saleType =
            this.rawData.price && this.rawData.price.sale_type
                ? relatedPropertiesElements.saleTypes[this.rawData.price.sale_type]
                : '';
        const zoning = this.rawData.zoning ? relatedPropertiesElements.zonings[this.rawData.zoning].toString() : '';
        const roadAccess =
            this.rawData.geo && this.rawData.geo.road_access
                ? relatedPropertiesElements.roadAccess[this.rawData.geo.road_access].toString()
                : '';
        const financeTerm =
            this.rawData.price && this.rawData.price.financial_term ? this.rawData.price.financial_term : 0;
        const categories = this.rawData.categories ? Array.from(this.rawData.categories).map((o) => o.id) : [];
        return {
            isFeatured: this.rawData.is_featured ? 1 : 0,
            sellerId: this.rawData.seller ? this.rawData.seller.id : 0,
            propertyId: this.rawData.listing_id || '',
            fpid: this.rawData.fpid || '',
            apn: this.rawData.apn || '',
            propertyType,
            categories,
            saleType,
            purchasePrice: this.rawData.price && this.rawData.price.price ? this.rawData.price.price.toString() : '',
            processingFee:
                this.rawData.price && this.rawData.price.processing_fee
                    ? this.rawData.price.processing_fee.toString()
                    : '',
            financeTerm,
            financeTermMonths: this.getFinanceTermMonths(financeTerm),
            financeTermYears: this.getFinanceTermYears(financeTerm),
            rate:
                this.rawData.price && this.rawData.price.percentage_rate
                    ? this.rawData.price.percentage_rate.toString()
                    : '',
            monthlyPayment:
                this.rawData.price && this.rawData.price.monthly_payment
                    ? this.rawData.price.monthly_payment.toString()
                    : '',
            yearlyTaxes: this.rawData.price && this.rawData.price.taxes ? this.rawData.price.taxes.toString() : '',
            title: this.rawData.title || '',
            desc: this.prepareDesc(this.rawData.description),
            size: this.rawData.geo && this.rawData.geo.acreage ? this.rawData.geo.acreage.toString() : '',
            zoning,
            zoningDesc: this.rawData.zoning_desc || '',
            roadAccess,
            selectedUtilities: this.rawData.utilities ? this.rawData.utilities.map((utility) => utility.id) : [],
            subdivisionName: this.rawData.subdivision ? this.rawData.subdivision.name : '',
            subdivisionDues:
                this.rawData.subdivision && this.rawData.subdivision.yearly_dues
                    ? this.rawData.subdivision.yearly_dues.toString()
                    : '',
            importantLinks: this.rawData.links
                ? this.rawData.links.map((link) => ({
                      link: link.name,
                      desc: link.desc,
                      id: generateRandomId(),
                  }))
                : [],
            importantLinkUrl: '',
            importantLinkDesc: '',
            videos: this.rawData.videos
                ? this.rawData.videos.map((video) => ({
                      link: video.name,
                      desc: video.desc,
                      id: generateRandomId(),
                  }))
                : [],
            videoDesc: '',
            videoUrl: '',
            uploadingFileInputValue: '',
            uploadedFiles: [],
            uploadedImages: [],
            fullAddressOrGeo:
                this.rawData.geo && this.rawData.geo.latitude && this.rawData.geo.longitude
                    ? this.rawData.geo.latitude + ',' + this.rawData.geo.longitude
                    : '',
            city: this.rawData.geo && this.rawData.geo.city ? this.rawData.geo.city : '',
            address: this.rawData.geo && this.rawData.geo.address ? this.rawData.geo.address : '',
            state: this.rawData.geo && this.rawData.geo.state ? this.rawData.geo.state : '',
            county: this.rawData.geo && this.rawData.geo.county ? this.rawData.geo.county : '',
            zip: this.rawData.geo && this.rawData.geo.zip ? this.rawData.geo.zip : '',
        };
    };
}
