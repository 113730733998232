import { ALIGN_CENTER, ALIGN_LEFT, ALIGN_RIGHT } from 'domain/constants/alignment';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const OneColumnWrapper = ({ children, containerClassName, alignment, withContainer }) => {
    if (!children) {
        return null;
    }

    const cssClass = 'text-' + alignment;

    const column = (
        <Row>
            <Col className={cssClass}>{children}</Col>
        </Row>
    );

    if (withContainer) {
        return <Container className={containerClassName}>{column}</Container>;
    }

    return column;
};

OneColumnWrapper.propTypes = {
    children: PropTypes.element,
    containerClassName: PropTypes.string,
    alignment: PropTypes.oneOf([ALIGN_LEFT, ALIGN_RIGHT, ALIGN_CENTER]),
    withContainer: PropTypes.bool,
};

OneColumnWrapper.defaultProps = {
    alignment: ALIGN_LEFT,
    withContainer: true,
};

export default OneColumnWrapper;
