import Title from 'components/Common/Title';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { handlePropertyFormInputAction, onDescChangeAction } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ContentFields = ({ handleInput, onDescChange, title, desc }) => {
    return (
        <React.Fragment>
            <Row form>
                <Col>
                    <Title size={5} underline className={styles['form-title']}>
                        Content
                    </Title>
                </Col>
            </Row>
            <Row form>
                <Col>
                    <FormGroup>
                        <Label>{fields.title}</Label>
                        <Input
                            type={'text'}
                            name={'title'}
                            placeholder={fields.title}
                            value={title}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.desc}</Label>
                        <Editor
                            editorState={desc}
                            name={'desc'}
                            onEditorStateChange={onDescChange}
                            wrapperClassName={styles['desc-editor-wrapper']}
                            editorClassName={styles['editor-field']}
                            toolbar={{
                                options: [
                                    'inline',
                                    'blockType',
                                    'fontSize',
                                    'fontFamily',
                                    'list',
                                    'textAlign',
                                    'colorPicker',
                                    'link',
                                    'emoji',
                                    'image',
                                    'remove',
                                    'history',
                                ],
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

ContentFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onDescChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
    onDescChange: onDescChangeAction,
};

const mapStateToProps = (state) => ({
    title: state.propertyForm.title,
    desc: state.propertyForm.desc,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentFields);
