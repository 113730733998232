import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import UserConverter from 'domain/api2domain/UserConverter';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginSocialAction } from 'redux/actions/authActions';
import { hideLoginModal, hideRegistrationModal } from 'redux/actions/modalsActions';
import { store } from 'redux/store';
import { setUserInSession } from 'utils/functions/session';
import ACREAPI from 'utils/services/ACREAPI';

function* loginSocialSaga(action) {
    try {
        yield put(loginSocialAction.request());

        const response = yield call(ACREAPI.auth().loginBySocialMedia, action.payload);
        const token = response.data.result.token;

        let rawUserData = response.data.result.user;
        rawUserData.token = token;

        const converter = new UserConverter();
        const user = converter.toDomain(rawUserData);

        setUserInSession(user);

        store.dispatch(hideLoginModal());
        store.dispatch(hideRegistrationModal());

        yield put(loginSocialAction.success({ token: token, ...rawUserData }));

        notifiSuccess({
            message: 'You are logged in',
        });
    } catch (e) {
        if (e.response && e.response.data && e.response.data.result) {
            notifiFail({
                message: e.response.data.result,
            });
        } else {
            notifiFail({
                message: 'Cannot login',
            });
        }

        yield put(loginSocialAction.failure());
    } finally {
        yield put(loginSocialAction.fulfill());
    }
}

export default function* loginSocialSagaWatcher() {
    yield takeLatest(loginSocialAction.trigger, loginSocialSaga);
}
