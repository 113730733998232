import PriceClass from 'domain/property/Price';
import { PropTypes } from 'utils/wrappers';

const Price = ({ value, withFraction }) => {
    const price = new PriceClass(value);

    if (withFraction) {
        return price.formatWithFraction();
    }

    return price.format();
};

Price.propTypes = {
    value: PropTypes.number,
    withFraction: PropTypes.bool,
};

Price.defaultProps = {
    withFraction: false,
};

export default Price;
