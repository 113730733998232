import noImage from 'common/images/no-image.svg';
import React from 'react';
import classes from './styles/NoImages.module.scss';

const NoImages = () => {
    return (
        <div className={classes['no-image']}>
            <img src={noImage} alt='' />
        </div>
    );
};

export default NoImages;
