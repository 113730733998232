import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { editUserAction } from 'redux/actions/usersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* editUserSaga(action) {
    try {
        yield put(editUserAction.request());

        yield call(ACREAPI.users().edit, action.payload);

        notifiSuccess({
            message: 'User has been updated',
        });

        yield put(editUserAction.success());
    } catch (e) {
        const message = e.response && e.response.data ? e.response.data.message : "User's update has been failed";

        notifiFail({
            message: message,
        });

        yield put(editUserAction.failure());
    } finally {
        yield put(editUserAction.fulfill());
    }
}

export default function* editUserSagaWatcher() {
    yield takeLatest(editUserAction.trigger, editUserSaga);
}
