import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchAllSellersAction } from 'redux/actions/sellersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* sellersSaga(action) {
    try {
        yield put(fetchAllSellersAction.request());

        const response = yield call(ACREAPI.sellers().index, action.payload);

        yield put(fetchAllSellersAction.success(response.data));
    } catch (e) {
        yield put(fetchAllSellersAction.failure());
    } finally {
        yield put(fetchAllSellersAction.fulfill());
    }
}

export default function* sellersSagaWatcher() {
    yield takeLatest(fetchAllSellersAction.trigger, sellersSaga);
}
