import ArticleCard from 'components/Common/ArticleCard';
import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

class Articles extends Component {
    render() {
        const { articles } = this.props;

        return articles.length > 0 ? (
            articles.map((article) => <ArticleCard key={article.id} article={article} />)
        ) : (
            <Alert color={'info'}>No articles found</Alert>
        );
    }
}

Articles.propTypes = {
    articles: PropTypes.array.isRequired,
};

export default Articles;
