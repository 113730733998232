import React, { Component } from 'react';
import { PropTypes } from 'utils/wrappers';
import FilterBox from './FilterBox';

class FilterBoxContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClosed: this.props.isClosed,
            checked: 0,
        };
    }

    toggleClosed = () => {
        this.setState({
            isClosed: !this.state.isClosed,
        });
    };
    checkedHandler = (num) => {
        this.setState({
            checked: num,
        });
    };
    render() {
        return (
            <FilterBox
                className={this.checked ? 'checked' : ''}
                {...this.props}
                isClosed={this.state.isClosed}
                toggleClosed={this.toggleClosed}
                checked={this.state.checked}
                checkedHandler={this.checkedHandler}
                filterSlug={this.props.filterSlug}
            />
        );
    }
}

FilterBoxContainer.propTypes = {
    filterSlug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
    isClosed: PropTypes.bool,
    checked: PropTypes.number,
};

export default FilterBoxContainer;
