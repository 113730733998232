import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import classes from '../styles/PopularCategory.module.scss';

const PopularCategory = ({ imagePath, name, url }) => {
    return (
        <Col className={classes['popular-category']} sm={6} xl={4}>
            <Link to={url} className={classes.image}>
                <img src={imagePath} alt={name} />
                <div className={classes.title}>{name}</div>
            </Link>
        </Col>
    );
};

PopularCategory.propTypes = {
    imagePath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default PopularCategory;
