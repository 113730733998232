import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { subscribeNewsletterReducer } from 'redux/reducers/subscribeNewsletterReducer';
import toggledPropertyIsFeaturedReducer from 'redux/reducers/toggledPropertyIsFeaturedReducer';
import {
    checkoutModalReducer,
    forgotPasswordModalReducer,
    loginModalReducer,
    registrationModalReducer,
} from './modalsReducers';
import panelSellerReducer from './panelSellerReducer';
import updatedSellerReducer from './updateSellerRequestReducer';
import accountActivationTokenReducer from './accountActivationTokenReducer';
import {
    adminArticleCreateActionReducer,
    adminArticleDeleteActionReducer,
    adminArticleNewReadActionReducer,
    adminArticlesFetchActionReducer,
    adminArticleUpdateActionReducer,
} from './adminArticleCrudReducer';
import adminPropertiesReducer from './adminPropertiesReducer';
import articlesReducer from './articlesReducer';
import backendPropertiesFiltersReducer from './backendPropertiesFiltersReducer';
import blogSliderReducer from './blogSliderReducer';
import changedPropertyStatusReducer from './changedPropertyStatusReducer';
import contactMessageReducer from './contactMessageReducer';
import deletePropertyReducer from './deletePropertyReducer';
import deleteUserReducer from './deleteUserReducer';
import editedUserReducer from './editedUserReducer';
import editUserReducer from './editUserReducer';
import favouritePropertiesReducer from './favouritePropertiesReducer';
import featuredArticlesReducer from './featuredArticlesReducer';
import featuredPropertiesReducer from './featuredPropertiesReducer';
import featuredPropertyCategoriesReducer from './featuredPropertyCategoriesReducer';
import fetchedPropertyAdminReducer from './fetchedPropertyAdminReducer';
import googleMapsAddressReducer from './googleMapsAddressReducer';
import isFavouritePropertyReducer from './isFavouritePropertyReducer';
import onePropertyContactMessageReducer from './onePropertyContactMessageReducer';
import onePropertyReducer from './onePropertyReducer';
import { paymentsReducer } from './paymentsReducers';
import profileReducer from './profileReducer';
import propertiesSearchParamsReducer from './propertiesSearchParamsReducer';
import propertyCategoriesReducer from './propertyCategoriesReducer';
import propertyFormReducer from './propertyFormReducer';
import relatedPropertiesElementsReducer from './relatedPropertiesElementsReducer';
import requestPasswordResetReducer from './requestPasswordResetReducer';
import requestUpdateUserRoleReducer from './requestUpdateUserRoleReducer';
import resetPasswordReducer from './resetPasswordReducer';
import rolesReducer from './rolesReducer';
import searchedPropertiesReducer from './searchedPropertiesReducer';
import sellerReducer from './sellerReducer';
import sellersReducer from './sellersReducer';
import showPhoneReducer from './showPhoneReducer';
import statesReducer from './statesReducer';
import storedPropertyReducer from './storedPropertyReducer';
import toggleIsFavouritePropertyReducer from './toggleIsFavouritePropertyReducer';
import updatedPropertyReducer from './updatedPropertyReducer';
import updateProfileReducer from './updateProfileReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import checkoutReducer from './checkoutReducer';

export default (history) =>
    combineReducers({
        router: connectRouter(history), // it has to be first - history router for connected-react-router
        loginModal: loginModalReducer,
        registrationModal: registrationModalReducer,
        forgotPasswordModal: forgotPasswordModalReducer,
        checkoutModal: checkoutModalReducer,
        user: userReducer,
        blogSlider: blogSliderReducer,
        articles: articlesReducer,
        contactMessage: contactMessageReducer,
        accountActivationToken: accountActivationTokenReducer,
        featuredArticles: featuredArticlesReducer,
        featuredProperties: featuredPropertiesReducer,
        searchedProperties: searchedPropertiesReducer,
        propertiesSearchParams: propertiesSearchParamsReducer,
        backendPropertiesFilters: backendPropertiesFiltersReducer,
        requestPasswordReset: requestPasswordResetReducer,
        resetedPassword: resetPasswordReducer,
        oneProperty: onePropertyReducer,
        onePropertyContactMessage: onePropertyContactMessageReducer,
        propertyCategories: propertyCategoriesReducer,
        featuredPropertyCategories: featuredPropertyCategoriesReducer,
        seller: sellerReducer,
        users: usersReducer,
        deletedUser: deleteUserReducer,
        requestEditUser: editUserReducer,
        editedUser: editedUserReducer,
        roles: rolesReducer,
        requestUpdateUserRole: requestUpdateUserRoleReducer,
        profile: profileReducer,
        requestUpdateProfile: updateProfileReducer,
        states: statesReducer,
        adminProperties: adminPropertiesReducer,
        adminArticles: adminArticlesFetchActionReducer,
        adminArticleNewRead: adminArticleNewReadActionReducer,
        adminArticleCreate: adminArticleCreateActionReducer,
        adminArticleUpdate: adminArticleUpdateActionReducer,
        adminArticleDelete: adminArticleDeleteActionReducer,
        relatedPropertiesElements: relatedPropertiesElementsReducer,
        updateSellerRequest: updatedSellerReducer,
        panelSeller: panelSellerReducer,
        deletedProperty: deletePropertyReducer,
        googleMapsAddress: googleMapsAddressReducer,
        sellers: sellersReducer,
        storedProperty: storedPropertyReducer,
        updatedProperty: updatedPropertyReducer,
        requestChangePropertyStatus: changedPropertyStatusReducer,
        requestTogglePropertyIsFeatured: toggledPropertyIsFeaturedReducer,
        propertyForm: propertyFormReducer,
        propertyAdmin: fetchedPropertyAdminReducer,
        toggleIsFavouriteProperty: toggleIsFavouritePropertyReducer,
        isFavouriteProperty: isFavouritePropertyReducer,
        payments: paymentsReducer,
        favouriteProperties: favouritePropertiesReducer,
        checkout: checkoutReducer,
        newsletterSubscription: subscribeNewsletterReducer,
        showPhone: showPhoneReducer,
    });
