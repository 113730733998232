import { fetchAllStatesAction } from 'redux/actions/statesActions';

const defaultState = {
    data: [],
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchAllStatesAction.REQUEST:
            return { ...state, areLoading: true };
        case fetchAllStatesAction.SUCCESS:
            return { ...state, data: action.payload };
        case fetchAllStatesAction.FULFILL:
            return { ...state, areLoading: false };
        default:
            return { ...state };
    }
};
