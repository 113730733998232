import Layout from 'components/Common/Layout';
import React from 'react';
import { Route } from 'react-router';
import { PropTypes } from 'utils/wrappers';

const LayoutRoute = ({ component: Component, title, pageWrapperClassName = '', withPageWrapper = true, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                document.getElementById('root').className = props.match.path.replace('/', ' ').trim();
                return (
                    <Layout title={title} withPageWrapper={withPageWrapper} pageWrapperClassName={pageWrapperClassName}>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
};

LayoutRoute.propTypes = {
    title: PropTypes.string,
    pageWrapperClassName: PropTypes.string,
    withPageWrapper: PropTypes.bool,
};

export default LayoutRoute;
