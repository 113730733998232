import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getFullAddressAction } from 'redux/actions/googleMapsActions';
import { handleSetAddressDataFromAPI } from 'redux/actions/propertyFormActions';
import GoogleMapsAPI from 'utils/services/GoogleMapsAPI/GoogleMapsAPI';

function* getFullAddressFromGoogleMapsSaga(action) {
    try {
        yield put(getFullAddressAction.request());
        const response = yield call(GoogleMapsAPI.reverse, action.payload);
        if (response.data && response.data.error_message) {
            notifiFail({
                message: response.data.error_message,
            });
        } else {
            yield put(getFullAddressAction.success(response.data));
            yield put(handleSetAddressDataFromAPI(response.data));
        }
    } catch (e) {
        notifiFail({
            message: 'Resolving location has been failed',
        });
        yield put(getFullAddressAction.failure());
    } finally {
        yield put(getFullAddressAction.fulfill());
    }
}

export default function* getFullAddressFromGoogleMapsSagaWatcher() {
    yield takeLatest(getFullAddressAction.trigger, getFullAddressFromGoogleMapsSaga);
}
