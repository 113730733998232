/**
 * Handle basic input
 * @param event
 */
export const handleInput = function (event) {
    this.setState({ [event.target.name]: event.target.value });
};

/**
 * Handle basic checkbox
 * @param event
 */
export const handleCheckbox = function (event) {
    this.setState({ [event.target.name]: !this.state[event.target.name] });
};
