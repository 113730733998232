const testimonials = [
    {
        opinion:
            'It was wonderful buying two properties from this website. It was convenient and the price was' +
            ' reasonable. They even finance you without a credit check.',
        client: 'Dante Pawa',
        location: 'San Antonio, Texas',
    },
    {
        opinion:
            "I bought my little slice of the forest from AcreAuctions. Don't think I could be much happier" +
            ' with the deal, the property, and my treatment by Robert and his company.',
        client: 'Jerry Keller',
        location: 'Alturas, CA',
    },
    {
        opinion:
            'We bought our land from Robert Tropp and we have had nothing but great experiences in all of our' +
            ' transactions with his company.',
        client: 'Floyd and Nancy Hilyard',
        location: 'Klamath Falls, Oregon',
    },
    {
        opinion:
            'I have been most impressed and pleased with the accuracy, courtesy, and speed of the purchase' +
            ' transaction.  I would highly recommend this company in the purchase any of their properties.',
        client: 'J. Whitener Hickory',
        location: 'NC',
    },
    {
        opinion: 'A great group of folks, real smooth, five stars, thank you!',
        client: 'Bob Hens',
        location: 'California',
    },
    {
        opinion: 'Very helpful. Property was as described. Recommended!',
        client: 'Kev C',
        location: 'Missouri',
    },
    {
        opinion: 'It has been a great experience doing business with this company thank you.',
        client: 'Sherrie',
        location: 'Oregon',
    },
    {
        opinion:
            'We purchased land in Missouri. It is almost paid for, and we intend to move there in a couple of' +
            ' years. It has been a pleasure doing business with this company.',
        client: 'Scott and Barbara Kleckner',
        location: 'Lake Havasu City Az.',
    },
    {
        opinion: 'Unbelievable just received deed today A+ super fast seller is best of the best',
        client: 'Ti Huber',
        location: 'Oregon',
    },
    {
        opinion:
            'The property I purchased is a great piece of real estate. I am very happy with the price and' +
            ' payments. It has been a pleasure dealing your professional staff.',
        client: 'Laura M.',
        location: 'El Monte, CA',
    },
];

export default testimonials;
