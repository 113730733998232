import SizeClass from 'domain/property/Size';
import { PropTypes } from 'utils/wrappers';

const Size = ({ value }) => {
    const size = new SizeClass(value);
    return size.format();
};

Size.propTypes = {
    value: PropTypes.number.isRequired,
};

export default Size;
