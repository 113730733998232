import AcreSpinner from 'components/Common/AcreSpinner';
import OneColumnWrapper from 'components/Common/OneColumnWrapper';
import Property from 'components/Page/Property/Property';
import PropertyConverter from 'domain/api2domain/PropertyConverter';
import { ALIGN_CENTER } from 'domain/constants/alignment';
import routes from 'domain/routes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { isFavouritePropertyAction, onePropertyAction } from 'redux/actions/propertiesActions';
import { isEmptyObject } from 'utils/functions/objects';
import { getRouteParameters } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import { showCheckoutModal } from 'redux/actions/modalsActions';
import { Helmet } from 'react-helmet';
import { productJsonLD, imageJsonLD, personJsonLD } from '../../../domain/seo/JsonLD/jsonld';
import { UCWords } from 'utils/functions/string';

class PropertyContainer extends Component {
    getPropertySlug = () => {
        const { slug } = getRouteParameters(routes.properties.one);
        return slug;
    };

    componentDidMount() {
        const slug = this.getPropertySlug();
        this.props.getProperty(slug);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // User is logged in already AND there is no information if property is his favourite?
        // Let's fetch this information
        if (
            prevProps.user.token !== this.props.user.token &&
            (this.props.property.is_favorite === null || this.props.property.is_favorite === undefined)
        ) {
            const slug = this.getPropertySlug();
            this.props.isFavouriteProperty(slug);
        }
    }

    render() {
        const { property, isLoading } = this.props;

        if (isLoading) {
            return (
                <OneColumnWrapper withContainer={false} alignment={ALIGN_CENTER}>
                    <AcreSpinner wrapped={false} />
                </OneColumnWrapper>
            );
        }

        if (!property || isEmptyObject(property)) {
            return (
                <OneColumnWrapper>
                    <Alert color={'info'} className='text-center'>
                        Sorry, property cannot be displayed. Try again later.
                    </Alert>
                </OneColumnWrapper>
            );
        }

        const converter = new PropertyConverter();
        const propertyPrepared = converter.toDomain(property);
        const product = productJsonLD(this.props.property);
        const gal = this.props.property && this.props.property.gallery;
        const t = UCWords(this.props.property.name ? this.props.property.name : this.props.property.slug);
        const d = UCWords(this.props.property.description);
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t}</title>
                    <meta name='description' content={d} />
                    <script id='productJsonLD' type='application/ld+json'>
                        {product}
                    </script>
                    <script id='personJsonLD' type='application/ld+json'>
                        {personJsonLD(propertyPrepared.seller)}
                    </script>
                    <script id='imageJsonLD' type='application/ld+json'>
                        {imageJsonLD(gal)}
                    </script>
                </Helmet>
                <Property
                    isLoading={isLoading}
                    property={propertyPrepared}
                    seller={propertyPrepared.seller}
                    onBuy={() => this.props.showCheckoutModal()}
                />
            </React.Fragment>
        );
    }
}

PropertyContainer.propTypes = {
    property: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getProperty: PropTypes.func.isRequired,
    isFavouriteProperty: PropTypes.func.isRequired,
    showCheckoutModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getProperty: onePropertyAction,
    isFavouriteProperty: isFavouritePropertyAction,
    showCheckoutModal,
};

const mapStateToProps = (state) => {
    return {
        property: state.oneProperty.propertyData,
        isLoading: state.oneProperty.isLoading,
        user: state.user,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyContainer);
