import { fetchBackendPropertiesFiltersAction } from 'redux/actions/propertiesSearchParamsActions';

const defaultState = {
    counties: [],
    states: [],
    propertyTypes: [],
    financing: [],
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchBackendPropertiesFiltersAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case fetchBackendPropertiesFiltersAction.SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case fetchBackendPropertiesFiltersAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        default:
            return {
                ...state,
            };
    }
};
