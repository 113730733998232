import SpinnerButton from 'components/Common/SpinnerButton/SpinnerButton';
import { states } from 'components/Page/Panel/data/states';
import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';

const fields = {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    password: 'Password',
    passwordConfirmation: 'Password confirmation',
    address: 'Address',
    addressSecond: 'Address second',
    city: 'City',
    state: 'State',
    zip: 'Zip code',
    phoneNumber: 'Phone number',
    faxNumber: 'Fax number',
    tollFreeNumber: 'Toll free number',
};

const UserEditingForm = ({
    zip,
    state,
    phoneNumber,
    faxNumber,
    email,
    firstName,
    lastName,
    password,
    address,
    tollFreeNumber,
    addressSecond,
    city,
    passwordConfirmation,
    handleInput,
    onSubmit,
}) => {
    return (
        <Form onSubmit={onSubmit}>
            <Row form>
                <Col lg={6}>
                    <FormGroup>
                        <Label>{fields.email}</Label>
                        <Input
                            bsSize={'sm'}
                            type='text'
                            onChange={handleInput}
                            name='email'
                            value={email}
                            placeholder={fields.email}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.firstName}</Label>
                        <Input
                            bsSize={'sm'}
                            type='text'
                            onChange={handleInput}
                            name='firstName'
                            value={firstName}
                            placeholder={fields.firstName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.lastName}</Label>
                        <Input
                            bsSize={'sm'}
                            type='text'
                            onChange={handleInput}
                            name='lastName'
                            value={lastName}
                            placeholder={fields.lastName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.password}</Label>
                        <Input
                            bsSize={'sm'}
                            type='password'
                            onChange={handleInput}
                            name='password'
                            value={password}
                            placeholder={fields.password}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.passwordConfirmation}</Label>
                        <Input
                            bsSize={'sm'}
                            type='password'
                            onChange={handleInput}
                            name='passwordConfirmation'
                            value={passwordConfirmation}
                            placeholder={fields.passwordConfirmation}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.phoneNumber}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'phoneNumber'}
                            value={phoneNumber}
                            onChange={handleInput}
                            placeholder={fields.phoneNumber}
                        />
                    </FormGroup>
                </Col>
                <Col lg={6}>
                    <FormGroup>
                        <Label>{fields.faxNumber}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'faxNumber'}
                            value={faxNumber}
                            onChange={handleInput}
                            placeholder={fields.faxNumber}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.tollFreeNumber}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'tollFreeNumber'}
                            value={tollFreeNumber}
                            onChange={handleInput}
                            placeholder={fields.tollFreeNumber}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.address}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'address'}
                            value={address}
                            onChange={handleInput}
                            placeholder={fields.address}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label hidden>{fields.addressSecond}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'addressSecond'}
                            value={addressSecond}
                            onChange={handleInput}
                            placeholder={fields.addressSecond}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.city}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'city'}
                            value={city}
                            onChange={handleInput}
                            placeholder={fields.city}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.state}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'select'}
                            name={'state'}
                            value={state}
                            onChange={handleInput}
                            placeholder={fields.state}
                        >
                            <option value={''}>State</option>
                            {states.map((val, i) => (
                                <option key={i} value={val}>
                                    {val}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.zip}</Label>
                        <Input
                            bsSize={'sm'}
                            type={'text'}
                            name={'zip'}
                            value={zip}
                            onChange={handleInput}
                            placeholder={fields.zip}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col lg={12}>
                    <SpinnerButton isLoading={false} color={'primary'} block>
                        Save
                    </SpinnerButton>
                </Col>
            </Row>
        </Form>
    );
};

UserEditingForm.propTypes = {
    handleInput: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default UserEditingForm;
