import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import Links from './Part/Links';
import classes from './styles/Footer.module.scss';
import ContactFormContainer from 'components/Common/Footer/Part/ContactForm/ContactFormContainer';

const Footer = ({ categories, states, linksColumns }) => {
    return (
        <footer>
            <Container>
                <Row>
                    <Links title={'Land by categories'} links={categories} />
                    <Links title={'Land states'} links={states} />
                    {linksColumns.map((column, index) => (
                        <Links key={index} title={column.title} links={column.links} />
                    ))}
                    <ContactFormContainer title='Contacts' />
                </Row>
            </Container>
            <Container fluid={true}>
                <Row>
                    <Col className={classes.copyright}>
                        {new Date().getFullYear()} © LANDZILLA.COM. ALL RIGHTS RESERVED.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

const linksShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

Footer.propTypes = {
    categories: PropTypes.arrayOf(linksShape).isRequired,
    states: PropTypes.arrayOf(linksShape).isRequired,
    linksColumns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            links: PropTypes.arrayOf(linksShape).isRequired,
        }),
    ).isRequired,
};

export default Footer;
