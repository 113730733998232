import React from 'react';
import { joinNotEmpty } from 'utils/functions/arrays';
import { PropTypes } from 'utils/wrappers';
import classes from './styles/Thumbnails.module.scss';

const Thumbnails = ({ areVisible, images, activeImageIndex, onThumbnailClick }) => {
    const MAX_VISIBLE_IMAGES_COUNT = 5;
    const withScrolling = images.length > MAX_VISIBLE_IMAGES_COUNT;
    const wrapperCssClasses = joinNotEmpty([classes.wrapper, withScrolling ? classes.scrolling : ''], ' ');

    return areVisible ? (
        <div className={classes.images}>
            <div className={wrapperCssClasses}>
                {images.map((image, index) => {
                    const imageUrl = image.url;
                    const imageRef = image.ref;

                    return (
                        <div
                            key={index}
                            className={getCssClass(index, activeImageIndex)}
                            onClick={() => onThumbnailClick(index)}
                            ref={imageRef}
                        >
                            <img src={imageUrl} alt='' />
                        </div>
                    );
                })}
            </div>
        </div>
    ) : null;
};

const getCssClass = (imageIndex, activeImageIndex) => {
    let result = classes.image;

    if (imageIndex === activeImageIndex) {
        result += ' ' + classes.active;
    }

    return result;
};

Thumbnails.propTypes = {
    areVisible: PropTypes.bool,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            ref: PropTypes.object.isRequired,
        }),
    ),
    activeImageIndex: PropTypes.number,
    onThumbnailClick: PropTypes.func,
};

Thumbnails.defaultProps = {
    areVisible: true,
    images: [],
    activeImageIndex: 0,
    onThumbnailClick: () => true,
};

export default Thumbnails;
