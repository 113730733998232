import Title from 'components/Common/Title';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import PopularCategory from './Fragment/PopularCategory';
import classes from './styles/PopularCategories.module.scss';

const PopularCategories = ({ categories }) => {
    if (categories === undefined || categories.length === 0) {
        return null;
    }

    return (
        <section className={'homepage-section ' + classes['popular-categories-section']}>
            <Container>
                <Row>
                    <Col>
                        <Title size={2}>Popular Categories</Title>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    {categories.map((category, index) => {
                        return (
                            <PopularCategory
                                key={index}
                                imagePath={category.imagePath}
                                name={category.name}
                                url={category.url}
                            />
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};

PopularCategories.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            imagePath: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }),
    ),
};

export default PopularCategories;
