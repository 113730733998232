import { takeLatest, put, call } from 'redux-saga/effects';
import { exportUsersAction } from 'redux/actions/usersActions';
import { download } from 'utils/functions/files';
import ACREAPI from 'utils/services/ACREAPI';

function* exportUsersSaga(action) {
    try {
        yield put(exportUsersAction.request());

        const response = yield call(ACREAPI.users().export, action.payload);

        download(response.data, 'users', action.payload.type);

        yield put(exportUsersAction.success());
    } catch (e) {
        yield put(exportUsersAction.failure());
    } finally {
        yield put(exportUsersAction.fulfill());
    }
}

export default function* exportUsersSagaWatcher() {
    yield takeLatest(exportUsersAction.trigger, exportUsersSaga);
}
