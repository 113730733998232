import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchFeaturedPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* featuredPropertyCategoriesSaga(action) {
    try {
        yield put(fetchFeaturedPropertyCategoriesAction.request()); // request

        const response = yield call(ACREAPI.propertyCategories().featured);

        yield put(fetchFeaturedPropertyCategoriesAction.success(response.data.result)); // success
    } catch (e) {
        yield put(fetchFeaturedPropertyCategoriesAction.failure()); // fail
    } finally {
        yield put(fetchFeaturedPropertyCategoriesAction.fulfill()); // fulfill
    }
}

export default function* featuredPropertyCategoriesSagaWatcher() {
    yield takeLatest(fetchFeaturedPropertyCategoriesAction.trigger, featuredPropertyCategoriesSaga);
}
