import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchAdminPropertiesAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* fetchAdminPropertiesSaga(action) {
    try {
        yield put(fetchAdminPropertiesAction.request());

        const response = yield call(ACREAPI.properties().adminIndex, action.payload);

        yield put(fetchAdminPropertiesAction.success(response.data));
    } catch (e) {
        yield put(fetchAdminPropertiesAction.failure());
    } finally {
        yield put(fetchAdminPropertiesAction.fulfill());
    }
}

export default function* fetchAdminPropertiesSagaWatcher() {
    yield takeLatest(fetchAdminPropertiesAction.trigger, fetchAdminPropertiesSaga);
}
