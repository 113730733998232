import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { push } from 'connected-react-router';
import { takeLatest, put, call } from 'redux-saga/effects';
import { updatePropertyAction } from 'redux/actions/propertiesActions';
import { clearPropertyFormAction } from 'redux/actions/propertyFormActions';
import ACREAPI from 'utils/services/ACREAPI';
import routes from 'domain/routes';

function* updatePropertySaga(action) {
    try {
        yield put(updatePropertyAction.request());

        yield call(ACREAPI.properties().update, action.payload);

        yield put(updatePropertyAction.success());
        yield put(push(routes.panel.properties.main));
        yield put(clearPropertyFormAction());

        notifiSuccess({
            message: 'Property has been updated',
        });
    } catch (e) {
        const message =
            e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : 'Saving of property has been failed';

        notifiFail({
            message,
        });

        yield put(updatePropertyAction.failure());
    } finally {
        yield put(updatePropertyAction.fulfill());
    }
}

export default function* updatePropertySagaWatcher() {
    yield takeLatest(updatePropertyAction.trigger, updatePropertySaga);
}
