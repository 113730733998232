import { fetchAllUsersAction } from 'redux/actions/usersActions';

const defaultState = {
    data: [],
    pagination: {},
    areLoading: true,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchAllUsersAction.REQUEST:
            return { ...state, areLoading: true };
        case fetchAllUsersAction.SUCCESS:
            return { ...state, data: action.payload.data, pagination: action.payload.pagination };
        case fetchAllUsersAction.FULFILL:
            return { ...state, areLoading: false };
        default:
            return { ...state };
    }
};
