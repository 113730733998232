export const isObject = (object) => {
    return typeof object === 'object' && object !== null && object !== undefined && !Array.isArray(object);
};

export const isEmptyObject = (object) => {
    return isObject(object) && Object.keys(object).length === 0;
};

export const isNullOrEmptyObject = (object) => {
    return object === null || object === undefined || isEmptyObject(object);
};
