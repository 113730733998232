import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

function* redirectTo(route) {
    if (route === null) {
        return;
    }

    yield put(push(route));
}

export default redirectTo;
