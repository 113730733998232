import React from 'react';

const YoutubeIframe = ({ videoId, className }) => {
    return (
        <iframe
            className={className}
            title={'youtube video'}
            width='100%'
            height={360}
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder='0'
        />
    );
};

export default YoutubeIframe;
