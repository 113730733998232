import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SearchFormContainer from './SearchForm';
import classes from './styles/Top.module.scss';

class Top extends Component {
    render() {
        return (
            <section className={classes['top-section']}>
                <Container>
                    <Row className={classes.title}>
                        <Col>
                            <h1 className={classes.text}>We Make Owning Land Easy</h1>
                        </Col>
                    </Row>
                    <Row className={classes.subtitle}>
                        <Col className={classes.text}>Find Land Deals and Owner Financed Land.</Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col sm={9} md={11} lg={8} className={'search'}>
                            <SearchFormContainer />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Top;
