import { notifiFail, notifiSuccess } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { updateSellerAction } from 'redux/actions/sellersActions';
import ACREAPI from 'utils/services/ACREAPI';

function* updateSellerSaga(action) {
    try {
        yield put(updateSellerAction.request());

        yield call(ACREAPI.sellers().update, action.payload);

        notifiSuccess({
            message: 'Your seller profile has been saved',
        });

        yield put(updateSellerAction.success());
    } catch (e) {
        const message =
            e.response && e.response.data ? e.response.data.message : 'Seller account saving has been failed';

        notifiFail({
            message: message,
        });

        yield put(updateSellerAction.failure());
    } finally {
        yield put(updateSellerAction.fulfill());
    }
}

export default function* updateSellerSagaWatcher() {
    yield takeLatest(updateSellerAction.trigger, updateSellerSaga);
}
