// TODO: write tests for it

/**
 *
 *
 * From object like {field: "value", field2: 123} function will return object of FormData class with these fields and
 * values. The function accepts file object too
 *
 * @param obj
 * @param form
 * @param namespace
 * @returns {*|FormData}
 */
export const objectToFormData = (obj, form, namespace) => {
    let formData = form || new FormData();
    let formKey;

    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File,
            // use recursivity.
            if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], formData, property);
            } else {
                // if it's a string or a File object
                formData.append(formKey, obj[property]);
            }
        }
    }

    return formData;
};
