import { STATUS_404 } from 'domain/constants/responseStatus';
import redirectToErrorPage from 'redux/utils/redirectToErrorPage';

function* handleResponseStatus(error) {
    const response = error.response;

    if (response !== undefined && response.status !== undefined && response.status === STATUS_404) {
        yield redirectToErrorPage(STATUS_404);
    }
}

export default handleResponseStatus;
