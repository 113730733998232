import React from 'react';
import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { Youtube } from 'utils/services/youtube';
import ImageFactory from './ImageFactory';
import YoutubeIframe from 'components/Page/Property/Part/Gallery/Fragment/YoutubeIframe';

class MediaFactory {
    static createMedias = (urls, className = '') => {
        if (isNullOrEmptyArray(urls)) {
            return null;
        }

        return urls.map((url, index) => {
            const videoId = Youtube.getVideoId(url || '');
            if (videoId) {
                return <YoutubeIframe key={index} videoId={videoId} className={className} />;
            } else {
                return ImageFactory.createImage(url, index, className);
            }
        });
    };
}

export default MediaFactory;
