import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchAllPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* propertyCategoriesSaga(action) {
    try {
        yield put(fetchAllPropertyCategoriesAction.request()); // request

        const response = yield call(ACREAPI.propertyCategories().index);

        yield put(fetchAllPropertyCategoriesAction.success(response.data.result)); // success
    } catch (e) {
        yield put(fetchAllPropertyCategoriesAction.failure()); // fail
    } finally {
        yield put(fetchAllPropertyCategoriesAction.fulfill()); // fulfill
    }
}

export default function* propertyCategoriesSagaWatcher() {
    yield takeLatest(fetchAllPropertyCategoriesAction.trigger, propertyCategoriesSaga);
}
