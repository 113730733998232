import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { call, put, takeLatest } from 'redux-saga/effects';
import { featuredPropertiesAction } from 'redux/actions/propertiesActions';
import ACREAPI from 'utils/services/ACREAPI';

function* featuredPropertiesSaga(action) {
    try {
        yield put(featuredPropertiesAction.request());
        const response = yield call(ACREAPI.properties().featured);
        yield put(featuredPropertiesAction.success(response.data.listings));
    } catch (error) {
        notifiFail({
            message: 'Loading of featured properties has failed',
            duration: 3000,
        });

        yield put(featuredPropertiesAction.failure());
    } finally {
        yield put(featuredPropertiesAction.fulfill());
    }
}

export default function* featuredPropertiesSagaWatcher() {
    yield takeLatest(featuredPropertiesAction.TRIGGER, featuredPropertiesSaga);
}
