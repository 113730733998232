import Carousel from 'components/Common/Carousel';
import Title from 'components/Common/Title';
import { LISTINGS } from 'domain/constants/saleType';
import routes from 'domain/routes';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import CtaButton from '../Common/CtaButton';
import FeaturedProperty from './Fragment/FeaturedProperty';

const FeaturedProperties = ({ properties }) => {
    if (properties === undefined || properties.length === 0) {
        return null;
    }

    const allPropertiesUrl = route(routes.properties.main, { saleType: LISTINGS });
    return (
        <section className='homepage-section featured-properties-section'>
            <Container>
                <Row>
                    <Col>
                        <Title size={2}>Featured Properties</Title>
                    </Col>
                </Row>
                <Row className='d-sm-none'>
                    <Col>
                        <Carousel slides={properties} />
                    </Col>
                </Row>
                <Row className='d-none d-sm-flex justify-content-center'>
                    {properties.map((property, index) => {
                        return (
                            <Col key={index} className='featured-property-wrapper' sm={6} lg={4} xl={4}>
                                {property}
                            </Col>
                        );
                    })}
                </Row>
                <CtaButton text='See all properties' url={allPropertiesUrl} />
            </Container>
        </section>
    );
};

FeaturedProperties.propTypes = {
    properties: PropTypes.arrayOf(FeaturedProperty).isRequired,
};

export default FeaturedProperties;
