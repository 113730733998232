import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPaymentsAction } from 'redux/actions/paymentsActions';
import { PAGINATION } from 'utils/constans/bootstrapTableChangeType';
import { handleInput } from 'utils/functions/inputHelpers';
import { PropTypes } from 'utils/wrappers';
import Payments from './Payments';

const NUM_ON_PAGE = 10;

class PaymentsContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);

        this.state = {
            phrase: '',
            page: 1,
        };
    }

    componentDidMount() {
        this.fetchPayments();
    }

    fetchPayments = () => {
        const { phrase, page } = this.state;

        this.props.fetchPayments({ phrase, page });
    };

    onSearch = (e) => {
        e.preventDefault();
        this.setState(
            {
                page: 1,
            },
            () => this.fetchPayments(),
        );
    };

    onTableChange = (type, data) => {
        if (type === PAGINATION) {
            this.setState(
                {
                    page: data.page,
                },
                () => {
                    this.fetchPayments();
                },
            );
        }
    };

    getPaginationOptions = () => {
        const { paymentsPagination } = this.props;

        return {
            page: this.state.page,
            sizePerPage: paymentsPagination ? paymentsPagination.per_page : NUM_ON_PAGE,
            totalSize: paymentsPagination ? paymentsPagination.total : 0,
            showTotal: true,
            hideSizePerPage: true,
        };
    };

    getColumns = () => [
        {
            dataField: 'status',
            text: 'Status',
        },
        {
            dataField: 'listing_id',
            text: 'Listing ID',
        },
        {
            dataField: 'buyer_first_name',
            text: 'First name',
        },
        {
            dataField: 'buyer_last_name',
            text: 'Last name',
        },
        {
            dataField: 'buyer_email',
            text: 'Email',
        },
        {
            dataField: 'buyer_phone',
            text: 'Phone',
        },
        {
            dataField: 'created_at',
            text: 'Date of payment',
        },
    ];

    render() {
        const { phrase } = this.state;

        return this.props.areLoadingPayments ? (
            <AcreSpinner wrapped />
        ) : (
            <Payments
                handleInput={this.handleInput}
                columns={this.getColumns()}
                phrase={phrase}
                paginationOptions={this.getPaginationOptions()}
                onSearch={this.onSearch}
                onTableChange={this.onTableChange}
            />
        );
    }
}

PaymentsContainer.propTypes = {
    areLoadingPayments: PropTypes.bool.isRequired,
    fetchPayments: PropTypes.func.isRequired,
    paymentsPagination: PropTypes.shape({
        total: PropTypes.number,
        count: PropTypes.number,
        per_page: PropTypes.number,
        current_page: PropTypes.number,
        total_pages: PropTypes.number,
    }),
};

const mapStateToProps = (state) => ({
    paymentsPagination: state.payments.pagination,
    areLoadingPayments: state.payments.isLoading,
});

const mapDispatchToProps = {
    fetchPayments: fetchPaymentsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsContainer);
