import AcreSpinner from 'components/Common/AcreSpinner';
import PrepareToStore from 'domain/property/PrepareToStore';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getRelatedPropertiesElementsAction,
    storePropertyAction,
    updatePropertyAction,
} from 'redux/actions/propertiesActions';
import {
    addFileToUploadedAction,
    addImageToUploadedAction,
    clearPropertyFormAction,
    fillPropertyFormAction,
} from 'redux/actions/propertyFormActions';
import { fetchAllSellersAction } from 'redux/actions/sellersActions';
import { PropTypes } from 'utils/wrappers';
import PrepareToEdit from 'domain/property/PrepareToEdit';
import PropertyForm from './PropertyForm';

class PropertyFormContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filled: false,
        };
    }

    onSubmit = (e) => {
        e.preventDefault();
        const prepareToStore = new PrepareToStore(this.props.propertyFormData);
        const preparedData = prepareToStore.prepareFormData();
        if (this.props.id) {
            this.props.updateProperty({ id: this.props.id, data: preparedData });
        } else {
            this.props.storeProperty(preparedData);
        }
    };

    componentDidMount() {
        this.props.clearForm();
        this.props.getPropertiesElements();
        this.props.fetchAllSellers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // fill data if data all is ready
        if (
            !this.state.filled &&
            !this.props.propertiesRelatedElementsIsLoading &&
            !this.props.property.isLoading &&
            this.props.id
        ) {
            this.setState(
                {
                    filled: true, //
                },
                () => {
                    this.fillForm();
                },
            );
        }
    }

    fillForm = () => {
        const { gallery, docs } = this.props.property.data;
        const prepareToEdit = new PrepareToEdit(this.props.property.data);
        this.props.fillPropertyForm(prepareToEdit.prepareForForm());
        PrepareToEdit.prepareDocs(docs, this.props.addFileToUploaded);
        PrepareToEdit.prepareImages(gallery, this.props.addImageToUploaded);
    };

    render() {
        const {
            id,
            propertiesRelatedElementsIsLoading,
            sellersAreLoading,
            updatedPropertyIsLoading,
            storedPropertyIsLoading,
        } = this.props;
        const isSaving = id ? updatedPropertyIsLoading : storedPropertyIsLoading;

        return propertiesRelatedElementsIsLoading || sellersAreLoading ? (
            <AcreSpinner wrapped />
        ) : (
            <PropertyForm isSaving={isSaving} onSubmit={this.onSubmit} />
        );
    }
}

PropertyFormContainer.propTypes = {
    id: PropTypes.string,
    propertiesRelatedElementsIsLoading: PropTypes.bool.isRequired,
    sellersAreLoading: PropTypes.bool.isRequired,
    storedPropertyIsLoading: PropTypes.bool.isRequired,
    addImageToUploaded: PropTypes.func.isRequired,
    fillPropertyForm: PropTypes.func.isRequired,
    storeProperty: PropTypes.func.isRequired,
    fetchAllSellers: PropTypes.func.isRequired,
    getPropertiesElements: PropTypes.func.isRequired,
    addFileToUploaded: PropTypes.func.isRequired,
    updateProperty: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    propertiesRelatedElementsIsLoading: state.relatedPropertiesElements.isLoading,
    sellersAreLoading: state.sellers.isLoading,
    storedPropertyIsLoading: state.storedProperty.isSending,
    updatedPropertyIsLoading: state.updatedProperty.isSending,
    propertyFormData: state.propertyForm,
    property: state.propertyAdmin,
});

const mapDispatchToProps = {
    getPropertiesElements: getRelatedPropertiesElementsAction,
    fetchAllSellers: fetchAllSellersAction,
    storeProperty: storePropertyAction,
    updateProperty: updatePropertyAction,
    fillPropertyForm: fillPropertyFormAction,
    addImageToUploaded: addImageToUploadedAction,
    addFileToUploaded: addFileToUploadedAction,
    clearForm: clearPropertyFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyFormContainer);
