import Title from 'components/Common/Title';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { handlePropertyFormInputAction } from 'redux/actions/propertyFormActions';
import { PropTypes } from 'utils/wrappers';
import { bsSize, fields } from '../../data/fieldsProps';
import styles from '../../styles/PropertyForm.module.scss';
import intlConfig from 'configuration/i18n';

const FinancialFields = ({
    handleInput,
    saleType,
    propertiesRelatedElements,
    purchasePrice,
    processingFee,
    financeTerm,
    financeTermMonths,
    financeTermYears,
    yearlyTaxes,
    monthlyPayment,
    rate,
}) => {
    return (
        <React.Fragment>
            <Row form>
                <Col>
                    <Title size={5} underline className={styles['form-title']}>
                        Financial
                    </Title>
                </Col>
            </Row>
            <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label>{fields.purchasePrice}</Label>
                        <Input
                            type={'text'}
                            name={'purchasePrice'}
                            placeholder={fields.purchasePrice}
                            value={purchasePrice}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label>{fields.financeTermYears}</Label>
                                <Input
                                    type={'text'}
                                    name={'financeTermYears'}
                                    placeholder={fields.financeTermYears}
                                    value={financeTermYears}
                                    onChange={handleInput}
                                    bsSize={bsSize}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>{fields.financeTermMonths}</Label>
                                <Input
                                    type={'text'}
                                    name={'financeTermMonths'}
                                    placeholder={fields.financeTermMonths}
                                    value={financeTermMonths}
                                    onChange={handleInput}
                                    bsSize={bsSize}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>{fields.rate}</Label>
                        <Input
                            type={'text'}
                            name={'rate'}
                            placeholder={fields.rate}
                            value={rate}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.monthlyPayment}</Label>
                        <Input
                            type={'text'}
                            name={'monthlyPayment'}
                            placeholder={fields.monthlyPayment}
                            value={useIntl().formatNumber(monthlyPayment, intlConfig.defaultFormats.number.USD)}
                            onChange={handleInput}
                            bsSize={bsSize}
                            disabled
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>{fields.processingFee}</Label>
                        <Input
                            type={'text'}
                            name={'processingFee'}
                            placeholder={fields.processingFee}
                            value={processingFee}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.saleType}</Label>
                        <Input
                            type={'select'}
                            name={'saleType'}
                            placeholder={fields.saleType}
                            value={saleType}
                            onChange={handleInput}
                            bsSize={bsSize}
                        >
                            <option value={''}>-</option>
                            {Object.keys(propertiesRelatedElements.saleTypes).map((key, index) => (
                                <option value={propertiesRelatedElements.saleTypes[key]} key={index}>
                                    {key}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>{fields.yearlyTaxes}</Label>
                        <Input
                            type={'text'}
                            name={'yearlyTaxes'}
                            placeholder={fields.yearlyTaxes}
                            value={yearlyTaxes}
                            onChange={handleInput}
                            bsSize={bsSize}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>
    );
};

FinancialFields.propTypes = {
    handleInput: PropTypes.func.isRequired,
    saleType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    purchasePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    processingFee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    financeTerm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    financeTermMonths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    financeTermYears: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    yearlyTaxes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    monthlyPayment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    propertiesRelatedElements: PropTypes.shape({
        saleTypes: PropTypes.object, // elements from api database
    }),
};

const mapDispatchToProps = {
    handleInput: handlePropertyFormInputAction,
};

const mapStateToProps = (state) => ({
    saleType: state.propertyForm.saleType,
    purchasePrice: state.propertyForm.purchasePrice,
    processingFee: state.propertyForm.processingFee,
    financeTerm: state.propertyForm.financeTerm,
    financeTermMonths: state.propertyForm.financeTermMonths,
    financeTermYears: state.propertyForm.financeTermYears,
    yearlyTaxes: state.propertyForm.yearlyTaxes,
    monthlyPayment: state.propertyForm.monthlyPayment,
    rate: state.propertyForm.rate,
    propertiesRelatedElements: state.relatedPropertiesElements.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialFields);
