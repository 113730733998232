import { createRoutine } from 'redux-saga-routines';
import { removeSession } from 'utils/functions/session';
import history from 'utils/history';
import routes from '../../domain/routes';

const LOGIN_ACTION_PREFIX = 'LOGIN';
export const loginAction = createRoutine(LOGIN_ACTION_PREFIX);

const CONFIRM_ACCOUNT_PREFIX = 'CONFIRM_ACCOUNT';
export const activateAccountAction = createRoutine(CONFIRM_ACCOUNT_PREFIX);

const SEND_REQUEST_PASSWORD_RESET = 'SEND_REQUEST_PASSWORD_RESET';
export const sendRequestResetPasswordAction = createRoutine(SEND_REQUEST_PASSWORD_RESET);

const RESET_PASSWORD = 'RESET_PASSWORD';
export const resetPasswordAction = createRoutine(RESET_PASSWORD);

export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const logoutAction = () => {
    console.log('LOGOUT_ACTION dispatched!');
    removeSession();
    history.push(routes.home);
    return { type: LOGOUT_ACTION };
};

const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const loginSocialAction = createRoutine(SOCIAL_LOGIN);
