export default [
    {
        q: 'Do I qualify for financing?',
        a: 'Yes, at Landzilla we offer our own in-house financing and anyone with proof of income can qualify. Unlike bank financing there are no credit checks or lengthy contracts. Our agreement is simple and straight forward and your credit score is not an issue.',
    },
    {
        q: 'Are there any pre-payment penalties?',
        a: 'No, we allow you to pay off your balance at any time and there are no penalties for doing so. Most customers who choose longer terms usually pay off their balances much sooner and send larger payment per month to avoid interest charges. If you decide to cash out your balance early not only will you not be penalized you will receive a 5% discount on that balance.',
    },
    {
        q: 'Where is the land located?',
        a:
            'Each property details page has a Google map that can be used to locate the exact location of the property. There is a link below the Google map to open a larger map in a new window. If you click this link the map of the property will open on maps.google.com which will allow you the option to get directions as well as use all the features of Google maps. Additional maps of the property are also available on the details page including plat maps that show the properties dimensions.\n' +
            'Note: If you plan on visiting the property it is recommended that you contact us first in order to insure that you have all the necessary maps and details needed to access the property.',
    },
    {
        q: 'How to buy?',
        a:
            'First you need to follow the "Buy Now" link listed on the property\'s details page. After filling out your contact information an Landzilla representative will contact you and provide all the information you\'ll need to make a down payment and get an agreement started.\n' +
            'Once your down payment is made an agreement will be delivered to you. We can e-mail, fax, or fed-ex your agreement which you then will need sign and send back to our office.\n' +
            'Once we receive you signed agreement with your information you will be entered into our payment system and you will be informed on how to make your monthly payments (they also receive a receipted agreement).',
    },
    {
        q: 'How do I make my monthly payments if I decide to finance the property?',
        a:
            'You have two options:\n' +
            'You can make single payments each month or you can sign up for recurring billing where your monthly payments will be sent automatically.\n' +
            'If you decide to pay using the first option you will be sent a payment coupon booklet in which you can make single payments either by mail or by calling us and paying by credit card.\n' +
            'If you decide to pay using our reoccurring billing system you can use your credit or debit card to set up automatic monthly paymentsOther options include using PayPal to make your payments or setting up reoccurring billing through your bank or preferred payment processor.',
    },
    {
        q: 'What kind of agreement is used when financing land?',
        a: 'We use a simple contract for deed which simply says that the property title will be transferred into your name as soon as you make your final payment and your property is paid in full. Our agreement is very straightforward. A sample agreement for sale can be provided upon request.',
    },
    {
        q: 'When will I receive title to the property?',
        a: "As soon as your final payment is made and your property is paid in full we will begin transferring the title into your name. We'll send all the necessary paperwork to the county and wait for them to record title. This process can take anywhere from 2-6 weeks depending on the county.",
    },
    {
        q: 'Are the pictures I see on Landzilla of the actual property?',
        a: 'Yes, all the pictures that you see on the property details pages were taken on-site and are of the actual property unless otherwise stated in the description.',
    },
    {
        q: 'Do these properties have road access?',
        a: 'Yes, all the properties offered on Landzilla have legal road easements and road access. If you see pictures of the property that means that we have personally inspected the property and have been on-site.',
    },
    {
        q: 'Are there any unpaid taxes, dues, or liens on the properties?',
        a: 'No, all the properties that you see for sale on Landzilla have free and clear title. All taxes and dues are current and there are no existing liens on any of properties we have for sale.',
    },
    {
        q: 'How do I pay my taxes and dues?',
        a: 'We pay all taxes and dues then you reimburse us. If there is ever any question about the bill we can mail you a copy. We do not finance the tax and dues amount. They can be paid all at once or in increments (1/2 at a time). You have a full year to pay them once we have applied them to your account.',
    },
];
