import React from 'react';
import { FormattedDate } from 'react-intl';
import { PropTypes } from 'utils/wrappers';
import classes from './ArticleDate.module.scss';

const ArticleDate = ({ date }) => {
    return (
        <div className={classes['date-container']}>
            <i className={'far fa-clock ' + classes.icon} />
            <span className={classes.date}>
                <FormattedDate value={date} format='short' />
            </span>
        </div>
    );
};

ArticleDate.propTypes = {
    date: PropTypes.string.isRequired,
};

export default ArticleDate;
