import { isNullOrEmptyArray } from 'utils/functions/arrays';
import { isEmptyString } from 'utils/functions/miscellaneous';
import { getUserFromSession } from 'utils/functions/session';

class Article {
    constructor(
        id: Number,
        title: String,
        slug: String,
        content: String,
        description: String,
        allowComments: Boolean,
        allowSomethings: Boolean,
        images: Array,
    ) {
        this.id = typeof id === 'number' ? id : 0;
        this.title = typeof title === 'string' ? title : '';
        this.slug = typeof slug === 'string' ? slug : '';
        this.content = typeof content === 'string' ? content : '';
        this.description = typeof description === 'string' ? description : '';
        this.allowComments = !!allowComments;
        this.allowSomethings = !!allowSomethings;
        this.images = images instanceof Array ? images : [];
    }

    getSlug(): String {
        return this.slug;
    }

    getDescription(): String {
        return this.description;
    }

    getId(): String {
        return this.id;
    }

    getTitle(): String {
        return this.title;
    }

    getContent(): String {
        return this.content;
    }

    getAllowComments(): Boolean {
        return this.allowComments;
    }

    getAllowSomethings(): Boolean {
        return this.allowSomethings;
    }

    getImages(): Array {
        return this.images;
    }

    getMainImage(): ?String {
        const images = this.getImages();

        if (isNullOrEmptyArray(images) || !images[0]) {
            return null;
        }

        return images[0];
    }

    toString(): String {
        return this.getTitle();
    }

    toFormData(): FormData {
        const result = new FormData();

        const user = getUserFromSession();
        const images = this.getImages();
        const media = [];

        result.append('author_id', Number(user.id));
        result.append('title', this.getTitle());
        if (!isEmptyString(this.getSlug())) {
            result.append('slug', this.getSlug());
        }
        result.append('content', this.getContent());
        result.append('description', this.getDescription());
        result.append('allow_comments', Number(this.getAllowComments()));
        result.append('allow_somethings', Number(this.getAllowSomethings()));

        if (!isNullOrEmptyArray(images)) {
            images.map((image) => result.append('image[]', image));
        }

        if (!isNullOrEmptyArray(media)) {
            media.map((media) => result.append('media[]', media));
        }

        return result;
    }
}

export default Article;
