import 'common/styles/modules/page.scss';
import Title from 'components/Common/Title';
import { ALIGN_LEFT } from 'domain/constants/alignment';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import './styles/FAQ.scss';

const FAQ = ({ topics, jumpTo }) => {
    return (
        <React.Fragment>
            <span id={'top'} />
            <Row>
                <Col xs={12} className={'faq-container'}>
                    <Title size={2} alignment={ALIGN_LEFT} withoutMarginTop={true}>
                        FAQ
                    </Title>
                    <div className={'table-contents'}>
                        {topics.map((topic, i) => (
                            <p key={`contents-${i}`}>
                                <a href={'#' + i} onClick={(e) => jumpTo(e, i)}>
                                    {topic.q}
                                </a>
                            </p>
                        ))}
                    </div>

                    {topics.map((topic, i) => (
                        <div key={`topic-${i}`} className={'topic'}>
                            <p id={i} className={'header'}>
                                {topic.q}
                            </p>
                            <p className={'answer'}>{topic.a}</p>
                        </div>
                    ))}
                </Col>
            </Row>
            <a href={'#top'}>
                <div className={'floating-btn'}>
                    <i className='fas fa-arrow-up' />
                </div>
            </a>
        </React.Fragment>
    );
};

FAQ.propTypes = {
    jumpTo: PropTypes.func.isRequired,
    topics: PropTypes.arrayOf(
        PropTypes.shape({
            q: PropTypes.string.isRequired,
            a: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default FAQ;
