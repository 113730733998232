import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendContactMessageAction } from 'redux/actions/contactMessageActions';
import { STATUS_SUCCESS } from 'redux/utils/requestWithoutData';
import { handleInput } from 'utils/functions/inputHelpers';
//import { DEFAULT_VALIDATOR_MESSAGES, hasError, recaptchaValidator } from 'utils/functions/validation';
import { hasError } from 'utils/functions/validation';
import { PropTypes } from 'utils/wrappers';
import validator from 'validator';
import { phoneNumberRegex } from '../../../../../domain/constants/regexes';
import ContactForm from './ContactForm';

//const recaptchaRef = React.createRef();

class ContactFormContainer extends Component {
    constructor(props) {
        super(props);

        this.handleInput = handleInput.bind(this);
        this.hasError = hasError.bind(this);
        this.fieldsNames = {
            name: 'name',
            email: 'email',
            phoneNumber: 'phoneNumber',
            message: 'message',
            //recaptcha: 'recaptcha',
        };

        this.state = {
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
            errors: [],
            //recaptcha: '',
        };
    }
    /*
    handleGoogleRecaptcha = (val) => {
        this.setState({
            recaptcha: val,
        });
    };
    */
    handleSubmit = (e) => {
        e.preventDefault();

        const errors = this.validate();
        this.setState(
            {
                errors: errors,
            },
            () => {
                if (this.state.errors.length > 0) {
                    notifiFail({
                        message: this.state.errors,
                        duration: 0,
                        title: 'Sending problems:',
                    });

                    return;
                }

                this.props.sendContactMessage(this.state);
            },
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            if (this.props.contactMessage.status === STATUS_SUCCESS) {
                this.setState({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    message: '',
                    recaptcha: '',
                });
            }

            // recaptchaRef.current.reset();
        }
    }

    validate = () => {
        let errors = [];
        const { phoneNumber, email, name, message /*, recaptcha */ } = this.state;
        /*
        if (!recaptchaValidator(recaptcha)) {
            errors.push({ field: this.fieldsNames.recaptcha, message: DEFAULT_VALIDATOR_MESSAGES.recaptcha });
        }
        */
        if (!validator.isEmail(email)) {
            errors.push({ field: this.fieldsNames.email, message: 'Email is incorrect' });
        }

        if (!validator.isLength(email, { min: 1, max: 255 })) {
            errors.push({ field: this.fieldsNames.email, message: 'Email must be at least 1 character' });
        }

        if (!validator.isLength(name, { min: 2, max: 255 })) {
            errors.push({ field: this.fieldsNames.name, message: 'The name must be least 2 characters' });
        }

        if (!validator.isLength(phoneNumber, { min: 1 })) {
            errors.push({ field: this.fieldsNames.phoneNumber, message: 'The phone number is required' });
        }

        if (!validator.matches(phoneNumber, phoneNumberRegex)) {
            errors.push({
                field: this.fieldsNames.phoneNumber,
                message: 'The phone number has incorrect format, correct e.g. 112-345-6789',
            });
        }

        if (!validator.isLength(message, { min: 5 })) {
            errors.push({ field: this.fieldsNames.message, message: 'The message must be least 5 characters' });
        }

        return errors;
    };

    render() {
        return (
            <ContactForm
                title={this.props.title}
                handleSubmit={this.handleSubmit}
                handleGoogleRecaptcha={this.handleGoogleRecaptcha}
                handleInput={this.handleInput}
                hasError={this.hasError}
                fieldsNames={this.fieldsNames}
                {...this.state}
            />
        );
    }
}

ContactFormContainer.propTypes = {
    title: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        contactMessage: state.contactMessage,
    };
};

const mapDispatchToProps = {
    sendContactMessage: sendContactMessageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer);
