import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { PropTypes } from 'utils/wrappers';
import './AcreBootstrapTable.scss';

const AcreBootstrapTable = ({ noDataIndication, paginationOptions, ...props }) => {
    return (
        <BootstrapTable
            {...props}
            noDataIndication={noDataIndication}
            pagination={paginationFactory(paginationOptions)}
            overlay={overlayFactory({
                spinner: true,
            })}
            classes={'acre-bootstrap-table'}
            bootstrap4
        />
    );
};

AcreBootstrapTable.propTypes = {
    noDataIndication: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    paginationOptions: PropTypes.shape({
        custom: PropTypes.bool,
        page: PropTypes.number,
        sizePerPage: PropTypes.number,
        hideSizePerPage: PropTypes.bool,
        showTotal: PropTypes.bool,
        totalSize: PropTypes.number,
    }),
};

AcreBootstrapTable.defaultProps = {
    noDataIndication: 'No data to show',
    paginationOptions: {},
};

export default AcreBootstrapTable;
