const defaultState = {
    data: null,
    pagination: null,
    isLoading: true,
};

export const generateRequestWithDataReducer = (routineActionMethod, hasPagination = false) => {
    return (state = defaultState, action) => {
        switch (action.type) {
            case routineActionMethod.REQUEST:
                return { ...state, isLoading: true };
            case routineActionMethod.SUCCESS:
                return hasPagination
                    ? {
                          ...state,
                          data: action.payload.data,
                          pagination: action.payload.pagination,
                      }
                    : {
                          ...state,
                          data: action.payload,
                      };
            case routineActionMethod.FULFILL:
                return { ...state, isLoading: false };
            default:
                return { ...state };
        }
    };
};
