import { fetchFeaturedPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';

const defaultState = {
    data: [],
    areLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case fetchFeaturedPropertyCategoriesAction.REQUEST:
            return {
                ...state,
                areLoading: true,
            };
        case fetchFeaturedPropertyCategoriesAction.SUCCESS:
            return {
                ...state,
                data: action.payload,
            };
        case fetchFeaturedPropertyCategoriesAction.FULFILL:
            return {
                ...state,
                areLoading: false,
            };
        default:
            return { ...state };
    }
};
