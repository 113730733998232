import { modalActionFactory } from 'redux/utils/modal';

export const forgotModalActionType = 'FORGOT_PASSWORD';
export const showForgotPasswordModal = modalActionFactory('show', forgotModalActionType);
export const hideForgotPasswordModal = modalActionFactory('hide', forgotModalActionType);

export const registrationModalActionType = 'REGISTRATION';
export const showRegistrationModal = modalActionFactory('show', registrationModalActionType);
export const hideRegistrationModal = modalActionFactory('hide', registrationModalActionType);

export const loginModalActionType = 'LOGIN';
export const showLoginModal = modalActionFactory('show', loginModalActionType);
export const hideLoginModal = modalActionFactory('hide', loginModalActionType);

export const checkoutModalActionType = 'CHECKOUT';
export const showCheckoutModal = modalActionFactory('show', checkoutModalActionType);
export const hideCheckoutModal = modalActionFactory('hide', checkoutModalActionType);
