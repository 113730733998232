import noImage from 'common/images/no-image.svg';
import AcreCard from 'components/Common/AcreCard/AcreCard';
import ArticleDate from 'components/Common/ArticleDate/ArticleDate';
import ArticleConverter from 'domain/api2domain/ArticleConverter';
import routes from 'domain/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { route } from 'utils/functions/routesHelper';
import { PropTypes } from 'utils/wrappers';
import './ArticleCard.scss';

const ArticleCard = ({ article }) => {
    if (!article) {
        return null;
    }

    const articleConverted = ArticleConverter.toDomain(article);

    if (articleConverted === null) {
        return null;
    }

    const mainImage = articleConverted.getMainImage();
    const imagePath = mainImage !== null ? mainImage.getThumbnailUrl() : noImage;

    const showArticleUrl = route(routes.blog.article, { articleSlug: article.slug });
    const titleLink = <Link to={showArticleUrl}>{article.title}</Link>;

    // const beforeTitle = (
    //     <div className='before-title'>
    //         <Link to={''}>{article.category.name}</Link>
    //     </div>
    // );

    const footer = (
        <Link to={showArticleUrl}>
            <ArticleDate date={article.updated_at} />
            <div className='read-more'>
                <span className='text'>Read more</span>
                <i className='fas fa-arrow-right icon' />
            </div>
        </Link>
    );

    return (
        <Col className='article-card-wrapper' sm={6} lg={4}>
            <AcreCard
                imagePath={imagePath}
                imageUrl={showArticleUrl}
                // beforeTitle={beforeTitle}
                title={titleLink}
                className='article-card'
                footer={footer}
            />
        </Col>
    );
};

ArticleCard.propTypes = {
    article: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        updated_at: PropTypes.string.isRequired,
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                fullsize: PropTypes.string.isRequired,
                preview: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

export default ArticleCard;
