import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import { takeLatest, put, call } from 'redux-saga/effects';
import { fetchProfileAction } from 'redux/actions/userActions';
import ACREAPI from 'utils/services/ACREAPI';

function* profileSaga(action) {
    try {
        yield put(fetchProfileAction.request());

        const response = yield call(ACREAPI.users().me, action.payload);

        yield put(fetchProfileAction.success(response.data.user));
    } catch (e) {
        notifiFail({
            message: 'Loading of your profile has failed',
        });

        yield put(fetchProfileAction.failure());
    } finally {
        yield put(fetchProfileAction.fulfill());
    }
}

export default function* profileSagaWatcher() {
    yield takeLatest(fetchProfileAction.trigger, profileSaga);
}
