import React from 'react';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import SortableArticleImage from 'components/Page/Panel/Blog/Form/Part/SortableArticleImage';
import styles from 'components/Page/Panel/Blog/Form/styles/ArticleForm.module.scss';
import { Row } from 'reactstrap';

const SortableArticleImagesContainer = ({ images: items, onDragEnd, reorderUploadedImage, removeUploadedImage }) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
    );

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={items} strategy={rectSortingStrategy}>
                <Row className={styles['files-list']}>
                    {items.map((image) => (
                        <SortableArticleImage
                            image={image}
                            key={image.id}
                            id={image.id}
                            reorderUploadedImage={reorderUploadedImage}
                            removeUploadedImage={removeUploadedImage}
                        />
                    ))}
                </Row>
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const map = items.map((image) => image.id);
            const oldIndex = map.indexOf(active.id);
            const newIndex = map.indexOf(over.id);
            onDragEnd(arrayMove(items, oldIndex, newIndex));
        }
    }
};

export default SortableArticleImagesContainer;
