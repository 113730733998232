export const fields = {
    seller: 'Seller',
    isFeatured: 'Is featured',
    propertyId: 'Property ID',
    fpid: 'FPID',
    apn: 'APN',
    propertyType: 'Property Type',
    categories: 'Categories',
    saleType: 'Sales Type',
    purchasePrice: 'Purchase Price',
    processingFee: 'Processing Fee',
    financeTermYears: 'Finance Term: years',
    financeTermMonths: 'and months',
    rate: 'Interest Rate, %',
    monthlyPayment: 'Monthly Payment',
    yearlyTaxes: 'Yearly Taxes',
    title: 'Title',
    desc: 'Description',
    size: 'Property size (in acres)',
    zoning: 'Zoning',
    zoningDesc: 'Zoning Description',
    roadAccess: 'Road Access',
    subdivisionName: 'Name',
    subdivisionDues: 'Dues',
    urlField: 'URL field',
    urlDesc: 'Description of URL',
    fullAddressOrGeo: 'Geo coordinates',
    file: 'Choose your file',
    noFileDescription: 'Before you choose the file provide description',
    fileDesc: 'Description of file',
    city: 'City',
    address: 'Address',
    state: 'State',
    county: 'County',
    zip: 'Zip Code',
};

export const bsSize = 'sm';
