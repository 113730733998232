import { createRoutine } from 'redux-saga-routines';

const FETCH_SELLER_ACTION_PREFIX = 'FETCH_SELLER_ACTION';
export const fetchSellerAction = createRoutine(FETCH_SELLER_ACTION_PREFIX);

const FETCH_ALL_SELLERS_ACTION_PREFIX = 'FETCH_ALL_SELLERS_ACTION';
export const fetchAllSellersAction = createRoutine(FETCH_ALL_SELLERS_ACTION_PREFIX);

const FETCH_SELLER_FOR_PANEL_ACTION_PREFIX = 'FETCH_SELLER_FOR_PANEL_ACTION';
export const fetchSellerForPanelAction = createRoutine(FETCH_SELLER_FOR_PANEL_ACTION_PREFIX);

const UPDATE_SELLER_ACTION_PREFIX = 'UPDATE_SELLER_ACTION';
export const updateSellerAction = createRoutine(UPDATE_SELLER_ACTION_PREFIX);
