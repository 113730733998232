import AcreSpinner from 'components/Common/AcreSpinner';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchFeaturedPropertyCategoriesAction } from 'redux/actions/propertyCategoriesActions';
import { route } from 'utils/functions/routesHelper';
import routes from '../../../../../domain/routes';
import { PropTypes } from 'utils/wrappers';
import PopularCategories from './PopularCategories';

class PopularCategoriesContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        this.props.fetchFeaturedCategories();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.categories !== this.props.categories && this.props.categories !== undefined) {
            this.prepareCategoriesLink();
        }
    }

    /**
     * Prepare lands' categories links to format [name, link] by category's name and slug
     */
    prepareCategoriesLink = () => {
        const categoriesLinks = [];

        this.props.categories.forEach((category) => {
            if (category.slug === 'land-auctions') {
                return; // Skip land-auctions
            }
            categoriesLinks.push({
                name: category.name,
                imagePath: category.imagePath,
                url: route(routes.properties.category, {
                    slug: category.slug,
                }),
            });
        });

        this.setState({
            categories: categoriesLinks,
        });
    };

    render() {
        return this.props.areLoading ? (
            <AcreSpinner wrapped={true} />
        ) : (
            <PopularCategories categories={this.state.categories} />
        );
    }
}

PopularCategoriesContainer.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            imagePath: PropTypes.string.isRequired,
        }),
    ),
    areLoading: PropTypes.bool.isRequired,
    fetchFeaturedCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    categories: state.featuredPropertyCategories.data,
    areLoading: state.featuredPropertyCategories.areLoading,
});

const mapDispatchToProps = {
    fetchFeaturedCategories: fetchFeaturedPropertyCategoriesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopularCategoriesContainer);
