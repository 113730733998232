import { notifiFail } from 'components/Common/FloatingNotifications/notifi';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';
import { PropTypes } from 'utils/wrappers';
import styles from '../../styles/ImageDropZone.module.scss';

const MB_2_SIZE = 2048000;

const ImageDropZone = ({ addImageToUploaded }) => {
    const onDrop = (acceptedImages, rejectedImages) => {
        acceptedImages.forEach((image) => {
            addImageToUploaded(image);
        });

        let rejectedFilesNames = '';
        rejectedImages.forEach((file, i) => {
            rejectedFilesNames += file.name;

            if (i + 1 !== rejectedImages.length) {
                rejectedFilesNames += ', ';
            }
        });

        if (rejectedImages.length > 0) {
            notifiFail({
                message: 'These files are rejected: ' + rejectedFilesNames,
            });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ['image/jpg', 'image/gif', 'image/jpeg', 'image/png'],
        maxSize: MB_2_SIZE,
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={styles['drop-zone']}>
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <React.Fragment>
                        <p className={styles['bold']}>Attach Images</p>
                        <p>Drag 'n' drop some files here</p>
                        <Button type={'button'} color={'primary'}>
                            or click to select files
                        </Button>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

ImageDropZone.propTypes = {
    addImageToUploaded: PropTypes.func.isRequired,
};

export default ImageDropZone;
