import {
    isFavouritePropertyAction,
    onePropertyAction,
    TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION,
} from 'redux/actions/propertiesActions';
import { isNullOrEmptyObject } from 'utils/functions/objects';

const defaultState = {
    propertyData: {},
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case onePropertyAction.REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case onePropertyAction.SUCCESS:
            return {
                ...state,
                propertyData: action.payload,
            };
        case onePropertyAction.FULFILL:
            return {
                ...state,
                isLoading: false,
            };
        case TOGGLE_IS_FAVOURITE_PROPERTY_DONE_ACTION:
        case isFavouritePropertyAction.SUCCESS:
            const isFavourite = action.payload.isFavourite;

            // Property is not loaded? Do not add "is_favorite" property to empty object, because of error while trying
            // to mark any of property as favourite on list of properties (the Listings page)
            //
            // undefined is not an object (evaluating 'source.geo.address')
            // src/components/Common/CheckoutModal/CheckoutModalContainer.jsx:53
            //
            if (isNullOrEmptyObject(state.propertyData)) {
                return defaultState;
            }

            return {
                ...state,
                propertyData: {
                    ...state.propertyData,
                    is_favorite: isFavourite,
                },
            };
        default:
            return {
                ...state,
            };
    }
};
